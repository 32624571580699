import { Form } from 'react-bootstrap'
import AccordianCard from '../../../../../../accordion-card/accordian-card'
import {
  editItemInFieldArray,
  pushItemToFieldArray,
  removeItemFromFieldArray
} from '../../../helpers'
import '../../../pitch-deck-analysis.css'
import FormCellMultiInput from '../../form/form-cell-multi-input'
import ResultsSection from '../results-section'

const CreateEditMarketing = props => {
  props = {
    ...props,
    pushItemToFieldArray,
    removeItemFromFieldArray,
    editItemInFieldArray
  }

  return (
    <div className='ideacheckertabbody'>
      <div className='ideacheckerquestions'>
        <AccordianCard
          title='Go-to-market (distribution channel)'
          activeKey='goToMarket'
          className='companyaccordion'
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName='goToMarketStrategy'
                displayName='What is your go-to-market strategy to gain
                              visibility and expand distribution channels for
                              your product-or-service to acquire new customers?'
              />
              <FormCellMultiInput
                props={props}
                actualName='doesBusinessModelSupportAcquisition'
                displayName='Does your business model support customer
                              acquisition profitably?'
              />
              <FormCellMultiInput
                props={props}
                actualName='planningToAcquireCustomersThroughMarketing'
                displayName='Are you planning to acquire customers through
                              brand marketing or other online forms of
                              acquisition?'
                datatip='Explain how you are thinking about the costs of such activities relative to the lifetime value of a customer.'
              />
              <FormCellMultiInput
                props={props}
                actualName='howProductWillBeDistributed'
                displayName='How will your product-or-service be distributed to
                              the end user?'
              />
              <FormCellMultiInput
                props={props}
                actualName='distributionChannelsUsed'
                displayName='What distribution channels will you use?'
              />
              <FormCellMultiInput
                props={props}
                actualName='whenWhereWhyHowConsumersBuy'
                displayName='When, where, why, and how do consumers buy your
                              product-or-service?'
              />
              <FormCellMultiInput
                props={props}
                actualName='isProductTransactionOriented'
                displayName='Is it transaction oriented (where you have limited
                              communication with your customers and focus
                              instead on efficiency or speed, such as a
                              self-service kiosk at an airport terminal)?'
              />
              <FormCellMultiInput
                props={props}
                actualName='isBuyingProcessRelationshipOriented'
                displayName='Is the process of buying your product-or-service
                              relationship oriented (like a loyalty program
                              where you learn customer preferences and make
                              suggestions about other items of interest)?'
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title='Promotion'
          activeKey='promotion'
          style={{ marginTop: '10px' }}
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName='areYouPlanningToBuildDirectForce'
                displayName='Are you planning to build a direct, outside sales
                              force, and can the average selling price of your
                              product-or-service support this go-to-market
                              strategy?'
              />
              <FormCellMultiInput
                props={props}
                actualName='howToCommunicateWithConsumers'
                displayName='How will you communicate with consumers and make
                              them aware of your product-or-service?'
              />
              <FormCellMultiInput
                props={props}
                actualName='willUseWordOfMouth'
                displayName='Will you use word of mouth?'
              />
              <FormCellMultiInput
                props={props}
                actualName='willOptForSalesPromotions'
                displayName='Will you opt for sales promotions?'
              />
              <FormCellMultiInput
                props={props}
                actualName='doDirectSalesMakeSense'
                displayName='Do direct sales make the most sense?'
              />
              <FormCellMultiInput
                props={props}
                actualName='isTraditionalBetter'
                displayName='Is traditional advertising a better approach?'
              />
              <FormCellMultiInput
                props={props}
                actualName='doYouNeedToBuildBrandLoyalty'
                displayName="Do you need to build brand loyalty, or is your
                              product-or-service the only one that will fill
                              customers' needs?"
              />
            </Form.Group>
          </Form>
        </AccordianCard>
      </div>

      <ResultsSection props={props} type='marketing' />
    </div>
  )
}

export default CreateEditMarketing