import React, { useState, useReducer, useEffect } from 'react';
import NavBar from '../../../../layout/navbar/navbar';
import CreateEditIdeaUniqueness from './create-edit-idea-validation/idea-uniqueness';
import CreateEditBusinessViability from './create-edit-idea-validation/business-viability';
import './idea-validation.css';
import { Accordion, Col, Card, Form, Button, Container, Tabs, Tab, Spinner, Alert } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList, BsExclamationTriangle } from 'react-icons/bs';
import { useRealmApp } from '../../../../RealmApp';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const CreateIdeaValidation = () => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // get current user
    const { currentUser } = useRealmApp();

    // check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // realm functions declaration
    const {
        // saveMyIdea,
        saveMyIdeaTEST,
        checkIdeaHasResults } = currentUser.functions;

    const [ideaId, setIdeaId] = useState(null);

    // use history to navigate page
    const history = useHistory();

    const [state, dispatch] = useReducer(reducer, {

        ideaValidationId: "",
        ideaValidationName: "",

        // how unique is your idea
        idea: "",
        industry: "",
        categories: [],
        location: "",
        markets: [],
        entityType: [],
        stageOfDevelopment: "",
        businessModelType: [],
        defensibilityMoat: [],
        reach: 0,
        impact: 0,
        confidence: 0,
        ease: 0,

        // how viable is your business
        customerSegment: [""],
        customerPain: [""],
        marketingTiming: [""],
        customerGain: [""],
        competitors: [""],
        competitiveDifferentiation: [""],
        monetizationModel: [""],
        marketStrategy: [""],
        marketSegments: [""],
        earlyRevenue: [""],
        kpiMetrics: [""],
        financingStrategy: [""],
        businessValuation: [""],
        exitStrategy: [""],
        teamExperience: [""],
    });

    function reducer(state, action) {

        switch (action.type) {

            case 'update ideaValidationId': { return { ...state, ideaValidationId: action.value } }

            case 'update ideaValidationName': { return { ...state, ideaValidationName: action.value } }

            // how unique is your idea
            case 'update idea': { return { ...state, idea: action.value } }
            case 'update industry': { return { ...state, industry: action.value } }
            case 'update location': { return { ...state, location: action.value } }
            case 'update categories': return { ...state, categories: action.value }
            case 'update markets': return { ...state, markets: action.value }
            case 'update entityType': return { ...state, entityType: action.value }
            case 'update stageOfDevelopment': { return { ...state, stageOfDevelopment: action.value } }
            case 'update businessModelType': return { ...state, businessModelType: action.value }
            case 'update defensibilityMoat': return { ...state, defensibilityMoat: action.value }
            case 'update reach': { return { ...state, reach: action.value } }
            case 'update impact': { return { ...state, impact: action.value } }
            case 'update confidence': { return { ...state, confidence: action.value } }
            case 'update ease': { return { ...state, ease: action.value } }

            // how viable is your business
            case 'update customerSegment': {
                const index = action.index;
                const copiedList = [...state.customerSegment];
                copiedList[index] = action.value;
                return { ...state, customerSegment: copiedList }
            }

            case 'add customerSegment':
                return {
                    ...state,
                    customerSegment: [
                        ...state.customerSegment,
                        action.value
                    ]
                };

            case 'remove customerSegment': {
                const copiedList = [...state.customerSegment];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    customerSegment: copiedList
                }
            }

            case 'update customerPain': {
                const index = action.index;
                const copiedList = [...state.customerPain];
                copiedList[index] = action.value;
                return { ...state, customerPain: copiedList }
            }

            case 'add customerPain':
                return {
                    ...state,
                    customerPain: [
                        ...state.customerPain,
                        action.value
                    ]
                };

            case 'remove customerPain': {
                const copiedList = [...state.customerPain];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    customerPain: copiedList
                }
            }

            case 'update marketingTiming': {
                const index = action.index;
                const copiedList = [...state.marketingTiming];
                copiedList[index] = action.value;
                return { ...state, marketingTiming: copiedList }
            }

            case 'add marketingTiming':
                return {
                    ...state,
                    marketingTiming: [
                        ...state.marketingTiming,
                        action.value
                    ]
                };

            case 'remove marketingTiming': {
                const copiedList = [...state.marketingTiming];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    marketingTiming: copiedList
                }
            }

            case 'update customerGain': {
                const index = action.index;
                const copiedList = [...state.customerGain];
                copiedList[index] = action.value;
                return { ...state, customerGain: copiedList }
            }

            case 'add customerGain':
                return {
                    ...state,
                    customerGain: [
                        ...state.customerGain,
                        action.value
                    ]
                };

            case 'remove customerGain': {
                const copiedList = [...state.customerGain];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    customerGain: copiedList
                }
            }

            case 'update competitors': {
                const index = action.index;
                const copiedList = [...state.competitors];
                copiedList[index] = action.value;
                return { ...state, competitors: copiedList }
            }

            case 'add competitors':
                return {
                    ...state,
                    competitors: [
                        ...state.competitors,
                        action.value
                    ]
                };

            case 'remove competitors': {
                const copiedList = [...state.competitors];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    competitors: copiedList
                }
            }

            case 'update competitiveDifferentiation': {
                const index = action.index;
                const copiedList = [...state.competitiveDifferentiation];
                copiedList[index] = action.value;
                return { ...state, competitiveDifferentiation: copiedList }
            }

            case 'add competitiveDifferentiation':
                return {
                    ...state,
                    competitiveDifferentiation: [
                        ...state.competitiveDifferentiation,
                        action.value
                    ]
                };

            case 'remove competitiveDifferentiation': {
                const copiedList = [...state.competitiveDifferentiation];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    competitiveDifferentiation: copiedList
                }
            }

            case 'update monetizationModel': {
                const index = action.index;
                const copiedList = [...state.monetizationModel];
                copiedList[index] = action.value;
                return { ...state, monetizationModel: copiedList }
            }

            case 'add monetizationModel':
                return {
                    ...state,
                    monetizationModel: [
                        ...state.monetizationModel,
                        action.value
                    ]
                };

            case 'remove monetizationModel': {
                const copiedList = [...state.monetizationModel];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    monetizationModel: copiedList
                }
            }

            case 'update marketStrategy': {
                const index = action.index;
                const copiedList = [...state.marketStrategy];
                copiedList[index] = action.value;
                return { ...state, marketStrategy: copiedList }
            }

            case 'add marketStrategy':
                return {
                    ...state,
                    marketStrategy: [
                        ...state.marketStrategy,
                        action.value
                    ]
                };

            case 'remove marketStrategy': {
                const copiedList = [...state.marketStrategy];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    marketStrategy: copiedList
                }
            }

            case 'update marketSegments': {
                const index = action.index;
                const copiedList = [...state.marketSegments];
                copiedList[index] = action.value;
                return { ...state, marketSegments: copiedList }
            }

            case 'add marketSegments':
                return {
                    ...state,
                    marketSegments: [
                        ...state.marketSegments,
                        action.value
                    ]
                };

            case 'remove marketSegments': {
                const copiedList = [...state.marketSegments];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    marketSegments: copiedList
                }
            }

            case 'update earlyRevenue': {
                const index = action.index;
                const copiedList = [...state.earlyRevenue];
                copiedList[index] = action.value;
                return { ...state, earlyRevenue: copiedList }
            }

            case 'add earlyRevenue':
                return {
                    ...state,
                    earlyRevenue: [
                        ...state.earlyRevenue,
                        action.value
                    ]
                };

            case 'remove earlyRevenue': {
                const copiedList = [...state.earlyRevenue];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    earlyRevenue: copiedList
                }
            }

            case 'update kpiMetrics': {
                const index = action.index;
                const copiedList = [...state.kpiMetrics];
                copiedList[index] = action.value;
                return { ...state, kpiMetrics: copiedList }
            }

            case 'add kpiMetrics':
                return {
                    ...state,
                    kpiMetrics: [
                        ...state.kpiMetrics,
                        action.value
                    ]
                };

            case 'remove kpiMetrics': {
                const copiedList = [...state.kpiMetrics];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    kpiMetrics: copiedList
                }
            }

            case 'update financingStrategy': {
                const index = action.index;
                const copiedList = [...state.financingStrategy];
                copiedList[index] = action.value;
                return { ...state, financingStrategy: copiedList }
            }

            case 'add financingStrategy':
                return {
                    ...state,
                    financingStrategy: [
                        ...state.financingStrategy,
                        action.value
                    ]
                };

            case 'remove financingStrategy': {
                const copiedList = [...state.financingStrategy];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    financingStrategy: copiedList
                }
            }

            case 'update businessValuation': {
                const index = action.index;
                const copiedList = [...state.businessValuation];
                copiedList[index] = action.value;
                return { ...state, businessValuation: copiedList }
            }

            case 'add businessValuation':
                return {
                    ...state,
                    businessValuation: [
                        ...state.businessValuation,
                        action.value
                    ]
                };

            case 'remove businessValuation': {
                const copiedList = [...state.businessValuation];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    businessValuation: copiedList
                }
            }

            case 'update exitStrategy': {
                const index = action.index;
                const copiedList = [...state.exitStrategy];
                copiedList[index] = action.value;
                return { ...state, exitStrategy: copiedList }
            }

            case 'add exitStrategy':
                return {
                    ...state,
                    exitStrategy: [
                        ...state.exitStrategy,
                        action.value
                    ]
                };

            case 'remove exitStrategy': {
                const copiedList = [...state.exitStrategy];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    exitStrategy: copiedList
                }
            }

            case 'update teamExperience': {
                const index = action.index;
                const copiedList = [...state.teamExperience];
                copiedList[index] = action.value;
                return { ...state, teamExperience: copiedList }
            }

            case 'add teamExperience':
                return {
                    ...state,
                    teamExperience: [
                        ...state.teamExperience,
                        action.value
                    ]
                };

            case 'remove teamExperience': {
                const copiedList = [...state.teamExperience];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    teamExperience: copiedList
                }
            }

            // Results
            case 'update analysisResult': { return { ...state, analysisResult: action.value } }

            default: { return 'default'; }
        }
    }

    // check if idea has results
    async function checkIdeaWasAnalyzed(idea) {

        try {

            const response = await checkIdeaHasResults({ ideaId: idea });

            // console.log("checkIdeaWasAnalyzed() response: ", response);

            if (response === true) {
                // console.log("You can view results at http://localhost:3000/founders/idea-validation/view/" + idea);

                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                    <p style={{ marginLeft: "28px" }}>Your results are ready. <Link to={`/founders/idea-validation/view/${idea}`} target="_blank" rel="noopener noreferrer" className="alert-cta">View results</Link></p>
                </div>);

                setIdeaId(null);
            }

        } catch (error) {

            // console.error("Failed to confirm: ", error);
        }
    }

    // Look for results every 10 seconds if ideaId is truthy
    useEffect(() => {
        const interval = setInterval(() => {

            // console.log("Activate checkIdeaHasResults if IdeaId exists. Idea: " + ideaId)
            if (ideaId) {
                checkIdeaWasAnalyzed(ideaId);
            }

        }, 10000);

        return () => clearInterval(interval);

    }, [ideaId]);

    // submit function
    async function handleSubmit() {
        if (state.ideaValidationName === "") {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>New idea must be named before analyzing.</p>
            </div>);
        }
        else {
            setAlert(true);
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Analyzing</p>
                    <p style={{ marginLeft: "28px" }}>Our real-time analytics might take up to 1 min to compute and show results.</p>
                    <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            try {
                // To-do: Handle result
                if (state.idea && state.industry && (state.categories.length > 0) && (state.markets.length > 0)) {
                    let response = await saveMyIdeaTEST(state); // Expecting the idea's ID                    

                    dispatch({ 'type': 'update ideaValidationId', 'value': response });

                    setIdeaId(response);
                }

            } catch (error) {

                const errorMessage = error;

                switch (errorMessage) {
                    case "Maximum attempts exceeded for Free account.":
                        setAlert(true);
                        setMessage(<div style={{ paddingTop: "10px" }}>
                            <p style={{ color: "red" }}><BsExclamationTriangle className="alert-icon" /> Free trial is over</p>
                            <p style={{ marginLeft: "28px" }}>Sorry, you've exceeded 3 analyses per day for your Freemium Plan. Try again tomorrow or unlock unlimited usage with our subscription plans. <a href="https://www.roiquant.com/pricing" target="_blank" rel="noopener noreferrer">Compare plans now</a>.</p>
                        </div>);
                        break;

                    case "Failed to save idea.":
                        setAlert(true);
                        setMessage(<div style={{ paddingTop: "10px" }}>
                            <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                            <p style={{ marginLeft: "28px" }}>An unknown error has occurred with no exception details. Please try again.</p>
                        </div>);
                        break;

                    default:
                        // TODO: Ask Paul for appropriate Alert message
                        setAlert(true);
                        setMessage(<div style={{ paddingTop: "10px" }}>
                            <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                            <p style={{ marginLeft: "28px" }}>An unknown error has occurred with no exception details. Please try again.</p>
                        </div>);
                }

                setIdeaId(null);
            }
        }
    }

    function handleClose() {
        // send user to idea validation landing page
        history.replace("/tools/founders/idea-validation");
    }

    return (

        <>
            <NavBar />

            <div id="ideachecker">

                {/* loading alert */}
                {alert
                    ? (<Alert className="error-message floating-alert" variant="light">
                        <div>
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                            {message}
                        </div>
                    </Alert>)
                    : ""
                }

                <Container className="col-11" style={{ paddingBottom: "200px" }}>

                    <div className="pitchdeckheaderdiv">
                        <div className="pitchdecktitlediv">
                            <br />
                            <span className="pitchdeckcreatetext">
                                Title
                            </span>
                            <br />
                            <Form>
                                <Form.Group>
                                    <Form.Control
                                        placeholder="Give this idea a name"
                                        className="pitchdecktitlefield"
                                        value={state.ideaValidationName}
                                        onChange={(e) => { dispatch({ 'type': 'update ideaValidationName', 'value': e.target.value }) }}
                                    />
                                </Form.Group>
                            </Form>
                        </div>

                        <div className="pitchdeckstatsdiv">
                            <Button onClick={() => handleSubmit()} className="pitchdeckanalysebutton glintanimation">
                                <span>Analyze</span>
                            </Button>

                            <button onClick={() => handleClose()} className="pitchdeckclosebutton">
                                <span>Close</span>
                            </button>

                            <FaEllipsisH style={{ marginLeft: "20px" }} />
                        </div>
                    </div>

                    <div>
                        <Tabs className="companytabs" defaultActiveKey="assumption">

                            <Tab eventKey="assumption" title="Assumption">

                                <div className="ideacheckertabbody">
                                    <Col className="ideacheckerquestions">
                                        <Accordion className="companyaccordion" defaultActiveKey="unique">
                                            <CreateEditIdeaUniqueness state={state} setState={dispatch} />
                                        </Accordion>

                                        <Accordion defaultActiveKey="viable" style={{ marginTop: "10px" }}>
                                            <CreateEditBusinessViability state={state} setState={dispatch} />
                                        </Accordion>
                                    </Col>

                                    <Col className="ideacheckerresults">
                                        <Accordion className="companyaccordion" defaultActiveKey="notification">
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="notification">
                                                    <div className="companytitlecard">
                                                        Notification
                                                    </div>

                                                    <div className="companyarrowiconright">
                                                        <FaChevronDown className="arrow-icon" />
                                                    </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="notification">
                                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                                        <span className="info-notes" style={{ fontSize: "16px" }}>
                                                            Note:
                                                            <ul style={{ paddingLeft: "17px" }}>
                                                                <li id="notes-points">
                                                                    Answer as many questions as you can on <span style={{ fontWeight: "bold" }}>idea uniqueness</span> and <span style={{ fontWeight: "bold" }}>business viability</span>.
                                                                </li>
                                                                <li id="notes-points">
                                                                    Due to large datasets, it may take up to 1 min to compute and show results.
                                                                </li>
                                                                <li id="notes-points">
                                                                    We use advanced real-time analytics and text analytics to analyze your inputs.
                                                                </li>
                                                                <li id="notes-points">
                                                                    Moreover, we analyze your inputs with large amount of entities' data (always increasing).
                                                                </li>
                                                                <li id="notes-points">
                                                                    Results are limited to only 50 companies for freemium users, while unlimited companies are shown to paying customers.
                                                                </li>
                                                                <li id="notes-points">
                                                                    <a href="https://viewpoints.roiquant.com/disclaimer/" target="_blank" rel="noopener noreferrer">Disclaimer</a>: Our idea checker is subject to continuous research and development to improve its accuracy and analytics system.
                                                                </li>
                                                            </ul>
                                                        </span>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Col>
                                </div>
                            </Tab>

                            <Tab eventKey="modelling" title="Modelling" disabled>

                            </Tab>

                        </Tabs>
                    </div>
                </Container>
            </div>
        </>

    )

}

export default CreateIdeaValidation;