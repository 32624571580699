import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import '../tools/founders/pitch-deck-analysis/pitch-deck-analysis.css';
import NavBar from '../../layout/navbar/navbar';
import AddEditBusiness from './add-edit-data-contribution/business';
import AddEditFinancials from './add-edit-data-contribution/financials';
import AddEditGeneralInformation from './add-edit-data-contribution/general-information';
import AddEditManagement from './add-edit-data-contribution/management';
import DataRoomComponent from '../data-room/data-room-component';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import { FaEllipsisH } from 'react-icons/fa';
import { useRealmApp } from '../../RealmApp';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { AiOutlineCheckSquare, AiOutlineCloseCircle } from 'react-icons/ai'
import { BiAnalyse } from 'react-icons/bi';

const AddDataContribution = () => {

    // get current user
    const { currentUser } = useRealmApp();

    // realm functions declaration
    const { saveDataContribution, generateAddEditFormId } = currentUser.functions;

    // use history to navigate page
    const history = useHistory();

    const [state, dispatch] = useReducer(reducer, {

        dataContributionId: "",
        dataContributionName: "",

        // general information tab
        profileType: "",
        entityType: "",
        investorTypes: [], // multi-select -1
        entityName: "",
        legalName: "",
        formerLegalName: [""], // add/remove
        currentWork: [{ currentDesignation: "", currentOrganization: "", shortBiography: "", longBiography: "" }], // group
        dateOfBirth: "",
        city: "",
        state: "",
        country: "",
        industry: "",
        categories: [], // multi-select 5
        education: [""], // add/remove
        previousProfileType: "",
        previousWork: [{ previousDesignation: "", previousOrganization: "", previousShortBiography: "", previousLongBiography: "" }], // group
        previousCity: "",
        previousState: "",
        previousCountry: "",
        shortDescription: "",
        longDescription: "",
        ownershipStatus: "",
        operationalStatus: "",
        foundedDate: "",
        operationalDuration: "",
        shutdownDate: "",
        tickerSymbol: "",
        email: "",
        telephone: "",
        website: "",
        blog: "",
        socialMedia: [""], // add/remove
        gender: "",
        firstTimeFounder: "",
        technicalFounder: "",

        // business tab
        shortInvestmentThesis: "",
        longInvestmentThesis: "",
        stageOfDevelopment: "",
        transactionalModelType: [], // multi-select -1
        defensibilityMoats: [], // multi-select 5
        assetsUnderManagement: "",
        investmentRange: "",
        sweetSpot: "",
        preferredInvestmentStages: [], // multi-select -1
        preferredInvestmentFocus: [], // multi-select -1
        preferredInvestmentGeography: [], // multi-select -1
        fundProduct: [{ fundProductName: "", fundProductShortDescription: "", fundProductLongDescription: "" }], // group
        product: [{ productName: "", productProprietorship: "", productShortDescription: "", productLongDescription: "" }], // group
        supportSustainableDevelopmentGoals: [], // multi-select -1
        markets: [], // multi-select 5
        totalAddressableMarket: "",
        serviceableAvailableMarket: "",
        serviceableObtainableMarket: "",
        customerPain: [""], // add/remove
        competitiveDifferentiation: [""], // add/remove
        directCompetitors: [""], // add/remove

        // financials tab
        numOfFunds: "",
        funds: [""], // add/remove
        numOfLimitedPartners: "",
        limitedPartners: [""], // add/remove
        lastFinancingDate: "",
        lastFinancingRound: "",
        financialViability: "",
        financingType: "",
        fundraisingTargetAmount: "",
        totalAmountRaised: "",
        remainingTargetAmount: "",
        numOfFundingRounds: "",
        postMoneyValuation: "",
        totalRevenue: "",
        marketCapitalization: "",
        numOfInvestors: "",
        fundingRounds: [{ fundingType: "", announcedDate: "", closedOnDate: "", targetFundingAmount: "", totalFundingAmount: "", remainingTargetAmount: "", investors: "" }], // group
        portfolioCompanies: [""], // add/remove
        numOfInvestments: "",
        numOfLeadInvestments: "",
        numOfCoInvestments: "",
        numOfFollowOnInvestments: "",
        numOfFailedInvestments: "",
        numOfExits: "",
        numOfAcquisitions: "",
        numOfBoardSeatsTaken: "",

        // management tab
        management: [""], // add/remove
        numOfPartners: "",
        partners: [""], // add/remove
        numOfEmployees: "",
        numOfBoardMembers: "",
        boardMembers: [""], // add/remove
        managementEmail: "",
        managementMobilePhone: "",
        managementWebsite: "",
        managementBlog: "",
        managementSocialMedia: [""] // add/remove
    });

    function reducer(state, action) {

        switch (action.type) {

            case 'update dataContributionId': { return { ...state, dataContributionId: action.value } }
            case 'update dataContributionName': { return { ...state, dataContributionName: action.value } }

            // general information tab - update (normal, multi-select)
            case 'update profileType': { return { ...state, profileType: action.value } }
            case 'update entityType': { return { ...state, entityType: action.value } }
            case 'update investorTypes': return { ...state, investorTypes: action.value } // multi-select -1
            case 'update entityName': { return { ...state, entityName: action.value } }
            case 'update legalName': { return { ...state, legalName: action.value } }
            case 'update dateOfBirth': { return { ...state, dateOfBirth: action.value } }
            case 'update gender': { return { ...state, gender: action.value } }
            case 'update firstTimeFounder': { return { ...state, firstTimeFounder: action.value } }
            case 'update technicalFounder': { return { ...state, technicalFounder: action.value } }
            case 'update city': { return { ...state, city: action.value } }
            case 'update state': { return { ...state, state: action.value } }
            case 'update country': { return { ...state, country: action.value } }
            case 'update industry': { return { ...state, industry: action.value } }
            case 'update categories': return { ...state, categories: action.value } // multi-select 5
            case 'update previousProfileType': { return { ...state, previousProfileType: action.value } }
            case 'update previousCity': { return { ...state, previousCity: action.value } }
            case 'update previousState': { return { ...state, previousState: action.value } }
            case 'update previousCountry': { return { ...state, previousCountry: action.value } }
            case 'update shortDescription': { return { ...state, shortDescription: action.value } }
            case 'update longDescription': { return { ...state, longDescription: action.value } }
            case 'update ownershipStatus': { return { ...state, ownershipStatus: action.value } }
            case 'update operationalStatus': { return { ...state, operationalStatus: action.value } }
            case 'update foundedDate': { return { ...state, foundedDate: action.value } }
            case 'update operationalDuration': { return { ...state, operationalDuration: action.value } }
            case 'update shutdownDate': { return { ...state, shutdownDate: action.value } }
            case 'update tickerSymbol': { return { ...state, tickerSymbol: action.value } }
            case 'update email': { return { ...state, email: action.value } }
            case 'update telephone': { return { ...state, telephone: action.value } }
            case 'update website': { return { ...state, website: action.value } }
            case 'update blog': { return { ...state, blog: action.value } }

            // general information tab - update
            case 'update formerLegalName': {
                const index = action.index;
                const copiedList = [...state.formerLegalName];
                copiedList[index] = action.value;
                return { ...state, formerLegalName: copiedList }
            }
            case 'update currentWork': {
                const index = action.index;
                const copiedList = [...state.currentWork];

                const copiedElement = { ...state.currentWork[index] };

                copiedElement[action.value.key] = action.value.value;

                // At position index, remove 1 items
                copiedList.splice(index, 1);

                // At position index, add new element
                copiedList.splice(index, 0, copiedElement);

                return { ...state, currentWork: copiedList }
            }
            case 'update previousWork': {
                const index = action.index;
                const copiedList = [...state.previousWork];

                const copiedElement = { ...state.previousWork[index] };

                copiedElement[action.value.key] = action.value.value;

                // At position index, remove 1 items
                copiedList.splice(index, 1);

                // At position index, add new element
                copiedList.splice(index, 0, copiedElement);

                return { ...state, previousWork: copiedList }
            }
            case 'update education': {
                const index = action.index;
                const copiedList = [...state.education];
                copiedList[index] = action.value;
                return { ...state, education: copiedList }
            }
            case 'update socialMedia': {
                const index = action.index;
                const copiedList = [...state.socialMedia];
                copiedList[index] = action.value;
                return { ...state, socialMedia: copiedList }
            }

            // general information tab - add
            case 'add formerLegalName':
                return {
                    ...state,
                    formerLegalName: [
                        ...state.formerLegalName,
                        action.value
                    ]
                };
            case 'add currentWork':
                return {
                    ...state,
                    currentWork: [
                        ...state.currentWork,
                        action.value
                    ]
                };
            case 'add previousWork':
                return {
                    ...state,
                    previousWork: [
                        ...state.previousWork,
                        action.value
                    ]
                };
            case 'add education':
                return {
                    ...state,
                    education: [
                        ...state.education,
                        action.value
                    ]
                };
            case 'add socialMedia':
                return {
                    ...state,
                    socialMedia: [
                        ...state.socialMedia,
                        action.value
                    ]
                };

            // general information tab - remove
            case 'remove formerLegalName': {
                const copiedList = [...state.formerLegalName];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    formerLegalName: copiedList
                }
            }
            case 'remove currentWork': {
                const copiedList = [...state.currentWork];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    currentWork: copiedList
                }
            }
            case 'remove previousWork': {
                const copiedList = [...state.previousWork];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    previousWork: copiedList
                }
            }
            case 'remove education': {
                const copiedList = [...state.education];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    education: copiedList
                }
            }
            case 'remove socialMedia': {
                const copiedList = [...state.socialMedia];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    socialMedia: copiedList
                }
            }

            // business tab - update (normal, multi-select)
            case 'update shortInvestmentThesis': { return { ...state, shortInvestmentThesis: action.value } }
            case 'update longInvestmentThesis': { return { ...state, longInvestmentThesis: action.value } }
            case 'update stageOfDevelopment': { return { ...state, stageOfDevelopment: action.value } }
            case 'update transactionalModelType': return { ...state, transactionalModelType: action.value } // multi-select -1
            case 'update defensibilityMoats': return { ...state, defensibilityMoats: action.value } // multi-select 5
            case 'update assetsUnderManagement': { return { ...state, assetsUnderManagement: action.value } }
            case 'update investmentRange': { return { ...state, investmentRange: action.value } }
            case 'update sweetSpot': { return { ...state, sweetSpot: action.value } }
            case 'update preferredInvestmentStages': return { ...state, preferredInvestmentStages: action.value } // multi-select -1
            case 'update preferredInvestmentFocus': return { ...state, preferredInvestmentFocus: action.value } // multi-select -1
            case 'update preferredInvestmentGeography': return { ...state, preferredInvestmentGeography: action.value } // multi-select -1
            case 'update supportSustainableDevelopmentGoals': return { ...state, supportSustainableDevelopmentGoals: action.value } // multi-select -1
            case 'update markets': return { ...state, markets: action.value } // multi-select 5
            case 'update totalAddressableMarket': { return { ...state, totalAddressableMarket: action.value } }
            case 'update serviceableAvailableMarket': { return { ...state, serviceableAvailableMarket: action.value } }
            case 'update serviceableObtainableMarket': { return { ...state, serviceableObtainableMarket: action.value } }

            // business tab - update
            case 'update fundProduct': {
                const index = action.index;
                const copiedList = [...state.fundProduct];

                const copiedElement = { ...state.fundProduct[index] };

                copiedElement[action.value.key] = action.value.value;

                // At position index, remove 1 items
                copiedList.splice(index, 1);

                // At position index, add new element
                copiedList.splice(index, 0, copiedElement);

                return { ...state, fundProduct: copiedList }
            }
            case 'update product': {
                const index = action.index;
                const copiedList = [...state.product];

                const copiedElement = { ...state.product[index] };

                copiedElement[action.value.key] = action.value.value;

                // At position index, remove 1 items
                copiedList.splice(index, 1);

                // At position index, add new element
                copiedList.splice(index, 0, copiedElement);

                return { ...state, product: copiedList }
            }
            case 'update customerPain': {
                const index = action.index;
                const copiedList = [...state.customerPain];
                copiedList[index] = action.value;
                return { ...state, customerPain: copiedList }
            }
            case 'update competitiveDifferentiation': {
                const index = action.index;
                const copiedList = [...state.competitiveDifferentiation];
                copiedList[index] = action.value;
                return { ...state, competitiveDifferentiation: copiedList }
            }
            case 'update directCompetitors': {
                const index = action.index;
                const copiedList = [...state.directCompetitors];
                copiedList[index] = action.value;
                return { ...state, directCompetitors: copiedList }
            }

            // business tab - add
            case 'add fundProduct':
                return {
                    ...state,
                    fundProduct: [
                        ...state.fundProduct,
                        action.value
                    ]
                };
            case 'add product':
                return {
                    ...state,
                    product: [
                        ...state.product,
                        action.value
                    ]
                };
            case 'add customerPain':
                return {
                    ...state,
                    customerPain: [
                        ...state.customerPain,
                        action.value
                    ]
                };
            case 'add competitiveDifferentiation':
                return {
                    ...state,
                    competitiveDifferentiation: [
                        ...state.competitiveDifferentiation,
                        action.value
                    ]
                };
            case 'add directCompetitors':
                return {
                    ...state,
                    directCompetitors: [
                        ...state.directCompetitors,
                        action.value
                    ]
                };

            // business tab - remove
            case 'remove fundProduct': {
                const copiedList = [...state.fundProduct];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    fundProduct: copiedList
                }
            }
            case 'remove product': {
                const copiedList = [...state.product];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    product: copiedList
                }
            }
            case 'remove customerPain': {
                const copiedList = [...state.customerPain];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    customerPain: copiedList
                }
            }
            case 'remove competitiveDifferentiation': {
                const copiedList = [...state.competitiveDifferentiation];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    competitiveDifferentiation: copiedList
                }
            }
            case 'remove directCompetitors': {
                const copiedList = [...state.directCompetitors];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    directCompetitors: copiedList
                }
            }

            // financials tab - update (normal, multi-select)
            case 'update numOfFunds': { return { ...state, numOfFunds: action.value } }
            case 'update numOfLimitedPartners': { return { ...state, numOfLimitedPartners: action.value } }
            case 'update lastFinancingDate': { return { ...state, lastFinancingDate: action.value } }
            case 'update lastFinancingRound': { return { ...state, lastFinancingRound: action.value } }
            case 'update financialViability': { return { ...state, financialViability: action.value } }
            case 'update financingType': { return { ...state, financingType: action.value } }
            case 'update fundraisingTargetAmount': { return { ...state, fundraisingTargetAmount: action.value } }
            case 'update totalAmountRaised': { return { ...state, totalAmountRaised: action.value } }
            case 'update remainingTargetAmount': { return { ...state, remainingTargetAmount: action.value } }
            case 'update numOfFundingRounds': { return { ...state, numOfFundingRounds: action.value } }
            case 'update postMoneyValuation': { return { ...state, postMoneyValuation: action.value } }
            case 'update totalRevenue': { return { ...state, totalRevenue: action.value } }
            case 'update marketCapitalization': { return { ...state, marketCapitalization: action.value } }
            case 'update numOfInvestors': { return { ...state, numOfInvestors: action.value } }
            case 'update numOfInvestments': { return { ...state, numOfInvestments: action.value } }
            case 'update numOfLeadInvestments': { return { ...state, numOfLeadInvestments: action.value } }
            case 'update numOfCoInvestments': { return { ...state, numOfCoInvestments: action.value } }
            case 'update numOfFollowOnInvestments': { return { ...state, numOfFollowOnInvestments: action.value } }
            case 'update numOfFailedInvestments': { return { ...state, numOfFailedInvestments: action.value } }
            case 'update numOfExits': { return { ...state, numOfExits: action.value } }
            case 'update numOfAcquisitions': { return { ...state, numOfAcquisitions: action.value } }
            case 'update numOfBoardSeatsTaken': { return { ...state, numOfBoardSeatsTaken: action.value } }

            // financials tab - update
            case 'update funds': {
                const index = action.index;
                const copiedList = [...state.funds];
                copiedList[index] = action.value;
                return { ...state, funds: copiedList }
            }
            case 'update limitedPartners': {
                const index = action.index;
                const copiedList = [...state.limitedPartners];
                copiedList[index] = action.value;
                return { ...state, limitedPartners: copiedList }
            }
            case 'update fundingRounds': {
                const index = action.index;
                const copiedList = [...state.fundingRounds];

                const copiedElement = { ...state.fundingRounds[index] };

                copiedElement[action.value.key] = action.value.value;

                // At position index, remove 1 items
                copiedList.splice(index, 1);

                // At position index, add new element
                copiedList.splice(index, 0, copiedElement);

                return { ...state, fundingRounds: copiedList }
            }
            case 'update portfolioCompanies': {
                const index = action.index;
                const copiedList = [...state.portfolioCompanies];
                copiedList[index] = action.value;
                return { ...state, portfolioCompanies: copiedList }
            }

            // financials tab - add
            case 'add funds':
                return {
                    ...state,
                    funds: [
                        ...state.funds,
                        action.value
                    ]
                };
            case 'add limitedPartners':
                return {
                    ...state,
                    limitedPartners: [
                        ...state.limitedPartners,
                        action.value
                    ]
                };
            case 'add fundingRounds':
                return {
                    ...state,
                    fundingRounds: [
                        ...state.fundingRounds,
                        action.value
                    ]
                };
            case 'add portfolioCompanies':
                return {
                    ...state,
                    portfolioCompanies: [
                        ...state.portfolioCompanies,
                        action.value
                    ]
                };

            // financials tab - remove
            case 'remove funds': {
                const copiedList = [...state.funds];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    funds: copiedList
                }
            }
            case 'remove limitedPartners': {
                const copiedList = [...state.limitedPartners];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    limitedPartners: copiedList
                }
            }
            case 'remove fundingRounds': {
                const copiedList = [...state.fundingRounds];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    fundingRounds: copiedList
                }
            }
            case 'remove portfolioCompanies': {
                const copiedList = [...state.portfolioCompanies];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    portfolioCompanies: copiedList
                }
            }

            // management tab - update (normal, multi-select)
            case 'update numOfPartners': { return { ...state, numOfPartners: action.value } }
            case 'update numOfEmployees': { return { ...state, numOfEmployees: action.value } }
            case 'update numOfBoardMembers': { return { ...state, numOfBoardMembers: action.value } }
            case 'update managementEmail': { return { ...state, managementEmail: action.value } }
            case 'update managementMobilePhone': { return { ...state, managementMobilePhone: action.value } }
            case 'update managementWebsite': { return { ...state, managementWebsite: action.value } }
            case 'update managementBlog': { return { ...state, managementBlog: action.value } }

            // management tab - update
            case 'update management': {
                const index = action.index;
                const copiedList = [...state.management];
                copiedList[index] = action.value;
                return { ...state, management: copiedList }
            }
            case 'update partners': {
                const index = action.index;
                const copiedList = [...state.partners];
                copiedList[index] = action.value;
                return { ...state, partners: copiedList }
            }
            case 'update boardMembers': {
                const index = action.index;
                const copiedList = [...state.boardMembers];
                copiedList[index] = action.value;
                return { ...state, boardMembers: copiedList }
            }
            case 'update managementSocialMedia': {
                const index = action.index;
                const copiedList = [...state.managementSocialMedia];
                copiedList[index] = action.value;
                return { ...state, managementSocialMedia: copiedList }
            }

            // management tab - add
            case 'add management':
                return {
                    ...state,
                    management: [
                        ...state.management,
                        action.value
                    ]
                };
            case 'add partners':
                return {
                    ...state,
                    partners: [
                        ...state.partners,
                        action.value
                    ]
                };
            case 'add boardMembers':
                return {
                    ...state,
                    boardMembers: [
                        ...state.boardMembers,
                        action.value
                    ]
                };
            case 'add managementSocialMedia':
                return {
                    ...state,
                    managementSocialMedia: [
                        ...state.managementSocialMedia,
                        action.value
                    ]
                };

            // management tab - remove
            case 'remove management': {
                const copiedList = [...state.management];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    management: copiedList
                }
            }
            case 'remove partners': {
                const copiedList = [...state.partners];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    partners: copiedList
                }
            }
            case 'remove boardMembers': {
                const copiedList = [...state.boardMembers];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    boardMembers: copiedList
                }
            }
            case 'remove managementSocialMedia': {
                const copiedList = [...state.managementSocialMedia];
                copiedList.splice(action.index, 1);
                return {
                    ...state,
                    managementSocialMedia: copiedList
                }
            }

            default: { return 'default'; }
        }
    }

    // realm function to generate id
    useEffect(() => {
        if (!state.dataContributionId) {
            async function getDataContributionId() {
                const id = await generateAddEditFormId();
                dispatch({ type: 'update dataContributionId', value: id });
            }
            try {
                getDataContributionId();
            } catch (err) {
                // console.error("unable to generate unique ID");
            }
        }
    }, [state.dataContributionId, generateAddEditFormId]);

    const [alert, setAlert] = useState(false);

    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    const [message, setMessage] = useState("");

    // handler on enter key pressed when saving project name
    const handleEnterSave = async (e) => {

        if (e.code === "Enter" || e.code === "NumpadEnter") {
            e.preventDefault()
            handleSave();
        }
    }
    
    // save function
    async function handleSave() {
        if (state.dataContributionName === "") {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>New profile must be named before saving and/or adding.</p>
            </div>);
        }
        else {
            setAlert(true);
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Saving</p>
                    <p style={{ marginLeft: "28px" }}>We'll notify you once your updated work is saved.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            // save data
            try {
                await saveDataContribution(state);

                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#5aa469" }}><AiOutlineCheckSquare className="alert-icon" /> Saved</p>
                    <p style={{ marginLeft: "28px" }}>Your profile is saved and updated. <Link to={`/profile/view/${state.dataContributionId}`} target="_blank" rel="noopener noreferrer" className="alert-cta">View profile</Link></p>
                </div>);

            } catch (err) {
                // console.error("could not save data. reason:", err);

                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>We failed to save your updated work. Please try again.</p>
                </div>);
            }
        }
    }

    function handleClose() {
        // send user to data contribution landing page
        history.replace("/profile-management");
    }

    return (

        <>
            <NavBar />

            <div id="ideachecker">

                {alert
                    ? (<Alert className="error-message floating-alert" variant="light">
                        <div>
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                            {message}
                        </div>
                    </Alert>)
                    : ""
                }

                <Container className="col-11" style={{ paddingBottom: "200px" }}>
                    <div className="pitchdeckheaderdiv">
                        <div className="pitchdecktitlediv">
                            <br />
                            <span className="pitchdeckcreatetext">
                                Add new profile
                                <sup data-tip="Contribute information to offer greater context and derive actionable insights.">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </span>
                            <br />
                            <Form>
                                <Form.Group>
                                    <Form.Control
                                        placeholder="Enter profile name"
                                        className="pitchdecktitlefield"
                                        onChange={(e) => { dispatch({ "type": "update dataContributionName", "value": e.target.value }) }}
                                        onKeyDown={(e)=> handleEnterSave(e)}
                                    />
                                </Form.Group>
                            </Form>
                        </div>

                        <div className="pitchdeckstatsdiv">
                            <button onClick={() => handleSave()} className="pitchdecksavebutton">
                                <span>Add</span>
                            </button>

                            <button onClick={() => handleClose()} className="pitchdeckclosebutton">
                                <span>Close</span>
                            </button>

                            <FaEllipsisH style={{ marginLeft: "20px" }} />
                        </div>
                    </div>

                    <div>
                        <Tabs className="companytabs" defaultActiveKey="generalInformation">

                            <Tab eventKey="generalInformation" title="General Information">
                                <AddEditGeneralInformation state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="business" title="Business">
                                <AddEditBusiness state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="financials" title="Financials">
                                <AddEditFinancials state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="management" title="Management">
                                <AddEditManagement state={state} setState={dispatch} />
                            </Tab>

                            <Tab eventKey="dataRoom" title="Data Room">
                                <DataRoomComponent />
                            </Tab>

                        </Tabs>
                    </div>
                </Container>
            </div >
        </>
    )
}

export default AddDataContribution;