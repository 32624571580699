import React, { useState } from 'react';
import { Card, Form, Alert, Button, Spinner } from 'react-bootstrap';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList } from 'react-icons/bs'
import './methods.css';

const BasicVCMethod = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    // realm function starts here
    const { simpleVCMethod } = props.currentUser.functions;

    // hook for form
    const [filters, setFilters] = useState({
        fundraisingTargetAmount: 0,
        equityStake: 0,
        esop: 0
    });

    // hook for results
    const [valuation, setValuation] = useState({
        preMoney: 0,
        postMoney: 0
    });

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            fundraisingTargetAmount: 0,
            equityStake: 0,
            esop: 0
        });
        setValuation({
            preMoney: 0,
            postMoney: 0
        });
        closeAlert();
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        setFilters({ ...filtersCopy });
    }

    // function to submit form
    async function submitFilters(e) {
        e.preventDefault();

        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Simulating</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        let form = {
            fundraisingTargetAmount: parseInt(filters.fundraisingTargetAmount),
            equityStake: parseInt(filters.equityStake),
            esop: parseInt(filters.esop)

        };

        // console.log("VC Method form:", form);
        try {
            const response = await simpleVCMethod(form);
            const data = JSON.parse(response).body;

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View results</a></p>
            </div>);

            setValuation({
                preMoney: data['Pre-money_valuation'],
                postMoney: data['Post-money_valuation'],
            })

        } catch (err) {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>Please try again.</p>
            </div>);

            // console.error(err);
        }
    }

    // function to submit form on enter
    function enterFilters(e) {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            submitFilters(e);
        }
    };

    return (
        // card as method
        <Card.Body className="prerevmethodsaccordioncardbody">

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            {/* form */}
            <div className="col-12 prerevmethodsdivfilter">
                <Form>
                    <Form.Group>
                        <div className="prerevmethodsrow">
                            {/* form field */}
                            <div className="prerevmethodsfield" style={{ paddingRight: "2%" }}>
                                <Form.Label>
                                    <sup>*</sup>
                                    Fundraising target amount
                                    <sup data-tip="Estimate the target amount of investment that you plan to fundraise">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control placeholder="How much money are you raising?" type="number"
                                    name="fundraisingTargetAmount" value={filters.fundraisingTargetAmount} onChange={changeFilters} onKeyDown={enterFilters} />
                                <Form.Text className="prerevmethodstextmuted">Estimate target amount in USD</Form.Text>
                            </div>

                            {/* form field */}
                            <div className="prerevmethodsfield" style={{ paddingRight: "2%" }}>
                                <Form.Label>
                                    <sup>*</sup>
                                    Equity stake
                                    <sup data-tip="Determine the percentage of equity ownership stake of your company that you decide to offer your investor(s)">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control placeholder="How much equity stake are you offering to your investor(s)?"
                                    type="number"
                                    name="equityStake" value={filters.equityStake} onChange={changeFilters} onKeyDown={enterFilters} />
                                <Form.Text className="prerevmethodstextmuted">Determine equity stake in percentage</Form.Text>
                            </div>

                            {/* form field */}
                            <div className="prerevmethodsfield" style={{ paddingRight: "2%" }}>
                                <Form.Label>
                                    Employee stock option plan (ESOP)
                                    <sup data-tip="If you plan to include the option pool in the pre-money valuation, determine the percentage of option pool that you decide to reserve for your employees">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control placeholder="How much stock option pool are you allocating for your employees, if any?"
                                    type="number"
                                    name="esop" value={filters.esop} onChange={changeFilters} onKeyDown={enterFilters} />
                                <Form.Text className="prerevmethodstextmuted">Determine stock option pool in percentage</Form.Text>
                            </div>
                        </div>

                        <div className="prerevmethodsdivbuttons">
                            {/* reset button */}
                            <button className="prerevmethodsresetbutton" onClick={resetFilters}>
                                <span>Clear</span>
                            </button>

                            {/* submit button */}
                            <Button className="prerevmethodssubmitbutton" onClick={submitFilters}>
                                <span>Simulate</span>
                            </Button>
                        </div>
                    </Form.Group>
                </Form>
            </div>

            {/* results */}
            <div className="col-12">
                <Card className="prerevmethodscard">
                    <div className="prerevmethods">
                        <table className="prerevmethodstable">
                            <tbody>
                                <tr>
                                    <td style={{ fontWeight: "bold", borderRight: "1px solid #dee2e6", borderBottom: "1px solid #dee2e6" }}>Pre-money valuation</td>
                                    <td style={{ borderLeft: "1px solid #dee2e6", borderBottom: "1px solid #dee2e6" }}>USD $ {valuation.preMoney?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold", borderRight: "1px solid #dee2e6", borderTop: "1px solid #dee2e6" }}>Post-money valuation</td>
                                    <td style={{ borderLeft: "1px solid #dee2e6", borderTop: "1px solid #dee2e6" }}>USD $ {valuation.postMoney?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>

            {/* notes */}
            <div className="col-12 prerevmethodsdivnotes">
                <span className="prerevmethodsnotes">
                    Note:
                    <ul>
                        <li>
                            Equity stake refers to the ownership of assets (shareholders' stake) in the company as represented by securities or stock.
                        </li>
                        <li>
                            The investment amount raised from investors and the valuation of your company determines how much equity ownership you offer to the investors.
                        </li>
                        <li>
                            Investors require <a href="https://www.investopedia.com/terms/e/equityfinancing.asp" target="_blank" rel="noopener noreferrer">
                                equity ownership</a> (percentage) to finance short or long term financing needs where you sell ownership stake (of your company) in the form of shares.
                        </li>
                        <li>
                            It is important to note that pre-money and post-money differ in the timing of valuation.
                        </li>
                        <li>
                            <a href="https://www.investopedia.com/ask/answers/difference-between-premoney-and-postmoney/" target="_blank" rel="noopener noreferrer">
                                Pre-money valuation</a> refers to the value of the company before it receives outside investment; includes initial capital injection.
                        </li>
                        <li>
                            <a href="https://www.investopedia.com/ask/answers/difference-between-premoney-and-postmoney/" target="_blank" rel="noopener noreferrer">
                                Post-money valuation</a> refers to the value of the company after it receives a round of financing; includes outside financing and/or the latest capital injection.
                        </li>
                        <li>
                            An <a href="https://www.investopedia.com/terms/o/option-pool.asp" target="_blank" rel="noopener noreferrer">
                                employee stock option pool</a> consists of shares of stock reserved for employees of a private company; the option pool is a way of attracting talented employees to a startup company.
                        </li>
                        <li>
                            The <a href="https://www.hbs.edu/faculty/Pages/item.aspx?num=6515" target="_blank" rel="noopener noreferrer">
                                venture capital method</a> was first introduced by Professor William Sahlman at Harvard Business School in 1987, which works out pre-money valuation by first determining post-money valuation using industry metrics.
                        </li>
                        <li>
                            “As some businesses are easier to value than others, the uncertainty associated with value estimation is different for different businesses. It is not how precise a valuation is that determines its usefulness, but how precise the value is relative to the estimation of other investors.” (<a href="https://www.amazon.com/Damodaran-Valuation-Security-Investment-Corporate/dp/0471751219" target="_blank" rel="noopener noreferrer">
                                Damodaran, 2006</a>)
                        </li>
                    </ul>
                </span>
            </div>
        </Card.Body>
    );
}

export default BasicVCMethod;