import React from 'react'
import PitchDeckDetail from './detail'
import BulletPitchDeckDetail from './bullet-detail'

const PitchDeckDetails = ({ detailsArray, data, className }) => {
  return detailsArray.map((detail, index) =>
    detail.type === 'bullets' ? (
      <BulletPitchDeckDetail
        key={index}
        displayName={detail.displayName}
        data={data}
        fieldName={detail.fieldName}
        className={className}
      />
    ) : (
      <PitchDeckDetail
        key={index}
        displayName={detail.displayName}
        data={data}
        fieldName={detail.fieldName}
        className={className}
      />
    )
  )
}

export default PitchDeckDetails