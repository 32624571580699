import React from 'react'
import { Card } from 'react-bootstrap'
import './company-detail.css'

function Financials(props) {

    return (
        <Card.Body id="financials">
            <div className="companydetailtablediv">
                <table className="companydetailtable">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Financing date</th>
                            <th>Financing round</th>
                            <th>Total amount raised</th>
                            <th>Post-money valuation</th>
                            <th>Financial viability</th>
                            <th>Fundraising target amount</th>
                            <th>Remaining target amount</th>
                            <th>Financing type</th>
                            <th>No. of investors</th>
                            <th>Board seats</th>
                            <th>Lead investors</th>
                            <th>Entity type</th>
                            <th>Financing status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!props?.financials
                            ? (<tr>
                                <td className="companydetailemptydata"><p className="companydetailxsdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                            </tr>)
                            : props.financials.map((financials, index) =>
                                <tr key={index}>

                                    <td><p className="companydetailxsdata">{index + 1}.</p></td>

                                    {!financials.date
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{financials.date}</p></td>)
                                    }

                                    {!financials.round
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{financials.round}</p></td>)
                                    }

                                    {!financials.amount
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{!financials.amount.totalRaised ? "" : <span>{financials.amount.totalRaised}</span>}</p></td>)
                                    }

                                    {!financials.amount
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{financials.amount.postmoneyValuation}</p></td>)
                                    }

                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!financials.amount
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{!financials.amount.fundraisingTarget ? "" : <span>{financials.amount.fundraisingTarget}</span>}</p></td>)
                                    }

                                    {!financials.amount
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{!financials.amount.remainingTarget ? "" : <span>{financials.amount.remainingTarget}</span>}</p></td>)
                                    }

                                    {!financials.financingType
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetaillongdata">{financials.financingType.map((type, index) =>
                                            !type
                                                ? ""
                                                : (<span key={index}>{type}, </span>)
                                        )}</p></td>)
                                    }

                                    {!financials.numOfInvestors
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{financials.numOfInvestors}</p></td>)
                                    }

                                    {!financials.boardSeats
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{financials.boardSeats}</p></td>)
                                    }

                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!financials.financingStatus
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{financials.financingStatus}</p></td>)
                                    }
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </Card.Body >
    )
}

export default Financials;