import React from 'react'
import SampleImage from '../../../assets/logo/roiquant - image not found.png';
import { Card } from 'react-bootstrap'
import { S3Media } from '../../third-party-services'
import './company-detail.css'

function Investors(props) {

    return (
        <Card.Body id="investors">
            <div className="companydetailtablediv">
                <table className="companydetailtable">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th style={{ color: "transparent" }}>Image</th>
                            <th>Name</th>
                            <th>Investor type</th>
                            <th>Description</th>
                            <th>Location</th>
                            <th>Preferred investment stage(s)</th>
                            <th>Stages invested in</th>
                            <th>Assets under management (AUM)</th>
                            <th>Investment range</th>
                            <th>Investment sweet spot</th>
                            <th>Preferred investment focus</th>
                            <th>Preferred investment geography</th>
                            <th>No. of investments</th>
                            <th>No. of lead investments</th>
                            <th>No. of follow-on investments</th>
                            <th>No. of co-investments</th>
                            <th>Portfolio companies</th>
                            <th>No. of exits</th>
                            <th>Total exit value</th>
                            <th>No. of failed investments</th>
                            <th>Loss on investments</th>
                            <th>No. of acquisitions</th>
                            <th>No. of board seats taken</th>
                            <th>No. of funds</th>
                            <th>Limited partners</th>
                            <th>Co-investors</th>
                            <th>No. of partners</th>
                            <th>Partners</th>
                            <th>No. of employees</th>
                            <th>Management</th>
                            <th>Board members</th>
                            <th>Founders</th>
                            <th>Investor reputation</th>
                            <th>Contact info</th>
                            <th>Entity type</th>
                            <th>Legal name</th>
                            <th>Former legal name(s)</th>
                            <th>Founded date</th>
                            <th>Ticker</th>
                        </tr>
                    </thead>

                    <tbody>
                        {!props?.investors
                            ? (<tr>
                                <td className="companydetailemptydata"><p className="companydetailxsdata">-</p></td>
                                <td>
                                    <img src={SampleImage} className="companydetaillogo" alt="" />
                                </td>
                                <td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailmldata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailmldata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                            </tr>)
                            : props.investors.map((investors, index) =>
                                <tr key={index}>

                                    <td><p className="companydetailxsdata">{index + 1}.</p></td>

                                    {!investors.image
                                        ? (<td>-</td>)
                                        : (<td><img src={S3Media + 'assets/photos/investors/' + investors.image} alt={investors.name} className="companydetaillogo" /></td>)
                                    }

                                    {!investors.name
                                        ? (<td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>)
                                        : (<td><p className="companydetailmediumdata">{investors.name}</p></td>)
                                    }

                                    {/* investor type */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!investors.description
                                        ? (<td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>)
                                        : (<td><p className="companydetaillongdata">{investors.description}</p></td>)
                                    }

                                    {!investors.location
                                        ? (<td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>)
                                        : (<td><p className="companydetaillongdata">{investors.location}</p></td>)
                                    }

                                    {/* investment stage */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!investors.investmentRecord
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{investors.investmentRecord}</p></td>)
                                    }

                                    {/* assets under management */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!investors.investmentRange
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{investors.investmentRange}</p></td>)
                                    }

                                    {!investors.sweetSpot
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{investors.sweetSpot}</p></td>)
                                    }

                                    {!investors.focus
                                        ? (<td className="companydetailemptydata"><p className="companydetailmldata">-</p></td>)
                                        : (<td><p className="companydetailmldata">{investors.focus}</p></td>)
                                    }

                                    {!investors.investmentGeo
                                        ? (<td className="companydetailemptydata"><p className="companydetailmldata">-</p></td>)
                                        : (<td><p className="companydetailmldata">{investors.investmentGeo}</p></td>)
                                    }

                                    {/* no of investments */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* lead */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* follow on */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* co */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* portfolio companies */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* exit */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* total exit value */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* failed */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* loss */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* acquisition */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* no. of board seats taken */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* no. of funds */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* limited partners */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* co investors*/}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* no. of partners */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* partners */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!investors.numOfEmployees
                                        ? (<td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>)
                                        : (<td><p className="companydetailmediumdata">{investors.numOfEmployees}</p></td>)
                                    }

                                    {/* management */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* board members */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* founders */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* investor reputation */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* phone number */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* entity type */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* legal name */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* former legal name(s) */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* founded */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* ticker symbol */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </Card.Body >
    )
}

export default Investors;