import { Form } from 'react-bootstrap'
import AccordianCard from '../../../../../../accordion-card/accordian-card'
import {
  editItemInFieldArray,
  pushItemToFieldArray,
  removeItemFromFieldArray
} from '../../../helpers'
import '../../../pitch-deck-analysis.css'
import ResultsSection from '../results-section'
import FormCellMultiInput from '../../form/form-cell-multi-input'
const CreateEditCompetition = props => {
  props = {
    ...props,
    pushItemToFieldArray,
    removeItemFromFieldArray,
    editItemInFieldArray
  }

  return (
    <div className='ideacheckertabbody'>
      <div className='ideacheckerquestions'>
        <AccordianCard
          className='companyaccordion'
          title='Competitors'
          activeKey='competitors'
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName='directCompetitors'
                displayName='Who are your direct competitors in your
                              competitive landscape?'
              />

              <FormCellMultiInput
                props={props}
                actualName='similarCompanies'
                displayName='Who are your similar companies in your competitive
                              landscape?'
              />

              <FormCellMultiInput
                props={props}
                actualName='productsAndServices'
                displayName='What are their products and services?'
              />

              <FormCellMultiInput
                props={props}
                actualName='marketShare'
                displayName='How much market share does each competitor
                              control?'
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title="Competitors' strength and weaknesses"
          activeKey='competitorsStrengthsAndWeaknesses'
          style={{ marginTop: '10px' }}
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName='strengthsAndWeaknesses'
                displayName="What are your competitors' strengths and
                              weaknesses?"
              />

              <FormCellMultiInput
                props={props}
                actualName='doCompetitorsEnjoyRecognition'
                displayName='Do they enjoy strong brand recognition of their
                              products or services?'
              />

              <FormCellMultiInput
                props={props}
                actualName='marketingStrategies'
                displayName='What are their marketing strategies?'
              />

              <FormCellMultiInput
                props={props}
                actualName='keyFactorsToProfitability'
                displayName='What are the key factors to their profitability?'
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title='Competitive differentiation (unique value proposition)'
          activeKey='competitiveDifferentiation'
          style={{ marginTop: '10px' }}
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName='competitiveDifferentiation'
                displayName='What is your unique value proposition or unique
                              selling point differentiating you from your
                              competition?'
                datatip='Explain how your competitive differentiation distinguishes you from your business rivals.'
              />

              <FormCellMultiInput
                props={props}
                actualName='whyValuePropositionIsUnique'
                displayName='Why is your value proposition unique and compelling?'
              />

              <FormCellMultiInput
                props={props}
                actualName='howYourProductIsBetter'
                displayName='How is your product ten times better or cheaper
                              than existing alternatives?'
              />

              <FormCellMultiInput
                props={props}
                actualName='differentiationsFromCompetitorsProduct'
                displayName="What differentiates your product-or-service from
                              competitors' offerings?"
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title='Industry competitive outlook'
          activeKey='industryCompetitiveOutlook'
          style={{ marginTop: '10px' }}
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName='competitiveOutlookForIndustry'
                displayName='What is the competitive outlook for the industry?'
              />

              <FormCellMultiInput
                props={props}
                actualName='howCompetitorsThreaten'
                displayName='How much of a threat are your competitors to your
                              venture?'
              />

              <FormCellMultiInput
                props={props}
                actualName='willCompetitorsBlock'
                displayName='Will your competitors aggressively block the
                              entrance of a new rival?'
              />

              <FormCellMultiInput
                props={props}
                actualName='willCompetitorsPoach'
                displayName='Will your competitors poach your ideas,
                              appropriating them for their own business (killing
                              your unique value proposition)?'
              />

              <FormCellMultiInput
                props={props}
                actualName='whoElseMightExploit'
                displayName='Who else might be able to observe and exploit the
                              same opportunity?'
              />
            </Form.Group>
          </Form>
        </AccordianCard>
      </div>

      <ResultsSection props={props} type='competition' />
    </div>
  )
}

export default CreateEditCompetition