import React from 'react'
import SampleImage from '../../../assets/logo/roiquant - image not found.png';
import { Card } from 'react-bootstrap'
import { S3Media } from '../../third-party-services'
import './company-detail.css'

function Management(props) {

    return (
        <Card.Body id="management">
            <div className="companydetailtablediv">
                <table className="companydetailtable">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th style={{ color: "transparent" }}>Image</th>
                            <th>Name</th>
                            <th>Previous designation</th>
                            <th>Previous company</th>
                            <th>Previous biography</th>
                            <th>Previous profile type</th>
                            <th>Previous location</th>
                            <th>Experience</th>
                            <th>Education</th>
                            <th>Reputation</th>
                            <th>Current company</th>
                            <th>Current designation</th>
                            <th>Current biography</th>
                            <th>Current profile type</th>
                            <th>Current location</th>
                            <th>No. of investments</th>
                            <th>No. of exits</th>
                            <th>Total exit value</th>
                            <th>No. of board seats taken</th>
                            <th>Contact info</th>
                        </tr>
                    </thead>

                    <tbody>
                        {!props?.management
                            ? (<tr>
                                <td className="companydetailemptydata"><p className="companydetailxsdata">-</p></td>
                                <td>
                                    <img src={SampleImage} className="companydetaillogo" alt="" />
                                </td>
                                <td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                            </tr>)
                            : props.management.map((management, index) =>
                                <tr key={index}>

                                    <td><p className="companydetailxsdata">{index + 1}.</p></td>

                                    <td>
                                        {!management.image
                                            ? (<img src={SampleImage} className="companydetaillogo" alt="" />)
                                            : (<img src={S3Media + 'assets/photos/founders/' + management.image} alt={management.name} className="companydetaillogo" />)
                                        }
                                    </td>

                                    {!management.name
                                        ? (<td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>)
                                        : (<td><p className="companydetailmediumdata">{management.name}</p></td>)
                                    }

                                    {!management.previousDesignation
                                        ? (<td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>)
                                        : (<td><p className="companydetaillongdata">{management.previousDesignation}</p></td>)
                                    }

                                    {/* previous company */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* previous biography */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* previous profile type */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* previous location */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* experience */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* education */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* reputation */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!management.currentCompany
                                        ? (<td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>)
                                        : (<td><p className="companydetailmediumdata">{management.currentCompany}</p></td>)
                                    }

                                    {/* current designation */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!management.biography
                                        ? (<td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>)
                                        : (<td><p className="companydetaillongdata">{management.biography}</p></td>)
                                    }

                                    {/* current profile type */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!management.location
                                        ? (<td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>)
                                        : (<td><p className="companydetailmediumdata">{management.location}</p></td>)
                                    }

                                    {/* no. of investments */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* no. of exits */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* total exit value */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* no. of board seats taken */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* contact info */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </Card.Body>
    )
}

export default Management;