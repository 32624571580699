import React, { useState } from 'react';
import NavBar from '../../layout/navbar/navbar';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io'
import ReferralInvite from './components/invite';
import ReferralRewards from './components/rewards';
import ReferralFaq from './components/faq';
import ReferralPerform from './components/perform';
import ReferralImpact from './components/impact';
import './referral-program.css';

const ReferralProgram = () => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
    }

    return (
        <>
            {/* navbar */}
            <NavBar />

            <div id="referral">
                <Container className="col-11" style={{ paddingTop: "50px", paddingBottom: "200px" }}>
                    <Row>
                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <ReferralInvite />
                        </Col>

                        <Col xl={3} lg={12} md={12} sm={12} xs={12}>
                            <ReferralRewards />
                        </Col>

                        <Col xl={3} lg={12} md={12} sm={12} xs={12}>
                            <ReferralFaq />
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <ReferralPerform />
                        </Col>

                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <ReferralImpact />
                        </Col>
                    </Row>
                </Container>
            </div>

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        <div style={{ paddingTop: "10px" }}>
                            <p style={{ fontSize: "16px", color: "#1f5f8b" }}>Referral program is on beta mode</p>
                            <p style={{ fontSize: "14px" }}>We've released our referral program and it's currently on beta mode.</p>
                            <p style={{ fontSize: "14px" }}>You can generate as many new invitation links as you want which can be copied and shared with your friends. Currently, we're only displaying the number of successful referrals.</p>
                        </div>
                    </div>
                </Alert>)
                : ""
            }
        </>
    )
}

export default ReferralProgram;