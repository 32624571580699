import React, { useState } from 'react';
import './filters.css';
import { Card, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { useRealmApp } from '../../../RealmApp';
import { countries } from '../../dropdown-options/countries';
import { entityTypes } from '../../dropdown-options/entity-types';
import { reasonsForShutdown } from '../../dropdown-options/reasons-for-shutdown';
import { lifespans } from '../../dropdown-options/lifespans';
import { fundingRounds } from '../../dropdown-options/funding-rounds';
import { fundingAmounts } from '../../dropdown-options/funding-amounts';
import { genders } from '../../dropdown-options/genders';
import { noOfFounders } from '../../dropdown-options/no-of-founders';

const Industries = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // realm functions declaration
    const { currentUser, logInAnonymous } = useRealmApp();

    // to check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // chart function declaration
    const { postmortemAnalysis } = currentUser.functions;

    // hook for user input
    const [filters, setFilters] = useState({
        type: "Industry",
        filtersView: true,
        entityType: "Entity type",
        industriesNum: "No. of industries",
        sliceNum: "",
        reasons: "Reasons for shutdown",
        lifespan: "Lifespan",
        location: "Location",
        fundingRound: "Funding round",
        fundingAmount: "Funding amount",
        gender: "Gender",
        numOfFounders: "No. of founders"
    });

    // hook for results
    const [investments, setInvestments] = useState(
        [
            {
                "Industry": "Retail sale via mail order houses or via internet",
                "Frequency": 2503,
                "%": 8.4
            },
            {
                "Industry": "Web portals",
                "Frequency": 2086,
                "%": 7.0
            },
            {
                "Industry": "Computer consultancy and computer facilities management activities",
                "Frequency": 1906,
                "%": 6.4
            },
            {
                "Industry": "Other residential care activities",
                "Frequency": 1509,
                "%": 5.1
            },
            {
                "Industry": "Advertising",
                "Frequency": 1445,
                "%": 4.9
            },
            {
                "Industry": "Activities of holding companies",
                "Frequency": 795,
                "%": 2.7
            },
            {
                "Industry": "Management consultancy activities",
                "Frequency": 776,
                "%": 2.6
            },
            {
                "Industry": "Computer programming activities",
                "Frequency": 773,
                "%": 2.6
            },
            {
                "Industry": "Software publishing",
                "Frequency": 715,
                "%": 2.4
            },
            {
                "Industry": "Internet",
                "Frequency": 686,
                "%": 2.3
            },
            {
                "Industry": "Temporary employment agency activities",
                "Frequency": 600,
                "%": 2.0
            },
            {
                "Industry": "Restaurants and mobile food service activities",
                "Frequency": 589,
                "%": 2.0
            },
            {
                "Industry": "Other professional, scientific and technical activities not elsewhere classified",
                "Frequency": 569,
                "%": 1.9
            },
            {
                "Industry": "Activities of call centres",
                "Frequency": 503,
                "%": 1.7
            },
            {
                "Industry": "Wireless telecommunications activities",
                "Frequency": 461,
                "%": 1.6
            },
            {
                "Industry": "Library and archives activities",
                "Frequency": 452,
                "%": 1.5
            },
            {
                "Industry": "Pre-primary and primary education",
                "Frequency": 292,
                "%": 1.0
            },
            {
                "Industry": "Other telecommunications activities",
                "Frequency": 290,
                "%": 1.0
            },
            {
                "Industry": "General secondary education",
                "Frequency": 277,
                "%": 0.9
            },
            {
                "Industry": "Data processing, hosting and related activities",
                "Frequency": 275,
                "%": 0.9
            }
        ]
    );

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            type: "Industry",
            filtersView: true,
            entityType: "Entity type",
            industriesNum: "No. of industries",
            sliceNum: "",
            reasons: "Reasons for shutdown",
            lifespan: "Lifespan",
            location: "Location",
            fundingRound: "Funding round",
            fundingAmount: "Funding amount",
            gender: "Gender",
            numOfFounders: "No. of founders"
        });
        setAlert(false);
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        let name2, value2, name3, value3;

        if (name === "industriesNum" && value === "No. of industries") {
            name2 = "filtersView";
            value2 = true;
        }
        else if (name === "industriesNum" && value !== "No. of industries") {
            name2 = "filtersView";
            value2 = false;

            if (value === "Top 10") {
                name3 = "sliceNum";
                value3 = "10";
            }

            if (value === "Top 20") {
                name3 = "sliceNum";
                value3 = "20";
            }

            if (value === "Top 30") {
                name3 = "sliceNum";
                value3 = "30";
            }
        }

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        filtersCopy[name2] = value2;
        filtersCopy[name3] = value3;
        setFilters({ ...filtersCopy });
    }

    // function to submit form
    function submitFilters(e) {
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        postmortemAnalysis(filters)
            .then(response => {
                let graphData = response;
                let slicedData = graphData.slice(0, filters.sliceNum);
                setInvestments(slicedData);
                closeAlert();
            })
            .catch(error => {
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Your filter query returned no results. Please change your filter values and try again.</p>
                </div>);
            })
        e.preventDefault();
    }

    return (
        <Card.Body className="accordion-card-body">

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div className="col-12 div-filter">
                {/* form */}
                <Form onSubmit={submitFilters}>
                    <Form.Group>
                        <Form.Row className="align-items-center">
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.industriesNum} as="select" name="industriesNum" onChange={changeFilters}>
                                    <option>No. of industries</option>
                                    <option>Top 10</option>
                                    <option>Top 20</option>
                                    <option>Top 30</option>
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.entityType} disabled={filters.filtersView} as="select" name="entityType" onChange={changeFilters}>
                                    <option>Entity type</option>
                                    {
                                        entityTypes.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.reasons} disabled={filters.filtersView} as="select" name="reasons" onChange={changeFilters}>
                                    <option>Reasons for shutdown</option>
                                    {
                                        reasonsForShutdown.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.lifespan} disabled={filters.filtersView} as="select" name="lifespan" onChange={changeFilters}>
                                    <option>Lifespan</option>
                                    {
                                        lifespans.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.location} disabled={filters.filtersView} as="select" name="location" onChange={changeFilters}>
                                    <option>Location</option>
                                    {
                                        countries.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.fundingRound} disabled={filters.filtersView} as="select" name="fundingRound" onChange={changeFilters}>
                                    <option>Funding round</option>
                                    {
                                        fundingRounds.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.fundingAmount} disabled={filters.filtersView} as="select" name="fundingAmount" onChange={changeFilters}>
                                    <option>Funding amount</option>
                                    {
                                        fundingAmounts.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.gender} disabled={filters.filtersView} as="select" name="gender" onChange={changeFilters}>
                                    <option>Gender</option>
                                    {
                                        genders.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.numOfFounders} disabled={filters.filtersView} as="select" name="numOfFounders" onChange={changeFilters}>
                                    <option>No. of founders</option>
                                    {
                                        noOfFounders.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            {/* reset button */}
                            <Col xs="auto">
                                <button onClick={resetFilters} className="reset-button tour-clear-filter">
                                    <span>Clear</span>
                                </button>
                            </Col>
                            {/* submit button */}
                            <Col xs="auto">
                                <button type="submit" className="submit-button">
                                    <span>Filter</span>
                                </button>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>
            </div>

            {/* results */}
            <div className="col-12">
                <Card className="graph-card">
                    <div>
                        {/* chart component */}
                        <ResponsiveContainer id="vertical-charts">
                            <BarChart data={investments}
                                margin={{ top: 20, right: 20, left: 100, bottom: 50 }}
                                width={1100}
                                height={1100}
                                fontFamily="AileronRegular"
                                layout={"vertical"}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type={"number"}
                                    orientation={"bottom"}
                                    fontSize="15px"
                                    fontFamily="AileronRegular"
                                    label={{ value: "Frequency", offset: -40, position: "insideBottom" }}
                                />
                                <YAxis type={"category"}
                                    orientation={"left"}
                                    dataKey="Industry"
                                    interval={0}
                                    fontSize={filters.industriesNum == "Top 10" ? "15px" : "9px"}
                                    fontFamily="AileronRegular"
                                    label={{ value: "Industries", angle: -90, offset: -80, position: "insideLeft" }}
                                />
                                <Tooltip />
                                <Bar dataKey="Frequency" fill="#8884d8" barSize={50}>
                                    <LabelList dataKey="%" position="right" />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </div>

            {/* notes */}
            <div className="col-12 extra-notes">
                <span className="info-notes">
                    Note:
                    <ul>
                        <li id="notes-points">
                            Total of {props.uniqueIndustries.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} unique industries were recorded.
                        </li>
                        <li id="notes-points">
                            Based on {props.companiesWithIndustries.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} inactive companies with recorded <span style={{ fontFamily: "AileronItalic" }}>industry data</span>.
                        </li>
                        <li id="notes-points">
                            In due time, the remaining {props.companiesWithIndustries.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} inactive companies without industry data will be identified and recorded by our algorithms.
                        </li>
                    </ul>
                </span>
            </div>
        </Card.Body>
    )
}

export default Industries;