import { Accordion, Card } from 'react-bootstrap'
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa'
import { IoMdInformationCircleOutline } from 'react-icons/io'

const AccordianCard = ({
  title,
  className,
  style,
  activeKey,
  infoText,
  children
}) => {
  return (
    <Accordion className={className} style={style} defaultActiveKey={activeKey}>
      <Card
        className='overflowvisible'
      // style={{ background: 'rgba(255, 255, 255, .65)' }}
      >
        <Accordion.Toggle
          as={Card.Header}
          className='companycardheader overflowhidden'
          eventKey={activeKey}
        >
          <div className='companyarrowicon'>
            <FaChevronDown />
          </div>

          <div className='companytitlecard'>{title}</div>

          <div className='companyellipsisicon'>
            <FaEllipsisH />
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={activeKey}>
          <Card.Body className='ideacheckeraccordioncardbody overflowhidden' id='companyinformation'>
            {infoText && (
              <div className=''>
                <IoMdInformationCircleOutline />
                <span className='info-text'>{infoText}</span>
              </div>
            )}

            <div className=''>{children}</div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

export default AccordianCard