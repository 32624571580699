import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRealmApp } from '../../../../RealmApp'
import './pitch-deck-analysis.css'
// import ViewManagement from './view-pitch-deck-analysis/management';
import ViewPlanBuilderPageLayout from '../../../../layout/pitch-deck-analysis-page/view'
import LoadingModal from '../../../modals/loading-modal'
import { closeAlert } from './helpers'

const ViewPitchDeckAnalysis = () => {
  // hook for alert component
  const [alert, setAlert] = useState(true)

  // hook for alert message
  const [message, setMessage] = useState(<LoadingModal />)

  const { currentUser } = useRealmApp()

  // if (!currentUser) {
  //     throw new Error(`Must be logged in`);
  // }

  // mongoDB realm function
  const { getMyPitchDeck } = currentUser.functions

  // get pitchDeckId
  const { pitchDeckId } = useParams()

  const [data, setData] = useState()

  const props = {
    data: data,
    setMessage,
    setAlert,
    message,
    alert
  }

  const params = {
    ...props,
    closeAlert: () => closeAlert(props),
  }

  useEffect(() => {
    // Get the selected pitch deck
    async function getPitchDeck(id) {
      // console.log(id);
      // console.log("Execute the code below");
      try {
        const response = await getMyPitchDeck({ pitchDeckId: id })
        const pitchDeck = JSON.parse(response)
        setData(pitchDeck)
        setAlert(false)
      } catch (err) {
        // console.error("Couldn't fetch data from Realm");
      }
    }

    if (pitchDeckId) {
      if (!data) {
        // console.log("Pitch Deck Id:", pitchDeckId);
        getPitchDeck(pitchDeckId)
      }
    }
  }, [data, pitchDeckId, getMyPitchDeck])

  // useEffect(() => {
  //   console.log('data:', data)
  // }, [data])
  return <ViewPlanBuilderPageLayout props={params} />
}

export default ViewPitchDeckAnalysis