import { Form } from 'react-bootstrap'
import AccordianCard from '../../../../../../accordion-card/accordian-card'
import {
  editItemInFieldArray,
  pushItemToFieldArray,
  removeItemFromFieldArray
} from '../../../helpers'
import '../../../pitch-deck-analysis.css'
import ResultsSection from '../results-section'
import FormCellMultiInput from '../../form/form-cell-multi-input'

const CreateEditOperations = props => {
  props = {
    ...props,
    pushItemToFieldArray,
    removeItemFromFieldArray,
    editItemInFieldArray
  }

  return (
    <div className='ideacheckertabbody'>
      <div className='ideacheckerquestions'>
        <AccordianCard
          className='companyaccordion'
          title='Traction'
          activeKey='traction'
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName='kpiMetrics'
                displayName='What is your traction (KPI metrics)?'
              />
              <FormCellMultiInput
                props={props}
                actualName='metricsThatShowAchievement'
                displayName='What are the traction metrics that show what you
                              have achieved so far?'
              />
              <FormCellMultiInput
                props={props}
                actualName='growthRate'
                displayName='What are the week-over-week or month-over-month
                              growth rate?'
              />
              <FormCellMultiInput
                props={props}
                actualName='proofOfLatentDemand'
                displayName='Is there proof of latent demand?'
              />
              <FormCellMultiInput
                props={props}
                actualName='isBusinessGrowing'
                displayName='Is your business growing?'
              />
              <FormCellMultiInput
                props={props}
                actualName='isProductSelfPerpetuatingGrowth'
                displayName='Is your product-or-service self-perpetuating its
                              growth?'
              />
              <FormCellMultiInput
                props={props}
                actualName='ableToAccomplishWithLess'
                displayName='Have you been able to accomplish with less money
                              in less time?'
              />
            </Form.Group>
          </Form>
        </AccordianCard>
      </div>

      <ResultsSection props={props} type='operations' />
    </div>
  )
}

export default CreateEditOperations