import React from 'react'
import '../../components/tools/founders/pitch-deck-analysis/pitch-deck-analysis.css'
import NavBar from '../navbar/navbar'
// import CreateEditManagement from './create-edit-pitch-deck-analysis/management';
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { FaEllipsisH } from 'react-icons/fa'
import MessageContainerModal from '../../components/modals/message-container-modal'
import PlanBuilderTabs from '../../components/tools/founders/pitch-deck-analysis/components/tabs'

const PlanBuilderPageLayout = ({ props }) => {
  const {
    state,
    dispatch,
    handleEnterSave,
    handleSave,
    handleClose,
    handleSubmit
  } = props
  return (
    <>
      <NavBar />
      <div id='ideachecker'>
        <MessageContainerModal props={props} />

        <Container className='col-11' style={{ paddingBottom: '200px' }}>
          <div className='pitchdeckheaderdiv'>
            <div className='pitchdecktitlediv'>
              <br />
              <span className='pitchdeckcreatetext'>Title</span>
              <br />
              <Form>
                <Form.Group>
                  <Form.Control
                    placeholder='Give this work a name'
                    value={state.pitchDeckName}
                    className='pitchdecktitlefield'
                    onChange={e => {
                      dispatch({
                        type: 'update pitchDeckName',
                        value: e.target.value
                      })
                    }}
                    onKeyDown={e => handleEnterSave(e)}
                  />
                </Form.Group>
              </Form>
            </div>

            <div className='pitchdeckstatsdiv'>
              <div className='pitchdeckstatsrow'>
                <Button
                  onClick={() => handleSubmit(props)}
                  className='pitchdeckanalysebutton glintanimation'
                >
                  <span>Analyze</span>
                </Button>
                <button
                  onClick={() => handleSave(props)}
                  className='pitchdecksavebutton'
                >
                  <span>Save</span>
                </button>
                <button
                  onClick={() => handleClose(props)}
                  className='pitchdeckclosebutton'
                >
                  <span>Close</span>
                </button>

                <FaEllipsisH style={{ marginLeft: '20px', placeSelf: 'center' }} />
              </div>
            </div>
          </div>

          <div>
            <PlanBuilderTabs props={props} />
          </div>
        </Container>
      </div>
    </>
  )
}

export default PlanBuilderPageLayout