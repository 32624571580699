import { useReducer } from 'react'

const useBusinessPlanBuilder = () => {
  const [state, dispatch] = useReducer(reducer, {
    pitchDeckId: '',
    pitchDeckName: '',
    isDataInitialized: false,

    // Overview Tab
    entityName: '',
    entityType: '', // Multi-select
    locationCity: '',
    locationState: '',
    locationCountry: '', // Multi-select
    ownershipStatus: '', // Multi-select
    onesentencePitch: '',
    markets: [],
    stageOfDevelopment: '', // Multi-select
    transactionalModelType: '', // Multi-select
    defensibilityMoats: [],
    industry: '', // Multi-select
    categories: [],
    vision: '',
    mission: '',
    lastFinancingDate: '',
    lastFinancingRound: '',
    numOfFundingRounds: '',

    // Business Tab
    businessDescription: '',
    customerPain: [''],
    areYouSolvingPersonalCriticalPainPoint: [''],
    howProblemIsAddressed: [''],
    currentSolutionsShortcomings: [''],
    howToGrowBusiness: [''],
    unitEconomics: [''],
    doesPriceChange: [''],
    preMoneyValuation: [''],
    postMoneyValuation: [''],
    equityOwnershipStakeToInvestors: [''],
    financingRound: '', // Multi-select
    financingStrategy: [''],
    howToUseMoney: [''],
    howMuchMoneyToRaise: [''],
    howMuchExpectedFromInvestors: [''],
    milestonesToAchieveBeforeRaiseMoney: [''],
    areYouRaisingEnoughToAccomplishMilestones: [''],
    exitStrategy: [''],
    howInvestorsGetMoney: [''],

    // Solution Tab
    customerGain: [''],
    howProductMeetNeeds: [''],
    willProductSolveNeeds: [''],
    howDidYouGetIdea: [''],
    isProductAspirin: [''],
    isProductVitamin: [''],
    factorsImportantInChoosing: [''],
    howYouRespondToNeed: [''],
    doCustomersLoveProduct: [''],

    // Market Tab
    customerSegments: [''],
    marketSegments: [''],
    problemProductSolves: [''],
    doesProductMakeLivesBetter: [''],
    marketTiming: [''],

    // Competition Tab
    directCompetitors: [''],
    similarCompanies: [''],
    productsAndServices: [''],
    marketShare: [''],
    strengthsAndWeaknesses: [''],
    doCompetitorsEnjoyRecognition: [''],
    marketingStrategies: [''],
    keyFactorsToProfitability: [''],
    competitiveDifferentiation: [''],
    whyValuePropositionIsUnique: [''],
    howYourProductIsBetter: [''],
    differentiationsFromCompetitorsProduct: [''],
    competitiveOutlookForIndustry: [''],
    howCompetitorsThreaten: [''],
    willCompetitorsBlock: [''],
    willCompetitorsPoach: [''],
    whoElseMightExploit: [''],

    // Operations Tab
    kpiMetrics: [''],
    metricsThatShowAchievement: [''],
    growthRate: [''],
    proofOfLatentDemand: [''],
    isBusinessGrowing: [''],
    isProductSelfPerpetuatingGrowth: [''],
    ableToAccomplishWithLess: [''],

    // Marketing Tab
    goToMarketStrategy: [''],
    doesBusinessModelSupportAcquisition: [''],
    planningToAcquireCustomersThroughMarketing: [''],
    howProductWillBeDistributed: [''],
    distributionChannelsUsed: [''],
    whenWhereWhyHowConsumersBuy: [''],
    isProductTransactionOriented: [''],
    isBuyingProcessRelationshipOriented: [''],
    areYouPlanningToBuildDirectForce: [''],
    howToCommunicateWithConsumers: [''],
    willUseWordOfMouth: [''],
    willOptForSalesPromotions: [''],
    doDirectSalesMakeSense: [''],
    isTraditionalBetter: [''],
    doYouNeedToBuildBrandLoyalty: [''],

    // Financials Tab
    monetizationModel: [''],
    areYouBuildingAnAnnuityBusiness: [''],
    areYouSellingDurableProduct: [''],
    financialMargins: [''],
    observedUnitEconomics: [''],
    doUnitEconomicsMakeSense: [''],
    pricingModel: [''],
    pricingModelCustomerDemand: [''],
    pricePointProductOffer: [''],
    isPriceEstablished: [''],
    relevantPricingStrategy: [''],
    appropriatePricingStrategy: [''],
    financialRevenue: [''],
    moneyMadeFromTargetMarket: [''],
    whenIsBusinessExpectedToMakeMoney: [''],
    durationToMakeMoney: [''],
    whenIsBusinessExpectedToReachBreakEven: [''],
    anticipateToBreakEven: [''],
    salesTargetBasedOnProjectedGrowth: [''],
    howLongToReachNextSalesMilestone: [''],
    costAcquiringCustomer: [''],
    howMuchWillCustomerBeWorth: [''],
    internalComponentsAssumptions: [''],
    // income statement year 2
    revenueYear2: 0,
    costOfRevenueYear2: 0,
    grossProfitYear2: 0,
    researchAndDevelopmentYear2: 0,
    salesAndMarketingYear2: 0,
    generalAndAdministrativeYear2: 0,
    totalOperatingExpensesYear2: 0,
    operatingIncomeYear2: 0,
    interestExpenseYear2: 0,
    otherIncomeNetYear2: 0,
    incomeBeforeIncomeTaxesYear2: 0,
    provisionForIncomeTaxesYear2: 0,
    netIncomeNetLossYear2: 0,
    // balance sheet year 2
    cashAndCashEquivalentsYear2: 0,
    accountsReceivableNetYear2: 0,
    inventoryYear2: 0,
    otherCurrentAssetsYear2: 0,
    totalCurrentAssetsYear2: 0,
    propertyPlantAndEquipmentNetYear2: 0,
    intangibleAssetsNetYear2: 0,
    otherNonCurrentAssetsYear2: 0,
    totalNonCurrentAssetsYear2: 0,
    totalAssetsYear2: 0,
    accountsPayableYear2: 0,
    accruedAndOtherYear2: 0,
    shortTermDebtYear2: 0,
    totalCurrentLiabilitiesYear2: 0,
    longTermDebtYear2: 0,
    otherLiabilitiesYear2: 0,
    totalNonCurrentLiabilitiesYear2: 0,
    totalLiabilitiesYear2: 0,
    equityCapitalYear2: 0,
    retainedEarningsYear2: 0,
    additionalPaidInCapitalYear2: 0,
    totalShareholdersEquityYear2: 0,
    totalLiabilitiesAndShareholdersEquityYear2: 0,
    // cash flow statement year 2
    netIncomeYear2: 0,
    depreciationAndAmortizationYear2: 0,
    changesInAssetsAndLiabilitiesYear2: 0,
    netCashProvidedByOperatingActivitiesYear2: 0,
    capitalExpenditureYear2: 0,
    capitalizedSoftwareDevelopmentCostsYear2: 0,
    maturitiesAndSalesOfInvestmentsYear2: 0,
    cashPaidForAcquisitionOfBusinessYear2: 0,
    netCashProvidedByInvestingActivitiesYear2: 0,
    proceedsFromIssuanceOfDebtYear2: 0,
    proceedsFromIssuanceOfEquityYear2: 0,
    debtRelatedCostsAndOtherNetYear2: 0,
    netCashProvidedByFinancingActivitiesYear2: 0,
    changesInCashDuringPeriodYear2: 0,
    cashAtBeginningOfPeriodYear2: 0,
    cashAtEndOfPeriodYear2: 0,
    // break even analysis year 2
    timeFrameYear2: 0,
    averagePriceSoldYear2: 0,
    averageCostToMakeOrDeliverYear2: 0,
    fixedCostsforMonthOrYearYear2: 0,
    percentageOfPriceProfitYear2: 0,
    totalSalesNeededToBreakEvenYear2: 0,
    numberOfUnitsSoldNeededToBreakEvenYear2: 0,
    // financial ratios analysis year 2
    profitMarginYear2: 0,
    returnOnEquityAfterTaxYear2: 0,
    returnOnAssetsYear2: 0,
    grossMarginYear2: 0,
    grossProfitMarginYear2: 0,
    netProfitMarginYear2: 0,
    operatingMarginYear2: 0,
    currentRatioYear2: 0,
    quickRatioYear2: 0,
    cashRatioYear2: 0,
    inventoryTurnoverYear2: 0,
    assetTurnoverYear2: 0,
    receivablesTurnoverYear2: 0,
    ebitYear2: 0,
    ebitdaYear2: 0,
    debtRatioYear2: 0,
    debtToEquityRatioYear2: 0,
    interestCoverageRatioYear2: 0,
    earningsPerShareBasicYear2: 0,
    earningsPerShareDilutedYear2: 0,
    dividendPayoutRatioYear2: 0,
    priceToEarningsRatioYear2: 0,
    // income statement year 1
    revenueYear1: 0,
    costOfRevenueYear1: 0,
    grossProfitYear1: 0,
    researchAndDevelopmentYear1: 0,
    salesAndMarketingYear1: 0,
    generalAndAdministrativeYear1: 0,
    totalOperatingExpensesYear1: 0,
    operatingIncomeYear1: 0,
    interestExpenseYear1: 0,
    otherIncomeNetYear1: 0,
    incomeBeforeIncomeTaxesYear1: 0,
    provisionForIncomeTaxesYear1: 0,
    netIncomeNetLossYear1: 0,
    // balance sheet year 1
    cashAndCashEquivalentsYear1: 0,
    accountsReceivableNetYear1: 0,
    inventoryYear1: 0,
    otherCurrentAssetsYear1: 0,
    totalCurrentAssetsYear1: 0,
    propertyPlantAndEquipmentNetYear1: 0,
    intangibleAssetsNetYear1: 0,
    otherNonCurrentAssetsYear1: 0,
    totalNonCurrentAssetsYear1: 0,
    totalAssetsYear1: 0,
    accountsPayableYear1: 0,
    accruedAndOtherYear1: 0,
    shortTermDebtYear1: 0,
    totalCurrentLiabilitiesYear1: 0,
    longTermDebtYear1: 0,
    otherLiabilitiesYear1: 0,
    totalNonCurrentLiabilitiesYear1: 0,
    totalLiabilitiesYear1: 0,
    equityCapitalYear1: 0,
    retainedEarningsYear1: 0,
    additionalPaidInCapitalYear1: 0,
    totalShareholdersEquityYear1: 0,
    totalLiabilitiesAndShareholdersEquityYear1: 0,
    // cash flow statement year 1
    netIncomeYear1: 0,
    depreciationAndAmortizationYear1: 0,
    changesInAssetsAndLiabilitiesYear1: 0,
    netCashProvidedByOperatingActivitiesYear1: 0,
    capitalExpenditureYear1: 0,
    capitalizedSoftwareDevelopmentCostsYear1: 0,
    maturitiesAndSalesOfInvestmentsYear1: 0,
    cashPaidForAcquisitionOfBusinessYear1: 0,
    netCashProvidedByInvestingActivitiesYear1: 0,
    proceedsFromIssuanceOfDebtYear1: 0,
    proceedsFromIssuanceOfEquityYear1: 0,
    debtRelatedCostsAndOtherNetYear1: 0,
    netCashProvidedByFinancingActivitiesYear1: 0,
    changesInCashDuringPeriodYear1: 0,
    cashAtBeginningOfPeriodYear1: 0,
    cashAtEndOfPeriodYear1: 0,
    // break even analysis year 1
    timeFrameYear1: 0,
    averagePriceSoldYear1: 0,
    averageCostToMakeOrDeliverYear1: 0,
    fixedCostsforMonthOrYearYear1: 0,
    percentageOfPriceProfitYear1: 0,
    totalSalesNeededToBreakEvenYear1: 0,
    numberOfUnitsSoldNeededToBreakEvenYear1: 0,
    // financial ratios analysis year 1
    profitMarginYear1: 0,
    returnOnEquityAfterTaxYear1: 0,
    returnOnAssetsYear1: 0,
    grossMarginYear1: 0,
    grossProfitMarginYear1: 0,
    netProfitMarginYear1: 0,
    operatingMarginYear1: 0,
    currentRatioYear1: 0,
    quickRatioYear1: 0,
    cashRatioYear1: 0,
    inventoryTurnoverYear1: 0,
    assetTurnoverYear1: 0,
    receivablesTurnoverYear1: 0,
    ebitYear1: 0,
    ebitdaYear1: 0,
    debtRatioYear1: 0,
    debtToEquityRatioYear1: 0,
    interestCoverageRatioYear1: 0,
    earningsPerShareBasicYear1: 0,
    earningsPerShareDilutedYear1: 0,
    dividendPayoutRatioYear1: 0,
    priceToEarningsRatioYear1: 0,
    // income statement year 0
    revenueYear0: 0,
    costOfRevenueYear0: 0,
    grossProfitYear0: 0,
    researchAndDevelopmentYear0: 0,
    salesAndMarketingYear0: 0,
    generalAndAdministrativeYear0: 0,
    totalOperatingExpensesYear0: 0,
    operatingIncomeYear0: 0,
    interestExpenseYear0: 0,
    otherIncomeNetYear0: 0,
    incomeBeforeIncomeTaxesYear0: 0,
    provisionForIncomeTaxesYear0: 0,
    netIncomeNetLossYear0: 0,
    // balance sheet year 0
    cashAndCashEquivalentsYear0: 0,
    accountsReceivableNetYear0: 0,
    inventoryYear0: 0,
    otherCurrentAssetsYear0: 0,
    totalCurrentAssetsYear0: 0,
    propertyPlantAndEquipmentNetYear0: 0,
    intangibleAssetsNetYear0: 0,
    otherNonCurrentAssetsYear0: 0,
    totalNonCurrentAssetsYear0: 0,
    totalAssetsYear0: 0,
    accountsPayableYear0: 0,
    accruedAndOtherYear0: 0,
    shortTermDebtYear0: 0,
    totalCurrentLiabilitiesYear0: 0,
    longTermDebtYear0: 0,
    otherLiabilitiesYear0: 0,
    totalNonCurrentLiabilitiesYear0: 0,
    totalLiabilitiesYear0: 0,
    equityCapitalYear0: 0,
    retainedEarningsYear0: 0,
    additionalPaidInCapitalYear0: 0,
    totalShareholdersEquityYear0: 0,
    totalLiabilitiesAndShareholdersEquityYear0: 0,
    // cash flow statement year 0
    netIncomeYear0: 0,
    depreciationAndAmortizationYear0: 0,
    changesInAssetsAndLiabilitiesYear0: 0,
    netCashProvidedByOperatingActivitiesYear0: 0,
    capitalExpenditureYear0: 0,
    capitalizedSoftwareDevelopmentCostsYear0: 0,
    maturitiesAndSalesOfInvestmentsYear0: 0,
    cashPaidForAcquisitionOfBusinessYear0: 0,
    netCashProvidedByInvestingActivitiesYear0: 0,
    proceedsFromIssuanceOfDebtYear0: 0,
    proceedsFromIssuanceOfEquityYear0: 0,
    debtRelatedCostsAndOtherNetYear0: 0,
    netCashProvidedByFinancingActivitiesYear0: 0,
    changesInCashDuringPeriodYear0: 0,
    cashAtBeginningOfPeriodYear0: 0,
    cashAtEndOfPeriodYear0: 0,
    // break even analysis year 0
    timeFrameYear0: 0,
    averagePriceSoldYear0: 0,
    averageCostToMakeOrDeliverYear0: 0,
    fixedCostsforMonthOrYearYear0: 0,
    percentageOfPriceProfitYear0: 0,
    totalSalesNeededToBreakEvenYear0: 0,
    numberOfUnitsSoldNeededToBreakEvenYear0: 0,
    // financial ratios analysis year 0
    profitMarginYear0: 0,
    returnOnEquityAfterTaxYear0: 0,
    returnOnAssetsYear0: 0,
    grossMarginYear0: 0,
    grossProfitMarginYear0: 0,
    netProfitMarginYear0: 0,
    operatingMarginYear0: 0,
    currentRatioYear0: 0,
    quickRatioYear0: 0,
    cashRatioYear0: 0,
    inventoryTurnoverYear0: 0,
    assetTurnoverYear0: 0,
    receivablesTurnoverYear0: 0,
    ebitYear0: 0,
    ebitdaYear0: 0,
    debtRatioYear0: 0,
    debtToEquityRatioYear0: 0,
    interestCoverageRatioYear0: 0,
    earningsPerShareBasicYear0: 0,
    earningsPerShareDilutedYear0: 0,
    dividendPayoutRatioYear0: 0,
    priceToEarningsRatioYear0: 0,

    // Risk Tab
    technologyRisk: [''],
    intellectualPropertyRisk: [''],
    developmentRisk: [''],
    operationalRisk: [''],
    productFitRisk: [''],
    channelRisk: [''],
    regulatoryRisk: [''],
    competitorRisk: [''],
    teamRisk: [''],
    hiringRisk: [''],
    financingRisk: [''],
    ceoRisk: [''],

    // Management Tab
    // teamExperience: [""],
    // doFoundersHaveInsightsOfCompetitiveLandscape: [""],
    // whereTeamWorked: [""],
    // teamExperienceAmount: [""],
    // contactsInTarget: [""],
    // workDoneRelatingToBusiness: [""],
    // teamAccomplishments: [""],
    // teamSchool: [""],
    // teamAchievements: [""],
    // teamProvenTrackRecord: [""],
    // teamAbilities: [""],
    // teamReputation: [""],
    // teamIntegrity: [""],
    // teamDedication: [""],
    // teamRealisticAboutSuccess: [""],
    // teamCapableOfRecognizingRisks: [""],
    // teamCourageToMakeDecisions: [""],
    // teamWhoIsVisionary: [""],
    // teamOfferingCaution: [""],
    // teamCommitmentToVenture: [""],
    // whatMotivatesTeam: [""],
    // whatAchievementsTeamWant: [""],
    // whatBenefitsTeamWant: [""],

    analysisResult: null
  })

  function reducer(state, action) {
    switch (action.type) {
      case 'initialize': {
        return action.value
      }

      case 'update value to state property': {
        return { ...state, [action.stateName]: action.value }
      }

      case 'push item to state array': {
        return {
          ...state,
          [action.stateName]: [...state[action.stateName], action.value]
        }
      }

      case 'remove item from state array by index': {
        const copiedList = [...state[action.stateName]]
        copiedList.splice(action.index, 1)
        return {
          ...state,
          [action.stateName]: copiedList
        }
      }

      case 'edit item in state array by index': {
        const index = action.index
        const copiedList = [...state[action.stateName]]
        copiedList[index] = action.value
        return {
          ...state,
          [action.stateName]: copiedList
        }
      }

      case 'update pitchDeckId': {
        return { ...state, pitchDeckId: action.value }
      }

      case 'update pitchDeckName': {
        return { ...state, pitchDeckName: action.value }
      }

      // Results
      case 'update analysisResult': {
        return { ...state, analysisResult: action.value }
      }

      default: {
        return 'default'
      }
    }
  }

  return { state, dispatch }
}

export default useBusinessPlanBuilder