import React, { useState } from 'react';
import { Button, Form, ProgressBar, Modal, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SampleImage from '../../../assets/logo/roiquant - image not found.png';
import ReactCountryFlag from 'react-country-flag';
import { S3Media } from '../../third-party-services';
import './investors-card.css';

//pop up card for investors losses, early-stage and very-early stage funding
function InvestorsCard(props) {
    return (
        <div key={props.slug}>
            <div className="investors-cardrow">
                {/* <div className="investors-products" >
                    <a href={'/company/' + props.slug}>
                        {!props.products
                            ? (<img src={SampleImage} alt={props.name} />)
                            : props.products.slice(0, 1).map((product) =>
                                product.images.slice(0, 1).map((image, index) =>
                                    <img src={S3Media + 'assets/photos/investors/' + image.image} alt={props.name} key={index} />
                                ))
                        }
                    </a>
                </div> */}

                <div className="investors-bottom" >
                    <div className="investors-location">
                        {!props.city
                            ? ""
                            : (<a href="#">
                                <span className="investors-tag">
                                    {!props.city ? "" : props.city}</span>
                            </a>
                            )}
                        {!props.state
                            ? ""
                            : (<a href="#">
                                <span className="investors-tag">
                                    {!props.state ? "" : props.state}</span>
                            </a>
                            )}
                        {!props.country
                            ? ""
                            : (<a href="#">
                                <span className="investors-flagicon">
                                    {!props.countryCode
                                        ? ""
                                        : <ReactCountryFlag countryCode={props.countryCode} title={props.country} />
                                    }
                                </span>
                            </a>
                            )}
                    </div>

                    <a href="#">
                        <div className="investors-logobox">
                            {!props.logo
                                ? (<img src={SampleImage} alt={props.slug} className="investors-logo" />)
                                : (<img src={S3Media + 'assets/photos/investors/' + props.logo} alt={props.name} className="investors-logo" />)
                            }
                        </div>
                        <span className="investors-name">
                            <h3>{!props.name ? "" : props.name}</h3>
                        </span>
                        <span className="investors-name2">
                            <p>{!props.description ? "" : props.description}</p>
                        </span>
                    </a>

                    <div className="investors-reasonsection">
                        <div className="investors-fxlayout5">
                            <div className="investors-leftheader">
                                {!props.numOfFailedInvestments
                                    ? ""
                                    : <p style={{ textAlign: "left", fontSize: "13px", color: "grey" }}>
                                        No. of failed investments: <span style={{ textDecoration: "none", fontSize: "13px", color: "black" }}>{!props.numOfFailedInvestments ? "" : props.numOfFailedInvestments}</span>
                                    </p>
                                }
                                {!props.lossOnInvestments
                                    ? ""
                                    : <p style={{ textAlign: "left", fontSize: "13px", color: "grey" }}>
                                        Loss on investments: <span style={{ textDecoration: "none", fontSize: "13px", color: "black" }}>{props.lossOnInvestments}</span>
                                    </p>
                                }
                                {!props.shutteredPortfolioCompanies
                                    ? ""
                                    : (<p style={{ textAlign: "left", fontSize: "13px", color: "grey" }}>
                                        Shuttered portfolio companies:
                                        <ol style={{ paddingLeft: "17px", marginBottom: "0px", fontSize: "13px", color: "black" }}>{props.shutteredPortfolioCompanies.slice(0, 5).map((portfolio, index) =>
                                            !portfolio
                                                ? ""
                                                : (<li><span key={index} >{portfolio.name}</span></li>)
                                        )}</ol>
                                    </p>)
                                }
                                {!props.portfolioCompanies
                                    ? ""
                                    : (<p style={{ textAlign: "left", fontSize: "13px", color: "grey" }}>
                                        Portfolio companies:
                                        <ol style={{ paddingLeft: "17px", marginBottom: "0px", fontSize: "13px", color: "black" }}>{props.portfolioCompanies.slice(0, 5).map((portfolio, index) =>
                                            !portfolio
                                                ? ""
                                                : (<li><span key={index} >{portfolio.name}</span></li>)
                                        )}</ol>
                                    </p>)
                                }
                                {!props.investmentStage
                                    ? ""
                                    : (<p style={{ textAlign: "left", fontSize: "13px", color: "grey" }}>
                                        Preferred investment stage(s):
                                        <ol style={{ paddingLeft: "17px", fontSize: "13px", color: "black" }}>{props.investmentStage.map((stage, index) =>
                                            !stage
                                                ? ""
                                                : (<li><span key={index} > {stage} </span></li>)
                                        )}</ol>
                                    </p>)
                                }
                                {!props.investmentTrackRecord
                                    ? ""
                                    : (<td style={{ textAlign: "left", fontSize: "13px", color: "grey" }}>
                                        Stages invested in:
                                        <ol style={{ paddingLeft: "17px", fontSize: "13px", color: "black" }}>
                                            {!props.investmentTrackRecord.veryEarlyStage
                                                ? ""
                                                : <li>Very-early stage: {props.investmentTrackRecord.veryEarlyStage}</li>
                                            }
                                            {!props.investmentTrackRecord.earlyStage
                                                ? ""
                                                : <li>Early stage: {props.investmentTrackRecord.earlyStage}</li>
                                            }

                                            {!props.investmentTrackRecord.growthStage
                                                ? ""
                                                : <li>Growth stage: {props.investmentTrackRecord.growthStage}</li>
                                            }

                                            {!props.investmentTrackRecord.lateStage
                                                ? ""
                                                : <li>Late stage: {props.investmentTrackRecord.lateStage}</li>
                                            }

                                            {!props.investmentTrackRecord.postIPO
                                                ? ""
                                                : <li>Post-IPO: {props.investmentTrackRecord.postIPO}</li>
                                            }
                                        </ol>
                                    </td>)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="investors-tag">
                        {!props.investorType
                            ? ""
                            : props.investorType.slice(0, 3).map((items, index) =>
                                <a href="#" key={index} className="investors-industrybutton">{items}</a>
                            )
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default InvestorsCard;