import React from "react";
import * as Realm from "realm-web";

const RealmAppContext = React.createContext();

export const useRealmApp = () => {
    const app = React.useContext(RealmAppContext);
    if (!app) {
        throw new Error('You must call useRealmApp() inside of a <RealmAppProvider />');
    }
    return app;
}

export const RealmAppProvider = ({ appId, children }) => {

    const realm = new Realm.App(appId);

    const [app, setApp] = React.useState(realm);

    React.useEffect(() => {
        setApp(new Realm.App(appId));
    }, [appId]);

    // Wrap the Realm.App object's user state with React state
    const [currentUser, setCurrentUser] = React.useState(app.currentUser);


    function getUserTier() {
        const customData = app.currentUser.customData;
        const tierString = customData?.tier?.$numberInt; // e.g. {$numberLong: "1"}
        let tier;

        switch (tierString) {
            case "1":
                tier = 1;
                break;

            case "2":
                tier = 2;
                break;

            case "3":
                tier = 3;
                break;

            case "4":
                tier = 4;
                break;

            default:
                switch (customData?.tier?.$numberLong) {
                    case "1":
                        tier = 1;
                        break;

                    case "2":
                        tier = 2;
                        break;

                    case "3":
                        tier = 3;
                        break;

                    case "4":
                        tier = 4;
                        break;

                    default:
                        tier = 1;
                }
        }

        return tier;
    }

    function getUserDetails() {
        const customData = app.currentUser.customData;
        const username = customData?.name;
        const userdob = customData?.dob;
        const usergender = customData?.gender;
        const userreferredby = customData?.referredBy;
        // const useranonymouslink = customData?.anonymousLink;
        // const userpersonalisedlink = customData?.personalisedLink;
        return {
            name: username, dob: userdob, gender: usergender, referredBy: userreferredby
            // anonymousLink: useranonymouslink, personalisedLink: userpersonalisedlink 
        };
    }

    async function refreshUserDetails() {
        await app.currentUser.refreshCustomData();

        const customData = app.currentUser.customData;
        const username = customData?.name;
        const userdob = customData?.dob;
        const usergender = customData?.gender;
        const userreferredby = customData?.referredBy;
        // const useranonymouslink = customData?.anonymousLink;
        // const userpersonalisedlink = customData?.personalisedLink;

        return {
            name: username, dob: userdob, gender: usergender, referredBy: userreferredby,
            // anonymousLink: useranonymouslink, personalisedLink: userpersonalisedlink
        };
    }

    async function logIn(email, password) {

        const credentials = Realm.Credentials.emailPassword(email, password)
        // Log in the user
        await app.logIn(credentials)

        // If successful, app.currentUser is the user that just logged in
        setCurrentUser(app.currentUser)

    }

    async function logOut() {

        // Log out the currently active user
        await app.currentUser?.logOut()

        // If another user was logged in too, they're now the current user.
        // Otherwise, app.currentUser is null.
        setCurrentUser(app.currentUser)
    }

    // To register a new email/password user
    async function register(email, password) {

        await app.emailPasswordAuth.registerUser(email, password)

    }

    function isLoggedIn() {
        if (!app.currentUser) return false
        return true
    }

    // To send a new confirmation email to a user
    async function resendConfirmation(email) {

        await app.emailPasswordAuth.retryCustomConfirmation(email);
    }

    // To confirm a registered user
    // Note: The provider must be set to send a confirmation email, the token and tokenId values are included as query parameters in the Email Confirmation URL.
    async function confirm(token, tokenId) {
        await app.emailPasswordAuth.confirmUser(token, tokenId)
    }

    // To send a password reset email to a user
    // Note: The provider must be configured to run a reset password function
    async function sendResetPwdEmail(email, newPassword) {

        await app.emailPasswordAuth.callResetPasswordFunction(email, newPassword)
    }

    // To complete the password reset
    // Note: Must be done within 30 minutes of the initial request
    // Note: The provider must be configured to use the built-in password reset email. The token and tokenId are
    // included as query query parameters in the password reset URL
    async function completePwdReset(newPassword, token, tokenId) {
        await app.emailPasswordAuth.resetPassword(newPassword, token, tokenId);
    }


    const wrapped = { ...app, currentUser, logIn, logOut, getUserTier, getUserDetails, refreshUserDetails, register, resendConfirmation, confirm, sendResetPwdEmail, completePwdReset, isLoggedIn };

    return (
        <RealmAppContext.Provider value={wrapped}>
            {children}
        </RealmAppContext.Provider>
    );
};