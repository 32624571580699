import React, { useEffect, useState, useReducer, useRef } from "react";
import { Form, Alert, Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useRealmApp } from '../../../../RealmApp';
import { Steps } from 'intro.js-react';
import { steps } from './components/editor-intro.js';
import NavBar from '../../../../layout/navbar/navbar';
import { styleManager, pageManager, createStorageManager } from './components/editor-components.js';
import grapesjs from 'grapesjs';
import gjsPresetWebpage from "grapesjs-preset-webpage";
import gjsCustomCodePlugin from 'grapesjs-custom-code';
import gjsTyped from 'grapesjs-typed';
import gjsStyleFilter from 'grapesjs-style-filter';
import gjsBlocksFlexbox from 'grapesjs-blocks-flexbox';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { BsArrowsFullscreen, BsExclamationTriangle } from 'react-icons/bs';
import { BiAnalyse } from 'react-icons/bi';
import { FaEllipsisH } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import 'intro.js/introjs.css';
import './no-code-builder.css';

// This is the create page for no code builder 
const CreateNoCodeBuilder = () => {

  const { currentUser, getUserTier } = useRealmApp();
  const projName = useRef(null);
  const history = useHistory();
  const [editor, setEditor] = useState(null);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showClearCanvas, setshowClearCanvas] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [initialStep, setInitialStep] = useState(0);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");

  const [state, dispatch] = useReducer(reducer, {
    projectId: "",
    projectName: "",
    projectData: {},
    assets: {},
    htmlFiles: {},
    cssFiles: {}
  });

  function reducer(state, action) {
    switch (action.type) {
      case 'update projectId': { return { ...state, projectId: action.value } }
      case 'update projectName': { return { ...state, projectName: action.value } }
      case 'update projectData': { return { ...state, projectData: action.value } }
      case 'update assets': { return { ...state, assets: action.value } }
      case 'update htmlFiles': { return { ...state, htmlFiles: action.value } }
      case 'update cssFiles': { return { ...state, cssFiles: action.value } }
      default: { return 'default'; }
    }
  }

  const { saveNoCodeDocument, createId, saveFilesToAtlas } = currentUser.functions;

  const onExit = () => {
    setEnabled(false);
  };

  function closeAlert() {
    setAlert(false);
    setMessage("");
  };

  function handleClose() {
    history.replace("/tools/founders/no-code-builder");
  };

  function displaySaveCard() {
    setShowConfirmSave(true);
  };

  const [showUpgradePlan, setShowUpgradePlan] = useState(false);

  function hideUpgradePlan() {
    setShowUpgradePlan(false);
  }

  function displayPublishCard() {
    if (getUserTier() === 2) {
      setShowUpgradePlan(true);
    }
    else {
      setAlert(true);
      setMessage(<div style={{ paddingTop: "10px" }}>
        <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
        <p style={{ marginLeft: "28px" }}>Publish feature will be available after saving the project.</p>
      </div>);
    }
  };

  function displayClearCanvas() {
    setshowClearCanvas(true);
  };

  function hideSaveCard() {
    setShowConfirmSave(false);
  };

  function hideClearCanvas() {
    setshowClearCanvas(false);
  };

  function handleClearCanvas() {
    setshowClearCanvas(false);
    editor.DomComponents.clear();
  };

  function hideMobileAlert() {
    setIsMobile(false);
  };

  async function handleSave() {
    // if project name is available, save project to nocode_project collection
    if (projName.current.value) {
      setShowConfirmSave(false);
      setAlert(true);
      setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
          <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Saving</p>
          <p style={{ marginLeft: "28px" }}>We'll notify you once your updated project is saved.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
          <Spinner animation="border" variant="secondary" />
        </div>
      </div>);

      try {
        await saveNoCodeDocument(state);
        // console.log("Realm function saveNoCodeDocument executed!");
      }
      catch (err) {
        // console.log(err);
      }

      try {
        const input = {
          projectId: state.projectId,
          projectName: state.projectName,
          htmlFiles: state.htmlFiles,
          cssFiles: state.cssFiles,
          createNewDistribution: true
        };
        // save html/css files to nocode_files. files will be simultaneously uploaded to s3 and create distribution
        await saveFilesToAtlas(input);

        setAlert(true);
        setMessage(<div style={{ paddingTop: "10px" }}>
          <p style={{ color: "#1f5f8b" }}><AiOutlineCheckCircle className="alert-icon" /> Saved</p>
          <p style={{ marginLeft: "28px" }}>Your project is saved.</p>
        </div>);

        //  redirect to list of projects on landing page
        // history.replace("/tools/founders/no-code-builder");

      } catch (err) {
        setAlert(true);
        setMessage(<div style={{ paddingTop: "10px" }}>
          <p style={{ fontSize: "14px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
          <p style={{ marginLeft: "28px", fontSize: "14px" }}>{err.error}</p>
        </div>);
      }
    }
    else {
      setShowConfirmSave(false);
      setAlert(true);
      setMessage(<div style={{ paddingTop: "10px" }}>
        <p style={{ fontSize: "14px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
        <p style={{ marginLeft: "28px", fontSize: "14px" }}>New project must be named before saving.</p>
      </div>);
    }
  };

  // Dim screen and alert user so the no code builder is not usable when screen size is less than 1200px
  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Ask realm for a new unique ID for the no code builder
  useEffect(() => {
    if (!state.projectId) {
      async function getNoCodeId() {
        const id = await createId();
        dispatch({ type: 'update projectId', value: id });
      }
      try {
        getNoCodeId();
      } catch (err) {
        // console.error("Unable to generate unique ID");
      }
    }
  }, [
    // state.projectId, createId // The list is commented out to prevent createId from running twice!
  ]);

  // Load instance of grapesjs editor 
  useEffect(() => {
    if (!editor) {
      const editor =
        grapesjs.init({
          container: '#editor',
          pageManager: pageManager,
          styleManager: styleManager,
          storageManager: createStorageManager,
          plugins: [gjsPresetWebpage, gjsCustomCodePlugin, gjsTyped, gjsStyleFilter, gjsBlocksFlexbox],
          pluginsOpts: {
            gjsPresetWebpage: {},
            gjsCustomCodePlugin: {},
            gjsTyped: {},
            gjsStyleFilter: {},
            gjsBlocksFlexbox: {}
          }
        });

      // Replace storage
      editor.Storage.add('remote', {
        async store() {
          try {
            dispatch({ type: 'update projectData', value: editor.getProjectData() });
            dispatch({ type: 'update assets', value: editor.getProjectData().assets });

            displaySaveCard();
          }
          catch (err) {
            // console.log(err);
          }
        }
      });

      // set visibility of components to true by default (dotted lines)
      editor.Panels.getButton('options', 'sw-visibility').set('active', true);

      // If you want to remove other default button, first get panel ID, then get button's ID in panel's log, then remove button by it's ID
      // e.g : var myPanel = editor.Panels.getPanel('options'); console.log(myPanel);
      // Remove buttons from panel, to undo, just remove the 'removeButton' line
      editor.Panels.removeButton('options', 'canvas-clear');

      // blocked features for standard plan users
      if (getUserTier() === 2) {
        editor.Panels.removeButton('options', 'export-template');
        editor.Panels.removeButton('options', 'gjs-open-import-webpage');
        editor.Panels.addButton('options', [
          {
            id: 'export-template',
            className: 'fa fa-code',
            command: function openUpgradePlan(e) {
              setShowUpgradePlan(true);
            },
          },
          {
            id: 'gjs-open-import-webpage',
            className: 'fa fa-download',
            command: function openUpgradePlan(e) {
              setShowUpgradePlan(true);
            },
            attributes: { title: 'Import code' }
          },
        ]);
      }

      // Add new buttons to different panel id
      editor.Panels.addButton('options', [
        {
          id: 'Delete',
          className: 'fa fa-trash',
          command: 'canvas-clear',
          attributes: { title: 'Clear canvas' }
        },
        {
          id: 'Info',
          className: 'fa fa-info-circle',
          command: 'guided-tour',
          attributes: { title: 'Info' }
        },
        {
          id: 'Save',
          label: '<small>Save</small>',
          className: 'save-btn',
          command: function saveProject(e) {
            editor.store();
            // This button will merge with 'deploy project' function to speed up the distribution in the backend being created
            const getPages = editor.getProjectData().pages;

            let htmlPage = []; let tmpHtml = []; let cssPage = []; let tmpCss = [];
            let htmlPages = { pageName: [], value: [] };
            let cssPages = { pageName: [], value: [] };

            // For every page 
            getPages.forEach(function (i, array) {
              let pageName = i.name;
              let pageID = i.id;

              // Select it's page id and get it's html and css, ensure that each html pages have '.html' & css pages have '.css' 
              editor.Pages.select(pageID);
              htmlPage = pageName + `.html`
              // I have to add in the <!doctype..> as it's not included when files are being exported from grapesjs. We also need to specify the css file in the stylesheet setting
              tmpHtml = `<!doctype html><html lang="en">
                         <head>
                          <meta charset="utf-8">
                          <link rel="stylesheet" href="./css/` + pageName + `.css` + `"></head>
                          ` + editor.getHtml() + `</body><html>`;

              cssPage = pageName + `.css`
              tmpCss = editor.getCss();

              // Push html pages to 'htmlPages' array
              htmlPages.pageName.push(htmlPage);
              htmlPages.value.push(tmpHtml);

              // Push css pages to 'cssPages' array
              cssPages.pageName.push(cssPage);
              cssPages.value.push(tmpCss);
            });

            // Update 'cssFiles' & 'htmlFiles' state
            dispatch({ type: 'update htmlFiles', value: htmlPages });
            dispatch({ type: 'update cssFiles', value: cssPages });

            displaySaveCard();
          },
          attributes: { title: 'Save work' }
        },
        {
          id: 'Publish',
          className: 'publish-btn',
          label: '<small>Publish</small>',
          command: function saveHTMLCSS() {
            displayPublishCard();
          },
          attributes: { title: 'Publish work' }
        },
      ]);

      editor.Commands.add('guided-tour', editor => {
        setEnabled(true);
      });

      editor.Commands.add('canvas-clear', editor => {
        displayClearCanvas();
      });

      let editPanel = null;
      editor.Panels.addButton('views',
        {
          id: 'Page',
          attributes: { class: 'fa fa-file', title: "Pages" },
          active: false,
          command: {
            run: function (editor) {
              if (editPanel == null) {
                const editMenuDiv = document.createElement('div')
                editMenuDiv.innerHTML = `
                      <div id="your-content" >
                          <div class="pages"> Pages </div>                         
                          <div class="pages-inputbox">
                           <input class="pages-input" type="text" id="newPageName" placeholder="Enter new page name"/>
                           <button class="pages-btn" id="addPage" type="button"> Add </button>
                           </div>
                            <div class="pages-list">
                            <table class="pages-table">
                            <th class="pages-th" colspan="3"> List of pages </th>
                                <tbody id="pageContainer">
                                </tbody>
                              </table>
                            </div>
                      </div>
                  `
                const panels = editor.Panels.getPanel('views-container')
                panels.set('appendContent', editMenuDiv).trigger('change:appendContent')
                editPanel = editMenuDiv
              };

              const addPageBtn = document.getElementById('addPage');
              const pageContainer = document.getElementById('pageContainer');
              addPageBtn.addEventListener("click", addNewPage);

              // Get current list of pages
              const arrayOfPages = editor.getProjectData();
              let getPages = arrayOfPages.pages;

              pageContainer.innerHTML = getPages.map((page) => (
                "<tr class=pages-tr><td class=pages-first-td> </td><td class=pages-second-td> " + page.name + "</td><td class=pages-third-td value=" + page.id + "> </td></tr>")).join(' ');

              // Get all td in pageContainer and convert to array
              const list = Array.from(document.getElementsByClassName("pages-third-td"));
              // For each td in list, add edit/delete button
              list.forEach(function (i) {
                // Create edit button
                var editButton = document.createElement('button');
                editButton.appendChild(document.createTextNode('edit'));
                editButton.type = "button";
                editButton.style = "padding:5px;"
                editButton.innerHTML = '<i class="fa fa-edit" aria-hidden="true"></i>'
                editButton.value = i.attributes.value.value;
                editButton.addEventListener('click', function (e) {
                  editor.Pages.select(e.target.value);
                });
                i.appendChild(editButton);
                // Create delete button
                var deleteButton = document.createElement('button');
                deleteButton.appendChild(document.createTextNode('delete'));
                deleteButton.type = "button";
                deleteButton.style = "padding:5px;"
                deleteButton.innerHTML = ' <i class="fa fa-trash" aria-hidden="true"></i>'
                deleteButton.value = i.attributes.value.value;
                deleteButton.addEventListener('click', function (e) {
                  // Delete page from grapesjs
                  editor.Pages.remove(e.target.value);
                  // Once deleted, it will go to the main page so it will not stay in the empty page
                  const mainPage = editor.Pages.getMain();
                  editor.Pages.select(mainPage);
                  // remove no., name and button element from dom by targetting parent node of this element
                  let removeEl = this.parentNode.parentNode;
                  pageContainer.removeChild(removeEl);

                });
                i.appendChild(deleteButton);
              });

              function addNewPage(e) {
                e.preventDefault();

                let newPageInput = document.getElementById('newPageName').value;
                if (newPageInput !== "") {
                  let addNewPage = editor.Pages.add({
                    id: '',
                    name: newPageInput,
                    styles: `.my-class { color: green }`,
                    component: '<div class="my-class">My new page</div>',
                  });
                  // go to new page once added
                  editor.Pages.select(addNewPage.id);
                  // clear input field once submitted
                  const newPageInputBox = document.getElementById('newPageName');
                  newPageInputBox.value = '';

                  // currently have to add this section again, as DOM are not updated with new page list unless you clicked on other panel first
                  const arrayOfPages = editor.getProjectData();
                  let getPages = arrayOfPages.pages;

                  pageContainer.innerHTML = getPages.map((page) => (
                    "<tr class=pages-tr><td class=pages-first-td> </td><td class=pages-second-td> " + page.name + "</td><td class=pages-third-td value=" + page.id + "> </td></tr>")).join(' ');

                  // get all td in pageContainer and convert to array
                  const newList = Array.from(document.getElementsByClassName("pages-third-td"));
                  // For every list in array, add an edit and delete button
                  newList.forEach(function (i) {
                    var editButton = document.createElement('button');
                    editButton.appendChild(document.createTextNode('edit'));
                    editButton.type = "button";
                    editButton.style = "padding:5px;"
                    editButton.innerHTML = '<i class="fa fa-edit" aria-hidden="true"></i>'
                    editButton.value = i.attributes.value.value;
                    editButton.addEventListener('click', function (e) {
                      editor.Pages.select(e.target.value);
                    });
                    i.appendChild(editButton);

                    var deleteButton = document.createElement('button');
                    deleteButton.appendChild(document.createTextNode('delete'));
                    deleteButton.type = "button";
                    deleteButton.style = "padding:5px;"
                    deleteButton.innerHTML = '<i class="fa fa-trash" aria-hidden="true"></i>'
                    deleteButton.value = i.attributes.value.value;
                    deleteButton.addEventListener('click', function (e) {
                      // Delete page from grapesjs
                      editor.Pages.remove(e.target.value);
                      // Once deleted, it will go to the main page so it will not stay in the empty page
                      const mainPage = editor.Pages.getMain();
                      editor.Pages.select(mainPage);
                      // remove no., name and button element from dom by targetting parent node of this element
                      let removeEl = this.parentNode.parentNode;
                      pageContainer.removeChild(removeEl);

                    });
                    i.appendChild(deleteButton);
                  });
                }
              };

              editPanel.style.display = 'block'
            },
            stop: function (editor) {
              if (editPanel != null) {
                editPanel.style.display = 'none'
              }
            }
          }
        });

      // Add code to auto close block's categories
      const timer = setTimeout(() => {
        let categories = editor.BlockManager.getCategories();
        categories.each((category) => category.set("open", false));
      }, 1000);

      // When a block is dragged to canvas
      editor.on('component:add', component => {
        //check if it's a form
        const checkComp = component.is('form');
        //if true, put https endpoint as our form's action
        if (checkComp) {
          component.addAttributes(
            {
              'enctype': 'application/x-www-form-urlencoded', required: false,
              'action': 'https://y4gpawpxgtmf63tgbq23aulktq0ogqda.lambda-url.eu-west-1.on.aws/', required: false
            });
        }
      });

      // Add filter to style manager under 'extra'
      editor.StyleManager.addProperty('extra', { extend: 'filter' });
      editor.StyleManager.addProperty('extra', { extend: 'filter', property: 'backdrop-filter' });

      setEditor(editor);

      window.addEventListener('resize', handleResize);

      return () => {
        clearTimeout(timer)
      };
    }
  }, [editor]);

  return (
    <>
      <NavBar />

      {/* Put "isMobile" logic here so that overall page will dim with alert / non functional if it's lesser than 1200px */}
      <div id="nocodebuilder" className={`${isMobile ? 'is-mobile' : ''}`}>

        {/* Logic to avoid the guided tour being rendered first time, only after user click it*/}
        <Steps enabled={enabled} steps={steps} initialStep={initialStep} onExit={onExit} options={{ hideNext: false }} />

        {isMobile
          ? <Modal className="modal-div" show={isMobile} onHide={hideMobileAlert} backdrop="static" fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header style={{ borderBottom: "none", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}> <BsArrowsFullscreen /></Modal.Header>
            <Modal.Body>
              <div>
                <p>Your browser is too small.</p>
                <p>Resize your browser to be at least 1200px wide to get back into design mode.</p>
              </div>
            </Modal.Body>
          </Modal>
          : ""
        }
        <Container className="col-11" style={{ paddingBottom: "200px" }}>

          <div className="pitchdeckheaderdiv">
            <div className="pitchdecktitlediv">
              <br />
              <span className="pitchdeckcreatetext">
                Title
              </span>
              <br />
              <Form>
                <Form.Group>
                  <Form.Control
                    placeholder="Give this work a name"
                    className="pitchdecktitlefield"
                    ref={projName}
                    onChange={(e) => { dispatch({ 'type': 'update projectName', 'value': e.target.value }) }}
                  />
                </Form.Group>
              </Form>
            </div>

            <div className="pitchdeckstatsdiv">
              <button onClick={() => handleClose()} className="pitchdeckclosebutton">
                <span>Close</span>
              </button>

              <FaEllipsisH style={{ marginLeft: "20px" }} />
            </div>
          </div>

          <div className='nocodemaincontent'>
            {alert
              ? (<Alert className="error-message floating-alert" variant="light">
                <div>
                  <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                  {message}
                </div>
              </Alert>)
              : ""
            }
            <div id='editor'></div>

          </div>
        </Container>
      </div>

      {/* Pop up to confirm save page */}
      <Modal className="modal-div" show={showConfirmSave} onHide={hideSaveCard} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton style={{ borderBottom: "none" }} />
        <Modal.Body>

          <p style={{ textAlign: "center" }}>Are you sure you want to create this project?</p>

          <div style={{ textAlign: "center" }}>
            <button className="cancelbutton" onClick={hideSaveCard}>Cancel</button>

            <button className="orderbutton glintanimation" onClick={handleSave}>
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Pop up to clear canvas */}
      <Modal className="modal-div" show={showClearCanvas} onHide={hideClearCanvas} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton style={{ borderBottom: "none" }} />
        <Modal.Body>

          <p style={{ textAlign: "center" }}>Are you sure you want to clear the canvas?</p>

          <div style={{ textAlign: "center" }}>
            <button className="cancelbutton" onClick={hideClearCanvas}>Cancel</button>

            <button className="modal-delete glintanimation" onClick={handleClearCanvas}>
              Clear
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* pop-up to block feature */}
      <Modal className="modal-div" show={showUpgradePlan} onHide={hideUpgradePlan} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton style={{ borderBottom: "none" }} />
        <Modal.Body>

          <Alert className="error-message" variant="danger" style={{ fontSize: "16px" }}>
            <BsExclamationTriangle /> Sorry, this feature is not included in your Standard Plan
          </Alert>

          <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Get started with Premium Plan at USD $25 per month</p>

          <div style={{ textAlign: "center" }}>
            <a href="https://www.roiquant.com/pricing" target="_blank" rel="noopener noreferrer">
              <Button className="modal-compare glintanimation">Compare plans</Button>
            </a>
          </div>

          <hr style={{ margin: "1.5rem 0" }} />

          <p style={{ fontWeight: "bold", marginBottom: "0" }}>Things to know:</p>

          <ul className="modal-list">
            <li>Downgrade your subscription plan at any time</li>
            <li>Once payment is made, there is no refund</li>
            <li>If you downgrade or cancel, you will no longer have access to the features that are only available in the subscription plan which you downgraded or cancelled from</li>
          </ul>

          <div style={{ textAlign: "center" }}>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <Button className="modal-product glintanimation">Learn more about the product</Button>
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateNoCodeBuilder