import LoginForm from './login-form'
import React, { useState, useEffect } from 'react'
import validator from 'validator'
import { useHistory } from 'react-router-dom'
import { useRealmApp } from '../../RealmApp'
import isStrongPassword from 'validator/lib/isStrongPassword';
import isLength from 'validator/lib/isLength';
import { Button, Alert, Spinner } from 'react-bootstrap'
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { AiOutlineCloseCircle } from 'react-icons/ai';

function LoginPage() {

    const history = useHistory();

    const { logIn, resendConfirmation, isLoggedIn } = useRealmApp();

    useEffect(() => {
        if (isLoggedIn()) history.push('/dashboard');
    }, [])

    const [credentials, setCredentials] = useState({
        email: "",
        password: ""
    });

    // Keep track of input validation/errors
    const [response, setResponse] = useState("")

    async function handleLogin(credentials) {



        // Login the user

        setResponse(
            <Alert className="error-message floating-alert" variant="light">
                <div>
                    <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                        onClick={(event) => {
                            event.preventDefault()
                            setResponse("")
                        }}>
                        <IoMdClose /></Button></div>
                    <div style={{ display: "flex", paddingTop: "10px" }}>
                        <div style={{ width: "90%" }}>
                            <p><span style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</span></p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Attempting to log in</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                        </div>
                        <div style={{ width: "10%", textAlign: "right" }}>
                            <Spinner animation="border" variant="secondary" />
                        </div>
                    </div>
                </div>
            </Alert>
        )

        try {

            await logIn(credentials.email, credentials.password)

            history.push('/dashboard')

        }
        catch (error) {

            if (error.error) {

                switch (error.error) {

                    case "invalid username/password":
                        setResponse(<Alert className="error-message floating-alert" variant="light">
                            <div>
                                <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                                    onClick={(event) => {
                                        event.preventDefault()
                                        setResponse("")
                                    }}><IoMdClose /></Button></div>
                                <div style={{ paddingTop: "10px" }}>
                                    <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                    <p style={{ marginLeft: "28px", fontSize: "14px" }}>The sign-in information is not correct. Please enter a correct email or password, and try again.</p>
                                    <p style={{ marginLeft: "28px", fontSize: "14px" }}>If you still have trouble logging in, you may try to <a href="/reset-password">reset your password,</a> or <a href="/create-account">create a new account,</a> or <a href="/contact">contact us.</a></p>
                                </div>
                            </div>
                        </Alert>);
                        break

                    case "confirmation required":
                        setResponse(<Alert className="error-message floating-alert" variant="light">
                            <div>
                                <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                                    onClick={(event) => {
                                        event.preventDefault()
                                        setResponse("")
                                    }}><IoMdClose /></Button></div>
                                <div style={{ paddingTop: "10px" }}>
                                    <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Account verification</p>
                                    <p style={{ marginLeft: "28px", fontSize: "14px" }}>Your account is not verified. Please check the email we've sent you for instruction to verify your account.</p>
                                    <p style={{ marginLeft: "28px", fontSize: "14px" }}>For security reasons, you need to verify your account in order to use our services.</p>
                                    <div style={{ textAlign: "right" }}>
                                        <Button className="thankyouloginbtn glintanimation"
                                            onClick={(event) => {
                                                event.preventDefault()
                                                handleResendConfirmation(credentials.email)
                                            }}>
                                            Re-send verification email
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Alert>);
                        break




                    default:
                        setResponse(
                            <Alert className="error-message floating-alert" variant="light">
                                <div>
                                    <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                                        onClick={(event) => {
                                            event.preventDefault()
                                            setResponse("")
                                        }}>
                                        <IoMdClose /></Button></div>
                                    Unhandled service error: {error.error}
                                </div>
                            </Alert>
                        )
                }

            }
            else {
                setResponse(
                    <Alert className="error-message floating-alert" variant="light">
                        <div>
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                                onClick={(event) => {
                                    event.preventDefault()
                                    setResponse("")
                                }}>
                                <IoMdClose /></Button></div>
                            Unhandled error: {error}
                        </div>
                    </Alert>
                )
            }

        }

    }

    async function handleResendConfirmation(email) {

        setResponse(
            <Alert className="error-message floating-alert" variant="light">
                <div>
                    <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                        onClick={(event) => {
                            event.preventDefault()
                            setResponse("")
                        }}>
                        <IoMdClose /></Button></div>
                    <div style={{ display: "flex", paddingTop: "10px" }}>
                        <div style={{ width: "90%" }}>
                            <p><span style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</span></p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Attempting to log in</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                        </div>
                        <div style={{ width: "10%", textAlign: "right" }}>
                            <Spinner animation="border" variant="secondary" />
                        </div>
                    </div>
                </div>
            </Alert>
        )



        try {


            await resendConfirmation(email)




            setResponse(
                <Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                            onClick={(event) => {
                                event.preventDefault()
                                setResponse("")
                            }}>
                            <IoMdClose /></Button></div>
                        Confirmation email sent
                    </div>
                </Alert>

            )


        } catch (error) {

            if (error.error) {
                setResponse(
                    <Alert className="error-message floating-alert" variant="light">
                        <div>
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                                onClick={(event) => {
                                    event.preventDefault()
                                    setResponse("")
                                }}>
                                <IoMdClose /></Button></div>
                            Unhandled service error: {error.error}
                        </div>
                    </Alert>

                )

            }
            else {
                setResponse(
                    <Alert className="error-message floating-alert" variant="light">
                        <div>
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                                onClick={(event) => {
                                    event.preventDefault()
                                    setResponse("")
                                }}>
                                <IoMdClose /></Button></div>
                            Unhandled error
                        </div>
                    </Alert>

                )
            }


        }

    }


    //Password validation check function
    function checkPwdLength(password) {
        //Check if password is atleast 8 characters
        return isLength(password, { min: 8 })
    }

    function measurePwdLength(password) {
        //Measure the password length
        return password.length
    }

    function checkPwdSymbol(password) {
        //Check if password contains at least 1 symbol
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 0, minSymbols: 1, minLowercase: 0 })
    }

    function checkPwdUppercase(password) {
        //Check if password contains at least 1 uppercase
        return isStrongPassword(password, { minUppercase: 1, minLength: 0, minNumbers: 0, minSymbols: 0, minLowercase: 0 })
    }

    function checkPwdLowercase(password) {
        //Check if password contains at least 1 lowercase
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 0, minSymbols: 0, minLowercase: 1 })
    }

    function checkPwdNum(password) {
        //Check if password contains at least 1 number
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 1, minSymbols: 0, minLowercase: 0 })
    }

    return (
        <LoginForm handleLogin={handleLogin} credentials={credentials} setCredentials={setCredentials} response={response}
            handleResendConfirmation={handleResendConfirmation} checkPwdLength={checkPwdLength}
            checkPwdSymbol={checkPwdSymbol} checkPwdUppercase={checkPwdUppercase} checkPwdLowercase={checkPwdLowercase}
            checkPwdNum={checkPwdNum} measurePwdLength={measurePwdLength}
        />
    )
}

export default LoginPage