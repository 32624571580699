import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { table } from './table'
import './funding-benchmark.css'

const ViewVCFundingBenchmarks = props => {

    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY === 0) {
                setScroll(false);
            }
            else {
                setScroll(true);
            }
        });
    }, []);

    return (
        <>
            <Card>
                <Card.Body id='companyinformation'>
                    <table className='fundingBenchmarkTable view' style={{ overflowX: 'auto' }}>

                        {
                            scroll
                                ? <tbody className="rowSticky">
                                    <tr className="rowFirst">
                                        <td style={{ backgroundColor: '#e3e7f1' }}>Business development phase</td>

                                        {table['Business development phase'].map((item, index) => (
                                            <td key={index} colSpan={item.col} style={{ fontWeight: 'bold', backgroundColor: '#fff2cc' }}>{item.name}</td>
                                        ))}
                                    </tr>

                                    <tr className="rowSecond">
                                        <td style={{ backgroundColor: '#e3e7f1' }}>Core competency</td>

                                        {table['Core competency'].map((item, index) => (
                                            <td key={index} colSpan={item.col} style={{ fontWeight: 'bold', backgroundColor: '#e1e1fa' }}>{item.name}</td>
                                        ))}
                                    </tr>

                                    <tr className="rowThird">
                                        <td style={{ backgroundColor: '#e3e7f1' }}>Validation type</td>

                                        {table['Validation type'].map((item, index) => (
                                            <td key={index} colSpan={item.col} style={{ fontWeight: 'bold', backgroundColor: '#ffd1d1' }}>{item.name}</td>
                                        ))}
                                    </tr>

                                    <tr className="rowFourth">
                                        <td style={{ backgroundColor: '#e3e7f1' }}>Funding round</td>

                                        {table['Funding round'].map((item, index) => (
                                            <td key={index} colSpan={item.col} style={{ fontWeight: 'bold', backgroundColor: 'aliceblue' }}>{item.name}</td>
                                        ))}
                                    </tr>
                                </tbody>
                                : <tbody>
                                    <tr>
                                        <td style={{ backgroundColor: '#e3e7f1' }}>Business development phase</td>

                                        {table['Business development phase'].map((item, index) => (
                                            <td key={index} colSpan={item.col} style={{ fontWeight: 'bold', backgroundColor: '#fff2cc' }}>{item.name}</td>
                                        ))}
                                    </tr>

                                    <tr>
                                        <td style={{ backgroundColor: '#e3e7f1' }}>Core competency</td>

                                        {table['Core competency'].map((item, index) => (
                                            <td key={index} colSpan={item.col} style={{ fontWeight: 'bold', backgroundColor: '#e1e1fa' }}>{item.name}</td>
                                        ))}
                                    </tr>

                                    <tr>
                                        <td style={{ backgroundColor: '#e3e7f1' }}>Validation type</td>

                                        {table['Validation type'].map((item, index) => (
                                            <td key={index} colSpan={item.col} style={{ fontWeight: 'bold', backgroundColor: '#ffd1d1' }}>{item.name}</td>
                                        ))}
                                    </tr>

                                    <tr>
                                        <td style={{ backgroundColor: '#e3e7f1' }}>Funding round</td>

                                        {table['Funding round'].map((item, index) => (
                                            <td key={index} colSpan={item.col} style={{ fontWeight: 'bold', backgroundColor: 'aliceblue' }}>{item.name}</td>
                                        ))}
                                    </tr>
                                </tbody>
                        }

                        <tbody>
                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Goal</td>

                                {table['Goal'].map((item, index) => (
                                    <td key={index} colSpan={item.col} style={{ fontWeight: 'bold', backgroundColor: '#ead1dc' }}>{item.name}</td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Entry criteria for each phase</td>

                                {table['Entry criteria for each phase'].map((item, index) => (
                                    <td key={index} colSpan={item.col} style={{ textAlign: 'left' }}>{item.name}</td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Evaluation criteria: traction & product-market fit (PMF)</td>

                                {table['Evaluation criteria: traction & product-market fit (PMF)'].map((item, index) => (
                                    <td key={index} colSpan={item.col} style={{ textAlign: 'left' }}>{item.name}</td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Employees size (persons)</td>

                                {table['Employees size (persons)'].map((item, index) => (
                                    <td colSpan={item.col} key={index}><span style={{ fontWeight: 'bold' }}>{item.median}</span>{' '}{item.range}</td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Monthly recurring revenue (MRR in USD)</td>

                                {table['Monthly recurring revenue (MRR in USD)'].map((item, index) => (
                                    <td colSpan={item.col} key={index}><span style={{ fontWeight: 'bold' }}>{item.median}</span>{' '}{item.range}</td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Annual recurring revenue (ARR in USD)</td>

                                {table['Annual recurring revenue (ARR in USD)'].map((item, index) => (
                                    <td colSpan={item.col} key={index}><span style={{ fontWeight: 'bold' }}>{item.median}</span>{' '}{item.range}</td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Year-on-Year (YoY) growth rate (%)</td>

                                {table['Year-on-Year (YoY) growth rate (%)'].map((item, index) => (
                                    <td colSpan={item.col} key={index}><span style={{ fontWeight: 'bold' }}>{item.median}</span>{' '}{item.range}</td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Monthly burn rate (USD)</td>

                                {table['Monthly burn rate (USD)'].map((item, index) => (
                                    <td colSpan={item.col} key={index}><span style={{ fontWeight: 'bold' }}>{item.median}</span>{' '}{item.range}</td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>
                                    Investment size (USD)
                                </td>

                                {table['Investment size (USD)'].map((item, index) => (
                                    <td key={index} colSpan={item.col}><span style={{ fontWeight: 'bold' }}>{item.range}</span></td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Equity stake (%)</td>

                                {table['Equity stake (%)'].map((item, index) => (
                                    <td colSpan={item.col} key={index} style={{ fontWeight: 'bold' }}>{item.name}</td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Post-money valuation (USD)</td>

                                {table['Post-money valuation (USD)'].map((item, index) => (
                                    <td key={index} colSpan={item.col} style={{ fontWeight: 'bold' }}>{item.name}</td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Funding sources</td>

                                {table['Funding sources'].map((item, index) => (
                                    <td colSpan={item.col} key={index} style={{ textAlign: 'left' }}>{item.name}</td>
                                ))}
                            </tr>

                            <tr>
                                <td style={{ backgroundColor: '#e3e7f1' }}>Defensibility moat</td>

                                {table['Defensibility moat'].map((item, index) => (
                                    <td key={index} colSpan={item.col} style={{ textAlign: 'left' }}>{item.name}</td>
                                ))}
                            </tr>

                            <tr>
                                <td rowSpan={3} style={{ backgroundColor: '#e3e7f1' }}>Evaluation criteria: conviction</td>

                                {table['Evaluation criteria: conviction'].row1.map((item, index) => (
                                    <td key={index} colSpan={item.col} style={{ backgroundColor: '#fff2cc', textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>{item.title}</span>{item.name}</td>
                                ))}
                            </tr>

                            <tr>
                                {table['Evaluation criteria: conviction'].row2.map((item, index) => (
                                    <td key={index} colSpan={item.col} style={{ fontWeight: 'normal', padding: '15px', backgroundColor: '#ead1dc', textAlign: 'left' }}>{item.name}</td>
                                ))}
                            </tr>

                            <tr>
                                {table['Evaluation criteria: conviction'].row3.map((item, index) => (
                                    <td key={index} colSpan={item.col} style={{ fontWeight: 'normal', padding: '15px', backgroundColor: '#d0e0e3', textAlign: 'left' }}>{item.name}</td>
                                ))}
                            </tr>
                        </tbody>
                    </table>

                    <p style={{ fontFamily: 'AileronItalic', fontSize: '12px', marginBottom: '0', marginTop: '1rem' }}>
                        <em><sup>*</sup>Last updated on 8 February 2024.</em>
                    </p>

                    <div className='col-12 prerevmethodsdivnotes'>
                        <span className='info-notes prerevmethodsnotes'>
                            Note:
                            <ol>
                                <li>
                                    <a href='https://viewpoints.roiquant.com/disclaimer' target='_blank' rel='noopener noreferrer'>Disclaimer</a>:{' '}
                                    <span style={{ fontWeight: 'bold' }}>The information shared above is for general reference only and do not constitute any legal, business and/or investment advice; please check the accuracy and verify the information</span>
                                </li>

                                <li>Post-money valuation = investment size (USD) / equity stake(%)</li>

                                <li>
                                    Should you wish to estimate your pre-revenue business valuation with proven models and frameworks, you may use our{' '}
                                    <a href='/simulation/founders/pre-revenue-valuation'>roiquant's pre-revenue business valuation simulator</a>
                                </li>

                                <li>
                                    Published by{' '}
                                    <a href='https://www.roiquant.com' target='_blank' rel='noopener noreferrer'>roiquant.com</a>{' '}-{' '}
                                    <span style={{ fontFamily: 'AileronItalic' }}>an intelligent decision support system helping early-stage startup founders to reduce risk of failure and build business defensibility</span>
                                </li>

                                <li>
                                    These venture capital funding benchmarks for startups were adapted from{' '}
                                    <a href='https://www.indexventures.com/' target='_blank' rel='noopener noreferrer'>Index Ventures</a>{' '}(
                                    <a href='https://www.indexventures.com/rewardingtalent/introduction-to-this-edition' target='_blank' rel='noopener noreferrer'>Rewarding talent: a guide to stock options for European entrepreneurs, 2017</a>),{' '}
                                    <a href='https://twitter.com/brexHQ' target='_blank' rel='noopener noreferrer'>Brex</a>{' '}(
                                    <a href='https://www.geekwire.com/2018/startups-spend-money-new-data-shows-burn-rates-differ-industry-region/' target='_blank' rel='noopener noreferrer'>Startup cash burn report, 2018</a>),{' '}
                                    <a href='https://twitter.com/parulia' target='_blank' rel='noopener noreferrer'>Parul Singh</a>{' '}(
                                    <a href='https://medium.com/swlh/raising-seed-capital-heres-your-2018-guide-on-how-to-do-it-successfully-c73c42530598' target='_blank' rel='noopener noreferrer'>Seed gradient, 2018</a>),{' '}
                                    <a href='https://quinterojs.medium.com/' target='_blank' rel='noopener noreferrer'>Sebastian Quintero</a>{' '}(
                                    <a href='https://medium.com/journal-of-empirical-entrepreneurship/making-sense-of-startup-valuations-with-data-science-1dededaf18bb' target='_blank' rel='noopener noreferrer'>Predicting a startup valuation with data science, 2019</a>),{' '}
                                    <a href='https://www.pointnine.com/' target='_blank' rel='noopener noreferrer'>Point Nine</a>{' '}(
                                    <a href='https://twitter.com/roiquant/status/1748178309568221237' target='_blank' rel='noopener noreferrer'>SaaS funding napkin, 2016-2023</a>),{' '}
                                    <a href='https://openviewpartners.com/' target='_blank' rel='noopener noreferrer'>OpenView Venture Partners</a>{' '}(
                                    <a href='https://openviewpartners.com/2021-financial-operating-benchmarks-report/' target='_blank' rel='noopener noreferrer'>Financial & operating benchmarks, 2021</a>),{' '}
                                    <a href='https://www.linkedin.com/in/jonathan-kendall-cal-vc/' target='_blank' rel='noopener noreferrer'>Jonathan Kendall</a>{' '}(
                                    <a href='https://vc-list.com/pre-seed-ecosystem/' target='_blank' rel='noopener noreferrer'>Understanding the pre-seed ecosystem, 2021</a>),{' '}
                                    <a href='https://www.mckinsey.com/capabilities/mckinsey-digital/how-we-help-clients' target='_blank' rel='noopener noreferrer'>McKinsey & Company</a>{' '}(
                                    <a href='https://www.mckinsey.com/capabilities/mckinsey-digital/our-insights/scale-or-fail-how-incumbents-can-industrialize-new-business-building' target='_blank' rel='noopener noreferrer'>Scale or fail: how incumbents can industrialize new-business building, 2021</a>),{' '}
                                    <a href='https://500.co/' target='_blank' rel='noopener noreferrer'>Courtney Powell</a>{' '}(
                                    <a href='https://www.linkedin.com/posts/courtneyjpowell_startups-vc-venturecapital-activity-6919966231508766720-o5_4/' target='_blank' rel='noopener noreferrer'>COO & Managing Partner of 500 Global, 2022</a>),{' '}
                                    <a href='https://stride.vc/' target='_blank' rel='noopener noreferrer'>Fred Destin</a>{' '}(
                                    <a href='https://twitter.com/fdestin/status/1530112598242430979' target='_blank' rel='noopener noreferrer'>Founder of Stride VC, 2022</a>),{' '}
                                    <a href='https://twitter.com/TheSaaSCFO' target='_blank' rel='noopener noreferrer'>Ben Murray</a>{' '}(
                                    <a href='https://twitter.com/roiquant/status/1537030425935511552' target='_blank' rel='noopener noreferrer'>Founder & SaaS CFO of The SaaS News, 2022</a>),{' '}
                                    <a href='https://twitter.com/lpolovets' target='_blank' rel='noopener noreferrer'>Leo Polovets</a>{' '}(
                                    <a href='https://twitter.com/lpolovets/status/1547046895125532672' target='_blank' rel='noopener noreferrer'>General Partner of Susa Ventures, 2022</a>), and{' '}
                                    <a href='https://twitter.com/parulia' target='_blank' rel='noopener noreferrer'>Parul Singh</a>{' '}(
                                    <a href='https://twitter.com/parulia/status/1565109614227509249' target='_blank' rel='noopener noreferrer'>Partner of Initialized Capital Management, 2022</a>)
                                </li>
                                <li>
                                    Citation and republishing: roiquant's articles and reports may be republished in accordance with our{' '}
                                    <a href='https://viewpoints.roiquant.com/citation-policy' target='_blank' rel='noopener noreferrer'>Citation Policy</a>{' '}and{' '}
                                    <a href='https://viewpoints.roiquant.com/terms-of-service' target='_blank' rel='noopener noreferrer'>Terms of Service</a>
                                </li>
                            </ol>
                        </span>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default ViewVCFundingBenchmarks
