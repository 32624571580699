export const fundingRounds = [
    { "key": "Accelerator" },
    { "key": "Angel" },
    { "key": "Bridge" },
    { "key": "Convertible Note" },
    { "key": "Corporate Round" },
    { "key": "Crowdfunding" },
    { "key": "Debt Financing" },
    { "key": "Early Stage" },
    { "key": "Equity Crowdfunding" },
    { "key": "Exit" },
    { "key": "Funding Round" },
    { "key": "Grant" },
    { "key": "IPO" },
    { "key": "Initial Coin Offering" },
    { "key": "Initial Public Offering" },
    { "key": "Late Stage" },
    { "key": "Limited Partnership" },
    { "key": "M&A" },
    { "key": "Non-Equity Assistance" },
    { "key": "Post-IPO Debt" },
    { "key": "Post-IPO Equity" },
    { "key": "Post-Seed" },
    { "key": "Pre-Seed" },
    { "key": "Private Equity" },
    { "key": "Product Crowdfunding" },
    { "key": "SAFE" },
    { "key": "Secondary Market" },
    { "key": "Seed" },
    { "key": "Series A" },
    { "key": "Series B" },
    { "key": "Series C" },
    { "key": "Series D" },
    { "key": "Series E" },
    { "key": "Series F" },
    { "key": "Series G" },
    { "key": "Series H" },
    { "key": "Undisclosed" },
    { "key": "Venture Round" }
];