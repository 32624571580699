import React, { useState, PureComponent } from 'react';
import './filters.css';
import { Card, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { useRealmApp } from '../../../RealmApp';
import { fundingRounds } from '../../dropdown-options/funding-rounds';
import { ventureYearsStart } from '../../dropdown-options/years-start';
import { ventureYearsEnd } from '../../dropdown-options/years-end';
import { industriesOld } from '../../dropdown-options/industries';
import { countries } from '../../dropdown-options/countries';

class CustomizedAxisTick extends PureComponent {
    render() {
        const {
            x, y, stroke, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" transform="rotate(-20)">{payload.value}</text>
            </g>
        );
    }
}

const LeadCoInvestments = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // realm functions declaration
    const { currentUser, logInAnonymous } = useRealmApp();

    // to check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // chart function declaration
    const { investmentPortfolioAnalysis } = currentUser.functions;

    // hook for user input
    const [filters, setFilters] = useState({
        type: "Lead and co-investments",
        fundingRound: "Funding Round",
        yearStart: "Year Start",
        yearEnd: "Year End",
        industry: "Industry",
        location: "Location"
    });

    // hook for results
    const [investments, setInvestments] = useState(
        [
            {
                "amountRaised": "<$1M",
                "Co-investments": 1823,
                "Lead investments": 227
            },
            {
                "amountRaised": "$1M-2M",
                "Co-investments": 2345,
                "Lead investments": 325
            },
            {
                "amountRaised": "$2M-$3M",
                "Co-investments": 1488,
                "Lead investments": 241
            },
            {
                "amountRaised": "$3M-$4M",
                "Co-investments": 913,
                "Lead investments": 197
            },
            {
                "amountRaised": "$4M-$5M",
                "Co-investments": 782,
                "Lead investments": 208
            },
            {
                "amountRaised": "$5M-$6M",
                "Co-investments": 563,
                "Lead investments": 125
            },
            {
                "amountRaised": "$6M-$7M",
                "Co-investments": 537,
                "Lead investments": 141
            },
            {
                "amountRaised": "$7M-$8M",
                "Co-investments": 505,
                "Lead investments": 112
            },
            {
                "amountRaised": "$8M-$9M",
                "Co-investments": 339,
                "Lead investments": 79
            },
            {
                "amountRaised": "$9M-$10M",
                "Co-investments": 615,
                "Lead investments": 147
            },
            {
                "amountRaised": ">$10M",
                "Co-investments": 5141,
                "Lead investments": 954
            }
        ]
    );

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            type: "Lead and co-investments",
            fundingRound: "Funding Round",
            yearStart: "Year Start",
            yearEnd: "Year End",
            industry: "Industry",
            location: "Location"
        });
        setAlert(false);
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        setFilters({ ...filtersCopy });
    }

    // function to submit form
    function submitFilters(e) {
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        investmentPortfolioAnalysis(filters)
            .then(response => {
                let graphData = response;
                setInvestments(graphData);
                closeAlert();
            })
            .catch(error => {
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Your filter query returned no results. Please change your filter values and try again.</p>
                </div>);
            })
        e.preventDefault();
    }

    return (
        <Card.Body className="accordion-card-body">

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div className="col-12 div-filter">
                <Form onSubmit={submitFilters}>
                    <Form.Group>
                        <Form.Row className="align-items-center">
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.fundingRound} as="select" name="fundingRound" onChange={changeFilters}>
                                    <option>Funding Round</option>
                                    {
                                        fundingRounds.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.yearStart}
                                    as="select" name="yearStart" onChange={changeFilters}>
                                    <option>Year Start</option>
                                    {
                                        ventureYearsStart.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.yearEnd}
                                    as="select" name="yearEnd" onChange={changeFilters}>
                                    <option>Year End</option>
                                    {
                                        ventureYearsEnd.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.industry}
                                    as="select" name="industry" onChange={changeFilters}>
                                    <option>Industry</option>
                                    {
                                        industriesOld.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.location}
                                    as="select" name="location" onChange={changeFilters}>
                                    <option>Location</option>
                                    {
                                        countries.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto">
                                <button onClick={resetFilters} className="reset-button tour-clear-filter">
                                    <span>Clear</span>
                                </button>
                            </Col>
                            <Col xs="auto">
                                <button type="submit" className="submit-button">
                                    <span>Filter</span>
                                </button>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>
            </div>

            <div className="col-12">
                <Card className="graph-card">
                    <div>
                        <ResponsiveContainer id="vc-charts">
                            <BarChart data={investments}
                                margin={{ top: 20, right: 20, left: 20, bottom: 100 }}
                                width={1100}
                                height={500}
                                fontFamily="AileronRegular"
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="amountRaised"
                                    interval={0}
                                    fontSize="11px"
                                    fontFamily="AileronRegular"
                                    tick={<CustomizedAxisTick />}
                                    label={{ value: "USD", offset: -80, position: "insideBottom" }} />
                                <YAxis fontSize="15px"
                                    fontFamily="AileronRegular"
                                    label={{ value: "Frequency", angle: -90, position: "insideLeft" }} />
                                <Tooltip />
                                <Bar dataKey="Lead investments" fill="#8884d8" barSize={50} />
                                <Bar dataKey="Co-investments" fill="#82ca9d" barSize={50} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </div>

            <div className="col-12 extra-notes">
                <span className="info-notes">
                    Note:
                    <ul>
                        <li id="notes-points">
                            Based on 8,055 companies, 1,868 investors collectively made 2,756 lead investments and 15,051 co-investments across the <span style={{ fontFamily: "AileronItalic" }}>very-early stage, early stage, growth stage, late stage and post-IPO stage</span> from June 1983 to Sept 2020.
                        </li>
                        <li id="notes-points">
                            Out of 1,868 investors, the lead investments were led by 975 unique investors, while the co-investments were co-led by 1,665 unique investors.
                        </li>
                        <li id="notes-points">
                            Nnamdi Okike led the largest lead investment in Folhamatic's series A round of USD $597 million (in a single funding round) on June 2011.
                        </li>
                    </ul>
                </span>
            </div>
        </Card.Body>
    );
}

export default LeadCoInvestments;