import React, { useState, useRef } from 'react';
import './filters.css';
import './network.css';
import { Card, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
// import { MapContainer, CircleMarker, TileLayer, Tooltip } from 'react-leaflet';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { DataSet, Network } from 'vis-network/standalone/esm/vis-network';
import { useRealmApp } from '../../../RealmApp';
import { investors } from '../../dropdown-options/investors';
import { fundingRounds } from '../../dropdown-options/funding-rounds';
import { ventureYearsStart } from '../../dropdown-options/years-start';
import { ventureYearsEnd } from '../../dropdown-options/years-end';
import { industriesOld } from '../../dropdown-options/industries';
import { countries } from '../../dropdown-options/countries';

const CoInvestors = (props) => {

    // A reference to the div that will contain the graph
    const container = useRef(null)

    // A reference to the vis network instance
    const network = useRef(null)

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // realm functions declaration
    const { currentUser } = useRealmApp();

    // to check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // chart function declaration
    const { getCoInvestorsNetworkGraph } = currentUser.functions;

    // hook for user input
    const [filters, setFilters] = useState({
        investor: "Investor",
        fundingRound: "Funding Round",
        yearStart: "Year Start",
        yearEnd: "Year End",
        industry: "Industry",
        location: "Location",
        layout: "Layout"
    });

    // hook for graph view
    const [graphView, setGraphView] = useState(false);

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            investor: "Investor",
            fundingRound: "Funding Round",
            yearStart: "Year Start",
            yearEnd: "Year End",
            industry: "Industry",
            location: "Location",
            layout: "Layout"
        });
        setAlert(false);
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        setFilters({ ...filtersCopy });

        if (name === "layout" && value === "Hierarchical") {
            setGraphView(true);
        }
        else if (name === "layout" && value !== "Hierarchical") {
            setGraphView(false);
        }
    }

    // function to submit form
    async function submitFilters() {
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {

            const response = await getCoInvestorsNetworkGraph(filters);

            const nodes = (response.nodes);
            const edges = (response.edges);

            if (nodes && nodes.length !== 0) {
                if (edges && edges.length !== 0) {
                    // const nodes = new DataSet(investments.nodes);
                    // const edges = new DataSet(investments.edges);
                    let data = {
                        nodes: new DataSet(nodes),
                        edges: new DataSet(edges),
                    };
                    let options = {
                        layout: {
                            hierarchical: graphView,
                            randomSeed: 191006,
                            improvedLayout: false
                        },
                        nodes: {
                            shape: "circle"
                        },
                        edges: {
                            color: "#000000",
                            arrows: {
                                to: {
                                    enabled: false
                                }
                            },
                            smooth: {
                                enabled: false,
                                type: 'continuous'
                            }
                        },
                        physics: {
                            stabilization: {
                                enabled: true,
                                fit: true,
                                iterations: 100,
                            },
                            adaptiveTimestep: true,
                            barnesHut: {
                                gravitationalConstant: -8000,
                                springConstant: 0.04,
                                springLength: 95
                            },
                            timestep: 1
                        },
                        height: "500",
                        width: "1150"
                    }
                    network.current = new Network(container.current, data, options);
                }
            }

            closeAlert();
        } catch (error) {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>Your filter query returned no results. Please change your filter values and try again.</p>
            </div>);
        }
    }

    return (
        <Card.Body className="accordion-card-body">

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div className="col-12 div-filter" id="card-tour">
                <Form onSubmit={(e) => { submitFilters(); e.preventDefault(); }}>
                    <Form.Group>
                        <Form.Row className="align-items-center">
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.investor} as="select" name="investor" onChange={changeFilters}>
                                    <option>Investor</option>
                                    {
                                        investors.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.fundingRound} as="select" name="fundingRound" onChange={changeFilters}>
                                    <option>Funding Round</option>
                                    {
                                        fundingRounds.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.yearStart}
                                    as="select" name="yearStart" onChange={changeFilters}>
                                    <option>Year Start</option>
                                    {
                                        ventureYearsStart.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.yearEnd}
                                    as="select" name="yearEnd" onChange={changeFilters}>
                                    <option>Year End</option>
                                    {
                                        ventureYearsEnd.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.industry}
                                    as="select" name="industry" onChange={changeFilters}>
                                    <option>Industry</option>
                                    {
                                        industriesOld.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.location}
                                    as="select" name="location" onChange={changeFilters}>
                                    <option>Location</option>
                                    {
                                        countries.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.layout} as="select" name="layout" onChange={changeFilters}>
                                    <option>Layout</option>
                                    <option>Star</option>
                                    <option>Hierarchical</option>
                                </Form.Control>
                            </Col>
                            <Col xs="auto">
                                <button onClick={resetFilters} className="reset-button tour-clear-filter">
                                    <span>Clear</span>
                                </button>
                            </Col>
                            <Col xs="auto">
                                <button type="submit" className="submit-button">
                                    <span>Filter</span>
                                </button>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>
            </div>

            <div className="col-12">
                <IoMdInformationCircleOutline id="information-icon" />
                <span className="info-text">
                    Select single or multiple values and click 'filter' to display the data. Due to the large amount of companies, it may take up to 1 min to show results.
                </span>
            </div>

            <div className="col-12">
                <Card className="graph-card">
                    <div ref={container}></div>
                </Card>
            </div>

            <div className="col-12 extra-notes">
                <span className="info-notes">
                    Note:
                    <ul>
                        <li id="notes-points">
                            Collectively, 1,962 investors (<span style={{ fontFamily: "AileronItalic" }}>with funding rounds data</span>) made a total of 20,075 investments (<span style={{ fontFamily: "AileronItalic" }}>comprised of both leads and co-leads</span>) into the same 8,055 unique companies across the <span style={{ fontFamily: "AileronItalic" }}>very-early stage, early stage, growth stage, late stage and post-IPO stage</span> from June 1983 to Sept 2020.
                        </li>
                        <li id="notes-points">
                            Out of the 1,962 investors, the co-investments were co-led by 1,754 unique co-investors within their networks.
                        </li>
                    </ul>
                </span>
            </div>
        </Card.Body>
    );
}

export default CoInvestors;