import { Accordion, Card, Col, ProgressBar } from 'react-bootstrap'
import { FaChevronDown } from 'react-icons/fa'
import { capitalize } from '../../../../../../uitls/helpers'

const ResultsSection = ({ props, type }) => {
  return (
    <Col className='ideacheckerresults' style={{ paddingRight: '0px' }}>
      <Accordion className='companyaccordion' defaultActiveKey='resultstats'>
        <Card style={{ background: 'rgba(255, 255, 255, .65) !important' }}>
          <Accordion.Toggle
            as={Card.Header}
            className='companycardheader'
            eventKey='resultstats'
          >
            <div className='companytitlecard'>Results</div>

            <div className='companyarrowiconright'>
              <FaChevronDown className='arrow-icon' />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='resultstats'>
            <Card.Body style={{ fontFamily: 'AileronRegular' }}>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  textAlign: 'center',
                  marginBottom: '10px'
                }}
              >
                {capitalize(type)} score:{' '}
                {props.state.analysisResult?.[type].score
                  ? props.state.analysisResult[type].score <= 0
                    ? 0
                    : props.state.analysisResult[type].score
                  : 0}
                %
              </div>

              {props.state.analysisResult?.[type].score < 50 ? (
                <ProgressBar
                  now={
                    props.state.analysisResult?.[type].score
                      ? props.state.analysisResult[type].score
                      : 0
                  }
                  variant='danger'
                  style={{ height: '2rem' }}
                />
              ) : (
                <ProgressBar
                  now={
                    props.state.analysisResult?.[type].score
                      ? props.state.analysisResult[type].score
                      : 0
                  }
                  variant='success'
                  style={{ height: '2rem' }}
                />
              )}

              <br />

              {/* {
                                    (props.state.analysisResult?.error)
                                        ? (<Alert className="error-message" variant="danger" style={{ marginBottom: "0" }}>
                                            {props.state.analysisResult.error}
                                        </Alert>)
                                        : ""
                                } */}

              {type === 'overall' && (
                <>
                  <br />
                  <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                    How do we analyze your business plan?
                  </span>

                  <br />

                  <div style={{ fontSize: '14px', paddingTop: '4%' }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <p style={{ width: '80%', marginBottom: '0' }}>
                        Business
                      </p>
                      <p
                        style={{
                          width: '20%',
                          marginBottom: '0',
                          textAlign: 'right'
                        }}
                      >
                        {props.state.analysisResult?.business.score
                          ? props.state.analysisResult.business.score > 0
                            ? props.state.analysisResult.business.score
                            : 0
                          : 0}
                        %
                      </p>
                    </div>
                    <ProgressBar
                      now={
                        props.state.analysisResult?.business.score
                          ? props.state.analysisResult.business.score
                          : 0
                      }
                      variant='danger'
                      style={{ height: '0.2rem' }}
                    />
                  </div>

                  <div style={{ fontSize: '14px', paddingTop: '4%' }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <p style={{ width: '80%', marginBottom: '0' }}>
                        Solution
                      </p>
                      <p
                        style={{
                          width: '20%',
                          marginBottom: '0',
                          textAlign: 'right'
                        }}
                      >
                        {props.state.analysisResult?.solution.score
                          ? props.state.analysisResult.solution.score > 0
                            ? props.state.analysisResult.solution.score
                            : 0
                          : 0}
                        %
                      </p>
                    </div>
                    <ProgressBar
                      now={
                        props.state.analysisResult?.solution.score
                          ? props.state.analysisResult.solution.score
                          : 0
                      }
                      variant='danger'
                      style={{ height: '0.2rem' }}
                    />
                  </div>

                  <div style={{ fontSize: '14px', paddingTop: '4%' }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <p style={{ width: '80%', marginBottom: '0' }}>Market</p>
                      <p
                        style={{
                          width: '20%',
                          marginBottom: '0',
                          textAlign: 'right'
                        }}
                      >
                        {props.state.analysisResult?.market.score
                          ? props.state.analysisResult.market.score > 0
                            ? props.state.analysisResult.market.score
                            : 0
                          : 0}
                        %
                      </p>
                    </div>
                    <ProgressBar
                      now={
                        props.state.analysisResult?.market.score
                          ? props.state.analysisResult.market.score
                          : 0
                      }
                      variant='danger'
                      style={{ height: '0.2rem' }}
                    />
                  </div>

                  <div style={{ fontSize: '14px', paddingTop: '4%' }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <p style={{ width: '80%', marginBottom: '0' }}>
                        Competition
                      </p>
                      <p
                        style={{
                          width: '20%',
                          marginBottom: '0',
                          textAlign: 'right'
                        }}
                      >
                        {props.state.analysisResult?.competition.score
                          ? props.state.analysisResult.competition.score > 0
                            ? props.state.analysisResult.competition.score
                            : 0
                          : 0}
                        %
                      </p>
                    </div>
                    <ProgressBar
                      now={
                        props.state.analysisResult?.competition.score
                          ? props.state.analysisResult.competition.score
                          : 0
                      }
                      variant='danger'
                      style={{ height: '0.2rem' }}
                    />
                  </div>

                  <div style={{ fontSize: '14px', paddingTop: '4%' }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <p style={{ width: '80%', marginBottom: '0' }}>
                        Operations
                      </p>
                      <p
                        style={{
                          width: '20%',
                          marginBottom: '0',
                          textAlign: 'right'
                        }}
                      >
                        {props.state.analysisResult?.operations.score
                          ? props.state.analysisResult.operations.score > 0
                            ? props.state.analysisResult.operations.score
                            : 0
                          : 0}
                        %
                      </p>
                    </div>
                    <ProgressBar
                      now={
                        props.state.analysisResult?.operations.score
                          ? props.state.analysisResult.operations.score
                          : 0
                      }
                      variant='danger'
                      style={{ height: '0.2rem' }}
                    />
                  </div>

                  <div style={{ fontSize: '14px', paddingTop: '4%' }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <p style={{ width: '80%', marginBottom: '0' }}>
                        Marketing
                      </p>
                      <p
                        style={{
                          width: '20%',
                          marginBottom: '0',
                          textAlign: 'right'
                        }}
                      >
                        {props.state.analysisResult?.marketing.score
                          ? props.state.analysisResult.marketing.score > 0
                            ? props.state.analysisResult.marketing.score
                            : 0
                          : 0}
                        %
                      </p>
                    </div>
                    <ProgressBar
                      now={
                        props.state.analysisResult?.marketing.score
                          ? props.state.analysisResult.marketing.score
                          : 0
                      }
                      variant='danger'
                      style={{ height: '0.2rem' }}
                    />
                  </div>

                  <div style={{ fontSize: '14px', paddingTop: '4%' }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <p style={{ width: '80%', marginBottom: '0' }}>
                        Financials
                      </p>
                      <p
                        style={{
                          width: '20%',
                          marginBottom: '0',
                          textAlign: 'right'
                        }}
                      >
                        {props.state.analysisResult?.financials.score
                          ? props.state.analysisResult.financials.score > 0
                            ? props.state.analysisResult.financials.score
                            : 0
                          : 0}
                        %
                      </p>
                    </div>
                    <ProgressBar
                      now={
                        props.state.analysisResult?.financials.score
                          ? props.state.analysisResult.financials.score
                          : 0
                      }
                      variant='danger'
                      style={{ height: '0.2rem' }}
                    />
                  </div>

                  <div style={{ fontSize: '14px', paddingTop: '4%' }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <p style={{ width: '80%', marginBottom: '0' }}>Risk</p>
                      <p
                        style={{
                          width: '20%',
                          marginBottom: '0',
                          textAlign: 'right'
                        }}
                      >
                        {props.state.analysisResult?.risk.score
                          ? props.state.analysisResult.risk.score > 0
                            ? props.state.analysisResult.risk.score
                            : 0
                          : 0}
                        %
                      </p>
                    </div>
                    <ProgressBar
                      now={
                        props.state.analysisResult?.risk.score
                          ? props.state.analysisResult.risk.score
                          : 0
                      }
                      variant='danger'
                      style={{ height: '0.2rem' }}
                    />
                  </div>
                </>
              )}

              {/* <div style={{ fontSize: "14px", paddingTop: "4%" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <p style={{ width: "80%", marginBottom: "0" }}>Management</p>
                                        <p style={{ width: "20%", marginBottom: "0", textAlign: "right" }}>
                                            {props.state.analysisResult?.management.score
                                                ? props.state.analysisResult.management.score > 0
                                                    ? props.state.analysisResult.management.score
                                                    : 0
                                                : 0}%
                                        </p>
                                    </div>
                                    <ProgressBar now={props.state.analysisResult?.management.score ? props.state.analysisResult.management.score : 0} variant="danger" style={{ height: "0.2rem" }} />
                                </div> */}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defaultActiveKey='notification' style={{ marginTop: '10px' }}>
        <Card style={{ background: 'rgba(255, 255, 255, .65) !important' }}>
          <Accordion.Toggle
            as={Card.Header}
            className='companycardheader'
            eventKey='notification'
          >
            <div className='companytitlecard'>Notification</div>

            <div className='companyarrowiconright'>
              <FaChevronDown className='arrow-icon' />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='notification'>
            <Card.Body style={{ fontFamily: 'AileronRegular' }}>
              <span className='info-notes' style={{ fontSize: '16px' }}>
                Note:
                <ul style={{ paddingLeft: '17px' }}>
                  <li id='notes-points'>
                    Write no less than 2 words for short answers
                  </li>
                  <li id='notes-points'>
                    Write more than 7 words for normal answers
                  </li>
                  <li id='notes-points'>
                    Due to large datasets, it may take up to 1 min to compute
                    and show results.
                  </li>
                  <li id='notes-points'>
                    We use advanced real-time analytics and text analytics to
                    analyze your inputs.
                  </li>
                </ul>
              </span>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <Accordion defaultActiveKey='feedback' style={{ marginTop: '10px' }}>
        <Card style={{ background: 'rgba(255, 255, 255, .65) !important' }}>
          <Accordion.Toggle
            as={Card.Header}
            className='companycardheader'
            eventKey='feedback'
          >
            <div className='companytitlecard'>Feedback</div>

            <div className='companyarrowiconright'>
              <FaChevronDown className='arrow-icon' />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='feedback'>
            <Card.Body style={{ fontFamily: 'AileronRegular' }}>
              {!props.state.analysisResult ? (
                ''
              ) : (
                <div className='pitchdeckrecommended'>
                  Recommended readings:
                  <ul style={{ paddingLeft: '17px' }}>
                    {props.state.analysisResult?.[
                      type === 'overall' ? 'overview' : type
                    ].recommended_read.map((reading, index) => (
                      <li key={index}>
                        <a
                          href={reading.LINK}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {reading.TITLE}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Col>
  )
}

export default ResultsSection