import React from 'react'
import FinancialTableRowHeaderCenter from '../financials-table/financial-table-row-center-header'
import FinancialTableEmptyRow from '../financials-table/financial-table-empty-row'
import FinancialTableRow from './financial-table-row'
import FinancialTableRowHeader from './financial-table-row-header'

const FinancialsTableRowsView = ({ data, rowArray }) => {
  return (
    <>
      {rowArray?.map((row, index) =>
        row.type === 'bodyHeader' ? (
          <FinancialTableRowHeader
            key={index}
            displayName={row.displayName}
            className={row.className}
          />
        ) : row.type === 'rowBreak' ? (
          <FinancialTableEmptyRow key={index} />
        ) : row.type === 'bodyHeaderCenter' ? (
          <FinancialTableRowHeaderCenter
            key={index}
            displayName={row.displayName}
          />
        ) : (
          <FinancialTableRow
            key={index}
            data={data}
            fieldName={row.fieldName}
            displayName={row.displayName}
            className={row.className}
          />
        )
      )}
    </>
  )
}

export default FinancialsTableRowsView