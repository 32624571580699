import React, { useState } from 'react';
import SampleImage from '../../../assets/logo/roiquant - logo not found.png';
import ReactCountryFlag from 'react-country-flag';
import { S3Media } from '../../third-party-services';
import './productshowcase-card.css';

//pop up card for product showcase
function ProductShowcaseCard(props) {
    return (
        <div key={props.slug}>
            <div className="product-cardrow">
                <div className="product-products" >
                    <a href={!props.slug || props.slug == "" || props.slug.length < 0 ? "#" : '/company/' + props.slug}>
                        {!props.products
                            ? (<img src={SampleImage} alt={props.name} />)
                            : props.products.slice(0, 1).map((product) =>
                                !product.image
                                    ? <img src={SampleImage} alt={props.name} />
                                    : product.images.slice(0, 1).map((image, index) =>
                                        <img src={S3Media + 'assets/photos/products/' + image.image} alt={props.name} key={index} />
                                    ))
                        }
                    </a>
                </div>

                <div className="product-bottom" >
                    <div className="product-location">
                        {!props.city
                            ? ""
                            : (<a href="#">
                                <span className="product-tag">
                                    {!props.city ? "" : props.city}</span>
                            </a>
                            )}
                        {!props.state
                            ? ""
                            : (<a href="#">
                                <span className="product-tag">
                                    {!props.state ? "" : props.state}</span>
                            </a>
                            )}
                        {!props.country
                            ? ""
                            : (<a href="#">
                                <span className="product-flagicon">
                                    {!props.countryCode
                                        ? ""
                                        : <ReactCountryFlag countryCode={props.countryCode} title={props.country} />
                                    }
                                </span>
                            </a>
                            )}
                    </div>

                    <a href={!props.slug || props.slug == "" || props.slug.length < 0 ? "#" : '/company/' + props.slug}>
                        <div className="product-logobox">
                            {!props.logo
                                ? (<img src={SampleImage} alt={props.slug} className="product-logo" />)
                                : (<img src={S3Media + 'assets/photos/logo/' + props.logo} alt={props.name} className="product-logo" />)
                            }
                        </div>
                        <span className="product-name">
                            <h3>{!props.tradeName ? "" : props.tradeName}</h3>
                        </span>
                        <span className="product-name2">
                            <p>{!props.description ? "" : props.description}</p>
                        </span>
                    </a>

                    <div className="product-tag">
                        {!props.industry
                            ? ""
                            : <a href="#" className="product-industrybutton">{props.industry}</a>
                        }
                        {!props.categories
                            ? ""
                            : props.categories.slice(0, 2).map((category, index) =>
                                <a href="#" key={index} className="product-categorybutton">{category}</a>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductShowcaseCard;
