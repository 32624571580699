import React, { useState, useEffect } from 'react';
import NavBar from '../../../layout/navbar/navbar';
import SampleImage from '../../../../assets/logo/roiquant - logo not found.png';
import { Button, Form, Modal, Spinner, Alert } from 'react-bootstrap';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { useRealmApp } from '../../../RealmApp';
import { Link } from 'react-router-dom';
import '../funding/funding.css';

const VeryEarlyStageDeals = () => {

    // hook for alert component - show alert on page load
    const [alert, setAlert] = useState(true);

    // hook for alert message - show message on page load
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // realm functions declaration
    const { currentUser } = useRealmApp();

    // check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // table functions declaration
    const { getListOfPitchDecks } = currentUser.functions;

    // const [argument, setArgument] = useState({
    //     numOfRows: 50,
    //     currentPage: 1
    // });

    // const [page, setPage] = useState({
    //     startNum: 1,
    //     endNum: 50,
    //     lastPage: null
    // });

    // hook for table data
    const [tableData, setTableData] = useState({
        results: [],
        total: 0
    });

    // fetch data on page load
    useEffect(async () => {
        try {
            let response = await getListOfPitchDecks()
            const data = JSON.parse(response);
            setTableData({
                results: data.list,
                total: data.total
            });
            // console.log(tableData);
        } catch (err) { }
        // close alert when data is received
        setAlert(false);
    }, []);

    // function to update hooks
    // async function changeFilters(e) {
    //     e.preventDefault();

    //     let name = e.target.name;
    //     let value = e.target.value;

    //     // change current page value
    //     if (name.includes("Page")) {

    //         let currentPageName = "currentPage", currentPageValue,
    //             startNumName = "startNum", startNumValue,
    //             endNumName = "endNum", endNumValue;

    //         if (name === "firstPage") {
    //             currentPageValue = 1;
    //             startNumValue = 1;
    //             endNumValue = argument.numOfRows;
    //         }
    //         if (name === "prevPage") {
    //             currentPageValue = argument.currentPage - 1;
    //             startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
    //             endNumValue = currentPageValue * argument.numOfRows;
    //         }
    //         if (name === "nextPage") {
    //             currentPageValue = argument.currentPage + 1;
    //             startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
    //             endNumValue = currentPageValue * argument.numOfRows;
    //         }
    //         if (name === "lastPage") {
    //             currentPageValue = page.lastPage;
    //             startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
    //             endNumValue = tableData?.total;
    //         }

    //         argument[currentPageName] = currentPageValue;
    //         page[startNumName] = startNumValue;
    //         page[endNumName] = endNumValue;

    //         setArgument({ ...argument });

    //         setPage({ ...page });
    //     }

    //     // change last page value
    //     if (name === "numOfRows") {

    //         let currentPageName = "currentPage", currentPageValue = 1,
    //             numOfRowsName = "numOfRows", numOfRowsValue,
    //             startNumName = "startNum", startNumValue = 1,
    //             endNumName = "endNum", endNumValue,
    //             lastPageName = "lastPage", lastPageValue;

    //         if (value === "50") {
    //             numOfRowsValue = 50;
    //             endNumValue = 50;
    //             lastPageValue = tableData?.total / 50;
    //         }
    //         if (value === "100") {
    //             numOfRowsValue = 100;
    //             endNumValue = 100;
    //             lastPageValue = tableData?.total / 100;
    //         }
    //         if (value === "500") {
    //             numOfRowsValue = 500;
    //             endNumValue = 500;
    //             lastPageValue = tableData?.total / 500;
    //         }

    //         argument[currentPageName] = currentPageValue;
    //         argument[numOfRowsName] = numOfRowsValue;
    //         page[startNumName] = startNumValue;
    //         page[endNumName] = endNumValue;
    //         page[lastPageName] = lastPageValue;

    //         setArgument({ ...argument });

    //         setPage({ ...page });
    //     }

    //     try {
    //         const response = await getListOfPitchDecks();
    //         const data = JSON.parse(response);

    //         setTableData(data);
    //         // console.log(tableData);

    //         // show alert when data is received
    //         setAlert(true);
    //         setMessage(<div style={{ paddingTop: "10px" }}>
    //             <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
    //             <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
    //         </div>);
    //     } catch (err) { }
    // }

    return (
        <>
            <NavBar />

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="funding">
                <div className="fundingheaderdiv">
                    <div className="fundingtitlediv">
                        <br />
                        <span className="fundingtext">
                            Very-early stage deal flow
                            <sup data-tip="">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </span>
                    </div>
                </div>

                <div className="fundingdiv">
                    <table className="fundingtable">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th style={{ color: "transparent" }}>Image</th>
                                <th>Name</th>
                                <th>Entity type</th>
                                <th>Description</th>
                                <th>Business plan</th>
                                <th>Financing status</th>
                                <th>Industry</th>
                                <th>Categories</th>
                                <th>Location</th>
                                <th>Ownership status</th>
                                <th>Stage of development</th>
                                <th>Transactional model type</th>
                                <th>Defensibility moats</th>
                                <th>Last financing round</th>
                                <th>Total raised</th>
                                <th>Post-money valuation</th>
                                <th>Last financing date</th>
                                <th>No. of funding round(s)</th>
                                <th>No. of investors</th>
                                <th>Investors</th>
                                <th>Total revenue</th>
                                <th>Market capitalization</th>
                                <th>No. of investments</th>
                                <th>No. of acquisitions</th>
                                <th>No. of board seats taken</th>
                                <th>Competition</th>
                                <th>Management</th>
                                <th>No. of employees</th>
                                <th>Company reputation</th>
                                <th>Contact info</th>
                                <th>Operational duration</th>
                                <th>Founded date</th>
                                <th>Legal name</th>
                                <th>Former legal name(s)</th>
                                <th>Ticker</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.results.map((company, index) =>
                                <tr key={index}>
                                    <td><p className="detailxsdata">{index + 1}.</p></td>

                                    <td><img src={SampleImage} alt={company.name} className="investorlogo" /></td>

                                    {!company.overview.entityName
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailmediumdata">{company.overview.entityName}</p></td>)
                                    }

                                    {!company.overview.entityType
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailmediumdata">{company.overview.entityType}</p></td>)
                                    }

                                    {!company.overview.shortDescription
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detaillongdata">{company.overview.shortDescription}</p></td>)
                                    }

                                    {!company.pitchDeckId
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td>
                                            <p className="detailmediumdata">
                                                {/* dynamically pass slug of element that is clicked */}
                                                <Link to={`/founders/business-plan/view/${company.pitchDeckId}`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                    View
                                                </Link>
                                            </p>
                                        </td>)
                                    }

                                    {/* financing strategy */}
                                    <td className="detailemptydata">-</td>

                                    {!company.overview.industry
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailmldata">{company.overview.industry}</p></td>)
                                    }

                                    {!company.overview.categories
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailmldata">{company.overview.categories.map((category, index) =>
                                            !category
                                                ? ""
                                                : (<span key={index}>{category}, </span>)
                                        )}</p></td>)
                                    }

                                    {!company.overview.locationCountry
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailmediumdata">{!company.overview.locationCity ? "" : <span>{company.overview.locationCity},</span>} {!company.overview.locationState ? "" : <span>{company.overview.locationState},</span>} {!company.overview.locationCountry ? "" : <span>{company.overview.locationCountry}</span>}</p></td>)
                                    }

                                    {!company.overview.ownershipStatus
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailshortdata">{company.overview.ownershipStatus}</p></td>)
                                    }

                                    {!company.overview.stageOfDevelopment
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailshortdata">{company.overview.stageOfDevelopment}</p></td>)
                                    }

                                    {!company.overview.transactionalModelType
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailshortdata">{company.overview.transactionalModelType}</p></td>)
                                    }

                                    {!company.overview.defensibilityMoats
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailmldata">{company.overview.defensibilityMoats.map((moat, index) =>
                                            !moat
                                                ? ""
                                                : (<span key={index}>{moat}, </span>)
                                        )}</p></td>)
                                    }

                                    {!company.overview.funding.lastFinancingRound
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailshortdata">{company.overview.funding.lastFinancingRound}</p></td>)
                                    }

                                    {/* total raised */}
                                    <td className="detailemptydata">-</td>

                                    {/* post-money valuation */}
                                    <td className="detailemptydata">-</td>

                                    {!company.overview.funding.lastFinancingDate
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailshortdata">{company.overview.funding.lastFinancingDate}</p></td>)
                                    }

                                    {!company.overview.funding.numOfFundingRounds
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailshortdata">{company.overview.funding.numOfFundingRounds}</p></td>)
                                    }

                                    {/* number of investors */}
                                    <td className="detailemptydata">-</td>

                                    {/* investors */}
                                    <td className="detailemptydata">-</td>

                                    {/* total revenue */}
                                    <td className="detailemptydata">-</td>

                                    {/* market capitalization */}
                                    <td className="detailemptydata">-</td>

                                    {!company.overview.funding.numOfFundingRounds
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><p className="detailshortdata">{company.overview.funding.numOfFundingRounds}</p></td>)
                                    }

                                    {/* number of acquisitions */}
                                    <td className="detailemptydata">-</td>

                                    {/* number of board seats taken */}
                                    <td className="detailemptydata">-</td>

                                    {/* {!company.competition.directCompetitors && !company.competition.similarCompanies
                                        ? (<td className="detailemptydata">-</td>)
                                        : (<td><div className="detailmldata detailcutdata">
                                            <ol className="detailproductlist">
                                                {!company.competition.directCompetitors
                                                    ? ""
                                                    : (company.competition.directCompetitors.map((competitor, index) =>
                                                        <li key={index} className="detailbodydata">{competitor.name}</li>)
                                                    )}

                                                {!company.competition.similarCompanies
                                                    ? ""
                                                    : (company.competition.similarCompanies.map((similar, index) =>
                                                        <li key={index} className="detailbodydata">{similar.name}</li>)
                                                    )}
                                            </ol>
                                        </div></td>)
                                    } */}
                                    <td className="detailemptydata">-</td>

                                    {/* management */}
                                    <td className="detailemptydata">-</td>

                                    {/* number of employees */}
                                    <td className="detailemptydata">-</td>

                                    {/* company reputation */}
                                    <td className="detailemptydata">-</td>

                                    {/* contact info */}
                                    <td className="detailemptydata">-</td>

                                    {/* operational duration */}
                                    <td className="detailemptydata">-</td>

                                    {/* founded date */}
                                    <td className="detailemptydata">-</td>

                                    {/* legal name */}
                                    <td className="detailemptydata">-</td>

                                    {/* former legal name(s) */}
                                    <td className="detailemptydata">-</td>

                                    {/* ticker symbol */}
                                    <td className="detailemptydata">-</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {/* <div className="fundingfilters">
                    <div className="filterrownumber">
                        <p>{page.startNum}-{page.endNum} of {tableData.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    </div>

                    <div className="filterpagination">

                        <Button name="firstPage" onClick={changeFilters} className="filterpage">First</Button>
                        {
                            argument.currentPage > 1
                                ? (<Button name="prevPage" onClick={changeFilters} className="filterpage">Prev</Button>)
                                : ""
                        }
                        {
                            argument.currentPage < page.lastPage
                                ? (<Button name="nextPage" onClick={changeFilters} className="filterpage">Next</Button>)
                                : ""
                        }
                        <Button name="lastPage" onClick={changeFilters} className="filterpage">Last</Button>
                    </div>

                    <div className="filterrowshow">
                        <p>Show rows: </p>
                        <Form.Control value={argument.numOfRows} as="select" name="numOfRows" onChange={changeFilters} className="filterrowselect">
                            <option>50</option>
                            <option>100</option>
                            <option>500</option>
                        </Form.Control>
                    </div>
                </div> */}

            </div>
        </>
    );
}

export default VeryEarlyStageDeals;