import React, { useState, useEffect } from 'react';
import NavBar from '../../../layout/navbar/navbar';
import SampleImage from '../../../../assets/logo/roiquant - logo not found.png';
import DropdownComponent from '../../dropdown';
import NumberDropdownComponent from '../../dropdown/number-dropdown';
import TimeDropdownComponent from '../../dropdown/time-dropdown';
import InvestorsCard from '../../pop-up-card/investors-card';
import { Button, Form, Modal, Spinner, Alert } from 'react-bootstrap';
import { AiOutlineCloseCircle, AiOutlineExpandAlt } from 'react-icons/ai';
import { FaEdit, FaEllipsisH } from 'react-icons/fa';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList } from 'react-icons/bs';
import { useRealmApp } from '../../../RealmApp';
import { S3Media } from '../../../third-party-services';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import './funding.css';

const GrowthStage = () => {

    // hook for alert component - show alert on page load
    const [alert, setAlert] = useState(true);

    // hook for alert message - show message on page load
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // function to scroll table
    function scrollToTop() {
        window.scrollTo(0, 0);

        document.getElementById('table-data').scrollIntoView();
    }

    // realm functions declaration
    const { currentUser, logInAnonymous, getUserTier } = useRealmApp();

    // check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // function to hide modal
    const history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }

    // table functions declaration
    const { getGrowthStageFundingTest, getCompanyDetailsForExpand } = currentUser.functions;

    // hook for argument to realm function
    const [argument, setArgument] = useState({
        numOfRows: 50,
        currentPage: 1,
        searchTerm: "",
        selectedHeader: "",
        selectedDropdown: "",
    });

    // hook for front-end pagination
    const [page, setPage] = useState({
        startNum: 1,
        endNum: 50,
        lastPage: 0
    });

    // hook for table data
    const [tableData, setTableData] = useState({
        results: [],
        total: 0,
        lastPage: 0,
        statistics: {
            financingSources: 0
        }
    });

    // fetch data on page load
    useEffect(async () => {
        try {
            if (!getUserTier() || getUserTier() < 3) {
                setTableData("");
            }
            else {
                let response = await getGrowthStageFundingTest(argument)
                setTableData(JSON.parse(response.body));

                page["lastPage"] = JSON.parse(response.body).lastPage;

                setPage({ ...page });
            }
        } catch (err) { }
        // close alert when data is received
        setAlert(false);
    }, []);

    // function to update hooks
    async function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // change current page value
        if (name.includes("Page")) {

            let currentPageName = "currentPage", currentPageValue,
                startNumName = "startNum", startNumValue,
                endNumName = "endNum", endNumValue;

            if (name === "firstPage") {
                currentPageValue = 1;
                startNumValue = 1;
                endNumValue = argument.numOfRows;
            }
            if (name === "prevPage") {
                currentPageValue = argument.currentPage - 1;
                startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
                endNumValue = currentPageValue * argument.numOfRows;
            }
            if (name === "nextPage") {
                currentPageValue = argument.currentPage + 1;
                startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
                endNumValue = currentPageValue * argument.numOfRows;
            }
            if (name === "lastPage") {
                currentPageValue = page.lastPage;
                startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
                endNumValue = tableData.total;
            }

            argument[currentPageName] = currentPageValue;
            page[startNumName] = startNumValue;
            page[endNumName] = endNumValue;

            setArgument({ ...argument });
            setPage({ ...page });
        }

        // if numOfRows is changed, then reset all values for pagination
        if (name === "numOfRows") {
            let lastPage = Math.ceil(tableData.total / value);

            argument["currentPage"] = 1;
            argument["numOfRows"] = value;
            page["startNum"] = 1;
            page["endNum"] = value;
            page["lastPage"] = lastPage;

            setArgument({ ...argument });
            setPage({ ...page });
        }

        // change search term value
        if (name === "searchTerm") {
            let argumentCopy = { ...argument };

            argumentCopy[name] = value;

            setArgument({ ...argumentCopy });
        }

        // only fetch data if numOfRows or currentPage is changed
        if (name.localeCompare("searchTerm") !== 0) {
            // show alert when data is fetching
            setAlert(true);
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                    <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            try {
                let response = await getGrowthStageFundingTest(argument);
                setTableData(JSON.parse(response.body));
                // console.log(tableData);

                // show alert when data is received
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                    <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
                </div>);
            } catch (err) { }
        }
    }

    // function to submit search term
    async function submitSearch(e) {
        e.preventDefault();

        let argumentCopy = { ...argument };

        argumentCopy["numOfRows"] = "50";
        argumentCopy["currentPage"] = "1";

        setArgument({ ...argumentCopy });

        // show alert when data is fetching
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            let response = await getGrowthStageFundingTest(argument)
            setTableData(JSON.parse(response.body));
            // console.log(tableData);

            if (JSON.parse(response.body).total == 0) {
                // show alert when data is received
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Sorry, your search query returned no results.</p>
                    <ul style={{ marginLeft: "3px" }}>
                        <li>Check for typing or spelling errors</li>
                        <li>Use a different search term and try a new search</li>
                        <li>We may not yet have the item you're searching for</li>
                    </ul>
                    <p style={{ marginLeft: "28px" }}>If you still can't find what you're looking for, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">share your feedback</a> to help us improve.</p>
                </div>);
            }
            else {
                // show alert when data is received
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                    <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
                </div>);
            }

            let pageCopy = { ...page };

            pageCopy["endNum"] = JSON.parse(response.body).results.length.toString();
            pageCopy["lastPage"] = JSON.parse(response.body).lastPage;

            setPage({ ...pageCopy });
        } catch (err) { }
    }

    // function to listen to keyboard
    function enterSearch(e) {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            submitSearch(e);
        }
    };

    // function to reset search term
    async function resetSearch(e) {
        e.preventDefault();

        argument["searchTerm"] = "";
        argument["currentPage"] = 1;

        setArgument({ ...argument });
        setTableData({
            results: [],
            total: 0,
            lastPage: 0
        });
        setPage({
            startNum: 1,
            endNum: 50,
            lastPage: 0
        });

        // show alert when data is fetching
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            let response = await getGrowthStageFundingTest(argument);
            setTableData(JSON.parse(response.body));
            // console.log(tableData);

            page["lastPage"] = JSON.parse(response.body).lastPage;

            setPage({ ...page });

            // show alert when data is received
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
            </div>);
        } catch (err) { }
    }

    // hook for expand card modal
    const [expandCard, setExpandCard] = useState(false);

    // hook for expand card data
    const [expandData, setExpandData] = useState();

    // function to fetch more details for expand modal
    async function getCompanyDetails(e) {

        // show alert message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await getCompanyDetailsForExpand(e);

            const array1 = response[0].companies;

            const array2 = response[0].details;

            const mergedArray = mergeArraysOfObjectsByKey(array1, array2, "name");

            setExpandData(mergedArray);
            setExpandCard(true);
        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

        // close alert
        closeAlert();
    }

    // merge two arrays of objects together based on key
    function mergeArraysOfObjectsByKey(array1, array2, key) {
        const mergedArray = [];

        // iterate over the first array
        array1.forEach((object1) => {
            // find the corresponding object in the second array
            const object2 = array2.find((object2) => object2[key] === object1[key]);

            // if there is a corresponding object in the second array, merge the two objects
            if (object2) {
                const mergedObject = Object.assign({}, object1, object2);
                mergedArray.push(mergedObject);
            } else {
                // if there is no corresponding object in the second array, just add the first object to the merged array
                mergedArray.push(object1);
            }
        });

        return mergedArray;
    }

    // function to open expand modal
    const openExpandCard = (e) => {
        setExpandCard(true);
        setExpandData(e);
    }

    // function to close expand modal
    function closeExpandCard() {
        setExpandCard(false);
        setExpandData(null);
    }

    // function to show pop up card when user clicks logo 
    const [showCard, setShowCard] = useState(false);
    const [moreInfo, setMoreInfo] = useState([]);

    const handleData = (e) => {

        try {
            const newData = tableData.results.filter(investors => investors.name === e)
                .map(investors =>
                    investors = {
                        "slug": investors.slug,
                        "name": investors.name,
                        "description": investors.description,
                        "logo": investors.logo,
                        "location": investors.location,
                        "country": investors.location.country,
                        "countryCode": investors.location.countryCode,
                        "state": investors.location.state,
                        "city": investors.location.city,
                        "investorType": investors.investorType,
                        "lossOnInvestments": investors.lossOnInvestments,
                        "numOfFailedInvestments": investors.numOfFailedInvestments,
                        "portfolioCompanies": investors.portfolioCompanies,
                        "investmentStage": investors.investmentStage,
                        "investmentTrackRecord": investors.investmentTrackRecord
                    });

            setMoreInfo(newData);
            setShowCard(true);

        } catch (error) {

            const newData = tableData.results.filter(investors => investors.name === e)
                .map(investors =>
                    investors = {
                        "slug": investors.slug,
                        "name": investors.name,
                        "description": investors.description,
                        "logo": investors.logo,
                        "investorType": investors.investorType,
                        "lossOnInvestments": investors.lossOnInvestments,
                        "numOfFailedInvestments": investors.numOfFailedInvestments,
                        "portfolioCompanies": investors.portfolioCompanies,
                        "investmentStage": investors.investmentStage,
                        "investmentTrackRecord": investors.investmentTrackRecord
                    });

            setMoreInfo(newData);
            setShowCard(true);
        }
    }

    function hideCard() {
        setShowCard(false);
    }

    const collection = "investors";

    // filter function - the args/constructor are locales (en) language and options (numeric collation are set to true and sensitivity level set to base)
    const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
    const [fieldName, setFieldName] = useState([]);
    const [hideField, setHideField] = useState(false);

    // sort handler
    async function sort(value, header) {

        if (value === " Hide Field") {
            setFieldName(fieldName => [...fieldName, header]);
            setHideField(true);
        };

        if (value === " Sort A to Z" || value === " Sort smaller to larger" || value === " Sort older to newer" || value === " Sort Z to A" || value === " Sort larger to smaller" || value === " Sort newer to older") {
            // show alert when data is being fetched
            setAlert(true);
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Sorting</p>
                    <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            //update the argument state variable
            let newHeader = header;

            switch (header) {
                case "name": { newHeader = "name"; break; }
                case "description": { newHeader = "description"; break; }
                case "numOfFailedInvestments": { newHeader = "numOfFailedInvestments"; break; }
                case "portfolioCompanies": { newHeader = "portfolioCompanies"; break; }
                case "reputation": { newHeader = "reputation"; break; }
                case "investmentStage": { newHeader = "investmentStage"; break; }
                case "investmentGeography": { newHeader = "investmentGeography"; break; }
                case "numOfInvestments": { newHeader = "numOfInvestments"; break; }
                case "numOfLeadInvestments": { newHeader = "numOfLeadInvestments"; break; }
                case "numOfFollowOnInvestments": { newHeader = "numOfFollowOnInvestments"; break; }
                case "numOfCoInvestments": { newHeader = "numOfCoInvestments"; break; }
                case "numOfAcquisitions": { newHeader = "numOfAcquisitions"; break; }
                case "boardSeats": { newHeader = "boardSeats"; break; }
                case "limitedPartners": { newHeader = "limitedPartners"; break; }
                case "coInvestors": { newHeader = "coInvestors"; break; }
                case "numofPartners": { newHeader = "numofPartners"; break; }
                case "partners": { newHeader = "partners"; break; }
                case "socialMedia": { newHeader = "socialMedia"; break; }
                case "entityType": { newHeader = "entityType"; break; }
                case "legalName": { newHeader = "legalName"; break; }
                case "numOfFunds": { newHeader = "numOfFunds"; break; }
                case "assets": { newHeader = "assets"; break; }
                case "location": { newHeader = "location.city"; break; }
                case "investmentRange": { newHeader = "investmentRange.totalNumOfStages"; break; }
                case "investmentTrackRecord": { newHeader = "investmentTrackRecord.totalNumOfStages"; break; }
                case "investmentFocus": { newHeader = "investmentFocus"; break; }
                case "sweetSpot": { newHeader = "sweetSpot.totalNumOfStages"; break; }
                case "management": { newHeader = "management.name"; break; }
                case "investorType": { newHeader = "investorType"; break; }
                case "boardMembers": { newHeader = "boardMembers.firstName"; break; }
                case "lossOnInvestments": { newHeader = "lossOnInvestmentsObj.int"; break; }
                case "numOfEmployees": { newHeader = "numOfEmployeesObj.min"; break; }
                case "numOfExit": { newHeader = "numOfCoInvestments"; break; }
                case "website": { newHeader = "profileLink.url"; break; }
                case "founders": { newHeader = "founders"; break; }
                case "founded": { newHeader = "founded.dateOrder"; break; }
                case "email": { newHeader = "contact.emails"; break; }
                case "phone": { newHeader = "contact.phoneNumber"; break; }
                case "address": { newHeader = "address.street1"; break; }
                case "primaryBusinessName": { newHeader = "primaryBusinessName"; break; }
                case "former_legalName": { newHeader = "former_legalName"; break; }
                case "stockSymbol": { newHeader = "stockSymbol"; break; }
                case "exitValue": { newHeader = "exitValue"; break; }
                default: { newHeader = null; }
            }
            //argument is updated
            let argumentCopy = { ...argument },
                name1 = "selectedHeader",
                name2 = "selectedDropdown";

            argumentCopy[name1] = newHeader;
            argumentCopy[name2] = value;
            setArgument({ ...argumentCopy });
        }
    }

    useEffect(() => {
        const getSortedResults = async () => {
            //if these arguments are updated, send to backend
            if ((argument.selectedHeader && argument.selectedDropdown != null) && (argument.searchTerm === null)) {
                try {
                    let response = await getGrowthStageFundingTest(argument);
                    // console.log(argument);
                    const updatedList = JSON.parse(response.body);
                    setTableData(updatedList);

                    // show alert when data is received
                    setAlert(true);
                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                        <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
                    </div>);

                } catch (err) {
                    // show alert when error is received
                    setAlert(true);
                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                        <p style={{ marginLeft: "28px" }}>There seems to be an issue and we couldn't sort your selected column(s). Please try again.</p>
                        <p style={{ marginLeft: "28px" }}>If you still have trouble sorting your selected column(s), please <a href="https://www.roiquant.com/contact">contact our support team</a> for help.
                        </p>
                    </div>);
                }
            } else if ((argument.selectedHeader && argument.selectedDropdown != null) && (argument.searchTerm != null)) {
                try {
                    let response = await getGrowthStageFundingTest(argument);
                    const updatedList = JSON.parse(response.body);
                    setTableData(updatedList);

                    // show alert when data is received
                    setAlert(true);
                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                        <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
                    </div>);
                } catch (err) {
                }
            }
        };
        getSortedResults();
        return () => {
        };
    }, [argument]);

    function unhideFields() {
        setHideField(false);
        if (fieldName) {
            fieldName.length = 0;
        }
    }

    return (
        <>
            <NavBar />

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="funding">
                <div className="fundingheaderdiv">
                    <div className="fundingtitlediv">
                        <br />
                        <span className="fundingtext">
                            Growth stage funding
                            <sup data-tip="Search for funding with our founder-friendly collection of revenue-based financing, growth venture capital, and other growth stage funding sources.">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </span>
                    </div>

                    <div className="fundingstatsdiv">
                        <table className="tg" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td className="table-data db-header">
                                        Financing sources
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-data db-data">
                                        {
                                            tableData.statistics && !tableData.statistics.financingSources
                                                ? tableData.statistics.financingSources.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                : "22,171"
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {
                    !getUserTier() || getUserTier() < 3
                        ? (<Modal className="modal-div" show="true" onHide={goToPreviousPath} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton style={{ borderBottom: "none" }} />
                            <Modal.Body>
                                <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Get started with Premium Plan at USD $25 per month</p>

                                <p style={{ textAlign: "left" }}>Growth stage funding sources for businesses generating growth traction and revenue</p>

                                <div style={{ textAlign: "center" }}>
                                    <a href="https://www.roiquant.com/pricing" target="_blank" rel="noopener noreferrer">
                                        <Button className="modal-compare glintanimation">Compare plans</Button>
                                    </a>
                                </div>

                                <hr style={{ margin: "1.5rem 0" }} />

                                <p style={{ fontWeight: "bold", marginBottom: "0" }}>Things to know:</p>

                                <ul className="modal-list">
                                    <li>Downgrade your subscription plan at any time</li>
                                    <li>Once payment is made, there is no refund</li>
                                    <li>If you downgrade or cancel, you will no longer have access to the features that are only available in the subscription plan which you downgraded or cancelled from</li>
                                </ul>

                                <div style={{ textAlign: "center" }}>
                                    <a href="https://www.roiquant.com/sourcing/funding/growth-stage" target="_blank" rel="noopener noreferrer">
                                        <Button className="modal-product glintanimation">Learn more about the product</Button>
                                    </a>
                                </div>
                            </Modal.Body>
                        </Modal>)
                        : (<>
                            <div className="shutdowndatasearchdiv">
                                <div className="shutdowndatasearchbox">
                                    <Form>
                                        <Form.Group controlId="formSearch">
                                            <Form.Control className="inputbox" value={argument.searchTerm} type="name" name="searchTerm" placeholder="Search from growth stage funding" onChange={changeFilters} onKeyDown={enterSearch} />
                                        </Form.Group>
                                    </Form>
                                </div>

                                <div className="shutdowndatasearchbuttons">
                                    {
                                        argument.searchTerm == null || argument.searchTerm == ""
                                            ? <button className="shutdowndataresetbutton" disabled={true}>
                                                <span>Clear</span>
                                            </button>
                                            : <button className="shutdowndataresetbutton" onClick={resetSearch}>
                                                <span>Clear</span>
                                            </button>
                                    }

                                    {
                                        argument.searchTerm == null || argument.searchTerm == ""
                                            ? <Button className="shutdowndatasearchbutton" disabled>
                                                <span>Search</span>
                                            </Button>
                                            : <Button className="shutdowndatasearchbutton" onClick={submitSearch}>
                                                <span>Search</span>
                                            </Button>
                                    }
                                </div>

                                <div className="shutdowndatahidefieldbuttons">
                                    <p className="shutdowndatahidefieldbutton" onClick={unhideFields}>
                                        {!hideField ? <span>Hide Field</span> : hideField && fieldName.length === 1 ? <span>Unhide Field</span> : <span>Unhide Fields</span>}
                                    </p>
                                    <p className="shutdownellipsisicon"><FaEllipsisH /></p>
                                </div>
                            </div>

                            <div className="fundingdiv">
                                <table className="fundingtable" id="table-data">
                                    <thead>
                                        <tr>
                                            <th>No.<span style={{ float: "right" }}></span></th>
                                            <th style={{ color: "transparent" }}>Image</th>
                                            {!fieldName.includes('name') ? <th>Name <span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "name")} /></span></th> : null}
                                            {!fieldName.includes('investorType') ? <th>Funding type<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "investorType")} /></span></th> : null}
                                            {!fieldName.includes('description') ? <th>Description<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "description")} /></span></th> : null}
                                            {!fieldName.includes('location') ? <th>Location<span style={{ float: "right" }}>  <DropdownComponent onClick={(value, header) => sort(value, "location")} /></span></th> : null}
                                            {!fieldName.includes('investmentStage') ? <th>Preferred funding stage(s)<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "investmentStage")} /></span></th> : null}
                                            {!fieldName.includes('investmentTrackRecord') ? <th>Stages invested in<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "investmentTrackRecord")} /></span></th> : null}
                                            {!fieldName.includes('assets') ? <th>Assets under management (AUM)<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "assets")} /></span></th> : null}
                                            {!fieldName.includes('investmentRange') ? <th>Funding range<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "investmentRange")} /></span></th> : null}
                                            {!fieldName.includes('sweetSpot') ? <th>Funding sweet spot<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "sweetSpot")} /></span></th> : null}
                                            {!fieldName.includes('investmentFocus') ? <th>Preferred funding focus<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "investmentFocus")} /></span></th> : null}
                                            {!fieldName.includes('investmentGeography') ? <th>Preferred funding geography<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "investmentGeography")} /></span></th> : null}
                                            {!fieldName.includes('numOfInvestments') ? <th>No. of fundings<span style={{ float: "right" }}> <NumberDropdownComponent onClick={(value, header) => sort(value, "numOfInvestments")} /></span></th> : null}
                                            {!fieldName.includes('numOfLeadInvestments') ? <th>No. of lead investments<span style={{ float: "right" }}> <NumberDropdownComponent onClick={(value, header) => sort(value, "numOfLeadInvestments")} /></span></th> : null}
                                            {!fieldName.includes('numOfFollowOnInvestments') ? <th>No. of follow-on investments<span style={{ float: "right" }}> <NumberDropdownComponent onClick={(value, header) => sort(value, "numOfFollowOnInvestments")} /></span></th> : null}
                                            {!fieldName.includes('numOfCoInvestments') ? <th>No. of co-investments<span style={{ float: "right" }}> <NumberDropdownComponent onClick={(value, header) => sort(value, "numOfCoInvestments")} /></span></th> : null}
                                            {!fieldName.includes('portfolioCompanies') ? <th>Portfolio companies<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "portfolioCompanies")} /></span></th> : null}
                                            {!fieldName.includes('numOfExit') ? <th>No. of exits<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "numOfExit")} /></span></th> : null}
                                            {!fieldName.includes('exitValue') ? <th>Total exit value<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "exitValue")} /></span></th> : null}
                                            {!fieldName.includes('numOfFailedInvestments') ? <th>No. of failed investments<span style={{ float: "right" }}> <NumberDropdownComponent onClick={(value, header) => sort(value, "numOfFailedInvestments")} /></span></th> : null}
                                            {!fieldName.includes('lossOnInvestments') ? <th>Loss on investments<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "lossOnInvestments")} /></span></th> : null}
                                            {!fieldName.includes('numOfAcquisitions') ? <th>No. of acquisitions<span style={{ float: "right" }}> <NumberDropdownComponent onClick={(value, header) => sort(value, "numOfAcquisitions")} /></span></th> : null}
                                            {!fieldName.includes('boardSeats') ? <th>No. of board seats taken<span style={{ float: "right" }}> <NumberDropdownComponent onClick={(value, header) => sort(value, "boardSeats")} /></span></th> : null}
                                            {!fieldName.includes('numOfFunds') ? <th>No. of funds<span style={{ float: "right" }}> <NumberDropdownComponent onClick={(value, header) => sort(value, "numOfFunds")} /></span></th> : null}
                                            {!fieldName.includes('limitedPartners') ? <th>Limited partners<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "limitedPartners")} /></span></th> : null}
                                            {!fieldName.includes('coInvestors') ? <th>Co-investors<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "coInvestors")} /></span></th> : null}
                                            {!fieldName.includes('numofPartners') ? <th>No. of partners<span style={{ float: "right" }}> <NumberDropdownComponent onClick={(value, header) => sort(value, "numofPartners")} /></span></th> : null}
                                            {!fieldName.includes('partners') ? <th>Partners<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "partners")} /></span></th> : null}
                                            {!fieldName.includes('numOfEmployees') ? <th>No. of employees<span style={{ float: "right" }}><NumberDropdownComponent onClick={(value, header) => sort(value, "numOfEmployees")} /></span> </th> : null}
                                            {!fieldName.includes('management') ? <th>Management<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "management")} /></span> </th> : null}
                                            {!fieldName.includes('boardMembers') ? <th>Board members<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "boardMembers")} /></span> </th> : null}
                                            {!fieldName.includes('founders') ? <th>Founders<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "founders")} /></span></th> : null}
                                            {!fieldName.includes('investorReputation') ? <th>Investor reputation<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "investorReputation")} /></span></th> : null}
                                            {!fieldName.includes('email') ? <th>Email<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "email")} /></span> </th> : null}
                                            {!fieldName.includes('socialMedia') ? <th>Social media<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "socialMedia")} /></span></th> : null}
                                            {!fieldName.includes('website') ? <th>Website<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "website")} /></span></th> : null}
                                            {!fieldName.includes('phone') ? <th>Phone no.<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "phone")} /></span></th> : null}
                                            {!fieldName.includes('address') ? <th>Address<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "address")} /></span></th> : null}
                                            {!fieldName.includes('entityType') ? <th>Entity type<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "entityType")} /></span></th> : null}
                                            {!fieldName.includes('legalName') ? <th>Legal name<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "legalName")} /></span></th> : null}
                                            {!fieldName.includes('primaryBusinessName') ? <th>Other business name(s)<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "primaryBusinessName")} /></span></th> : null}
                                            {!fieldName.includes('former_legalName') ? <th>Former legal name(s)<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "former_legalName")} /></span></th> : null}
                                            {!fieldName.includes('foundedDate') ? <th>Founded date<span style={{ float: "right" }}><TimeDropdownComponent onClick={(value, header) => sort(value, "founded")} /></span></th> : null}
                                            {!fieldName.includes('stockSymbol') ? <th>Ticker<span style={{ float: "right" }}><DropdownComponent onClick={(value, header) => sort(value, "stockSymbol")} /></span></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.results.map((investor, index) =>
                                            <tr key={index}>
                                                <td><p className="detailxsdata">{index + 1}.</p></td>

                                                {!investor.logo
                                                    ? (<td><img src={SampleImage} alt={investor.name} className="investorlogo" onClick={(e) => handleData(investor.name)} /></td>)
                                                    : (<td><img src={S3Media + 'assets/photos/investors/' + investor.logo} alt={investor.name} className="investorlogo" onClick={(e) => handleData(investor.name)} /></td>)
                                                }

                                                {!investor.name
                                                    ? (<>{!fieldName.includes('name') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('name') ? <td>
                                                        {!investor.slug
                                                            ? (<p className="detailmediumdata">{investor.name}</p>)
                                                            : (<>
                                                                {/* <Button className="shutdowndataeditbutton">
                                                                    <Link to={`/data/edit/${collection}/${investor.slug}`} target="_blank" rel="noopener noreferrer" className="shutdowndataedit">
                                                                        <sup><FaEdit /></sup>
                                                                    </Link>
                                                                </Button> */}
                                                                <Link to={`/investor/${investor.slug}`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                                    {investor.name}
                                                                </Link>
                                                            </>)
                                                        }
                                                    </td> : null}</>)
                                                    // : (<td><p className="detailmediumdata">{company.tradeName}</p></td>)
                                                }

                                                {!investor.investorType
                                                    ? (<>{!fieldName.includes('investorType') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('investorType')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => openExpandCard(investor.investorType.join(", "))}><AiOutlineExpandAlt /></Button>
                                                            <p className="detailmediumdata funding-cut-data">{investor.investorType.join(", ")}</p>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {!investor.description
                                                    ? (<>{!fieldName.includes('description') ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('description')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => openExpandCard(investor.description)}><AiOutlineExpandAlt /></Button>
                                                            <p className="detaillongdata">{investor.description}</p>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {!investor.location
                                                    ? (<>{!fieldName.includes('location') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('location') ? <td><p className="detailmediumdata">{!investor.location.city ? "" : <span>{investor.location.city},</span>} {!investor.location.state ? "" : <span>{investor.location.state},</span>} {!investor.location.country ? "" : <span>{investor.location.country}</span>}</p></td> : null}</>)
                                                }

                                                {!investor.investmentStage
                                                    ? (<>{!fieldName.includes('investmentStage') ? <td><p className="detailml2data" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('investmentStage')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => openExpandCard(investor.investmentStage.join(", "))}><AiOutlineExpandAlt /></Button>
                                                            <p className="detailml2data funding-cut-data">{investor.investmentStage.join(", ")}</p>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {!investor.investmentTrackRecord
                                                    ? (<>{!fieldName.includes('investmentTrackRecord') ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('investmentTrackRecord') ? <td>
                                                        <div className="detaillongdata funding-cut-data">
                                                            <ol className="detailproductlist">
                                                                {!investor.investmentTrackRecord.veryEarlyStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Very-early stage: {investor.investmentTrackRecord.veryEarlyStage}</li>
                                                                }

                                                                {!investor.investmentTrackRecord.earlyStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Early stage: {investor.investmentTrackRecord.earlyStage}</li>
                                                                }

                                                                {!investor.investmentTrackRecord.growthStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Growth stage: {investor.investmentTrackRecord.growthStage}</li>
                                                                }

                                                                {!investor.investmentTrackRecord.lateStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Late stage: {investor.investmentTrackRecord.lateStage}</li>
                                                                }

                                                                {!investor.investmentTrackRecord.postIPO
                                                                    ? ""
                                                                    : <li className="detailbodydata">Post-IPO: {investor.investmentTrackRecord.postIPO}</li>
                                                                }
                                                            </ol>
                                                        </div>
                                                    </td> : null}</>)
                                                }

                                                {/* assets under management */}
                                                {!fieldName.includes('assets') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}

                                                {!investor.investmentRange
                                                    ? (<>{!fieldName.includes('investmentRange') ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('investmentRange') ? <td>
                                                        <div className="detaillongdata funding-cut-data">
                                                            <ol className="detailproductlist">
                                                                {!investor.investmentRange.veryEarlyStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Very-early stage: {investor.investmentRange.veryEarlyStage}</li>
                                                                }

                                                                {!investor.investmentRange.earlyStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Early stage: {investor.investmentRange.earlyStage}</li>
                                                                }

                                                                {!investor.investmentRange.growthStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Growth stage: {investor.investmentRange.growthStage}</li>
                                                                }

                                                                {!investor.investmentRange.lateStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Late stage: {investor.investmentRange.lateStage}</li>
                                                                }

                                                                {!investor.investmentRange.postIPO
                                                                    ? ""
                                                                    : <li className="detailbodydata">Post-IPO: {investor.investmentRange.postIPO}</li>
                                                                }
                                                            </ol>
                                                        </div>
                                                    </td> : null}</>)
                                                }

                                                {!investor.sweetSpot
                                                    ? (<>{!fieldName.includes('sweetSpot') ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('sweetSpot') ? <td>
                                                        <div className="detaillongdata funding-cut-data">
                                                            <ol className="detailproductlist">
                                                                {!investor.sweetSpot.veryEarlyStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Very-early stage: {investor.sweetSpot.veryEarlyStage}</li>
                                                                }

                                                                {!investor.sweetSpot.earlyStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Early stage: {investor.sweetSpot.earlyStage}</li>
                                                                }

                                                                {!investor.sweetSpot.growthStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Growth stage: {investor.sweetSpot.growthStage}</li>
                                                                }

                                                                {!investor.sweetSpot.lateStage
                                                                    ? ""
                                                                    : <li className="detailbodydata">Late stage: {investor.sweetSpot.lateStage}</li>
                                                                }

                                                                {!investor.sweetSpot.postIPO
                                                                    ? ""
                                                                    : <li className="detailbodydata">Post-IPO: {investor.sweetSpot.postIPO}</li>
                                                                }
                                                            </ol>
                                                        </div>
                                                    </td> : null}</>)
                                                }

                                                {!investor.investmentFocus
                                                    ? (<>{!fieldName.includes('investmentFocus') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('investmentFocus')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => openExpandCard(investor.investmentFocus.join(", "))}><AiOutlineExpandAlt /></Button>
                                                            <p className="detailmldata funding-cut-data">{investor.investmentFocus.join(", ")}</p>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {!investor.investmentGeography
                                                    ? (<>{!fieldName.includes('investmentGeography') ? <td><p className="detailml2data" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('investmentGeography')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => openExpandCard(investor.investmentGeography.join(", "))}><AiOutlineExpandAlt /></Button>
                                                            <p className="detailml2data funding-cut-data">{investor.investmentGeography.join(", ")}</p>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {!investor.numOfInvestments
                                                    ? (<>{!fieldName.includes('numOfInvestments') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('numOfInvestments') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{investor.numOfInvestments}</p></td> : null}</>)
                                                }

                                                {!investor.numOfLeadInvestments
                                                    ? (<>{!fieldName.includes('numOfLeadInvestments') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('numOfLeadInvestments') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>{investor.numOfLeadInvestments}</p></td> : null}</>)
                                                }

                                                {!investor.numOfFollowOnInvestments
                                                    ? (<>{!fieldName.includes('numOfFollowOnInvestments') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('numOfFollowOnInvestments') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>{investor.numOfFollowOnInvestments}</p></td> : null}</>)
                                                }

                                                {!investor.numOfCoInvestments
                                                    ? (<>{!fieldName.includes('numOfCoInvestments') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('numOfCoInvestments') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>{investor.numOfCoInvestments}</p></td> : null}</>)
                                                }

                                                {!investor.portfolioCompanies
                                                    ? (<>{!fieldName.includes('portfolioCompanies') ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('portfolioCompanies')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => getCompanyDetails({ slug: investor.slug, field: "portfolioCompanies", table: "investors" })}><AiOutlineExpandAlt /></Button>
                                                            <div className="detaillongdata funding-cut-data">
                                                                <ol className="detailproductlist">{investor.portfolioCompanies.map((company, index) =>
                                                                    !company
                                                                        ? ""
                                                                        : (<li key={index} className="detailbodydata">{company.name}</li>)
                                                                )}
                                                                </ol>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {!investor.numOfExit
                                                    ? (<>{!fieldName.includes('numOfExit') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('numOfExit') ? <td><p className="detailshortdata">{investor.numOfCoInvestments}</p></td> : null}</>)
                                                }

                                                {/* total exit value */}
                                                {!fieldName.includes('exitValue') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                                {!investor.numOfFailedInvestments
                                                    ? (<>{!fieldName.includes('numOfFailedInvestments') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('numOfFailedInvestments') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>{investor.numOfFailedInvestments}</p></td> : null}</>)
                                                }

                                                {!investor.lossOnInvestmentsObj
                                                    ? (<>{!fieldName.includes('lossOnInvestments') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('lossOnInvestments') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>{investor.lossOnInvestmentsObj.str}</p></td> : null}</>)
                                                }

                                                {!investor.numOfAcquisitions
                                                    ? (<>{!fieldName.includes('numOfAcquisitions') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('numOfAcquisitions') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{investor.numOfAcquisitions}</p></td> : null}</>)
                                                }

                                                {/* no. of board seats taken */}
                                                {!fieldName.includes('boardSeats') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                                {!investor.numOfFunds
                                                    ? (<>{!fieldName.includes('numOfFunds') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('numOfFunds') ? <td><p className="detailshortdata">{investor.numOfFunds}</p></td> : null}</>)
                                                }

                                                {/* limited partners */}
                                                {!fieldName.includes('limitedPartners') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                                {!investor.coInvestors
                                                    ? (<>{!fieldName.includes('coInvestors') ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('coInvestors')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => openExpandCard(investor.coInvestors.join(", "))}><AiOutlineExpandAlt /></Button>
                                                            <p className="detaillongdata funding-cut-data">{investor.coInvestors.join(", ")}</p>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {/* no. of partners */}
                                                {!fieldName.includes('numofPartners') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                                {/* partners */}
                                                {!fieldName.includes('partners') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                                {!investor.numOfEmployeesObj
                                                    ? (<>{!fieldName.includes('numOfEmployees') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('numOfEmployees') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{investor.numOfEmployeesObj.range}</p></td> : null}</>)
                                                }

                                                {!investor.management
                                                    ? (<>{!fieldName.includes('management') ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('management')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => openExpandCard(investor.management)}><AiOutlineExpandAlt /></Button>
                                                            <div className="detaillongdata funding-cut-data">
                                                                <ol className="detailproductlist">{investor.management.map((founder, index) =>
                                                                    !founder
                                                                        ? ""
                                                                        : (<li key={index} className="detailbodydata">{founder.name}</li>)
                                                                )}
                                                                </ol>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {!investor.boardMembers
                                                    ? (<>{!fieldName.includes('boardMembers') ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('boardMembers')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => openExpandCard(investor.boardMembers)}><AiOutlineExpandAlt /></Button>
                                                            <div className="detaillongdata funding-cut-data">
                                                                <ol className="detailproductlist">{investor.boardMembers.map((member, index) =>
                                                                    !member
                                                                        ? ""
                                                                        : (<li key={index} className="detailbodydata">{member.name}</li>)
                                                                )}
                                                                </ol>
                                                            </div>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {!investor.founders
                                                    ? (<>{!fieldName.includes('founders') ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('founders')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => openExpandCard(investor.founders.join(", "))}><AiOutlineExpandAlt /></Button>
                                                            <p className="detaillongdata funding-cut-data">{investor.founders.join(", ")}</p>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {/* investor reputation */}
                                                {!fieldName.includes('investorReputation') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}

                                                {!investor.contact
                                                    ? (<>{!fieldName.includes('email') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('email') ? <td><a className="detailmediumdata fundinglink" href={"mailto:" + investor.contact.emails}>{investor.contact.emails}</a></td> : null}</>)
                                                }

                                                {!investor.socialMedia
                                                    ? (<>{!fieldName.includes('socialMedia') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('socialMedia') ? <td><div className="detailmediumdata funding-cut-data">
                                                        <ul className="detailproductlist">{investor.socialMedia.map((social, index) =>
                                                            !social
                                                                ? ""
                                                                : (<li key={index} className="detailbodydata"><a className="shutdowndatalink" href={social}>
                                                                    {social.includes("twitter")
                                                                        ? "Twitter"
                                                                        : ""
                                                                    }
                                                                    {social.includes("facebook")
                                                                        ? "Facebook"
                                                                        : ""
                                                                    }
                                                                    {social.includes("linkedin")
                                                                        ? "LinkedIn"
                                                                        : ""
                                                                    }
                                                                </a></li>)
                                                        )}
                                                        </ul>
                                                    </div></td> : null}</>)
                                                }

                                                {!investor.profileLink
                                                    ? (<>{!fieldName.includes('website') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('website') ? <td>{investor.profileLink.map((link, index) =>
                                                        link.platform === "Website"
                                                            ? (<a className="detailmediumdata fundinglink" href={link.url} key={index}>{link.url}</a>)
                                                            : ""
                                                    )}
                                                    </td> : null}</>)
                                                }

                                                {!investor.contact
                                                    ? (<>{!fieldName.includes('phone') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('phone') ? <td><p className="detailshortdata">{investor.contact.phoneNumber}</p></td> : null}</>)
                                                }

                                                {!investor.address
                                                    ? (<>{!fieldName.includes('address') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('address') ? <td><p className="detailmediumdata">{!investor.address.street1 ? "" : <span>{investor.address.street1},</span>} {!investor.address.street2 ? "" : <span>{investor.address.street2},</span>} {!investor.location.city ? "" : <span>{investor.location.city}</span>} {!investor.location.country ? "" : <span>{investor.location.country}</span>}</p></td> : null}</>)
                                                }

                                                {!investor.entityType
                                                    ? (<>{!fieldName.includes('entityType') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('entityType') ? <td><p className="detailshortdata">{investor.entityType}</p></td> : null}</>)
                                                }

                                                {!investor.legalName
                                                    ? (<>{!fieldName.includes('legalName') ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('legalName') ? <td><p className="detailmediumdata">{investor.legalName}</p></td> : null}</>)
                                                }

                                                {!investor.primaryBusinessName
                                                    ? (<>{!fieldName.includes('primaryBusinessName') ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('primaryBusinessName')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => openExpandCard(investor.primaryBusinessName.join(", "))}><AiOutlineExpandAlt /></Button>
                                                            <p className="detaillongdata funding-cut-data">{investor.primaryBusinessName.join(", ")}</p>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {!investor.former_legalName
                                                    ? (<>{!fieldName.includes('former_legalName') ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('former_legalName')
                                                        ? <td>
                                                            <Button className="expand-button" onClick={() => openExpandCard(investor.former_legalName.join(", "))}><AiOutlineExpandAlt /></Button>
                                                            <p className="detailmldata funding-cut-data">{investor.former_legalName.join(", ")}</p>
                                                        </td>
                                                        : null
                                                    }</>)
                                                }

                                                {!investor.foundedDate
                                                    ? (<>{!fieldName.includes('foundedDate') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('foundedDate') ? <td><p className="detailshortdata">{investor.founded.dateStr}</p></td> : null}</>)
                                                }

                                                {!investor.stockSymbol
                                                    ? (<>{!fieldName.includes('stockSymbol') ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td> : null}</>)
                                                    : (<>{!fieldName.includes('stockSymbol') ? <td><p className="detailshortdata">{investor.stockSymbol}</p></td> : null}</>)
                                                }
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="fundingfilters">
                                <div className="filterrownumber">
                                    {argument.searchTerm === "" || argument.selectedHeader === "" || argument.selectedDropdown === ""
                                        ? (<p>{page.startNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}-{page.endNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} of {tableData.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>)
                                        : (<p>1-{tableData.results.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} of {tableData.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>)
                                    }
                                </div>

                                <div className="filterpagination">
                                    {
                                        argument.currentPage != 1
                                            ? (<Button name="firstPage" onClick={changeFilters} className="filterpage">First</Button>)
                                            : ""
                                    }
                                    {
                                        argument.currentPage > 1
                                            ? (<Button name="prevPage" onClick={changeFilters} className="filterpage">Prev</Button>)
                                            : ""
                                    }
                                    {
                                        argument.currentPage < page.lastPage
                                            ? (<Button name="nextPage" onClick={changeFilters} className="filterpage">Next</Button>)
                                            : ""
                                    }
                                    {
                                        argument.currentPage != page.lastPage
                                            ? (<Button name="lastPage" onClick={changeFilters} className="filterpage">Last</Button>)
                                            : ""
                                    }
                                </div>

                                <div className="filterrowshow">
                                    <p>Show rows: </p>
                                    <Form.Control value={argument.numOfRows} as="select" name="numOfRows" onChange={changeFilters} className="filterrowselect">
                                        <option>50</option>
                                        {argument.searchTerm === "" || argument.selectedHeader === "" || argument.selectedDropdown === ""
                                            ? (<>
                                                <option>100</option>
                                                <option>500</option>
                                            </>)
                                            : ""
                                        }
                                    </Form.Control>
                                </div>
                            </div>
                        </>)
                }

            </div>

            {/* pop up modal for more row's info */}
            <Modal show={showCard} onHide={hideCard} backdrop="static" fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="funding-header close" closeButton />

                <Modal.Body className="funding-body">

                    {moreInfo.map((investors) =>
                        <div key={investors.slug}>
                            <InvestorsCard
                                slug={investors.slug}
                                name={investors.name}
                                description={investors.description}
                                logo={investors.logo}
                                location={investors.location}
                                country={investors.country}
                                countryCode={investors.countryCode}
                                state={investors.state}
                                city={investors.city}
                                investorType={investors.investorType}
                                numOfFailedInvestments={investors.numOfFailedInvestments}
                                lossOnInvestments={investors.lossOnInvestments}
                                portfolioCompanies={investors.portfolioCompanies}
                                investmentStage={investors.investmentStage}
                                investmentTrackRecord={investors.investmentTrackRecord}
                            />
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            {/* expand card modal */}
            <Modal className="modal-div" show={expandCard} onHide={closeExpandCard} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <div style={{ border: "1px solid #dee2e6", borderRadius: "0.35rem", height: "80vh", overflow: "auto" }}>
                        {Array.isArray(expandData)
                            ? <ol style={{ paddingTop: "1.5em", paddingRight: "1.5em", paddingBottom: "1.5em", margin: "0 !important" }}>
                                {expandData.map((element, index) =>
                                    !element
                                        ? ""
                                        : element.label
                                            ? (<li key={index}>{element.label}{!element.reason ? "" : ": " + element.reason}</li>)
                                            : element.name
                                                ? (<li key={index}>
                                                    {
                                                        !element.slug
                                                            ? element.name
                                                            : (<Link to={`/company/${element.slug}`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                                {element.name}
                                                            </Link>)
                                                    }: {
                                                        !element.description
                                                            ? "No data available at this time."
                                                            : element.description
                                                    }
                                                </li>)
                                                : ""
                                )}
                            </ol>
                            : <p style={{ padding: "1.5em", margin: "0 !important" }}>{expandData}</p>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default GrowthStage;