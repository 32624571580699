import React from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FaSortAmountUpAlt, FaSortAmountDownAlt, FaEyeSlash } from 'react-icons/fa';
import { NavDropdown } from 'react-bootstrap';
import Styles from './dropdown.css';

const NumberDropdownComponent = (props) => {

    function selectedDropdown(event) {
        props.onClick(event.target.innerText);
    }

    return (
        <>
            <NavDropdown alignRight className="sort-navbardropdown" title={<IoMdArrowDropdown className="sort-navbaricon" />} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={selectedDropdown} value={props.sortAsc} className="sort-navbardropdownitem" id="sort-navbaractive"><FaSortAmountDownAlt /> Sort smaller to larger</NavDropdown.Item>
                <NavDropdown.Item onClick={selectedDropdown} value={props.sortDesc} className="sort-navbardropdownitem" id="sort-navbaractive"><FaSortAmountUpAlt /> Sort larger to smaller</NavDropdown.Item>
                <NavDropdown.Item onClick={selectedDropdown} value={props.hideField} className="sort-navbardropdownitem" id="sort-navbaractive"><FaEyeSlash /> Hide Field</NavDropdown.Item>
            </NavDropdown>
        </>
    );
}

export default NumberDropdownComponent;