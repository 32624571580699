import React, { useState } from 'react';
import { Card, Form, Spinner, Alert, Button, Accordion } from 'react-bootstrap';
import { IoMdClose, IoMdInformationCircleOutline } from 'react-icons/io';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList } from 'react-icons/bs';
import { useRealmApp } from '../../../../RealmApp';
import './funding-benchmark.css';

const AnalyzeVCFundingBenchmarks = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // realm functions declaration
    const { currentUser, logInAnonymous } = useRealmApp();

    // to check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // chart function declaration
    const { fundingBenchmark } = currentUser.functions;

    // hook for feedback one
    const [feedbackOne, setFeedbackOne] = useState(
        {
            "fundingRound": "",
            "employeeSize": "",
            "monthlyRecurringRevenue": "",
            "annualRecurringRevenue": "",
            "yearOnYearGrowthRate": "",
            "monthlyBurnRate": "",
            "investmentSize": "",
            "investorEquityStake": "",
            "postMoneyValuation": ""
            // "fundingRound": "Preseed",
            // "employeeSize": "10",
            // "monthlyRecurringRevenue": "10000",
            // "annualRecurringRevenue": "120000.00",
            // "yearOnYearGrowthRate": "10",
            // "monthlyBurnRate": "1000",
            // "investmentSize": "1500000",
            // "investorEquityStake": "30",
            // "postMoneyValuation": "5000000"
        }
    );

    // hook for feedback two
    const [feedbackTwo, setFeedbackTwo] = useState(
        {
            "ableToCommit": "",
            "confidenceLevel": "",
            "investmentSize": "",
            "investorEquityStake": "",
            "postMoneyValuation": ""
            // "ableToCommit": "Yes",
            // "confidenceLevel": "Low",
            // "investmentSize": "3000000",
            // "investorEquityStake": "20",
            // "postMoneyValuation": "7000000"
        }
    );

    const [results, setResults] = useState(
        // {
        //     "feedbackOne": {
        //         "employeeSize": {
        //             "result": "70.0",
        //             "notes": "Employee size is within the IQR range and greater than the median"
        //         },
        //         "monthlyRecurringRevenue": {
        //             "result": "73.33333333333333",
        //             "notes": "Monthly Recurring Revenue is within the IQR range and is less than the median"
        //         },
        //         "annualRecurringRevenue": {
        //             "result": "73.33333333333333",
        //             "notes": "Annual Recurring Revenue is within the IQR range and is less than the median"
        //         },
        //         "yearOnYearGrowthRate": {
        //             "result": "100",
        //             "notes": "Year on Year Growth Rate is within the IQR range and is the LQ"
        //         },
        //         "monthlyBurnRate": {
        //             "result": "100",
        //             "notes": "Monthly Burn Rate is within the IQR range and is the LQ"
        //         },
        //         "investmentSize": {
        //             "notes": "Investment Size is within the IQR range and is greater than the median"
        //         },
        //         "investorEquityStake": {
        //             "notes": "Investor Equity Stake is not within the maximum and the minimum range"
        //         },
        //         "postMoneyValuation": {
        //             "notes": "Post Money Valuation is within the maximum and the minimum range"
        //         }
        //     },
        //     "feedbackTwo": {
        //         "fundingRound": "Seed",
        //         "employeeSize": "13.0",
        //         "monthlyRecurringRevenue": "41666.66667",
        //         "annualRecurringRevenue": "500000.0",
        //         "yearOnYearGrowthRate": "40.0",
        //         "monthlyBurnRate": "50000.0",
        //         "investmentSize": "Funding size is not within the maximum and the minimum range",
        //         "investorEquityStake": "Investor Equity Stake is within the maximum and the minimum range",
        //         "postMoneyValuation": "Post Money Valuation is within the maximum and the minimum range"
        //     }
        // }
    );

    function calculatePostMoneyValuation() {
        let calculation = feedbackOne.investmentSize / (feedbackOne.investorEquityStake / 100);

        // create a copy of filters with desired changes
        let feedbackOneCopy = { ...feedbackOne };

        feedbackOneCopy["postMoneyValuation"] = calculation.toFixed(2);
        setFeedbackOne({ ...feedbackOneCopy });
    }

    // function to update hooks
    function updateForm(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        if (name == "ableToCommit" || name == "confidenceLevel" || name == "investmentSize2" || name == "investorEquityStake2" || name == "postMoneyValuation2") {
            if (name == "investmentSize2") {
                name = "investmentSize";
            }
            else if (name == "investorEquityStake2") {
                name = "investorEquityStake";
            }
            else if (name == "postMoneyValuation2") {
                name = "postMoneyValuation";
            }

            // create a copy of filters with desired changes
            let feedbackTwoCopy = { ...feedbackTwo };

            feedbackTwoCopy[name] = value;
            setFeedbackTwo({ ...feedbackTwoCopy });
        }
        else if (name == "monthlyRecurringRevenue") {
            let name2 = "annualRecurringRevenue";
            let value2 = value * 12;

            let feedbackOneCopy = { ...feedbackOne };

            feedbackOneCopy[name] = value;
            feedbackOneCopy[name2] = value2.toFixed(2);
            setFeedbackOne({ ...feedbackOneCopy });
        }
        else if (name == "annualRecurringRevenue") {
            let name2 = "monthlyRecurringRevenue";
            let value2 = value / 12;

            let feedbackOneCopy = { ...feedbackOne };

            feedbackOneCopy[name] = value;
            feedbackOneCopy[name2] = value2.toFixed(2);
            setFeedbackOne({ ...feedbackOneCopy });
        }
        else if (name == "fundingRound" && value == "Series D & beyond") {
            let feedbackOneCopy = { ...feedbackOne };

            feedbackOneCopy[name] = value;
            setFeedbackOne({ ...feedbackOneCopy });

            let name2 = "ableToCommit";
            let value2 = "No";

            let feedbackTwoCopy = { ...feedbackTwo };

            feedbackTwoCopy[name2] = value2;
            setFeedbackTwo({ ...feedbackTwoCopy });
        }
        else {
            // create a copy of filters with desired changes
            let feedbackOneCopy = { ...feedbackOne };

            feedbackOneCopy[name] = value;
            setFeedbackOne({ ...feedbackOneCopy });
        }
    }

    function resetForm() {
        setFeedbackOne(
            {
                "fundingRound": "",
                "employeeSize": "",
                "monthlyRecurringRevenue": "",
                "annualRecurringRevenue": "",
                "yearOnYearGrowthRate": "",
                "monthlyBurnRate": "",
                "investmentSize": "",
                "investorEquityStake": "",
                "postMoneyValuation": "",
            }
        );

        setFeedbackTwo(
            {
                "ableToCommit": "",
                "confidenceLevel": "",
                "investmentSize": "",
                "investorEquityStake": "",
                "postMoneyValuation": "",
            }
        );

        setResults();
    }

    // function to send hooks to backend
    function simulateForm(e) {
        // console.log({ "feedbackOne": feedbackOne, "feedbackTwo": feedbackTwo });

        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Simulating</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        fundingBenchmark({ "feedbackOne": feedbackOne, "feedbackTwo": feedbackTwo })
            .then(response => {
                // console.log(response);
                setResults(response);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                    <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#results" className="alert-cta">View results</a></p>
                </div>);
            })
            .catch(error => {
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Please try again.</p>
                </div>);
            })
        e.preventDefault();
    }

    return (
        <>

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <Accordion className="companyaccordion" defaultActiveKey="form" style={{ paddingTop: "0" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="form">
                        <div className="companyarrowicon">
                            <FaChevronDown />
                        </div>

                        <div className="companytitlecard">
                            Funding benchmarks
                        </div>

                        <div className="companyellipsisicon">
                            <FaEllipsisH />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="form">
                        <Card.Body id="companyinformation">
                            <div className="col-12">
                                <IoMdInformationCircleOutline />
                                <span className="info-text">
                                    Please fill in all required fields marked with asterisks (<sup>*</sup>).
                                </span>
                            </div>

                            <table className="fundingBenchmarkTable">
                                <tbody>
                                    <tr>
                                        <td><sup>*</sup>Funding round</td>

                                        <td>
                                            <Form.Control required as="select" name="fundingRound" value={feedbackOne.fundingRound} onChange={updateForm}>
                                                <option></option>
                                                <option>Preseed</option>
                                                <option>Seed</option>
                                                <option>Series A</option>
                                                <option>Series B</option>
                                                <option>Series C</option>
                                                <option>Series D & beyond</option>
                                            </Form.Control>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><sup>*</sup>Employees size (persons)</td>

                                        <td>
                                            <Form.Control className="inputbox" type="number" min="1" name="employeeSize" value={feedbackOne.employeeSize} onChange={updateForm} placeholder="Enter employee size" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><sup>*</sup>Monthly recurring revenue (MRR)</td>

                                        <td>
                                            <Form.Control className="inputbox" type="number" min="1" name="monthlyRecurringRevenue" value={feedbackOne.monthlyRecurringRevenue} onChange={updateForm} placeholder="Enter MRR" />
                                            <Form.Text className="text-muted">Enter amount in USD</Form.Text>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><sup>*</sup>Annual recurring revenue (ARR)</td>

                                        <td>
                                            <Form.Control className="inputbox" type="number" min="1" name="annualRecurringRevenue" value={feedbackOne.annualRecurringRevenue} onChange={updateForm} placeholder="Enter ARR" />
                                            <Form.Text className="text-muted">Enter amount in USD</Form.Text>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><sup>*</sup>Year-on-Year (YoY) growth rate</td>

                                        <td>
                                            <Form.Control className="inputbox" type="number" min="1" name="yearOnYearGrowthRate" value={feedbackOne.yearOnYearGrowthRate} onChange={updateForm} placeholder="Enter YoY growth rate" />
                                            <Form.Text className="text-muted">Enter amount in percentage</Form.Text>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><sup>*</sup>Monthly burn rate</td>

                                        <td>
                                            <Form.Control className="inputbox" type="number" min="1" name="monthlyBurnRate" value={feedbackOne.monthlyBurnRate} onChange={updateForm} placeholder="Enter monthly burn rate" />
                                            <Form.Text className="text-muted">Enter amount in USD</Form.Text>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><sup>*</sup>Investment size</td>

                                        <td>
                                            <Form.Control className="inputbox" type="number" min="1" name="investmentSize" value={feedbackOne.investmentSize} onChange={updateForm} placeholder="Enter investment size" />
                                            <Form.Text className="text-muted">Enter amount in USD</Form.Text>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><sup>*</sup>Investor equity stake</td>

                                        <td>
                                            <Form.Control className="inputbox" type="number" min="1" name="investorEquityStake" value={feedbackOne.investorEquityStake} onChange={updateForm} placeholder="Enter investor equity stake" />
                                            <Form.Text className="text-muted">Enter amount in percentage</Form.Text>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><sup>*</sup>Post-money valuation</td>

                                        <td>
                                            <Form.Control className="inputbox" type="number" min="1" name="postMoneyValuation" value={feedbackOne.postMoneyValuation} onChange={updateForm} placeholder="Enter post-money valuation" />
                                            <Form.Text className="text-muted">Enter amount in USD</Form.Text>
                                            <Form.Text className="text-muted">Don't know how to calculate post-money valuation? We'll do it for you.</Form.Text>
                                            <Form.Text className="text-muted">Please input the investment size (in USD) and investor equity stake (%) first, then <a onClick={calculatePostMoneyValuation}>click here</a>.</Form.Text>
                                            <Form.Text className="text-muted">Formula: Post-money valuation = Investment size (in USD) / Investor equity stake (%)</Form.Text>
                                        </td>
                                    </tr>



                                    {feedbackOne.fundingRound == "Series D & beyond"
                                        ? ""
                                        : <>
                                            <tr>
                                                <td><sup>*</sup>Are you planning to fundraise the next round?</td>

                                                <td>
                                                    <Form.Control required as="select" name="ableToCommit" value={feedbackTwo.ableToCommit} onChange={updateForm}>
                                                        <option></option>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </Form.Control>
                                                </td>
                                            </tr>

                                            {feedbackTwo.ableToCommit == "Yes"
                                                ? <>
                                                    <tr>
                                                        <td><sup>*</sup>What is your confidence level?</td>

                                                        <td>
                                                            <Form.Control required as="select" name="confidenceLevel" value={feedbackTwo.confidenceLevel} onChange={updateForm}>
                                                                <option></option>
                                                                <option>Low</option>
                                                                <option>Medium</option>
                                                                <option>High</option>
                                                            </Form.Control>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><sup>*</sup>Investment size for the next round</td>

                                                        <td>
                                                            <Form.Control className="inputbox" type="number" min="1" name="investmentSize2" value={feedbackTwo.investmentSize} onChange={updateForm} placeholder="Enter investment size" />
                                                            <Form.Text className="text-muted">Enter amount in USD</Form.Text>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><sup>*</sup>Investor equity stake for the next round</td>

                                                        <td>
                                                            <Form.Control className="inputbox" type="number" min="1" name="investorEquityStake2" value={feedbackTwo.investorEquityStake} onChange={updateForm} placeholder="Enter investor equity stake" />
                                                            <Form.Text className="text-muted">Enter amount in percentage</Form.Text>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td><sup>*</sup>Post-money valuation for the next round</td>

                                                        <td>
                                                            <Form.Control className="inputbox" type="number" min="1" name="postMoneyValuation2" value={feedbackTwo.postMoneyValuation} onChange={updateForm} placeholder="Enter post-money valuation" />
                                                            <Form.Text className="text-muted">Enter amount in USD</Form.Text>
                                                        </td>
                                                    </tr></>
                                                : ""
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>

                            <div className="buttonsDiv">
                                <button className="resetButton" onClick={resetForm}><span>Clear</span></button>

                                {feedbackOne.fundingRound == ""
                                    || feedbackOne.employeeSize == ""
                                    || feedbackOne.monthlyRecurringRevenue == ""
                                    || feedbackOne.annualRecurringRevenue == ""
                                    || feedbackOne.yearOnYearGrowthRate == ""
                                    || feedbackOne.monthlyBurnRate == ""
                                    || feedbackOne.investmentSize == ""
                                    || feedbackOne.investorEquityStake == ""
                                    || feedbackOne.postMoneyValuation == ""
                                    || (feedbackTwo.ableToCommit == "Yes"
                                        && (feedbackTwo.confidenceLevel == ""
                                            || feedbackTwo.investmentSize == ""
                                            || feedbackTwo.investorEquityStake == ""
                                            || feedbackTwo.postMoneyValuation == "")
                                    )
                                    ? <button className="simulateButton disabled" disabled>Simulate</button>
                                    : <button className="simulateButton" onClick={simulateForm}>Simulate</button>
                                }
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            {
                !results
                    ? ""
                    : <Accordion id="results" className="companyaccordion" defaultActiveKey="result">
                        <Card>
                            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="result">
                                <div className="companyarrowicon">
                                    <FaChevronDown />
                                </div>

                                <div className="companytitlecard">
                                    Results
                                </div>

                                <div className="companyellipsisicon">
                                    <FaEllipsisH />
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="result">
                                <Card.Body id="companyinformation">
                                    <table className="fundingBenchmarkTable results">
                                        <tbody>
                                            <tr>
                                                <td rowSpan={2}>Funding round</td>

                                                <td colSpan={3}>{feedbackOne.fundingRound}</td>

                                                {feedbackOne.fundingRound == "Series D & beyond" || feedbackTwo.ableToCommit == "No"
                                                    ? ""
                                                    : <td colSpan={2}>{results?.feedbackTwo.fundingRound}</td>
                                                }
                                            </tr>

                                            <tr className="subHeaderRow">
                                                <td>Input</td>

                                                <td>Positioning</td>

                                                <td>Remarks</td>

                                                <td colSpan={2}></td>
                                            </tr>

                                            <tr>
                                                <td>Employees size (persons)</td>

                                                <td>{parseInt(feedbackOne.employeeSize)} people</td>

                                                <td>{results?.feedbackOne.employeeSize.result == "" ? "-" : parseFloat(results?.feedbackOne.employeeSize.result).toFixed(1) + "%"}</td>

                                                <td>{results?.feedbackOne.employeeSize.notes}</td>

                                                {feedbackOne.fundingRound == "Series D & beyond" || feedbackTwo.ableToCommit == "No"
                                                    ? ""
                                                    : <td colSpan={2}>{results?.feedbackTwo.employeeSize == "" ? "-" : parseInt(results?.feedbackTwo.employeeSize) + " people"}</td>
                                                }
                                            </tr>

                                            <tr>
                                                <td>Monthly recurring revenue (MRR)</td>

                                                <td>USD $ {parseFloat(feedbackOne.monthlyRecurringRevenue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                                                <td>{results?.feedbackOne.monthlyRecurringRevenue.result == "" ? "-" : parseFloat(results?.feedbackOne.monthlyRecurringRevenue.result).toFixed(1) + "%"}</td>

                                                <td>{results?.feedbackOne.monthlyRecurringRevenue.notes}</td>

                                                {feedbackOne.fundingRound == "Series D & beyond" || feedbackTwo.ableToCommit == "No"
                                                    ? ""
                                                    : <td colSpan={2}>{results?.feedbackTwo.monthlyRecurringRevenue == "" ? "-" : "USD $ " + parseFloat(results?.feedbackTwo.monthlyRecurringRevenue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                }
                                            </tr>

                                            <tr>
                                                <td>Annual recurring revenue (ARR)</td>

                                                <td>USD $ {parseFloat(feedbackOne.annualRecurringRevenue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                                                <td>{results?.feedbackOne.annualRecurringRevenue.result == "" ? "-" : parseFloat(results?.feedbackOne.annualRecurringRevenue.result).toFixed(1) + "%"}</td>

                                                <td>{results?.feedbackOne.annualRecurringRevenue.notes}</td>

                                                {feedbackOne.fundingRound == "Series D & beyond" || feedbackTwo.ableToCommit == "No"
                                                    ? ""
                                                    : <td colSpan={2}>{results?.feedbackTwo.annualRecurringRevenue == "" ? "-" : "USD $ " + parseFloat(results?.feedbackTwo.annualRecurringRevenue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                }
                                            </tr>

                                            <tr>
                                                <td>Year-on-Year (YoY) growth rate</td>

                                                <td>{parseFloat(feedbackOne.yearOnYearGrowthRate).toFixed(1)}%</td>

                                                <td>{results?.feedbackOne.yearOnYearGrowthRate.result == "" ? "-" : parseFloat(results?.feedbackOne.yearOnYearGrowthRate.result).toFixed(1) + "%"}</td>

                                                <td>{results?.feedbackOne.yearOnYearGrowthRate.notes}</td>

                                                {feedbackOne.fundingRound == "Series D & beyond" || feedbackTwo.ableToCommit == "No"
                                                    ? ""
                                                    : <td colSpan={2}>{results?.feedbackTwo.yearOnYearGrowthRate == "" ? "-" : parseFloat(results?.feedbackTwo.yearOnYearGrowthRate).toFixed(1) + "%"}</td>
                                                }
                                            </tr>

                                            <tr>
                                                <td>Monthly burn rate</td>

                                                <td>USD $ {parseFloat(feedbackOne.monthlyBurnRate).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                                                <td>{results?.feedbackOne.monthlyBurnRate.result == "" ? "-" : parseFloat(results?.feedbackOne.monthlyBurnRate.result).toFixed(1) + "%"}</td>

                                                <td>{results?.feedbackOne.monthlyBurnRate.notes}</td>

                                                {feedbackOne.fundingRound == "Series D & beyond" || feedbackTwo.ableToCommit == "No"
                                                    ? ""
                                                    : <td colSpan={2}>{results?.feedbackTwo.monthlyBurnRate == "" ? "-" : "USD $ " + parseFloat(results?.feedbackTwo.monthlyBurnRate).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                }
                                            </tr>

                                            <tr>
                                                <td>Investment size</td>

                                                <td>USD $ {parseFloat(feedbackOne.investmentSize).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                                                <td>-</td>

                                                <td>{results?.feedbackOne.investmentSize.notes}</td>

                                                {feedbackOne.fundingRound == "Series D & beyond" || feedbackTwo.ableToCommit == "No"
                                                    ? ""
                                                    : <>
                                                        <td>{feedbackTwo.investmentSize == "" ? "-" : "USD $ " + parseFloat(feedbackTwo.investmentSize).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                                                        <td>{results?.feedbackTwo.investmentSize}</td>
                                                    </>
                                                }
                                            </tr>

                                            <tr>
                                                <td>Investor equity stake</td>

                                                <td>{parseFloat(feedbackOne.investorEquityStake).toFixed(1)}%</td>

                                                <td>-</td>

                                                <td>{results?.feedbackOne.investorEquityStake.notes}</td>

                                                {feedbackOne.fundingRound == "Series D & beyond" || feedbackTwo.ableToCommit == "No"
                                                    ? ""
                                                    : <>
                                                        <td>{feedbackTwo.investorEquityStake == "" ? "-" : parseFloat(feedbackTwo.investorEquityStake).toFixed(1) + "%"}</td>

                                                        <td>{results?.feedbackTwo.investorEquityStake}</td>
                                                    </>
                                                }
                                            </tr>

                                            <tr>
                                                <td>Post-money valuation</td>

                                                <td>USD $ {parseFloat(feedbackOne.postMoneyValuation).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                                                <td>-</td>

                                                <td>{results?.feedbackOne.postMoneyValuation.notes}</td>

                                                {feedbackOne.fundingRound == "Series D & beyond" || feedbackTwo.ableToCommit == "No"
                                                    ? ""
                                                    : <>
                                                        <td>{feedbackTwo.postMoneyValuation == "" ? "-" : "USD $ " + parseFloat(feedbackTwo.postMoneyValuation).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>

                                                        <td>{results?.feedbackTwo.postMoneyValuation}</td>
                                                    </>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="buttonsDiv">
                                        <button className="resetButton" onClick={resetForm}><span>Clear</span></button>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
            }
        </>
    )
}

export default AnalyzeVCFundingBenchmarks;