import React from 'react'
import { Card, Form, Button } from 'react-bootstrap';
import { IoMdClose, IoMdAdd } from 'react-icons/io';
import TextField from '@mui/material/TextField';
import '../data-contribution.css';
import { fundingRounds } from '../../dropdown-options/funding-rounds';

const AddFinancials = (props) => {

    const addField = (action) => {
        // action = { type: "example action type", value: <User's input> } // Example action
        props.setState(action);
    }

    const removeField = (action) => {
        props.setState(action)
    }

    const editField = (action) => {
        // action = { type: "example action type", index: 3, value: <User's input> } // Example action
        props.setState(action);
    }

    return (
        <>
            {props.state.profileType === "Finance"
                ? (<>
                    {/* card based on subtopics */}
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv ">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of funds
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many funds have you received?"
                                                        value={props.state.numOfFunds}
                                                        onChange={(e) => { props.setState({ "type": "update numOfFunds", "value": e.target.value }) }}
                                                    />
                                                    <Form.Text className="addnewprompt">Enter no. of funds</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Funds
                                                    </Form.Label>

                                                    {
                                                        props.state.funds.map((x, i) => {
                                                            return (
                                                                <div key={i} style={{ display: "flex" }}>
                                                                    <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                        <Form.Control
                                                                            name="funds"
                                                                            value={props.state.funds[i]}
                                                                            placeholder="What are your funds?"
                                                                            onChange={(e) => editField({ type: "update funds", value: e.target.value, index: i })}
                                                                            style={{ borderColor: "transparent" }}
                                                                        />
                                                                        {
                                                                            props.state.funds.length !== 1 &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { removeField({ type: "remove funds", index: i }); }}>
                                                                                <IoMdClose />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        props.state.funds.length - 1 === i &&
                                                                        <Button className="pitchdeckaddfield"
                                                                            onClick={() => { addField({ type: "add funds", value: "" }) }}>
                                                                            <IoMdAdd />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                    <Form.Text className="addnewprompt">Enter funds</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of limited partners
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many limited partners does your entity have?"
                                                        value={props.state.numOfLimitedPartners}
                                                        onChange={(e) => { props.setState({ "type": "update numOfLimitedPartners", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter no. of limited partners</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Limited partners
                                                    </Form.Label>

                                                    {
                                                        props.state.limitedPartners.map((x, i) => {
                                                            return (
                                                                <div key={i} style={{ display: "flex" }}>
                                                                    <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                        <Form.Control
                                                                            name="limitedPartners"
                                                                            value={props.state.limitedPartners[i]}
                                                                            placeholder="Who are the limited partners in your entity?"
                                                                            onChange={(e) => editField({ type: "update limitedPartners", value: e.target.value, index: i })}
                                                                            style={{ borderColor: "transparent" }}
                                                                        />
                                                                        {
                                                                            props.state.limitedPartners.length !== 1 &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { removeField({ type: "remove limitedPartners", index: i }); }}>
                                                                                <IoMdClose />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        props.state.limitedPartners.length - 1 === i &&
                                                                        <Button className="pitchdeckaddfield"
                                                                            onClick={() => { addField({ type: "add limitedPartners", value: "" }) }}>
                                                                            <IoMdAdd />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                    <Form.Text className="addnewprompt">Enter limited partners</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Last financing date
                                                    </Form.Label>

                                                    <TextField className="addnewdobinputbox" type="date" defaultValue={props.state.lastFinancingDate} onChange={(e) => { props.setState({ "type": "update lastFinancingDate", "value": e.target.value }) }} />

                                                    <Form.Text className="addnewprompt">Enter last financing date</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Last financing round
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What was your last financing round?"
                                                        value={props.state.lastFinancingRound}
                                                        onChange={(e) => { props.setState({ "type": "update lastFinancingRound", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter last financing round</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewlongrow">
                                                {/* form field */}
                                                <div className="addnewlongfield">
                                                    <Form.Label>
                                                        Portfolio companies
                                                    </Form.Label>

                                                    {
                                                        props.state.portfolioCompanies.map((x, i) => {
                                                            return (
                                                                <div key={i} style={{ display: "flex" }}>
                                                                    <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                        <Form.Control
                                                                            name="portfolioCompanies"
                                                                            value={props.state.portfolioCompanies[i]}
                                                                            placeholder="What are your portfolio companies?"
                                                                            onChange={(e) => editField({ type: "update portfolioCompanies", value: e.target.value, index: i })}
                                                                            style={{ borderColor: "transparent" }}
                                                                        />
                                                                        {
                                                                            props.state.portfolioCompanies.length !== 1 &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { removeField({ type: "remove portfolioCompanies", index: i }); }}>
                                                                                <IoMdClose />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        props.state.portfolioCompanies.length - 1 === i &&
                                                                        <Button className="pitchdeckaddfield"
                                                                            onClick={() => { addField({ type: "add portfolioCompanies", value: "" }) }}>
                                                                            <IoMdAdd />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                    <Form.Text className="addnewprompt">Enter portfolio companies</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>

                    {/* card based on subtopics */}
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv ">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Financial viability
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is your financial viability?"
                                                        value={props.state.financialViability}
                                                        onChange={(e) => { props.setState({ "type": "update financialViability", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter financial viability</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Financing type
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is your financing type?"
                                                        value={props.state.financingType}
                                                        onChange={(e) => { props.setState({ "type": "update financingType", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter financing type</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Fundraising target amount
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What was the fundraised target amount?"
                                                        value={props.state.fundraisingTargetAmount}
                                                        onChange={(e) => { props.setState({ "type": "update fundraisingTargetAmount", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter fundraising target amount</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Total amount raised
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What was the total amount raised?"
                                                        value={props.state.totalAmountRaised}
                                                        onChange={(e) => { props.setState({ "type": "update totalAmountRaised", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter total amount raised</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Remaining target amount
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What was the remaining target amount?"
                                                        value={props.state.remainingTargetAmount}
                                                        onChange={(e) => { props.setState({ "type": "update remainingTargetAmount", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter remaining target amount</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </>)
                : (<>
                    {/* card based on subtopics */}
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv ">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Last financing date
                                                    </Form.Label>

                                                    <TextField className="addnewdobinputbox" type="date" defaultValue={props.state.lastFinancingDate} onChange={(e) => { props.setState({ "type": "update lastFinancingDate", "value": e.target.value }) }} />

                                                    <Form.Text className="addnewprompt">Enter last financing date</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Last financing round
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What was your last financing round?"
                                                        value={props.state.lastFinancingRound}
                                                        onChange={(e) => { props.setState({ "type": "update lastFinancingRound", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter last financing round</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of funding rounds
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many rounds of funding have your entity raised?"
                                                        value={props.state.numOfFundingRounds}
                                                        onChange={(e) => { props.setState({ "type": "update numOfFundingRounds", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter no. of funding rounds</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>

                    {/* card based on subtopics */}
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv ">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Fundraising target amount
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What was the fundraised target amount?"
                                                        value={props.state.fundraisingTargetAmount}
                                                        onChange={(e) => { props.setState({ "type": "update fundraisingTargetAmount", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter fundraising target amount</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Total amount raised
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What was the total amount raised?"
                                                        value={props.state.totalAmountRaised}
                                                        onChange={(e) => { props.setState({ "type": "update totalAmountRaised", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter total amount raised</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Remaining target amount
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What was the remaining target amount?"
                                                        value={props.state.remainingTargetAmount}
                                                        onChange={(e) => { props.setState({ "type": "update remainingTargetAmount", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter remaining target amount</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Post-money valuation
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is your post-money valuation?"
                                                        value={props.state.postMoneyValuation}
                                                        onChange={(e) => { props.setState({ "type": "update postMoneyValuation", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter post-money valuation</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Total revenue
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is your total revenue?"
                                                        value={props.state.totalRevenue}
                                                        onChange={(e) => { props.setState({ "type": "update totalRevenue", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter total revenue</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Market capitalization
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is your market capitalization?"
                                                        value={props.state.marketCapitalization}
                                                        onChange={(e) => { props.setState({ "type": "update marketCapitalization", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter market capitalization</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Financing type
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is your financing type?"
                                                        value={props.state.financingType}
                                                        onChange={(e) => { props.setState({ "type": "update financingType", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter financing type</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Financial viability
                                                    </Form.Label>
                                                    <Form.Control

                                                        placeholder="What is your financial viability?"
                                                        value={props.state.financialViability}
                                                        onChange={(e) => { props.setState({ "type": "update financialViability", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter financial viability</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of investors
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many investors have invested in your entity?"
                                                        value={props.state.numOfInvestors}
                                                        onChange={(e) => { props.setState({ "type": "update numOfInvestors", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter no. of investors</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </>)
            }

            {props.state.profileType === "Organization" || props.state.profileType === "Education" || props.state.profileType === "Government" || props.state.profileType === "Project" || props.state.profileType === "Product"
                ? ( // card based on subtopics
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv ">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">

                                            {
                                                props.state.fundingRounds.map((x, i) => {
                                                    return (
                                                        <>
                                                            <div key={i}>
                                                                <div className="addnewshortrow">
                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Funding type
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="fundingType"
                                                                            as="select"
                                                                            value={props.state.fundingRounds[i].fundingType}
                                                                            onChange={(e) => { editField({ type: "update fundingRounds", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        >
                                                                            <option>Funding round</option>
                                                                            {
                                                                                fundingRounds.map((option) =>
                                                                                    <option>{option.key}</option>
                                                                                )
                                                                            }
                                                                        </Form.Control>

                                                                        <Form.Text className="addnewprompt">Select funding type</Form.Text>
                                                                    </div>

                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Announced date
                                                                        </Form.Label>

                                                                        <TextField
                                                                            name="announcedDate"
                                                                            value={props.state.fundingRounds[i].announcedDate}
                                                                            className="dobinputbox"
                                                                            type="date"
                                                                            onChange={(e) => { editField({ type: "update fundingRounds", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter date of announcement</Form.Text>
                                                                    </div>

                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Closed-on date
                                                                        </Form.Label>

                                                                        <TextField
                                                                            name="closedOnDate"
                                                                            value={props.state.fundingRounds[i].closedOnDate}
                                                                            className="dobinputbox"
                                                                            type="date"
                                                                            onChange={(e) => { editField({ type: "update fundingRounds", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter date of closing</Form.Text>
                                                                    </div>
                                                                </div>

                                                                <div className="addnewshortrow">
                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Target funding amount
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="targetFundingAmount"
                                                                            placeholder="What was the target funding amount?"
                                                                            value={props.state.fundingRounds[i].targetFundingAmount}
                                                                            onChange={(e) => { editField({ type: "update fundingRounds", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter target funding amount (in USD)</Form.Text>
                                                                    </div>

                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Total funding amount
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="totalFundingAmount"
                                                                            placeholder="What was the total funding amount?"
                                                                            value={props.state.fundingRounds[i].totalFundingAmount}
                                                                            onChange={(e) => { editField({ type: "update fundingRounds", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter total funding amount (in USD)</Form.Text>
                                                                    </div>

                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Remaining target amount
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="remainingTargetAmount"
                                                                            placeholder="What is the remaining target amount?"
                                                                            value={props.state.fundingRounds[i].remainingTargetAmount}
                                                                            onChange={(e) => { editField({ type: "update fundingRounds", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter remaining target amount</Form.Text>
                                                                    </div>
                                                                </div>

                                                                <div className="addnewlongrow">
                                                                    <div className="addnewlongfield">
                                                                        <Form.Label>
                                                                            Investors
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="investors"
                                                                            placeholder="Who were the investors involved?"
                                                                            value={props.state.fundingRounds[i].investors}
                                                                            onChange={(e) => { editField({ type: "update fundingRounds", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter investors involved</Form.Text>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    props.state.fundingRounds.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove fundingRounds", index: i }) }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }

                                                                {
                                                                    props.state.fundingRounds.length - 1 === i &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { addField({ type: "add fundingRounds", value: { fundingType: "", announcedDate: "", closedOnDate: "", targetFundingAmount: "", totalFundingAmount: "", remainingTargetAmount: "", investors: "" } }) }}>
                                                                        <IoMdAdd />
                                                                    </Button>
                                                                }

                                                                <hr />
                                                            </div>
                                                        </>
                                                    );
                                                })
                                            }

                                            <div className="addnewlongrow">
                                                {/* form field */}
                                                <div className="addnewlongfield">
                                                    <Form.Label>
                                                        Portfolio companies
                                                    </Form.Label>

                                                    {
                                                        props.state.portfolioCompanies.map((x, i) => {
                                                            return (
                                                                <div key={i} style={{ display: "flex" }}>
                                                                    <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                        <Form.Control
                                                                            name="portfolioCompanies"
                                                                            value={props.state.portfolioCompanies[i]}
                                                                            placeholder="What are your portfolio companies?"
                                                                            onChange={(e) => editField({ type: "update portfolioCompanies", value: e.target.value, index: i })}
                                                                            style={{ borderColor: "transparent" }}
                                                                        />
                                                                        {
                                                                            props.state.portfolioCompanies.length !== 1 &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { removeField({ type: "remove portfolioCompanies", index: i }); }}>
                                                                                <IoMdClose />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        props.state.portfolioCompanies.length - 1 === i &&
                                                                        <Button className="pitchdeckaddfield"
                                                                            onClick={() => { addField({ type: "add portfolioCompanies", value: "" }) }}>
                                                                            <IoMdAdd />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                    <Form.Text className="addnewprompt">Enter portfolio companies</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>)
                : ""
            }

            {props.state.profileType === "Organization" || props.state.profileType === "Finance" || props.state.profileType === "Education" || props.state.profileType === "Government"
                ? ( // card based on subtopics
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv ">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of investments
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many investments have you made?"
                                                        value={props.state.numOfInvestments}
                                                        onChange={(e) => { props.setState({ "type": "update numOfInvestments", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter no. of investments</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of lead investments
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many investments have you led?"
                                                        value={props.state.numOfLeadInvestments}
                                                        onChange={(e) => { props.setState({ "type": "update numOfLeadInvestments", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter no. of lead investments</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of co-investments
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many investments have you co-led?"
                                                        value={props.state.numOfCoInvestments}
                                                        onChange={(e) => { props.setState({ "type": "update numOfCoInvestments", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter no. of co-investments</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of follow-on investments
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many investments have you followed on?"
                                                        value={props.state.numOfFollowOnInvestments}
                                                        onChange={(e) => { props.setState({ "type": "update numOfFollowOnInvestments", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter no. of follow-on investments</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of failed investments
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many investments have you failed?"
                                                        value={props.state.numOfFailedInvestments}
                                                        onChange={(e) => { props.setState({ "type": "update numOfFailedInvestments", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter no. of failed investments</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of exits
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many exits have you made?"
                                                        value={props.state.numOfExits}
                                                        onChange={(e) => { props.setState({ "type": "update numOfExits", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter no. of exits</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of acquisitions
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many acquisitions have you made?"
                                                        value={props.state.numOfAcquisitions}
                                                        onChange={(e) => { props.setState({ "type": "update numOfAcquisitions", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter no. of acquisitions</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        No. of board seats taken
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How many board seats have you taken?"
                                                        value={props.state.numOfBoardSeatsTaken}
                                                        onChange={(e) => { props.setState({ "type": "update numOfBoardSeatsTaken", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter no. of board seats taken</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>)
                : ""
            }

        </>
    );
}

export default AddFinancials