export const transactionalModelTypes = [
    { key: "B2C" },
    { key: "B2B" },
    { key: "B2D" },
    { key: "C2C" },
    { key: "C2B" },
    { key: "D2D" },
    { key: "D2C" },
    { key: "B2B2C" },
    { key: "B2G" },
    { key: "G2G" },
    { key: "G2C" },
    { key: "M2C" },
    { key: "P2P" }
];