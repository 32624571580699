import React, { useRef } from 'react';
import Slider from '@mui/material/Slider';
import '../idea-validation.css';
import { Accordion, Card, Form } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { Multiselect } from 'multiselect-react-dropdown';
import { industriesNew } from '../../../../dropdown-options/industries';
import { countries } from '../../../../dropdown-options/countries';
import { categories } from '../../../../dropdown-options/categories';
import { transactionalModelTypes } from '../../../../dropdown-options/transactional-model-types';
import { competitiveMoats } from '../../../../dropdown-options/competitive-moats';
import { stagesOfDevelopment } from '../../../../dropdown-options/stages-of-development';
import { entityTypes } from '../../../../dropdown-options/entity-types';

const CreateEditIdeaUniqueness = (props) => {

    const multiselectIndustryRef = useRef(null);
    const multiselectCategoriesRef = useRef(null);
    const multiselectLocationRef = useRef(null);
    const multiselectMarketsRef = useRef(null);
    const multiselectEntityTypeRef = useRef(null);
    const multiselectStageOfDevelopmentRef = useRef(null);
    const multiselectBusinessModelTypeRef = useRef(null);
    const multiselectDefensibilityMoatRef = useRef(null);

    const marketReachMarks = [
        {
            value: 0,
            label: '0%'
        },
        {
            value: 1,
            label: ""
        },
        {
            value: 2,
            label: ""
        },
        {
            value: 10,
            label: ""
        },
        {
            value: 20,
            label: ""
        },
        {
            value: 30,
            label: ""
        },
        {
            value: 40,
            label: ""
        },
        {
            value: 50,
            label: ""
        },
        {
            value: 60,
            label: ""
        },
        {
            value: 70,
            label: ""
        },
        {
            value: 80,
            label: ""
        },
        {
            value: 90,
            label: ""
        },
        {
            value: 98,
            label: ""
        },
        {
            value: 99,
            label: ""
        },
        {
            value: 100,
            label: '100%'
        }
    ];

    function marketReachValueText(value) {
        return `${value}%`;
    }

    function marketReachValueLabelFormat(value) {
        return `${value}0,000 customers`;
    }

    const marketReachMarks2 = [
        {
            value: 0,
            label: '0%'
        },
        {
            value: 1,
            label: ""
        },
        {
            value: 2,
            label: ""
        },
        {
            value: 10,
            label: ""
        },
        {
            value: 20,
            label: ""
        },
        {
            value: 30,
            label: ""
        },
        {
            value: 40,
            label: ""
        },
        {
            value: 50,
            label: ""
        },
        {
            value: 60,
            label: ""
        },
        {
            value: 70,
            label: ""
        },
        {
            value: 80,
            label: ""
        },
        {
            value: 90,
            label: ""
        },
        {
            value: 98,
            label: ""
        },
        {
            value: 99,
            label: ""
        },
        {
            value: 100,
            label: '100%'
        }
    ];

    function marketReachValueText2(value) {
        return `${value}%`;
    }

    function marketReachValueLabelFormat2(value) {
        return `${value} customers`;
    }

    const impactValueMarks = [
        {
            value: 0,
            label: ''
        },
        {
            value: 25,
            label: 'Low'
        },
        {
            value: 50,
            label: 'Moderate'
        },
        {
            value: 75,
            label: 'High'
        },
        {
            value: 100,
            label: 'Very high'
        }
    ];

    function impactValueValueText(label) {
        return `${label}`;
    }

    function impactValueValueLabelFormat(label) {
        return `${label}`;
    }

    const confidenceLevelMarks = [
        {
            value: 0,
            label: ''
        },
        {
            value: 25,
            label: 'Low'
        },
        {
            value: 50,
            label: 'Moderate'
        },
        {
            value: 75,
            label: 'High'
        },
        {
            value: 100,
            label: 'Very high'
        }
    ];

    function confidenceLevelValueText(label) {
        return `${label}`;
    }

    function confidenceLevelValueLabelFormat(label) {
        return `${label}`;
    }

    const easeImplementationMarks = [
        {
            value: 0,
            label: ''
        },
        {
            value: 25,
            label: 'Very difficult'
        },
        {
            value: 50,
            label: 'Difficult'
        },
        {
            value: 75,
            label: 'Moderate'
        },
        {
            value: 100,
            label: 'Easy'
        }
    ];

    function easeImplementationValueText(label) {
        return `${label}`;
    }

    function easeImplementationValueLabelFormat(label) {
        return `${label}`;
    }

    return (

        <Card>
            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="unique">
                <div className="companyarrowicon">
                    <FaChevronDown className="arrow-icon" />
                </div>

                <div className="companytitlecard">
                    How unique is your idea?
                </div>

                <div className="companyellipsisicon">
                    <FaEllipsisH />
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="unique">
                <Card.Body className="ideacheckeraccordioncardbody">
                    <div className="col-12">
                        <IoMdInformationCircleOutline />
                        <span className="info-text">
                            Please fill in all required fields marked with asterisks (<sup>*</sup>).
                        </span>
                    </div>

                    <div className="col-12 div-filter">
                        <Form>
                            <Form.Group>
                                <div className="ideacheckerrow">
                                    <div>
                                        <Form.Label>
                                            <sup>*</sup>
                                            Conceptual idea (one-sentence pitch)
                                            <sup data-tip="In one single sentence, clearly explain your core business idea by focusing on your “why?”">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            value={props.state.idea}
                                            placeholder="For example, ecommerce platform powered by artificial intelligence selling premium organic food with speedy delivery services"
                                            onChange={(e) => { props.setState({ "type": "update idea", "value": e.target.value }) }}
                                        />
                                        <Form.Text className="text-muted">Describe your idea in 10 to 50 words for better accuracy and prediction</Form.Text>
                                    </div>
                                </div>

                                <div className="ideacheckerrow">
                                    <div>
                                        <Form.Label>
                                            <sup>*</sup>
                                            Industry
                                            <sup data-tip="Select the industrial grouping that best describe the production process, functions, and operations of your core business">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectIndustryRef}
                                            options={industriesNew}
                                            selectedValues={(props.state.industry) ? [{ key: props.state.industry }] : []}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="1"
                                            showCheckbox="true"
                                            placeholder="Which industry best describes your area of focus?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectIndustryRef.current.getSelectedItems();
                                                let list = selectedItems[0].key;
                                                if (!list) list = "";
                                                props.setState({ type: "update industry", value: list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select industry</Form.Text>
                                    </div>
                                </div>

                                <div className="ideacheckerrow">
                                    <div>
                                        <Form.Label>
                                            <sup>*</sup>
                                            Categories
                                            <sup data-tip="Select the categories that best define the specific niche of your business focus in a vertical market.">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectCategoriesRef}
                                            options={categories}
                                            selectedValues={props.state.categories.map(x => ({ key: x }))}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="5"
                                            closeOnSelect={false}
                                            showCheckbox="true"
                                            placeholder="What categories best define your idea?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectCategoriesRef.current.getSelectedItems();
                                                let list = [];
                                                selectedItems.forEach(item => {
                                                    for (const key in item) {
                                                        if (key === "key") {
                                                            list.push(item[key])
                                                        }
                                                    }
                                                });
                                                props.setState({ "type": "update categories", "value": list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select categories (maximum 5)</Form.Text>
                                    </div>
                                </div>

                                <div className="ideacheckerrow2">
                                    <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                        <Form.Label>
                                            <sup>*</sup>
                                            Location
                                            <sup data-tip="Select the location where your company is headquartered at">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectLocationRef}
                                            options={countries}
                                            selectedValues={(props.state.location) ? [{ key: props.state.location }] : []}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="1"
                                            showCheckbox="true"
                                            placeholder="Which country is your headquarter located at?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectLocationRef.current.getSelectedItems();
                                                let list = selectedItems[0].key;
                                                if (!list) list = "";
                                                props.setState({ type: "update location", value: list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select location</Form.Text>
                                    </div>

                                    <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                        <Form.Label>
                                            <sup>*</sup>
                                            Market(s)
                                            <sup data-tip="Select your market location(s) where you offer and sell your product-or-solution">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectMarketsRef}
                                            options={countries}
                                            selectedValues={props.state.markets.map(x => ({ key: x }))}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="5"
                                            closeOnSelect={false}
                                            showCheckbox="true"
                                            placeholder="Which market(s) are you targeting?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectMarketsRef.current.getSelectedItems();
                                                let list = [];
                                                selectedItems.forEach(item => {
                                                    for (const key in item) {
                                                        if (key === "key") {
                                                            list.push(item[key])
                                                        }
                                                    }
                                                });
                                                props.setState({ "type": "update markets", "value": list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select market(s) (maximum 5)</Form.Text>
                                    </div>

                                    <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                        <Form.Label>
                                            Entity type(s)
                                            <sup data-tip="Select the business entity type that dictates both the legal structure of your organization and how your company will be taxed">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectEntityTypeRef}
                                            options={entityTypes}
                                            selectedValues={props.state.entityType.map(x => ({ key: x }))}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="5"
                                            closeOnSelect={false}
                                            showCheckbox="true"
                                            placeholder="Which entity best describes your operation?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectEntityTypeRef.current.getSelectedItems();
                                                let list = [];
                                                selectedItems.forEach(item => {
                                                    for (const key in item) {
                                                        if (key === "key") {
                                                            list.push(item[key])
                                                        }
                                                    }
                                                });
                                                props.setState({ "type": "update entityType", "value": list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select entity type(s) (maximum 5)</Form.Text>
                                    </div>
                                </div>

                                <div className="ideacheckerrow2">
                                    <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                        <Form.Label>
                                            Stage of development
                                            <sup data-tip="Describe your current stage of business development">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectStageOfDevelopmentRef}
                                            options={stagesOfDevelopment}
                                            selectedValues={(props.state.stageOfDevelopment) ? [{ key: props.state.stageOfDevelopment }] : []}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="1"
                                            showCheckbox="true"
                                            placeholder="What is your current stage of development?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectStageOfDevelopmentRef.current.getSelectedItems();
                                                let list = selectedItems[0].key;
                                                if (!list) list = "";
                                                props.setState({ type: "update stageOfDevelopment", value: list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select development stage</Form.Text>
                                    </div>

                                    <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                        <Form.Label>
                                            Transactional model type(s)
                                            <sup data-tip="Select the transactional model type that best describe the relationship of transactions between your organization and your counterparts">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectBusinessModelTypeRef}
                                            options={transactionalModelTypes}
                                            selectedValues={props.state.businessModelType.map(x => ({ key: x }))}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="5"
                                            closeOnSelect={false}
                                            showCheckbox="true"
                                            placeholder="Which model best describes your commercial activity?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectBusinessModelTypeRef.current.getSelectedItems();
                                                let list = [];
                                                selectedItems.forEach(item => {
                                                    for (const key in item) {
                                                        if (key === "key") {
                                                            list.push(item[key])
                                                        }
                                                    }
                                                });
                                                props.setState({ "type": "update businessModelType", "value": list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select transactional model type(s) (maximum 5)</Form.Text>
                                    </div>

                                    <div className="idea-checker-field">
                                        <Form.Label>
                                            Competitive moat(s)
                                            <sup data-tip="Select the defensibility moat that best define your competitive advantage to protect long-term profits and market share">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectDefensibilityMoatRef}
                                            options={competitiveMoats}
                                            selectedValues={props.state.defensibilityMoat.map(x => ({ key: x }))}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="5"
                                            closeOnSelect={false}
                                            showCheckbox="true"
                                            placeholder="Which moat best defines your defensibility?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectDefensibilityMoatRef.current.getSelectedItems();
                                                let list = [];
                                                selectedItems.forEach(item => {
                                                    for (const key in item) {
                                                        if (key === "key") {
                                                            list.push(item[key])
                                                        }
                                                    }
                                                });
                                                props.setState({ "type": "update defensibilityMoat", "value": list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select competitive moat(s) (maximum 5)</Form.Text>
                                    </div>
                                </div>

                                <div className="ideacheckerrow2">
                                    <div className="idea-checker-field2">
                                        <Form.Label>
                                            Market reach
                                            <sup data-tip="Estimate the amount of customers you can reach in a year">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <p className="ideacheckerplaceholder">How many customers can you reach in 1 year?</p>
                                        <div style={{ textAlign: "center" }}>
                                            {
                                                props.state.businessModelType.includes("B2C") || props.state.businessModelType.includes("C2C") || props.state.businessModelType.includes("G2C") || props.state.businessModelType.includes("M2C")
                                                    ? (<Slider
                                                        key={`slider-${props.state.reach}`}
                                                        defaultValue={props.state.reach}
                                                        valueLabelFormat={marketReachValueLabelFormat}
                                                        getAriaValueText={marketReachValueText}
                                                        step={null}
                                                        valueLabelDisplay="on"
                                                        marks={marketReachMarks}
                                                        className="ideacheckerslider marketreachslider"
                                                        onChange={(e) => { props.setState({ "type": "update reach", "value": e.target.value }) }}
                                                    />)
                                                    : (<Slider
                                                        key={`slider-${props.state.reach}`}
                                                        defaultValue={props.state.reach}
                                                        valueLabelFormat={marketReachValueLabelFormat2}
                                                        getAriaValueText={marketReachValueText2}
                                                        step={null}
                                                        valueLabelDisplay="on"
                                                        marks={marketReachMarks2}
                                                        className="marketreachslider ideacheckerslider"
                                                        onChange={(e) => { props.setState({ "type": "update reach", "value": e.target.value }) }}
                                                    />)
                                            }
                                        </div>
                                        <Form.Text className="text-muted">Estimate market reach</Form.Text>
                                    </div>

                                    <div className="idea-checker-field2">
                                        <Form.Label>
                                            Impact value
                                            <sup data-tip="Estimate the impact value of your business offering to your customers">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <p className="ideacheckerplaceholder">What is the impact value of your idea?</p>
                                        <div style={{ textAlign: "center" }}>
                                            <Slider
                                                key={`slider-${props.state.impact}`}
                                                defaultValue={props.state.impact}
                                                valueLabelFormat={impactValueValueLabelFormat}
                                                getAriaValueText={impactValueValueText}
                                                step={null}
                                                valueLabelDisplay="on"
                                                marks={impactValueMarks}
                                                className="ideacheckerslider"
                                                onChange={(e) => { props.setState({ "type": "update impact", "value": e.target.value }) }}
                                            />
                                        </div>
                                        <Form.Text className="text-muted">Estimate impact value</Form.Text>
                                    </div>
                                </div>

                                <div className="ideacheckerrow2"><div className="idea-checker-field2">
                                    <Form.Label>
                                        Confidence level
                                        <sup data-tip="Determine the level of your confidence to execute your idea">
                                            <IoMdInformationCircleOutline id="information-icon" />
                                        </sup>
                                    </Form.Label>
                                    <p className="ideacheckerplaceholder">What is your confidence level in executing your idea?</p>
                                    <div style={{ textAlign: "center" }}>
                                        <Slider
                                            key={`slider-${props.state.confidence}`}
                                            defaultValue={props.state.confidence}
                                            valueLabelFormat={confidenceLevelValueLabelFormat}
                                            getAriaValueText={confidenceLevelValueText}
                                            step={null}
                                            valueLabelDisplay="on"
                                            marks={confidenceLevelMarks}
                                            className="ideacheckerslider"
                                            onChange={(e) => { props.setState({ "type": "update confidence", "value": e.target.value }) }}
                                        />
                                    </div>
                                    <Form.Text className="text-muted">Determine confidence level</Form.Text>
                                </div>

                                    <div className="idea-checker-field2">
                                        <Form.Label>
                                            Ease of implementation
                                            <sup data-tip="Evaluate how easy it is for your team to implement your business idea">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <p className="ideacheckerplaceholder">How easy is it to implement the idea?</p>
                                        <div style={{ textAlign: "center" }}>
                                            <Slider
                                                key={`slider-${props.state.ease}`}
                                                defaultValue={props.state.ease}
                                                valueLabelFormat={easeImplementationValueLabelFormat}
                                                getAriaValueText={easeImplementationValueText}
                                                step={null}
                                                valueLabelDisplay="on"
                                                marks={easeImplementationMarks}
                                                className="ideacheckerslider"
                                                onChange={(e) => { props.setState({ "type": "update ease", "value": e.target.value }) }}
                                            />
                                        </div>
                                        <Form.Text className="text-muted">Ease of implementation</Form.Text>
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>

    )

}

export default CreateEditIdeaUniqueness;