const FinancialTableHeader = () => {
  const date = new Date();
  let year = date.getFullYear();

  return (
    <thead>
      <tr className="financialstatementstablerowbreak">
        <th
          colSpan={2}
          className="financialstatementstabledatacenter"
          style={{ borderRight: "none" }}
        ></th>
        <th colSpan={3} className="financialstatementstabledatalast">
          Year ended December 31,
        </th>
      </tr>
      <tr className="financialstatementstablerowbreak">
        <th
          colSpan={2}
          className="financialstatementstabledatacenter"
          style={{ borderRight: "none" }}
        ></th>
        <th className="financialstatementstabledatacenter">{year - 2}</th>
        <th className="financialstatementstabledatacenter">{year - 1}</th>
        <th className="financialstatementstabledatalast">{year}</th>
      </tr>
      <tr className="financialstatementstablerowbreak">
        <th
          colSpan={2}
          className="financialstatementstabledatacenter"
          style={{ borderRight: "none" }}
        ></th>
        <th colSpan={3} className="financialstatementstabledatalast">
          (in thousands)
        </th>
      </tr>
    </thead>
  );
};

export default FinancialTableHeader;