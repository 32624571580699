import React from 'react'

const FinancialTableRowHeader = ({
  displayName,
  className = 'financialstatementstabledataleft companytitlecard'
}) => {
  return (
    <tr className='financialstatementstablerowview'>
      <th colSpan={2} className={className}>
        {displayName}
      </th>
      <td className='financialstatementstabledatacenter'></td>
      <td className='financialstatementstabledatacenter'></td>
      <td className='financialstatementstabledatalast'></td>
    </tr>
  )
}

export default FinancialTableRowHeader