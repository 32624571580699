import React from 'react'
import AccordianCard from '../../../../../../accordion-card/accordian-card'
import '../../../pitch-deck-analysis.css'
import PitchDeckDetails from '../../view-details/details-component'

function ViewSolution({ data }) {
  return (
    <>
      <AccordianCard
        className='companyaccordion'
        title='Solution'
        activeKey='solution'
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName:
                'What product-or-service are you offering (customer gain)?',
              fieldName: 'customerGain',
              type: 'bullets'
            }
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: '10px' }}
        title='Problem'
        activeKey='problem'
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName:
                'How does your product-or-service uniquely meet the needs of your target market?',
              fieldName: 'howProductMeetNeeds',
              type: 'bullets'
            },
            {
              displayName: `Will the product-or-service solve a fundamental need in the
                    market (whether or not that need is known currently to
                    customers) such that customers will pay real money to
                    purchase it?`,
              fieldName: 'willProductSolveNeeds',
              type: 'bullets'
            },
            {
              displayName: `How did you get to the current solution idea, incorporating
                    which insights and market data to help inform your opinion?`,
              fieldName: 'howDidYouGetIdea',
              type: 'bullets'
            },
            {
              displayName: `Is your product-or-service more of an aspirin?`,
              fieldName: 'isProductAspirin',
              type: 'bullets'
            },
            {
              displayName: `Is your product-or-service more of a vitamin?`,
              fieldName: 'isProductVitamin',
              type: 'bullets'
            },
            {
              displayName: `What factors are important to your customer segments in
                    choosing this type of product-or-service?`,
              fieldName: 'factorsImportantInChoosing',
              type: 'bullets'
            },
            {
              displayName: `How are you responding to a customer need in a new and
                    useful way?`,
              fieldName: 'howYouRespondToNeed',
              type: 'bullets'
            },
            {
              displayName: `Do users and customers love your product-or-service?`,
              fieldName: 'doCustomersLoveProduct',
              type: 'bullets'
            }
          ]}
        />
      </AccordianCard>
    </>
  )
}

export default ViewSolution