import React from 'react'
import AccordianCard from '../../../../../../accordion-card/accordian-card'
import '../../../pitch-deck-analysis.css'
import FinancialsTableView from '../../financials-table-view/financials-table'
import PitchDeckDetails from '../../view-details/details-component'

function ViewFinancials({ data }) {
  return (
    <>
      <AccordianCard
        className='companyaccordion'
        title='Monetization model'
        activeKey='monetizationModel'
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `How do you monetize or charge for your product-or-service offering?`,
              fieldName: 'monetizationModel',
              type: 'bullets'
            },
            {
              displayName: `Are you building an annuity business, such as a movie streaming subscription service that continues for years?`,
              fieldName: 'areYouBuildingAnAnnuityBusiness',
              type: 'bullets'
            },
            {
              displayName: `Are you selling a durable product that is purchased only occasionally during a lifetime, such as a washing machine or a car?`,
              fieldName: 'areYouSellingDurableProduct',
              type: 'bullets'
            },
            {
              displayName: `What are the margins?`,
              fieldName: 'financialMargins',
              type: 'bullets'
            },
            {
              displayName: `What are the financial metrics are you observing?`,
              fieldName: 'observedUnitEconomics',
              type: 'bullets'
            },
            {
              displayName: `Do the financial metrics make sense for your industry and/or vertical market?`,
              fieldName: 'doUnitEconomicsMakeSense',
              type: 'bullets'
            }
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: '10px' }}
        title='Pricing model'
        activeKey='pricingModel'
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `What is your pricing model?`,
              fieldName: 'pricingModel',
              type: 'bullets'
            },
            {
              displayName: `Will your pricing model be fixed or tiered or variable
                    depending on customer demand?`,
              fieldName: 'pricingModelCustomerDemand',
              type: 'bullets'
            },
            {
              displayName: `At what price point are you offering your
                    product-or-service?`,
              fieldName: 'pricePointProductOffer',
              type: 'bullets'
            },
            {
              displayName: `Will there be an established price?`,
              fieldName: 'isPriceEstablished',
              type: 'bullets'
            },
            {
              displayName: `What is your relevant pricing strategy?`,
              fieldName: 'relevantPricingStrategy',
              type: 'bullets'
            },
            {
              displayName: `What is the appropriate pricing strategy?`,
              fieldName: 'appropriatePricingStrategy',
              type: 'bullets'
            }
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: '10px' }}
        title='Revenue'
        activeKey='revenue'
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `What is your revenue?`,
              fieldName: 'financialRevenue',
              type: 'bullets'
            },
            {
              displayName: `How much money have you made or going to make from your
                    target market?`,
              fieldName: 'moneyMadeFromTargetMarket',
              type: 'bullets'
            },
            {
              displayName: `At what point do you expect the business to make money?`,
              fieldName: 'whenIsBusinessExpectedToMakeMoney',
              type: 'bullets'
            },
            {
              displayName: `Will it take up to six months or more than two years?`,
              fieldName: 'durationToMakeMoney',
              type: 'bullets'
            },
            {
              displayName: `At what level of sales will you reach the break-even point?`,
              fieldName: 'whenIsBusinessExpectedToReachBreakEven',
              type: 'bullets'
            },
            {
              displayName: `When do you anticipate to break-even?`,
              fieldName: 'anticipateToBreakEven',
              type: 'bullets'
            },
            {
              displayName: `What is your sales target based on projected year-on-year
                    growth?`,
              fieldName: 'salesTargetBasedOnProjectedGrowth',
              type: 'bullets'
            },
            {
              displayName: `After that, how long will it take to reach the next sales
                    milestones?`,
              fieldName: 'howLongToReachNextSalesMilestone',
              type: 'bullets'
            }
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: '10px' }}
        title='Cost structure'
        activeKey='costStructure'
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `What is the cost of acquiring a customer?`,
              fieldName: 'costAcquiringCustomer',
              type: 'bullets'
            },
            {
              displayName: `What do you think that customer will be worth over time with
                    the small sample size of data that you may have?`,
              fieldName: 'howMuchWillCustomerBeWorth',
              type: 'bullets'
            },
            {
              displayName: `What are your general costs assumptions based on the
                    internal components of your business, such as variable and
                    fixed costs, cost of capital, seasonal cash flow
                    fluctuations, and so on?`,
              fieldName: 'internalComponentsAssumptions',
              type: 'bullets'
            }
          ]}
        />
      </AccordianCard>

      <AccordianCard
        style={{ marginTop: '10px' }}
        title='Income statement'
        activeKey='incomeStatement'
      >
        <FinancialsTableView
          data={data}
          rowArray={[
            {
              fieldName: 'revenue',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              fieldName: 'costOfRevenue',
              displayName: 'cost of revenue',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'grossProfit',
              displayName: 'Gross profit',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              type: 'bodyHeader',
              displayName: 'Operating expenses:'
            },
            {
              fieldName: 'researchAndDevelopment',
              displayName: 'Research and development',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'salesAndMarketing',
              displayName: 'Sales and marketing',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'generalAndAdministrative',
              displayName: 'General and administrative',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'totalOperatingExpenses',
              displayName: 'Total operating expenses',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              fieldName: 'operatingIncome',
              displayName: 'Operating income / (loss)',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              fieldName: 'interestExpense',
              displayName: 'Interest expense',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'otherIncomeNet',
              displayName: 'Other income / (loss), net',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'incomeBeforeIncomeTaxes',
              displayName: 'Income / (loss) before income taxes',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              fieldName: 'provisionForIncomeTaxes',
              displayName: 'Provision / (benefit) for income taxes',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'netIncomeNetLoss',
              displayName: 'Net income / (net loss)',
              className: 'financialstatementstabledataleft companytitlecard'
            }
          ]}
        />
      </AccordianCard>

      <AccordianCard
        title='Balance sheet'
        activeKey='balanceSheet'
        style={{ marginTop: '10px' }}
      >
        <FinancialsTableView
          data={data}
          rowArray={[
            {
              type: 'bodyHeaderCenter',
              displayName: 'ASSETS'
            },
            {
              type: 'bodyHeader',
              displayName: 'Current assets:'
            },
            {
              fieldName: 'cashAndCashEquivalents',
              displayName: 'Cash and cash equivalents',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'accountsReceivableNet',
              displayName: 'Accounts receivable, net',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'inventory',
              displayName: 'Inventory',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'otherCurrentAssets',
              displayName: 'Other current assets',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'totalCurrentAssets',
              displayName: 'Total current assets',
              className: 'financialstatementstabledataindent companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              type: 'bodyHeader',
              displayName: 'Non-current assets:'
            },
            {
              fieldName: 'propertyPlantAndEquipmentNet',
              displayName: 'Property, plant, and equipment, net',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'intangibleAssetsNet',
              displayName: 'Intangible assets, net',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'otherNonCurrentAssets',
              displayName: 'Other non-current assets',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'totalNonCurrentAssets',
              displayName: 'Total non-current assets',
              className: 'financialstatementstabledataindent companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              fieldName: 'totalAssets',
              displayName: 'Total assets',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              type: 'bodyHeaderCenter',
              displayName: `LIABILITIES & SHAREHOLDERS' EQUITY`
            },
            {
              type: 'bodyHeader',
              displayName: 'Current liabilities:'
            },
            {
              fieldName: 'accountsPayable',
              displayName: 'Accounts payable',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'accruedAndOther',
              displayName: 'Accrued and other',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'shortTermDebt',
              displayName: 'Short-term debt',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'totalCurrentLiabilities',
              displayName: 'Total current liabilities',
              className: 'financialstatementstabledataindent companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              type: 'bodyHeader',
              displayName: 'Non-current liabilities:'
            },
            {
              fieldName: 'longTermDebt',
              displayName: 'Long-term debt',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'otherLiabilities',
              displayName: 'Other liabilities',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'totalNonCurrentLiabilities',
              displayName: 'Total non-current liabilities',
              className: 'financialstatementstabledataindent companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              fieldName: 'totalLiabilities',
              displayName: 'Total liabilities',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              type: 'bodyHeaderCenter',
              displayName: `SHAREHOLDERS' EQUITY`
            },
            {
              fieldName: 'equityCapital',
              displayName: 'Equity capital',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'retainedEarnings',
              displayName: 'Retained earnings / (accumulated deficit)',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'additionalPaidInCapital',
              displayName: 'Additional paid-in capital',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              type: 'rowBreak'
            },
            {
              fieldName: 'totalShareholdersEquity',
              displayName: `Total shareholders' equity`,
              className: 'financialstatementstabledataindent companytitlecard'
            },
            {
              fieldName: 'totalLiabilitiesAndShareholdersEquity',
              displayName: `Total liabilities and shareholders' equity`,
              className: 'financialstatementstabledataleft companytitlecard'
            }
          ]}
        />
      </AccordianCard>

      <AccordianCard
        title='Cash flow statement'
        activeKey='cashFlowStatement'
        style={{ marginTop: '10px' }}
      >
        <FinancialsTableView
          data={data}
          rowArray={[
            {
              type: 'bodyHeaderCenter',
              displayName: 'CASH FLOW FROM OPERATING ACTIVITIES'
            },
            {
              fieldName: 'netIncome',
              displayName: 'Net income / (loss)',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              type: 'bodyHeader',
              displayName: `Adjustments to reconcile net income /
                                      (loss) to net cash provided by / (used in)
                                      operating activities:`,
              className: 'financialstatementstabledataleft fontitalic fontthin'
            },
            {
              fieldName: 'depreciationAndAmortization',
              displayName: 'Depreciation and amortization',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'changesInAssetsAndLiabilities',
              displayName: 'Changes in assets and liabilities',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'netCashProvidedByOperatingActivities',
              displayName: `Net cash provided by / (used in) operating
                                    activities`,
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              type: 'bodyHeaderCenter',
              displayName: 'CASH FLOW FROM INVESTING ACTIVITIES'
            },
            {
              fieldName: 'capitalExpenditure',
              displayName:
                'Capital expenditure for property, plant, and equipment',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'capitalizedSoftwareDevelopmentCosts',
              displayName: 'Capitalized software development costs',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'maturitiesAndSalesOfInvestments',
              displayName: 'Maturities and sales of investments',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'cashPaidForAcquisitionOfBusiness',
              displayName:
                'Cash paid for acquisition of businesses / (net of cash acquired)',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'netCashProvidedByInvestingActivities',
              displayName:
                'Net cash provided by / (used in) investing activities',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              type: 'bodyHeaderCenter',
              displayName: `CASH FLOW FROM FINANCING ACTIVITIES`
            },
            {
              fieldName: 'proceedsFromIssuanceOfDebt',
              displayName: 'Proceeds from issuance / (repayment) of debt',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'proceedsFromIssuanceOfEquity',
              displayName: 'Proceeds from issuance / (repayment) of equity',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'debtRelatedCostsAndOtherNet',
              displayName: 'Debt-related costs and other, net',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'netCashProvidedByFinancingActivities',
              displayName:
                'Net cash provided by (used in) financing activities',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              type: 'rowBreak'
            },
            {
              fieldName: 'changesInCashDuringPeriod',
              displayName: 'Changes in cash during the period',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'cashAtBeginningOfPeriod',
              displayName: 'Cash at the beginning of the period',
              className: 'financialstatementstabledataleft fontthin'
            },
            {
              fieldName: 'cashAtEndOfPeriod',
              displayName: 'Cash at the end of the period',
              className: 'financialstatementstabledataleft companytitlecard'
            }
          ]}
        />
      </AccordianCard>

      <AccordianCard
        title='Break-even analysis'
        activeKey='breakEvenAnalysis'
        style={{ marginTop: '10px' }}
      >
        <FinancialsTableView
          data={data}
          rowArray={[
            {
              fieldName: 'timeFrame',
              displayName: 'Time frame (monthly / yearly)',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              fieldName: 'averagePriceSold',
              displayName: `Average price of each product or service
                                    sold (in USD)`,
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'averageCostToMakeOrDeliver',
              displayName: `Average cost of each product or service to
                                    make or deliver (in USD)`,
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'fixedCostsforMonthOrYear',
              displayName: `Fixed costs for the month or year (in USD)`,
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'percentageOfPriceProfit',
              displayName: 'Percentage of price that is profit (in %)',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              fieldName: 'totalSalesNeededToBreakEven',
              displayName: 'Total sales needed to break even (in USD)',
              className: 'financialstatementstabledataleft companytitlecard'
            },
            {
              fieldName: 'numberOfUnitsSoldNeededToBreakEven',
              displayName: 'Number of units sold needed to break even (in USD)',
              className: 'financialstatementstabledataleft companytitlecard'
            }
          ]}
        />
      </AccordianCard>

      <AccordianCard
        title='Financial ratios analysis'
        activeKey='financialRatiosAnalysis'
        style={{ marginTop: '10px' }}
      >
        <FinancialsTableView
          data={data}
          rowArray={[
            {
              type: 'bodyHeader',
              displayName: 'Profitability ratios'
            },
            {
              fieldName: 'profitMargin',
              displayName: `Profit margin`,
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'returnOnEquityAfterTax',
              displayName: `Return on equity (ROE), after tax (in %)`,
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'returnOnAssets',
              displayName: `Return on assets (ROA) (in %)`,
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'grossMargin',
              displayName: 'Gross margin',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'grossProfitMargin',
              displayName: 'Gross profit margin (in %)',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'netProfitMargin',
              displayName: 'Net profit margin (in %)',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'operatingMargin',
              displayName: 'Operating margin (return on sales) (in %)',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              type: 'rowBreak'
            },
            {
              type: 'bodyHeader',
              displayName: 'Liquidity ratios'
            },
            {
              fieldName: 'currentRatio',
              displayName: 'Current ratio',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'quickRatio',
              displayName: 'Quick ratio',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'cashRatio',
              displayName: 'Cash ratio',
              className: 'financialstatementstabledataindent fontthin'
            },

            {
              type: 'rowBreak'
            },
            {
              type: 'bodyHeader',
              displayName: 'Efficiency (activity) ratios'
            },
            {
              fieldName: 'inventoryTurnover',
              displayName: 'Inventory turnover (days)',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'assetTurnover',
              displayName: 'Asset turnover (days)',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'receivablesTurnover',
              displayName: 'Receivables turnover (days)',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'ebit',
              displayName: 'EBIT (Earnings before interest and taxes)',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'ebitda',
              displayName:
                'EBITDA (Earnings before interest, taxes, depreciation and amortization)',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              type: 'rowBreak'
            },
            {
              type: 'bodyHeader',
              displayName: 'Leverage (solvency) ratios'
            },
            {
              fieldName: 'debtRatio',
              displayName: 'Debt ratio',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'debtToEquityRatio',
              displayName: 'Debt-to-equity ratio',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'interestCoverageRatio',
              displayName: 'Interest coverage ratio',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              type: 'rowBreak'
            },
            {
              type: 'bodyHeader',
              displayName: 'Market (price) ratios'
            },
            {
              fieldName: 'earningsPerShareBasic',
              displayName: 'Earnings per share (basic)',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'earningsPerShareDiluted',
              displayName: 'Earnings per share (diluted)',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'dividendPayoutRatio',
              displayName: 'Dividend payout ratio',
              className: 'financialstatementstabledataindent fontthin'
            },
            {
              fieldName: 'priceToEarningsRatio',
              displayName: 'Price-to-earnings ratio',
              className: 'financialstatementstabledataindent fontthin'
            }
          ]}
        />
      </AccordianCard>
    </>
  )
}

export default ViewFinancials