import React, { useState, useEffect, useRef } from 'react'
import { Card, Form, Tabs, Tab, Button, Alert, Spinner } from 'react-bootstrap'
import { IoMdClose } from 'react-icons/io'
import { BiAnalyse } from 'react-icons/bi'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useRealmApp } from '../../../RealmApp'
import '../referral-program2.css'

const ReferralInvite = () => {

    const { currentUser } = useRealmApp();

    // realm functions
    const { createId, saveReferralInvitation } = currentUser.functions;

    const referralCopyButton = useRef(null);

    // hook for log (to be saved in referrals collection)
    const [log, setLog] = useState({
        emailTo: "",
        emailFrom: "",
        emailContent: "",
        referralLink: "",
        customReferralLink: "",
        referralType: "Anonymous invitation",
        method: ""
    });

    // handle change of values for log
    function changeLog(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        if (value === "Anonymous invitation") {
            let name1 = "emailFrom";
            let value1 = "";

            let name2 = "emailContent";
            let value2 = "";

            // create a copy of filters with desired changes
            let logChange = { ...log };

            logChange[name] = value;
            logChange[name1] = value1;
            logChange[name2] = value2;
            setLog({ ...logChange });
        }
        else {
            // create a copy of filters with desired changes
            let logChange = { ...log };

            logChange[name] = value;
            setLog({ ...logChange });
        }
    }

    // generate link
    useEffect(() => {
        async function generateId() {
            try {
                let response = await createId()

                let name1 = "referralLink";
                let name2 = "customReferralLink";
                let name3 = "text";
                let value = "https://app.roiquant.com/create-account/" + response;

                // create a copy of filters with desired changes
                let logChange = { ...log };

                logChange[name1] = value;
                logChange[name2] = value;
                setLog({ ...logChange });

                // create a copy of filters with desired changes
                let copyChanged = { ...copy };

                copyChanged[name3] = value;
                setCopy({ ...copyChanged });
            } catch (err) {
            }
        }
        generateId();
    }, []);

    // hook for copy
    const [copy, setCopy] = useState({
        text: "",
        copied: false
    });

    // handle change of values for copy
    function changeText(e) {
        e.preventDefault();

        let name1 = e.target.name;
        let name2 = "text";
        let value = e.target.value;

        // create a copy of filters with desired changes
        let logChange = { ...log };

        logChange[name1] = value;
        setLog({ ...logChange });

        // create a copy of filters with desired changes
        let copyChanged = { ...copy };

        copyChanged[name2] = value;
        setCopy({ ...copyChanged });
    }

    // handle copy function
    async function copyText() {
        let name1 = "copied";
        let value1 = true;

        // create a copy of filters with desired changes
        let copyChanged = { ...copy };

        copyChanged[name1] = value1;
        setCopy({ ...copyChanged });
    }

    const [alert, setAlert] = useState(false);

    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    const [message, setMessage] = useState("");

    // handle save log
    async function saveLog(e) {
        e.preventDefault();

        let name = "method";
        let value = "Email";

        // create a copy of filters with desired changes
        let logChanged = { ...log };

        logChanged[name] = value;
        setLog({ ...logChanged });

        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Sending</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your email has been sent.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            await saveReferralInvitation(log);

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><AiOutlineCheckCircle className="alert-icon" /> Sent</p>
                <p style={{ marginLeft: "28px" }}>Your email has been successfully sent.</p>
            </div>);
        } catch (err) {
            // console.error("Could not save the log:", err);

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>We failed to send your email. Please try again.</p>
            </div>);
        }
    }

    // generate link
    async function generateLink() {
        try {
            let response = await createId()

            let name1 = "referralLink";
            let name2 = "customReferralLink";
            let name3 = "text";
            let value = "https://app.roiquant.com/create-account/" + response;

            // create a copy of filters with desired changes
            let logChange = { ...log };

            logChange[name1] = value;
            logChange[name2] = value;
            setLog({ ...logChange });

            // create a copy of filters with desired changes
            let copyChanged = { ...copy };

            copyChanged[name3] = value;
            setCopy({ ...copyChanged });
        } catch (err) {
        }
    }

    useEffect(() => {
        // console.log(log);

        const handleClick = async (event) => {
            // console.log("button clicked");

            if (log.method !== "Invitation Link") {
                let name2 = "method";
                let value2 = "Invitation Link";

                // create a copy of filters with desired changes
                let logChanged = { ...log };

                logChanged[name2] = value2;
                setLog({ ...logChanged });
            }
            else {
                try {
                    await saveReferralInvitation(log);

                    setAlert(true);
                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "#1f5f8b" }}><AiOutlineCheckCircle className="alert-icon" /> Copied</p>
                        <p style={{ marginLeft: "28px" }}>Your link has been copied.</p>
                    </div>);
                } catch (err) {
                    // console.error("Could not save the log:", err);
                }
            }
        };

        const element = referralCopyButton.current;

        element.addEventListener("click", handleClick);

        return () => {
            element.removeEventListener("click", handleClick);
        };

    }, [copy, log]);

    return (
        <>

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <p className="referralcardheadermain">How are you inviting people?</p>

            <Card id="referralinvite" className="referralcard">

                <Tabs className="referraltabs" defaultActiveKey="invitationLink">
                    <Tab id="uncontrolled-tab-example" eventKey="email" title="Email" disabled>
                        <div className="referralinvitediv">
                            <p style={{ display: "flex" }}>
                                Invite friends and get free access to <Form.Control as="select" className="referralselect" style={{ padding: "0", margin: "0", height: "26px" }}>
                                    <option>Standard Plan</option>
                                </Form.Control>
                            </p>

                            <div>
                                <p>Email your friends</p>

                                <div className="referralinviteemaildiv">
                                    {log.referralType === "Anonymous invitation"
                                        ? (<Form.Control className="inputbox" type="text" placeholder="From: invite@roiquant.com" disabled />)
                                        : (<Form.Control className="inputbox" type="text" name="emailFrom" value={log.emailFrom} placeholder="From: invite@roiquant.com" onChange={changeLog} />)
                                    }

                                    <Form.Control className="inputbox" type="text" name="emailTo" value={log.emailTo} placeholder="To: enter email address" style={{ marginLeft: "15px" }} onChange={changeLog} />

                                    <Button className="referralinvitesendbutton glintanimation" onClick={saveLog}>
                                        <span>Send</span>
                                    </Button>
                                </div>

                                <div style={{ paddingTop: "10px" }}>
                                    {log.referralType === "Anonymous invitation"
                                        ? (<Form.Control className="inputbox" as="textarea" placeholder="Refer to 3 friends" disabled />)
                                        : (<Form.Control className="inputbox" as="textarea" name="emailContent" value={log.emailContent} placeholder="Refer to 3 friends" onChange={changeLog} />)
                                    }
                                </div>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey="invitationLink" title="Invitation Link">
                        <div className="referralinvitediv">
                            <p style={{ display: "flex" }}>
                                Invite friends and get free access to <Form.Control as="select" className="referralselect" style={{ padding: "0", margin: "0", height: "26px" }}>
                                    <option>Standard Plan</option>
                                </Form.Control>
                            </p>

                            <div>
                                <p>Copy your referral link</p>

                                <div className="referralinviteemaildiv">
                                    {log.referralType === "Anonymous invitation"
                                        ? (<Form.Control className="inputbox" type="text" name="referralLink" value={log.referralLink} onChange={changeText} disabled />)
                                        : (<Form.Control className="inputbox" type="text" name="customReferralLink" value={log.customReferralLink} onChange={changeText} />)
                                    }

                                    <CopyToClipboard text={copy.text} onCopy={copyText}>
                                        <Button ref={referralCopyButton} className="referralinvitesendbutton glintanimation">
                                            <span>Copy</span>
                                        </Button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey="socialMedia" title="Social Media" disabled>

                    </Tab>
                </Tabs>

                <hr style={{ marginBottom: "0" }} />

                <div className="referralinvitebottom">
                    <Form.Control as="select" className="referralselect">
                        <option>Referrals</option>
                    </Form.Control>

                    <Form.Control as="select" className="referralselect" name="referralType" value={log.referralType} onChange={changeLog}>
                        <option>Anonymous invitation</option>
                        <option>Personalised invitation</option>
                    </Form.Control>

                    <Button className="referralinvitegeneratebutton glintanimation" onClick={generateLink}>
                        <span>Generate new link</span>
                    </Button>
                </div>

            </Card>
        </>
    );
}

export default ReferralInvite