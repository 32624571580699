import React from 'react';
import '../idea-validation.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdInformationCircleOutline } from 'react-icons/io';

function ViewBusinessViability(props) {

    return (

        <Card>
            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="howViableIsYourBusiness">
                <div className="companyarrowicon">
                    <FaChevronDown />
                </div>

                <div className="companytitlecard">
                    How viable is your business?
                </div>

                <div className="companyellipsisicon">
                    <FaEllipsisH />
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="howViableIsYourBusiness">
                <Card.Body id="companyinformation">
                    <div>
                        <p className="companydetailtitle">
                            Who are your customers?
                            <sup data-tip="Explain who are the groups of people or businesses you aim to solve the problems and create values for">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.customerSegment
                                ? "-"
                                : (<ul>{props.customerSegment.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What real-world problem are you solving (customer pain)?
                            <sup data-tip="Very clearly describe the problem (critical pain point) you are solving">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.customerPain
                                ? "-"
                                : (<ul>{props.customerPain.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            Why now (marketing timing)?
                            <sup data-tip="Explain how your timing is driven by a shift in consumer behaviour, or a new technological advancement, or a regulatory change, or the disappearance of a previous competitor, or by anything at all">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.marketingTiming
                                ? "-"
                                : (<ul>{props.marketingTiming.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What product-or-solution are you offering (customer gain)?
                            <sup data-tip="Explain how your product-or-solution solves a fundamental need in the market (whether or not that need is currently known to customers) that customers will pay money to purchase it">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.customerGain
                                ? "-"
                                : (<ul>{props.customerGain.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            Who are your competitors?
                            <sup data-tip="Demonstrate that you understand your competitive landscape">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.competitors
                                ? "-"
                                : (<ul>{props.competitors.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What is your competitive differentiation?
                            <sup data-tip="Explain how you are ten times better or cheaper than existing alternatives">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.competitiveDifferentiation
                                ? "-"
                                : (<ul>{props.competitiveDifferentiation.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What is your monetization model?
                            <sup data-tip="Explain how your business generates revenue and make money">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.monetizationModel
                                ? "-"
                                : (<ul>{props.monetizationModel.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What is your go-to-market strategy?
                            <sup data-tip="Explain how you plan to acquire customers and if the average selling price of your product-or-solution support your go-to-market strategy">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.marketStrategy
                                ? "-"
                                : (<ul>{props.marketStrategy.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            How big is your market (TAM, SAM, SOM)?
                            <sup data-tip="Explain how your Total Available Market (TAM), Serviceable Available Market (SAM), and Serviceable Obtainable Market (SOM) are large enough to drive business viability and high return on investment">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.marketSegments
                                ? "-"
                                : (<ul>{props.marketSegments.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What is your early revenue, if any?
                            <sup data-tip="Prove that your customer segments are willing to pay for your product-or-solution offering">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.earlyRevenue
                                ? "-"
                                : (<ul>{props.earlyRevenue.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What is your traction (KPI metrics), if any?
                            <sup data-tip="Show traction and key metrics to demonstrate demand, progress, and growth">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.kpiMetrics
                                ? "-"
                                : (<ul>{props.kpiMetrics.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What is your financing strategy?
                            <sup data-tip="Outline your financing requirements, timeline, and how you will achieve your key milestones with the funding">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.financingStrategy
                                ? "-"
                                : (<ul>{props.financingStrategy.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What is your business valuation?
                            <sup data-tip="Explain how you calculated and derived the pre-money and post-money valuation of your business">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.businessValuation
                                ? "-"
                                : (<ul>{props.businessValuation.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            What is your business exit strategy, if any?
                            <sup data-tip="Demonstrate that you have an exit strategy to make an equitable profit from a transfer of business ownership to a third party via a merger, or acquisition, or initial public offering (IPO), or any liquidity event">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.exitStrategy
                                ? "-"
                                : (<ul>{props.exitStrategy.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            Why you (team experience)?
                            <sup data-tip="Explain why you and/or your team are uniquely qualified to win the market">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        <p className="companydetaildata">
                            {!props?.teamExperience
                                ? "-"
                                : (<ul>{props.teamExperience.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{point}</li>)
                                )}</ul>)
                            }
                        </p>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>

    )

}

export default ViewBusinessViability;