import { Form } from "react-bootstrap";

const FormCell = ({ props, actualName = "", displayName = "", placeholder, bottomText }) => {
  return (
    <div className="ideacheckeritem">
      <Form.Label>{displayName}</Form.Label>
      <Form.Control
        placeholder={placeholder}
        value={props.state[actualName]}
        onChange={(e) => {
          props.setState({
            type: "update value to state property",
            stateName: actualName,
            value: e.target.value,
          });
        }}
      />
      <Form.Text className="text-muted">{bottomText}</Form.Text>
    </div>
  );
};

export default FormCell;