export const stagesOfDevelopment = [
    { key: "Ideation" },
    { key: "Development" },
    { key: "Validation" },
    { key: "Growth" },
    { key: "Established" },
    { key: "Expansion" },
    { key: "Maturity" },
    { key: "Decline" },
    { key: "Exit" },
    { key: "Distress" }
];