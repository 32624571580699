export const fundingAmounts = [
    { "key": "Less than $1M" },
    { "key": "$1M-$5M" },
    { "key": "$5M-$10M" },
    { "key": "$10M-$50M" },
    { "key": "$50M-$100M" },
    { "key": "$100M-$500M" },
    { "key": "$500M-$1B" },
    { "key": "$1B-$5B" },
    { "key": "More than $5B" }
];