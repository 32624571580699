import React, { useState } from 'react'
import { Card, Form, Tabs, Tab } from 'react-bootstrap'
import '../referral-program2.css'

const ReferralImpact = () => {

    const [display, setDisplay] = useState({
        duration: "Last 7 days"
    });

    function changeDuration(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let displayChanged = { ...display };

        displayChanged[name] = value;
        setDisplay({ ...displayChanged });
    }

    return (
        <>
            <p className="referralcardheader" style={{ paddingTop: "40px" }}>How many people have you impacted?</p>

            <Card id="referralimpact" className="referralcard">

                <p style={{ padding: "12px 15px 0 15px", margin: "10px 15px 10px 15px" }}>By [timestamp], the first top 10 ambassadors in your country will get a prize.</p>

                <hr style={{ marginTop: "0" }} />

                <div className="referralimpactdiv">
                    <table className="referralimpacttable">
                        <thead>
                            <tr>
                                <th>Ambassadors</th>
                                <th>Last won</th>
                                <th>Rewards earned</th>
                                <th>Referrals</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <hr style={{ marginBottom: "0" }} />

                <div style={{ display: "flex" }}>
                    <Form.Control as="select" className="referralselect" name="duration" value={display.duration} onChange={e => changeDuration(e)}>
                        <option>Last 7 days</option>
                    </Form.Control>

                    <p className="referralimpactlastupdate">Last updated on [timestamp] (<span style={{ fontFamily: "AileronItalic" }}>hourly updates</span>)</p>
                </div>

            </Card>
        </>
    );
}

export default ReferralImpact