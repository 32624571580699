import React from 'react';
import { Card } from 'react-bootstrap';
import '../data-contribution.css';

function ViewGeneralInformation(props) {

    return (
        <>
            <Card style={{ marginTop: "40px" }}>
                <Card.Body id="companyinformation">
                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Profile type</p>
                            {!props?.profileType
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">{props.profileType}</p>)
                            }
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Entity type</p>
                            {!props?.entityType
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">{props.entityType}</p>)
                            }
                        </div>

                        {props.profileType === "Finance"
                            ? (<div className="pitchdeckdetaildiv">
                                <p className="companydetailtitle">Investor types</p>
                                {!props?.investorTypes
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.investorTypes}</p>)
                                }
                            </div>)
                            : ""
                        }
                    </div>
                </Card.Body>
            </Card>

            {props.profileType === "Person" || props.entityType === "Non-institutional investor" || props.entityType === "Advisor"
                ? (<>
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Entity name</p>
                                    {!props?.entityName
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.entityName}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Legal name</p>
                                    {!props?.legalName
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.legalName}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Former legal name(s)</p>
                                    {!props?.formerLegalName
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">
                                            <ul>{props.formerLegalName.map((name, index) =>
                                                !name
                                                    ? ""
                                                    : (<li className="pitchdeckanswers" key={index}>{name}</li>)
                                            )}</ul>
                                        </p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Date of birth</p>
                                    {!props?.dateOfBirth
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.dateOfBirth}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Industry</p>
                                    {!props?.industry
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.industry}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Categories</p>
                                    {!props?.categories
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.categories.map((category, index) =>
                                            !category
                                                ? ""
                                                : (<span key={index}>{category}, </span>)
                                        )}</p>)
                                    }
                                </div>
                            </div>

                            <div>
                                <p className="companydetailtitle">Education</p>
                                {!props?.education
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.education.map((edu, index) =>
                                        !edu
                                            ? ""
                                            : (<span key={index}>{edu}, </span>)
                                    )}</p>)
                                }
                            </div>

                            <div>
                                <p className="companydetailtitle">Current designation</p>
                                {!props?.currentWork
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">
                                        <ol>{props.currentWork.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<>
                                                    <li className="pitchdeckanswers" key={index}>{point.currentDesignation}</li>
                                                    <ul>
                                                        <li className="pitchdeckanswers" key={index}>Current organization: {point.currentOrganization}</li>
                                                        <li className="pitchdeckanswers" key={index}>Short biography: {point.shortBiography}</li>
                                                        <li className="pitchdeckanswers" key={index}>Long biography: {point.longBiography}</li>
                                                    </ul>
                                                </>)
                                        )}</ol>
                                    </p>)
                                }
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">City</p>
                                    {!props?.city
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.city}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">State</p>
                                    {!props?.state
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.state}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Country</p>
                                    {!props?.country
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.country}</p>)
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Previous profile type</p>
                                    {!props?.previousProfileType
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.previousProfileType}</p>)
                                    }
                                </div>
                            </div>

                            <div>
                                <p className="companydetailtitle">Previous designation</p>
                                {!props?.previousWork
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">
                                        <ol>{props.previousWork.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<>
                                                    <li className="pitchdeckanswers" key={index}>{point.previousDesignation}</li>
                                                    <ul>
                                                        <li className="pitchdeckanswers" key={index}>Current organization: {point.previousOrganization}</li>
                                                        <li className="pitchdeckanswers" key={index}>Short biography: {point.previousShortBiography}</li>
                                                        <li className="pitchdeckanswers" key={index}>Long biography: {point.previousLongBiography}</li>
                                                    </ul>
                                                </>)
                                        )}</ol>
                                    </p>)
                                }
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Previous city</p>
                                    {!props?.previousCity
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.previousCity}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Previous state</p>
                                    {!props?.previousState
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.previousState}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Previous country</p>
                                    {!props?.previousCountry
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.previousCountry}</p>)
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>)
                : (<>
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Entity name</p>
                                    {!props?.entityName
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.entityName}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Legal name</p>
                                    {!props?.legalName
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.legalName}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Former legal name(s)</p>
                                    {!props?.formerLegalName
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">
                                            <ul>{props.formerLegalName.map((name, index) =>
                                                !name
                                                    ? ""
                                                    : (<li className="pitchdeckanswers" key={index}>{name}</li>)
                                            )}</ul>
                                        </p>)
                                    }
                                </div>
                            </div>

                            <div>
                                <p className="companydetailtitle">Short description</p>
                                {!props?.shortDescription
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.shortDescription}</p>)
                                }
                            </div>

                            <div>
                                <p className="companydetailtitle">Long description</p>
                                {!props?.longDescription
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.longDescription}</p>)
                                }
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Ownership status</p>
                                    {!props?.ownershipStatus
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.ownershipStatus}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Operational status</p>
                                    {!props?.operationalStatus
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.operationalStatus}</p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Founded date</p>
                                    {!props?.foundedDate
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.foundedDate}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Operational duration</p>
                                    {!props?.operationalDuration
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.operationalDuration}</p>)
                                    }
                                </div>

                                {props.operationalStatus === "Active"
                                    ? ""
                                    : (<div className="pitchdeckdetaildiv">
                                        <p className="companydetailtitle">Shutdown date</p>
                                        {!props?.shutdownDate
                                            ? (<p className="companydetaildata">-</p>)
                                            : (<p className="companydetaildata">{props.shutdownDate}</p>)
                                        }
                                    </div>)
                                }
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">City</p>
                                    {!props?.city
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.city}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">State</p>
                                    {!props?.state
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.state}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Country</p>
                                    {!props?.country
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.country}</p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Industry</p>
                                    {!props?.industry
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.industry}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Categories</p>
                                    {!props?.categories
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.categories.map((category, index) =>
                                            !category
                                                ? ""
                                                : (<span key={index}>{category}, </span>)
                                        )}</p>)
                                    }
                                </div>

                                {props.profileType === "Education" || props.profileType === "Project" || props.profileType === "Product" || (props.profileType === "Government" && props.entityType !== "Government-owned corporation")
                                    ? ""
                                    : (<div className="pitchdeckdetaildiv">
                                        <p className="companydetailtitle">Ticker symbol</p>
                                        {!props?.tickerSymbol
                                            ? (<p className="companydetaildata">-</p>)
                                            : (<p className="companydetaildata">{props.tickerSymbol}</p>)
                                        }
                                    </div>)
                                }
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Email</p>
                                    {!props?.email
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.email}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Telephone</p>
                                    {!props?.telephone
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.telephone}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Website</p>
                                    {!props?.website
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.website}</p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Blog</p>
                                    {!props?.blog
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.blog}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Social media</p>
                                    {!props?.socialMedia
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">
                                            <ul>{props.socialMedia.map((social, index) =>
                                                !social
                                                    ? ""
                                                    : (<li className="pitchdeckanswers" key={index}>{social}</li>)
                                            )}</ul>
                                        </p>)
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>)
            }

            {props.profileType === "Organization" || props.profileType === "Finance" || props.profileType === "Education" || props.profileType === "Project" || props.profileType === "Product"
                ? (<>
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Gender</p>
                                    {!props?.gender
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.gender}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Are you a first time founder?</p>
                                    {!props?.firstTimeFounder
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.firstTimeFounder}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle"> Are you a technical or non-technical founder?</p>
                                    {!props?.technicalFounder
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.technicalFounder}</p>)
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>)
                : ""
            }
        </>
    );
}

export default ViewGeneralInformation