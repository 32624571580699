export const hearAboutUs = [
    { "key": "Email" },
    { "key": "Social media" },
    { "key": "Search engine" },
    { "key": "Advertisement" },
    { "key": "Word of mouth" },
    { "key": "Government authority or agency" },
    { "key": "Recommended by a friend or colleague" },
    { "key": "Research paper or analytical report" },
    { "key": "News article or blog or website" },
    { "key": "Social or networking event" },
    { "key": "School or university" },
    { "key": "Podcast" },
    { "key": "Other" },
];