import React from 'react';
import { Card } from 'react-bootstrap';
import '../data-contribution/data-contribution.css';

function Management(props) {

    return (
        <Card style={{ marginTop: "40px" }}>
            <Card.Body id="companyinformation">
                <div>
                    <p className="companydetailtitle">Management</p>
                    <p className="companydetaildata">{!props?.management
                        ? "-"
                        : props.management.map((element, index) => (
                            <span key={index} className="pitchdeckanswers">{element.name}, </span>
                        ))
                    }</p>
                </div>

                <div>
                    <p className="companydetailtitle">Founder(s)</p>
                    <p className="companydetaildata">{!props?.founders ? "-" : props.founders.join(", ")}</p>
                </div>

                <div className="companydetailrows">
                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">No. of employees</p>
                        <p className="companydetaildata">{!props?.numOfEmployees ? "-" : props.numOfEmployees}</p>
                    </div>

                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">No. of board member(s)</p>
                        {!props?.numOfBoardMembers
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.numOfBoardMembers}</p>)
                        }
                    </div>

                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">Board member(s)</p>
                        <p className="companydetaildata">{!props?.boardMembers
                            ? "-"
                            : props.boardMembers.map((element, index) => (
                                <span key={index} className="pitchdeckanswers">{element.name}, </span>
                            ))
                        }</p>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default Management