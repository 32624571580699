import React, { useState } from 'react';
import './filters.css';
import { Card, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { useRealmApp } from '../../../RealmApp';
import { industriesNew } from '../../dropdown-options/industries';
import { countries } from '../../dropdown-options/countries';
import { entityTypes } from '../../dropdown-options/entity-types';
import { lifespans } from '../../dropdown-options/lifespans';
import { fundingRounds } from '../../dropdown-options/funding-rounds';
import { fundingAmounts } from '../../dropdown-options/funding-amounts';
import { genders } from '../../dropdown-options/genders';
import { noOfFounders } from '../../dropdown-options/no-of-founders';

const Reasons = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // realm functions declaration
    const { currentUser, logInAnonymous } = useRealmApp();

    // to check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // chart function declaration
    const { postmortemAnalysis } = currentUser.functions;

    // hook for user input
    const [filters, setFilters] = useState({
        type: "Reasons for shutdown",
        filtersView: true,
        entityType: "Entity type",
        reasonsNum: "No. of reasons for shutdown",
        sliceNum: "",
        lifespan: "Lifespan",
        location: "Location",
        industry: "Industry",
        fundingRound: "Funding round",
        fundingAmount: "Funding amount",
        gender: "Gender",
        numOfFounders: "No. of founders"
    });

    // hook for results
    const [investments, setInvestments] = useState(
        [
            {
                "Reason Label": "Strong Competition",
                "Frequency": 167,
                "%": 8.6
            },
            {
                "Reason Label": "Poor Business Model",
                "Frequency": 125,
                "%": 6.5
            },
            {
                "Reason Label": "Lack of Funds",
                "Frequency": 114,
                "%": 5.9
            },
            {
                "Reason Label": "No Product-Market Fit",
                "Frequency": 90,
                "%": 4.6
            },
            {
                "Reason Label": "Failed Fundraising",
                "Frequency": 83,
                "%": 4.3
            },
            {
                "Reason Label": "Poor Product-Market Fit",
                "Frequency": 61,
                "%": 3.2
            },
            {
                "Reason Label": "Failed Acquisition (Takeover)",
                "Frequency": 59,
                "%": 3.0
            },
            {
                "Reason Label": "Acqui-hire",
                "Frequency": 48,
                "%": 2.5
            },
            {
                "Reason Label": "Acquired",
                "Frequency": 47,
                "%": 2.4
            },
            {
                "Reason Label": "No Market Validation",
                "Frequency": 41,
                "%": 2.1
            },
            {
                "Reason Label": "Poor Pivot",
                "Frequency": 40,
                "%": 2.1
            },
            {
                "Reason Label": "Covid-19 Pandemic",
                "Frequency": 40,
                "%": 2.1
            },
            {
                "Reason Label": "High Operational Cost",
                "Frequency": 33,
                "%": 1.7
            },
            {
                "Reason Label": "Financial Difficulties",
                "Frequency": 33,
                "%": 1.7
            },
            {
                "Reason Label": "Lack of Experience",
                "Frequency": 33,
                "%": 1.7
            },
            {
                "Reason Label": "Lack of Focus",
                "Frequency": 32,
                "%": 1.7
            },
            {
                "Reason Label": "No Sustainability",
                "Frequency": 32,
                "%": 1.7
            },
            {
                "Reason Label": "Strategy Issues",
                "Frequency": 32,
                "%": 1.7
            },
            {
                "Reason Label": "Poor Product",
                "Frequency": 30,
                "%": 1.5
            },
            {
                "Reason Label": "Founder Issues",
                "Frequency": 30,
                "%": 1.5
            },
            {
                "Reason Label": "Regulatory Issues",
                "Frequency": 29,
                "%": 1.5
            },
            {
                "Reason Label": "Bankruptcy",
                "Frequency": 29,
                "%": 1.5
            },
            {
                "Reason Label": "Poor Management",
                "Frequency": 29,
                "%": 1.5
            },
            {
                "Reason Label": "High Burn Rate",
                "Frequency": 28,
                "%": 1.4
            },
            {
                "Reason Label": "No Scalability",
                "Frequency": 26,
                "%": 1.3
            },
            {
                "Reason Label": "Failed M&A",
                "Frequency": 24,
                "%": 1.2
            },
            {
                "Reason Label": "Poor Marketing",
                "Frequency": 23,
                "%": 1.2
            },
            {
                "Reason Label": "Legal Issues",
                "Frequency": 22,
                "%": 1.1
            },
            {
                "Reason Label": "Lack of Strategy",
                "Frequency": 20,
                "%": 1.0
            },
            {
                "Reason Label": "Lies and Manipulation",
                "Frequency": 20,
                "%": 1.0
            }
        ]
    );

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            type: "Reasons for shutdown",
            filtersView: true,
            entityType: "Entity type",
            reasonsNum: "No. of reasons for shutdown",
            sliceNum: "",
            lifespan: "Lifespan",
            location: "Location",
            industry: "Industry",
            fundingRound: "Funding round",
            fundingAmount: "Funding amount",
            gender: "Gender",
            numOfFounders: "No. of founders"
        });
        setAlert(false);
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        let name2, value2, name3, value3;

        if (name === "reasonsNum" && value === "No. of reasons for shutdown") {
            name2 = "filtersView";
            value2 = true;
        }
        else if (name === "reasonsNum" && value !== "No. of reasons for shutdown") {
            name2 = "filtersView";
            value2 = false;

            if (value === "Top 10") {
                name3 = "sliceNum";
                value3 = "10";
            }

            if (value === "Top 20") {
                name3 = "sliceNum";
                value3 = "20";
            }

            if (value === "Top 30") {
                name3 = "sliceNum";
                value3 = "30";
            }
        }

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        filtersCopy[name2] = value2;
        filtersCopy[name3] = value3;
        setFilters({ ...filtersCopy });
    }

    // function to submit form
    function submitFilters(e) {
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        postmortemAnalysis(filters)
            .then(response => {
                let graphData = response;
                let slicedData = graphData.slice(0, filters.sliceNum);
                setInvestments(slicedData);
                closeAlert();
            })
            .catch(error => {
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Your filter query returned no results. Please change your filter values and try again.</p>
                </div>);
            })
        e.preventDefault();
    }

    return (
        <Card.Body className="accordion-card-body">

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div className="col-12 div-filter">
                {/* form */}
                <Form onSubmit={submitFilters}>
                    <Form.Group>
                        <Form.Row className="align-items-center">
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.reasonsNum} as="select" name="reasonsNum" onChange={changeFilters}>
                                    <option>No. of reasons for shutdown</option>
                                    <option>Top 10</option>
                                    <option>Top 20</option>
                                    <option>Top 30</option>
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.entityType} disabled={filters.filtersView} as="select" name="entityType" onChange={changeFilters}>
                                    <option>Entity type</option>
                                    {
                                        entityTypes.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.lifespan} disabled={filters.filtersView} as="select" name="lifespan" onChange={changeFilters}>
                                    <option>Lifespan</option>
                                    {
                                        lifespans.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.location} disabled={filters.filtersView} as="select" name="location" onChange={changeFilters}>
                                    <option>Location</option>
                                    {
                                        countries.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.industry} disabled={filters.filtersView}
                                    as="select" name="industry" onChange={changeFilters}>
                                    <option>Industry</option>
                                    {
                                        industriesNew.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.fundingRound} disabled={filters.filtersView} as="select" name="fundingRound" onChange={changeFilters}>
                                    <option>Funding round</option>
                                    {
                                        fundingRounds.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.fundingAmount} disabled={filters.filtersView} as="select" name="fundingAmount" onChange={changeFilters}>
                                    <option>Funding amount</option>
                                    {
                                        fundingAmounts.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.gender} disabled={filters.filtersView} as="select" name="gender" onChange={changeFilters}>
                                    <option>Gender</option>
                                    {
                                        genders.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.numOfFounders} disabled={filters.filtersView} as="select" name="numOfFounders" onChange={changeFilters}>
                                    <option>No. of founders</option>
                                    {
                                        noOfFounders.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            {/* reset button */}
                            <Col xs="auto">
                                <button onClick={resetFilters} className="reset-button tour-clear-filter">
                                    <span>Clear</span>
                                </button>
                            </Col>
                            {/* submit button */}
                            <Col xs="auto">
                                <button type="submit" className="submit-button">
                                    <span>Filter</span>
                                </button>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>
            </div>

            {/* results */}
            <div className="col-12">
                <Card className="graph-card">
                    <div>
                        {/* chart component */}
                        <ResponsiveContainer id="vertical-charts">
                            <BarChart data={investments}
                                margin={{ top: 20, right: 20, left: 100, bottom: 50 }}
                                width={1100}
                                height={1100}
                                fontFamily="AileronRegular"
                                layout={"vertical"}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type={"number"}
                                    orientation={"bottom"}
                                    fontSize="15px"
                                    fontFamily="AileronRegular"
                                    label={{ value: "Frequency", offset: -40, position: "insideBottom" }}
                                />
                                <YAxis type={"category"}
                                    orientation={"left"}
                                    dataKey="Reason Label"
                                    interval={0}
                                    fontSize="12px"
                                    fontFamily="AileronRegular"
                                    label={{ value: "Reasons for Shutdown", angle: -90, offset: -40, position: "insideLeft" }}
                                />
                                <Tooltip />
                                <Bar dataKey="Frequency" fill="#8884d8" barSize={50}>
                                    <LabelList dataKey="%" position="right" />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </div>

            {/* notes */}
            <div className="col-12 extra-notes">
                <span className="info-notes">
                    Note:
                    <ul>
                        <li id="notes-points">
                            Total of {props.uniqueReasons.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} unique reasons for shutdown were labelled.
                        </li>
                        <li id="notes-points">
                            Currently, only {props.companiesWithReasons.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} inactive companies are summarized and labelled with <span style={{ fontFamily: "AileronItalic" }}>reasons for shutdown</span>.
                        </li>
                        <li id="notes-points">
                            In due time, the remaining {props.companiesWithoutReasons.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} inactive companies will be automatically summarized and labelled by our algorithms.
                        </li>
                    </ul>
                </span>
            </div>
        </Card.Body>
    )
}

export default Reasons;