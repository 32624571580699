import React, { useState } from 'react';
import { Card, Form, Alert, Button, Spinner } from 'react-bootstrap';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList } from 'react-icons/bs'
import './methods.css';

const ScorecardMethod = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    // realm function starts here
    const { scorecardMethod } = props.currentUser.functions;
    // console.log("Current User", props.currentUser.functions)

    // hook for form
    const [filters, setFilters] = useState({
        teamExperience: "",
        willFoundingCeoStepAside: "",
        willFoundersBeCoachable: "",
        howCompleteIsManagement: "",
        totalAddressableMarket: "",
        totalAnnualSales: "",
        potentialRevenueGrowth: "",
        potentialRevenueGain: "",
        hasProductBeenDefined: "",
        isProductAttractive: "",
        canProductBeCopied: "",
        strengthOfCompetitors: "",
        competitorsProductQuality: "",
        salesChannels: "",
        doesCompanyNeedToRaise: "",
        positiveFactor1: "",
        positiveFactor2: "",
        positiveFactor3: "",
        positiveFactor4: "",
        positiveFactor5: "",
        negativeFactor1: "",
        negativeFactor2: "",
        negativeFactor3: "",
        negativeFactor4: "",
        negativeFactor5: "",
        name1: "",
        name2: "",
        name3: "",
        name4: "",
        name5: "",
        valuation1: 0,
        valuation2: 0,
        valuation3: 0,
        valuation4: 0,
        valuation5: 0,
        strengthOfFounders: "",
        sizeOfOpportunity: "",
        strengthOfProduct: "",
        competitiveEnvironment: "",
        salesAndMarketingChannels: "",
        needForAdditionalRounds: "",
        otherFactors: ""
    });

    // hook for results
    const [valuation, setValuation] = useState({
        averageValuationOfComparables: 0,
        preMoneyValuation: 0
    });

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            teamExperience: "",
            willFoundingCeoStepAside: "",
            willFoundersBeCoachable: "",
            howCompleteIsManagement: "",
            totalAddressableMarket: "",
            totalAnnualSales: "",
            potentialRevenueGrowth: "",
            potentialRevenueGain: "",
            hasProductBeenDefined: "",
            isProductAttractive: "",
            canProductBeCopied: "",
            strengthOfCompetitors: "",
            competitorsProductQuality: "",
            salesChannels: "",
            doesCompanyNeedToRaise: "",
            positiveFactor1: "",
            positiveFactor2: "",
            positiveFactor3: "",
            positiveFactor4: "",
            positiveFactor5: "",
            negativeFactor1: "",
            negativeFactor2: "",
            negativeFactor3: "",
            negativeFactor4: "",
            negativeFactor5: "",
            name1: "",
            name2: "",
            name3: "",
            name4: "",
            name5: "",
            valuation1: 0,
            valuation2: 0,
            valuation3: 0,
            valuation4: 0,
            valuation5: 0,
            strengthOfFounders: "",
            sizeOfOpportunity: "",
            strengthOfProduct: "",
            competitiveEnvironment: "",
            salesAndMarketingChannels: "",
            needForAdditionalRounds: "",
            otherFactors: ""
        });
        setValuation({
            averageValuationOfComparables: 0,
            preMoneyValuation: 0
        });

        closeAlert();
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        setFilters({ ...filtersCopy });
    }

    // function to submit form
    async function submitFilters(e) {
        e.preventDefault();

        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Simulating</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        let form = {
            teamExperience: filters.teamExperience,
            willFoundingCeoStepAside: filters.willFoundingCeoStepAside,
            willFoundersBeCoachable: filters.willFoundersBeCoachable,
            howCompleteIsManagement: filters.howCompleteIsManagement,
            totalAddressableMarket: filters.totalAddressableMarket,
            totalAnnualSales: filters.totalAnnualSales,
            potentialRevenueGrowth: filters.potentialRevenueGrowth,
            potentialRevenueGain: filters.potentialRevenueGain,
            hasProductBeenDefined: filters.hasProductBeenDefined,
            isProductAttractive: filters.isProductAttractive,
            canProductBeCopied: filters.canProductBeCopied,
            strengthOfCompetitors: filters.strengthOfCompetitors,
            competitorsProductQuality: filters.competitorsProductQuality,
            salesChannels: filters.salesChannels,
            doesCompanyNeedToRaise: filters.doesCompanyNeedToRaise,
            positiveFactor1: filters.positiveFactor1,
            positiveFactor2: filters.positiveFactor2,
            positiveFactor3: filters.positiveFactor3,
            positiveFactor4: filters.positiveFactor4,
            positiveFactor5: filters.positiveFactor5,
            negativeFactor1: filters.negativeFactor1,
            negativeFactor2: filters.negativeFactor2,
            negativeFactor3: filters.negativeFactor3,
            negativeFactor4: filters.negativeFactor4,
            negativeFactor5: filters.negativeFactor5,
            name1: filters.name1,
            name2: filters.name2,
            name3: filters.name3,
            name4: filters.name4,
            name5: filters.name5,
            valuation1: parseInt(filters.valuation1),
            valuation2: parseInt(filters.valuation2),
            valuation3: parseInt(filters.valuation3),
            valuation4: parseInt(filters.valuation4),
            valuation5: parseInt(filters.valuation5),
            strengthOfFounders: filters.strengthOfFounders,
            sizeOfOpportunity: filters.sizeOfOpportunity,
            strengthOfProduct: filters.strengthOfProduct,
            competitiveEnvironment: filters.competitiveEnvironment,
            salesAndMarketingChannels: filters.salesAndMarketingChannels,
            needForAdditionalRounds: filters.needForAdditionalRounds,
            otherFactors: filters.otherFactors
        };

        // console.log("Scorecard Method Form", form);

        try {
            const response = await scorecardMethod(form);
            const data = JSON.parse(response).body;

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View results</a></p>
            </div>);

            setValuation({
                averageValuationOfComparables: data['averageValuationOfComparables'],
                preMoneyValuation: data['preMoneyValuation']
            })

        } catch (err) {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>Please try again.</p>
            </div>);

            // console.error(err);
        }
    }

    // function to submit form on enter
    // function enterFilters(e) {
    //     if (e.code === "Enter" || e.code === "NumpadEnter") {
    //         submitFilters(e);
    //     }
    // };

    return (
        // card as method
        <Card.Body className="prerevmethodsaccordioncardbody">

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            {/* form */}
            <div className="col-12 prerevmethodsdivfilter">
                <Form>
                    <Form.Group>

                        {/* form header */}
                        <div className="prerevmethodsheader">
                            Strength of the founder(s) and management team: why you (team experience)?
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    How experienced are the founder(s) and team members to build the business?
                                    <sup data-tip="Determine the experience and capability of yourself and your team to develop the company growth.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="teamExperience" value={filters.teamExperience} onChange={changeFilters}>
                                    <option></option>
                                    <option>Many years of business experience</option>
                                    <option>Experienced in the business sector or industry or vertical</option>
                                    <option>Experienced in founding role, such as Founder, or Co-founder, or CEO, etc.</option>
                                    <option>Experienced in C-suite role, such as COO, or CFO, or CTO, etc.</option>
                                    <option>Experienced in managerial role, such as product manager, or project manager, or marketing manager, etc.</option>
                                    <option>Experienced in operative role, such as sales, or accounting, or technology, etc.</option>
                                    <option>No business experience at all</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Indicate the experience level of the management team</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Will the founding CEO be willing to step aside for an experienced CEO, if necessary?
                                    <sup data-tip="Determine the willingness of yourself or your co-founder whose role as the CEO to resign and make way for a more experienced CEO to grow the business.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="willFoundingCeoStepAside" value={filters.willFoundingCeoStepAside} onChange={changeFilters}>
                                    <option></option>
                                    <option>Willing</option>
                                    <option>Neutral</option>
                                    <option>Unwilling</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Indicate the willingness of the founding CEO</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Will the founder(s) be coachable?
                                    <sup data-tip="Determine the teachability and coachability of the leaders of the founding team.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="willFoundersBeCoachable" value={filters.willFoundersBeCoachable} onChange={changeFilters}>
                                    <option></option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Indicate the teachability of the founder(s)</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    How complete is the management team?
                                    <sup data-tip="Determine the completeness of your management team to build the business.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="howCompleteIsManagement" value={filters.howCompleteIsManagement} onChange={changeFilters}>
                                    <option></option>
                                    <option>Founder only (solo-entrepreneur)</option>
                                    <option>One competent team member in place</option>
                                    <option>Team members identified but full commitment is not definite</option>
                                    <option>Competent team in place</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Indicate the completeness of the management team</Form.Text>
                            </div>
                        </div>

                        {/* form header */}
                        <div className="prerevmethodsheader">
                            Size of the opportunity: how big is your market?
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    How big is your total addressable market?
                                    <sup data-tip="Determine the addressable market size of your business which is measured by sales and marketing efficacy/scalability, and competitive dynamics.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="totalAddressableMarket" value={filters.totalAddressableMarket} onChange={changeFilters}>
                                    <option></option>
                                    <option>Large market size</option>
                                    <option>Medium market size</option>
                                    <option>Small market size</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Estimate the total addressable market size</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    What is the size of the target market opportunity (total annual sales)?
                                    <sup data-tip="Determine the realistic size of your target market which is measured in total annual sales.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="totalAnnualSales" value={filters.totalAnnualSales} onChange={changeFilters}>
                                    <option></option>
                                    {filters.totalAddressableMarket === "Large market size"
                                        ? (<>
                                            <option>Less than USD $50 million</option>
                                            <option>USD $50 to $100 million</option>
                                            <option>More than USD $100 million</option>
                                        </>)
                                        : ("")
                                    }
                                    {filters.totalAddressableMarket === "Medium market size"
                                        ? (<>
                                            <option>Less than USD $10 million</option>
                                            <option>USD $10 to $50 million</option>
                                            <option>More than USD $50 million</option>
                                        </>)
                                        : ("")
                                    }
                                    {filters.totalAddressableMarket === "Small market size"
                                        ? (<>
                                            <option>Less than USD $5 million</option>
                                            <option>USD $5 to $15 million</option>
                                            <option>More than USD $15 million</option>
                                        </>)
                                        : ("")
                                    }
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Estimate the size of the target market</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    What is your potential revenue growth?
                                    <sup data-tip="Determine the revenue growth that your business may potentially gain.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="potentialRevenueGrowth" value={filters.potentialRevenueGrowth} onChange={changeFilters}>
                                    <option></option>
                                    <option>High revenue growth</option>
                                    <option>Moderate revenue growth</option>
                                    <option>Low revenue growth</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Estimate the potential revenue growth of the business</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    How much revenue in total can your company potentially gain in the next five years?
                                    <sup data-tip="Determine the realistic revenue growth of your business which is measured in total revenue.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="potentialRevenueGain" value={filters.potentialRevenueGain} onChange={changeFilters}>
                                    <option></option>
                                    {filters.potentialRevenueGrowth === "High revenue growth"
                                        ? (<>
                                            <option>Less than USD $20 million</option>
                                            <option>About USD $20 to $50 million</option>
                                            <option>More than USD $100 million (will require significant additional funding)</option>
                                        </>)
                                        : ("")
                                    }
                                    {filters.potentialRevenueGrowth === "Moderate revenue growth"
                                        ? (<>
                                            <option>Less than USD $5 million</option>
                                            <option>About USD $5 to $15 million</option>
                                            <option>More than USD $50 million</option>
                                        </>)
                                        : ("")
                                    }
                                    {filters.potentialRevenueGrowth === "Low revenue growth"
                                        ? (<>
                                            <option>Less than USD $1 million</option>
                                            <option>About USD $1 to $5 million</option>
                                            <option>More than USD $10 million</option>
                                        </>)
                                        : ("")
                                    }
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Estimate the total revenue that the business may gain</Form.Text>
                            </div>
                        </div>

                        {/* form header */}
                        <div className="prerevmethodsheader">
                            Strength of the product and/or intellectual property: what is your competitive differentiation?
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Has the product been defined and developed?
                                    <sup data-tip="Demonstrate that your product is well defined and has the ability to gain traction.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="hasProductBeenDefined" value={filters.hasProductBeenDefined} onChange={changeFilters}>
                                    <option></option>
                                    <option>The product is not completely defined and the team is still planning to develop the prototype</option>
                                    <option>The prototype is well defined, ready, and good quality</option>
                                    <option>The product is ready and has received good feedback from potential customers</option>
                                    <option>The product has received orders and made early sales from customers</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Determine the readiness and demand of the product</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Is the product attractive and compelling that customers want to buy?
                                    <sup data-tip="Demonstrate that your product has competitive edge in solving the customers' critical pain point.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="isProductAttractive" value={filters.isProductAttractive} onChange={changeFilters}>
                                    <option></option>
                                    <option>The product is a “vitamin pill” and is not essential</option>
                                    <option>The product is a “pain killer” solving customers' problems</option>
                                    <option>The product is a “pain killer“ and essential to customers</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Determine the problem-market fit of the product</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Can the product be copied by competitors?
                                    <sup data-tip="Demonstrate that your product has unique value proposition that is not easily copied by competition.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="canProductBeCopied" value={filters.canProductBeCopied} onChange={changeFilters}>
                                    <option></option>
                                    <option>The product can be easily copied and has no intellectual property</option>
                                    <option>The product is difficult to copy</option>
                                    <option>The product is unique and is protected by trade secrets</option>
                                    <option>The product is registered and has solid patent protections</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Determine the unfair advantage of the product</Form.Text>
                            </div>
                        </div>

                        {/* form header */}
                        <div className="prerevmethodsheader">
                            Competitive environment: how competitive is your market landscape?
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    What is the strength of competitors in the market?
                                    <sup data-tip="Demonstrate the strength of your competition in the market.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="strengthOfCompetitors" value={filters.strengthOfCompetitors} onChange={changeFilters}>
                                    <option></option>
                                    <option>The market is dominated by a single large competitor</option>
                                    <option>The market is dominated by several competitors</option>
                                    <option>The market is fractured and there are many small players</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Determine the competitiveness of the market landscape</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    How is the product quality of your competitors?
                                    <sup data-tip="Demonstrate the quality of your competitors' products in the market.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="competitorsProductQuality" value={filters.competitorsProductQuality} onChange={changeFilters}>
                                    <option></option>
                                    <option>The competitors' products are excellent</option>
                                    <option>The competitors' products are weak</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Determine the quality of products in the competitive environment</Form.Text>
                            </div>
                        </div>

                        {/* form header */}
                        <div className="prerevmethodsheader">
                            Sales and marketing channels, and partnerships: what is your go-to-market strategy?
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    What are your sales and marketing channels, and who are your business partners in sales and marketing?
                                    <sup data-tip="Demonstrate that you have developed partnerships and worked out your sales channels to market and sell your product.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="salesChannels" value={filters.salesChannels} onChange={changeFilters}>
                                    <option></option>
                                    <option>Sales channels have not yet been identified</option>
                                    <option>Initial sales and marketing methods have been tested</option>
                                    <option>Sales channels are secured and customers have placed trial orders</option>
                                    <option>Business partners have not been identified</option>
                                    <option>Key business partners are established</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Determine the go-to-market strategy and channels to distribute the product</Form.Text>
                            </div>
                        </div>

                        {/* form header */}
                        <div className="prerevmethodsheader">
                            Need for additional round(s) of financing: what is your financing strategy?
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Does your company need to raise money?
                                    <sup data-tip="Determine if your company requires financing or not.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control as="select" name="doesCompanyNeedToRaise" value={filters.doesCompanyNeedToRaise} onChange={changeFilters}>
                                    <option></option>
                                    <option>No, do not need to fundraise at the moment</option>
                                    <option>Yes, need to raise financing from angel investors (less capital)</option>
                                    <option>Yes, need to raise financing from venture capitalist (more capital)</option>
                                </Form.Control>
                                <Form.Text className="prerevmethodstextmuted">Indicate the financing requirement of the company</Form.Text>
                            </div>
                        </div>

                        {/* form header */}
                        <div className="prerevmethodsheader">
                            Other factors, if any?
                        </div>

                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    What may impact the valuation of your business, such as early adopters, or customer feedback, or market access, or market maturity, or regulatory risk, or any other factors?
                                    <sup data-tip="Consider the additional factor(s) that may potentially increase or decrease your business valuation.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <div className="prerevmethodsrow">
                                    <table className="prerevmethodshistorytable">
                                        <thead>
                                            <tr>
                                                <th>Positive factors</th>
                                                <th>Negative factors</th>
                                            </tr>
                                        </thead>
                                        {/* form fields */}
                                        <tbody>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="positiveFactor1" value={filters.positiveFactor1} onChange={changeFilters} />
                                                </td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="negativeFactor1" value={filters.negativeFactor1} onChange={changeFilters} />
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="positiveFactor2" value={filters.positiveFactor2} onChange={changeFilters} />
                                                </td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="negativeFactor2" value={filters.negativeFactor2} onChange={changeFilters} />
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="positiveFactor3" value={filters.positiveFactor3} onChange={changeFilters} />
                                                </td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="negativeFactor3" value={filters.negativeFactor3} onChange={changeFilters} />
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="positiveFactor4" value={filters.positiveFactor4} onChange={changeFilters} />
                                                </td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="negativeFactor4" value={filters.negativeFactor4} onChange={changeFilters} />
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="positiveFactor5" value={filters.positiveFactor5} onChange={changeFilters} />
                                                </td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="negativeFactor5" value={filters.negativeFactor5} onChange={changeFilters} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Form.Text className="prerevmethodstextmuted">Consider the additional factor(s) that may potentially increase or decrease your business valuation</Form.Text>
                            </div>
                        </div>

                        {/* form header */}
                        <div className="prerevmethodsheader">
                            Comparables: gather the valuations of other pre-revenue companies
                        </div>

                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Preferably, gather the valuations of other pre-revenue companies in the same industry or categories and target market location as your business.
                                    <sup data-tip="Compare the similar businesses that are competing with your company, in the same industry or business verticals (categories) and target market location, at the same maturity and market level.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <div className="prerevmethodsrow">
                                    <table className="prerevmethodshistorytable">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Valuation</th>
                                            </tr>
                                        </thead>
                                        {/* form fields */}
                                        <tbody>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="name1" value={filters.name1} onChange={changeFilters} />
                                                </td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="valuation1" value={filters.valuation1} type="number" onChange={changeFilters} />
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="name2" value={filters.name2} onChange={changeFilters} />
                                                </td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="valuation2" value={filters.valuation2} type="number" onChange={changeFilters} />
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="name3" value={filters.name3} onChange={changeFilters} />
                                                </td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="valuation3" value={filters.valuation3} type="number" onChange={changeFilters} />
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="name4" value={filters.name4} onChange={changeFilters} />
                                                </td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="valuation4" value={filters.valuation4} type="number" onChange={changeFilters} />
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="name5" value={filters.name5} onChange={changeFilters} />
                                                </td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control name="valuation5" value={filters.valuation5} type="number" onChange={changeFilters} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Form.Text className="prerevmethodstextmuted">Identify the valuations of your comparable companies (at least 1 comparable company is required for the simulation to work)</Form.Text>
                            </div>
                        </div>

                        {/* form header */}
                        <div className="prerevmethodsheader">
                            Comparables: compare with other pre-revenue companies
                        </div>

                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Compare your business against the comparable companies with the following value drivers.
                                    <sup data-tip="Determine the comparison ratings by comparing your company against your comparable companies.">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <div className="prerevmethodsrow">
                                    <table className="prerevmethodshistorytable">
                                        <thead>
                                            <tr>
                                                <th>Value driver</th>
                                                <th>Comparison rating</th>
                                            </tr>
                                        </thead>
                                        {/* form fields */}
                                        <tbody>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">Strength of the founder(s) and management team: why you (team experience)?</td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control as="select" name="strengthOfFounders" value={filters.strengthOfFounders} onChange={changeFilters} >
                                                        <option></option>
                                                        <option>Extremely positive impact</option>
                                                        <option>Positive impact</option>
                                                        <option>Neutral impact</option>
                                                        <option>Negative impact</option>
                                                        <option>Extremely negative impact</option>
                                                    </Form.Control>
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">Size of the opportunity: how big is your market?</td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control as="select" name="sizeOfOpportunity" value={filters.sizeOfOpportunity} onChange={changeFilters} >
                                                        <option></option>
                                                        <option>Extremely positive impact</option>
                                                        <option>Positive impact</option>
                                                        <option>Neutral impact</option>
                                                        <option>Negative impact</option>
                                                        <option>Extremely negative impact</option>
                                                    </Form.Control>
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">Strength of the product and/or intellectual property: what is your competitive differentiation?</td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control as="select" name="strengthOfProduct" value={filters.strengthOfProduct} onChange={changeFilters} >
                                                        <option></option>
                                                        <option>Extremely positive impact</option>
                                                        <option>Positive impact</option>
                                                        <option>Neutral impact</option>
                                                        <option>Negative impact</option>
                                                        <option>Extremely negative impact</option>
                                                    </Form.Control>
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">Competitive environment: how competitive is your market landscape?</td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control as="select" name="competitiveEnvironment" value={filters.competitiveEnvironment} onChange={changeFilters} >
                                                        <option></option>
                                                        <option>Extremely positive impact</option>
                                                        <option>Positive impact</option>
                                                        <option>Neutral impact</option>
                                                        <option>Negative impact</option>
                                                        <option>Extremely negative impact</option>
                                                    </Form.Control>
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">Sales and marketing channels, and partnerships: what is your go-to-market strategy?</td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control as="select" name="salesAndMarketingChannels" value={filters.salesAndMarketingChannels} onChange={changeFilters} >
                                                        <option></option>
                                                        <option>Extremely positive impact</option>
                                                        <option>Positive impact</option>
                                                        <option>Neutral impact</option>
                                                        <option>Negative impact</option>
                                                        <option>Extremely negative impact</option>
                                                    </Form.Control>
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">Need for additional round(s) of financing: what is your financing strategy?</td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control as="select" name="needForAdditionalRounds" value={filters.needForAdditionalRounds} onChange={changeFilters} >
                                                        <option></option>
                                                        <option>Extremely positive impact</option>
                                                        <option>Positive impact</option>
                                                        <option>Neutral impact</option>
                                                        <option>Negative impact</option>
                                                        <option>Extremely negative impact</option>
                                                    </Form.Control>
                                                </td>
                                            </tr>
                                            <tr className="prerevmethodstablerow">
                                                <td className="prerevmethodstabledata">Other factors, if any?</td>
                                                <td className="prerevmethodstabledata">
                                                    <Form.Control as="select" name="otherFactors" value={filters.otherFactors} onChange={changeFilters} >
                                                        <option></option>
                                                        <option>Extremely positive impact</option>
                                                        <option>Positive impact</option>
                                                        <option>Neutral impact</option>
                                                        <option>Negative impact</option>
                                                        <option>Extremely negative impact</option>
                                                    </Form.Control>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Form.Text className="prerevmethodstextmuted">Select your comparison ratings according to the value drivers</Form.Text>
                            </div>
                        </div>

                        <div className="prerevmethodsdivbuttons">
                            {/* reset button */}
                            <button className="prerevmethodsresetbutton" onClick={resetFilters}>
                                <span>Clear</span>
                            </button>

                            {/* submit button */}
                            <Button className="prerevmethodssubmitbutton" onClick={submitFilters}>
                                <span>Simulate</span>
                            </Button>
                        </div>
                    </Form.Group>
                </Form>
            </div>

            {/* results */}
            <div className="col-12">
                <Card className="prerevmethodscard">
                    <div className="prerevmethods">
                        <table className="prerevmethodstable">
                            <tbody>
                                <tr>
                                    <td style={{ fontWeight: "bold", borderRight: "1px solid #dee2e6", borderBottom: "1px solid #dee2e6" }}>Average valuation of comparables</td>
                                    <td style={{ borderLeft: "1px solid #dee2e6", borderBottom: "1px solid #dee2e6" }}>USD $ {valuation.averageValuationOfComparables?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "bold", borderRight: "1px solid #dee2e6", borderTop: "1px solid #dee2e6" }}>Pre-money valuation</td>
                                    <td style={{ borderLeft: "1px solid #dee2e6", borderTop: "1px solid #dee2e6" }}>USD $ {valuation.preMoneyValuation?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>

            {/* notes */}
            <div className="col-12 prerevmethodsdivnotes">
                <span className="info-notes prerevmethodsnotes">
                    Note:
                    <ul>
                        <li>
                            First, measure your own company's valuation based on the scorecard's value drivers.
                        </li>
                        <li>
                            Second, identify the valuations of similar businesses that are competing with your company, in the same industry or business verticals (categories) and target market location, at the same maturity and market level to determine their average pre-revenue valuations.
                        </li>
                        <li>
                            Third, determine your pre-revenue valuations by comparing your business against the comparable companies with the scorecard's value drivers.
                        </li>
                        <li>
                            “The scorecard method compares the target company to typical startup ventures funded by angel investors and adjusts the median valuation of recently funded companies in the same industry or business verticals (categories), and target market location, to establish a pre-revenue valuation of the target company.” (<a href="https://www.angelcapitalassociation.org/blog/scorecard-valuation-methodology-rev-2019-establishing-the-valuation-of-pre-revenue-start-up-companies/" target="_blank" rel="noopener noreferrer">
                                Payne, 2019</a>)
                        </li>
                        <li>
                            <a href="https://www.investopedia.com/ask/answers/difference-between-premoney-and-postmoney/" target="_blank" rel="noopener noreferrer">
                                Pre-money valuation</a> refers to the value of a company not including external funding or the latest round of funding; best described as how much a startup might be worth before receiving any investments into the company.
                        </li>
                        <li>
                            The <a href="https://www.angelcapitalassociation.org/blog/scorecard-valuation-methodology-rev-2019-establishing-the-valuation-of-pre-revenue-start-up-companies/" target="_blank" rel="noopener noreferrer">
                                scorecard method</a> was created by Bill Payne in 2001, which uses subjective techniques to adjust the median valuation of comparable companies in the similar business industries or verticals, and target market location, to derive the pre-money valuation for a target company.
                        </li>
                        <li>
                            “As some businesses are easier to value than others, the uncertainty associated with value estimation is different for different businesses. It is not how precise a valuation is that determines its usefulness, but how precise the value is relative to the estimation of other investors.” (<a href="https://www.amazon.com/Damodaran-Valuation-Security-Investment-Corporate/dp/0471751219" target="_blank" rel="noopener noreferrer">
                                Damodaran, 2006</a>)
                        </li>
                    </ul>
                </span>
            </div>
        </Card.Body>
    );
}

export default ScorecardMethod;