import React, { useState } from 'react';
import SampleImage from '../../../assets/logo/roiquant - logo not found.png';
import ReactCountryFlag from 'react-country-flag';
import { S3Media } from '../../third-party-services';
import './companies-card.css';

//pop up card for shuttered companies and discontinued product
function CompaniesCard(props) {
    return (
        <div key={props.slug}>
            <div className="companies-cardrow">
                <div className="companies-products" >
                    <a href={'/company/' + props.slug}>
                        {!props.products
                            ? (<img src={SampleImage} alt={props.name} />)
                            : props.products.slice(0, 1).map((product) =>
                                product.images.slice(0, 1).map((image, index) =>
                                    <img src={S3Media + 'assets/photos/products/' + image.image} alt={props.name} key={index} />
                                ))
                        }
                    </a>
                </div>

                <div className="companies-bottom" >
                    <div className="companies-location">
                        {!props.city
                            ? ""
                            : (<a href="#">
                                <span className="companies-tag">
                                    {!props.city ? "" : props.city}</span>
                            </a>
                            )}
                        {!props.state
                            ? ""
                            : (<a href="#">
                                <span className="companies-tag">
                                    {!props.state ? "" : props.state}</span>
                            </a>
                            )}
                        {!props.country
                            ? ""
                            : (<a href="#">
                                <span className="companies-flagicon">
                                    {!props.countryCode
                                        ? ""
                                        : <ReactCountryFlag countryCode={props.countryCode} title={props.country} />
                                    }
                                </span>
                            </a>
                            )}
                    </div>

                    <a href={'/company/' + props.slug} >
                        <div className="companies-logobox">
                            {!props.logo
                                ? (<img src={SampleImage} alt={props.slug} className="companies-logo" />)
                                : (<img src={S3Media + 'assets/photos/logo/' + props.logo} alt={props.name} className="companies-logo" />)
                            }
                        </div>
                        <span className="companies-name">
                            <h3>{!props.tradeName ? "" : props.tradeName}</h3>
                        </span>
                        <span className="companies-name2">
                            <p>{!props.description ? "" : props.description}</p>
                        </span>
                    </a>

                    <div className="companies-reasonsection">
                        <div className="companies-fxlayout5">
                            <div className="companies-leftheader">
                                {!props.reasonsForFailure
                                    ? ""
                                    : <div style={{ textAlign: "left", fontSize: "13px", color: "grey" }}>
                                        {!props.reasonsForFailure.length ? "" : props.reasonsForFailure.length}
                                        {props.reasonsForFailure.length === 1 ? <span> reason</span> : <span> reasons </span>} for shutdown:
                                    </div>
                                }
                            </div>
                        </div>
                        {!props.reasonsForFailure
                            ? ""
                            : props.reasonsForFailure.map((reason, index) =>
                                <div className="companies-reason" key={index}>
                                    <a href="#" className="companies-reasonbutton glintanimation">{reason.label}</a>
                                </div>
                            )}
                    </div>

                    <div className="companies-tag">
                        {!props.industry
                            ? ""
                            : <a href="#" className="companies-industrybutton">{props.industry}</a>
                        }
                        {!props.categories
                            ? ""
                            : props.categories.slice(0, 2).map((category, index) =>
                                <a href="#" key={index} className="companies-categorybutton">{category}</a>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompaniesCard;
