import React from 'react';
import { Card } from 'react-bootstrap';
import '../data-contribution.css';

function ViewFinancials(props) {

    return (
        <>
            {props.profileType === "Finance"
                ? (<>
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">No. of funds</p>
                                    {!props?.numOfFunds
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.numOfFunds}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Funds</p>
                                    {!props?.funds
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">
                                            <ul>{props.funds.map((fund, index) =>
                                                !fund
                                                    ? ""
                                                    : (<li className="pitchdeckanswers" key={index}>{fund}</li>)
                                            )}</ul>
                                        </p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">No. of limited partners</p>
                                    {!props?.numOfLimitedPartners
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.numOfLimitedPartners}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Limited partners</p>
                                    {!props?.limitedPartners
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">
                                            <ul>{props.limitedPartners.map((partner, index) =>
                                                !partner
                                                    ? ""
                                                    : (<li className="pitchdeckanswers" key={index}>{partner}</li>)
                                            )}</ul>
                                        </p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Last financing date</p>
                                    {!props?.lastFinancingDate
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.lastFinancingDate}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Last financing round</p>
                                    {!props?.lastFinancingRound
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.lastFinancingRound}</p>)
                                    }
                                </div>
                            </div>

                            <div>
                                <p className="companydetailtitle">Portfolio companies</p>
                                {!props?.portfolioCompanies
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">
                                        <ul>{props.portfolioCompanies.map((company, index) =>
                                            !company
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{company}</li>)
                                        )}</ul>
                                    </p>)
                                }
                            </div>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Financial viability</p>
                                    {!props?.financialViability
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.financialViability}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Financing type</p>
                                    {!props?.financingType
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.financingType}</p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Fundraising target amount</p>
                                    {!props?.fundraisingTargetAmount
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.fundraisingTargetAmount}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Total amount raised</p>
                                    {!props?.totalAmountRaised
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.totalAmountRaised}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Remaining target amount</p>
                                    {!props?.remainingTargetAmount
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.remainingTargetAmount}</p>)
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>)
                : (<>
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Last financing date</p>
                                    {!props?.lastFinancingDate
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.lastFinancingDate}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Last financing round</p>
                                    {!props?.lastFinancingRound
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.lastFinancingRound}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">No. of funding round(s)</p>
                                    {!props?.numOfFundingRounds
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.numOfFundingRounds}</p>)
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Fundraising target amount</p>
                                    {!props?.fundraisingTargetAmount
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.fundraisingTargetAmount}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Total amount raised</p>
                                    {!props?.totalAmountRaised
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.totalAmountRaised}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Remaining target amount</p>
                                    {!props?.remainingTargetAmount
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.remainingTargetAmount}</p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Post-money valuation</p>
                                    {!props?.postMoneyValuation
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.postMoneyValuation}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Total revenue</p>
                                    {!props?.totalRevenue
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.totalRevenue}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Market capitalization</p>
                                    {!props?.marketCapitalization
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.marketCapitalization}</p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Financing type</p>
                                    {!props?.financingType
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.financingType}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Financial viability</p>
                                    {!props?.financialViability
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.financialViability}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">No. of investors</p>
                                    {!props?.numOfInvestors
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.numOfInvestors}</p>)
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </>)
            }

            {props.profileType === "Organization" || props.profileType === "Education" || props.profileType === "Government" || props.profileType === "Project" || props.profileType === "Product"
                ? (<Card style={{ marginTop: "40px" }}>
                    <Card.Body id="companyinformation">

                        <div>
                            <p className="companydetailtitle">Funding rounds</p>
                            {!props?.fundingRounds
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">
                                    <ol>{props.fundingRounds.map((point, index) =>
                                        !point
                                            ? ""
                                            : (<>
                                                <li className="pitchdeckanswers" key={index}>{point.fundingType}</li>
                                                <ul>
                                                    <li className="pitchdeckanswers" key={index}>Announced date: {point.announcedDate}</li>
                                                    <li className="pitchdeckanswers" key={index}>Closed-on date: {point.closedOnDate}</li>
                                                    <li className="pitchdeckanswers" key={index}>Target funding amount: {point.targetFundingAmount}</li>
                                                    <li className="pitchdeckanswers" key={index}>Total funding amount: {point.totalFundingAmount}</li>
                                                    <li className="pitchdeckanswers" key={index}>Remaining target amount: {point.remainingTargetAmount}</li>
                                                    <li className="pitchdeckanswers" key={index}>Investors: {point.investors}</li>
                                                </ul>
                                            </>)
                                    )}</ol>
                                </p>)
                            }
                        </div>

                        <div>
                            <p className="companydetailtitle">Portfolio companies</p>
                            {!props?.portfolioCompanies
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">
                                    <ul>{props.portfolioCompanies.map((company, index) =>
                                        !company
                                            ? ""
                                            : (<li className="pitchdeckanswers" key={index}>{company}</li>)
                                    )}</ul>
                                </p>)
                            }
                        </div>
                    </Card.Body>
                </Card>)
                : ""
            }

            {props.profileType === "Organization" || props.profileType === "Finance" || props.profileType === "Education" || props.profileType === "Government"
                ? (<Card style={{ marginTop: "40px" }}>
                    <Card.Body id="companyinformation">
                        <div className="companydetailrows">
                            <div className="pitchdeckdetaildiv">
                                <p className="companydetailtitle">No. of investments</p>
                                {!props?.numOfInvestments
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.numOfInvestments}</p>)
                                }
                            </div>

                            <div className="pitchdeckdetaildiv">
                                <p className="companydetailtitle">No. of lead investments</p>
                                {!props?.numOfLeadInvestments
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.numOfLeadInvestments}</p>)
                                }
                            </div>

                            <div className="pitchdeckdetaildiv">
                                <p className="companydetailtitle">No. of co-investments</p>
                                {!props?.numOfCoInvestments
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.numOfCoInvestments}</p>)
                                }
                            </div>
                        </div>

                        <div className="companydetailrows">
                            <div className="pitchdeckdetaildiv">
                                <p className="companydetailtitle">No. of follow-on investments</p>
                                {!props?.numOfFollowOnInvestments
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.numOfFollowOnInvestments}</p>)
                                }
                            </div>

                            <div className="pitchdeckdetaildiv">
                                <p className="companydetailtitle">No. of failed investments</p>
                                {!props?.numOfFailedInvestments
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.numOfFailedInvestments}</p>)
                                }
                            </div>
                        </div>

                        <div className="companydetailrows">
                            <div className="pitchdeckdetaildiv">
                                <p className="companydetailtitle">No. of exits</p>
                                {!props?.numOfExits
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.numOfExits}</p>)
                                }
                            </div>

                            <div className="pitchdeckdetaildiv">
                                <p className="companydetailtitle">No. of acquisitions</p>
                                {!props?.numOfAcquisitions
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.numOfAcquisitions}</p>)
                                }
                            </div>

                            <div className="pitchdeckdetaildiv">
                                <p className="companydetailtitle">No. of board seats taken</p>
                                {!props?.numOfBoardSeatsTaken
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.numOfBoardSeatsTaken}</p>)
                                }
                            </div>
                        </div>
                    </Card.Body>
                </Card>)
                : ""
            }

        </>
    );
}

export default ViewFinancials