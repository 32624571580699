export const ventureYearsStart = [
    { "key": "1983" },
    { "key": "1994" },
    { "key": "1995" },
    { "key": "1996" },
    { "key": "1997" },
    { "key": "1998" },
    { "key": "1999" },
    { "key": "2000" },
    { "key": "2001" },
    { "key": "2002" },
    { "key": "2003" },
    { "key": "2004" },
    { "key": "2005" },
    { "key": "2006" },
    { "key": "2007" },
    { "key": "2008" },
    { "key": "2009" },
    { "key": "2010" },
    { "key": "2011" },
    { "key": "2012" },
    { "key": "2013" },
    { "key": "2014" },
    { "key": "2015" },
    { "key": "2016" },
    { "key": "2017" },
    { "key": "2018" },
    { "key": "2019" },
    { "key": "2020" }
];