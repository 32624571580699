import React, { useState } from 'react';
import NavBar from '../../../../layout/navbar/navbar';
import { Container, Button } from 'react-bootstrap';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { FaEllipsisH } from 'react-icons/fa';
import './funding-benchmark.css';
import ViewVCFundingBenchmarks from './view-vc-funding-benchmarks';
import AnalyzeVCFundingBenchmarks from './analyze-vc-funding-benchmarks';

const FundingBenchmark = (props) => {

    const [viewMode, setViewMode] = useState(true);

    function switchMode() {
        setViewMode(!viewMode);
    }

    return (
        <>

            {/* navbar */}
            <NavBar />

            <div id="fundingBenchmark" className="basePage">

                <Container className="col-11" style={{ paddingBottom: "200px" }}>

                    <div className="pitchdeckheaderdiv">
                        {/* title */}
                        <div className="pitchdecktitlediv">
                            <br />
                            <span id="title-text">
                                VC funding benchmarks
                                <sup data-tip="">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </span>
                        </div>

                        {/* buttons */}
                        <div className="pitchdeckstatsdiv">
                            <Button onClick={switchMode} className="pitchdeckaddbutton glintanimation">
                                <span>{viewMode ? "Analyze" : "View table"}</span>
                            </Button>

                            <FaEllipsisH style={{ marginLeft: "20px" }} />
                        </div>
                    </div>

                    {
                        viewMode
                            ? <ViewVCFundingBenchmarks />
                            : <AnalyzeVCFundingBenchmarks />
                    }

                </Container>

            </div>
        </>
    )
}

export default FundingBenchmark;