import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './competitive-landscape.css';
import NavBar from '../../../layout/navbar/navbar';
import CreateEditMarketLandscapeCompetition from './create-edit-competitive-landscape/market-landscape-competition';
import SampleImage from '../../../../assets/logo/roiquant - logo not found.png';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { FaEllipsisH } from 'react-icons/fa';
import { useRealmApp } from '../../../RealmApp';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList, BsExclamationTriangle } from 'react-icons/bs'
import { S3Media } from '../../../third-party-services';

const EditCompetitiveLandscape = () => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // function to scroll to top of screen
    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    // get current user
    const { currentUser } = useRealmApp();

    // check if user is logged in
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // realm functions declaration
    const { getMyCompetitiveLandscapeAnalysis, processCompetitiveLandscapeTest } = currentUser.functions;

    // get document id
    const { documentId } = useParams();

    // use history to navigate page
    const history = useHistory();

    // hook for attributes
    const [state, dispatch] = useReducer(reducer, {
        competitiveLandscapeName: "",

        // how competitive is your market landscape
        industry: "",
        categories: [], // multi-select
        locations: [], // multi-select
        financingStatus: [], // multi-select
        operationalStatus: [], // multi-select
        labourForces: [], // multi-select
        similarityScores: [], // multi-select
    });

    // function to update attributes
    function reducer(state, action) {
        switch (action.type) {
            case 'update competitiveLandscapeName': { return { ...state, competitiveLandscapeName: action.value } }
            case 'initialize': { return action.value }

            // how competitive is your market landscape
            case 'update industry': { return { ...state, industry: action.value } }
            case 'update categories': return { ...state, categories: action.value }
            case 'update locations': return { ...state, locations: action.value }
            case 'update financingStatus': return { ...state, financingStatus: action.value }
            case 'update operationalStatus': return { ...state, operationalStatus: action.value }
            case 'update labourForces': return { ...state, labourForces: action.value }
            case 'update similarityScores': return { ...state, similarityScores: action.value }

            default: { return 'default'; }
        }
    }

    // hook for results
    const [results, setResults] = useState(
        {
            groups: "",
            statistics: ""
        }
    );

    // realm function to get selected data
    useEffect(() => {
        async function getAnalysis(id) {
            try {
                const response = await getMyCompetitiveLandscapeAnalysis(id);

                dispatch({ type: 'initialize', value: response.input });
                setResults(response.result);

                setAlert(false);
            } catch (err) {
                // To-do: Alert user an error was returned
                // console.error("Couldn't initialize the state. Error:", err);
            }
        }
        if (documentId !== undefined && documentId !== null) {
            getAnalysis(documentId);
        }

    }, []);

    // submit function
    async function handleSubmit() {

        // set results to empty
        setResults({
            groups: "",
            statistics: ""
        });

        // check if name is not empty
        if (!state.competitiveLandscapeName || state.competitiveLandscapeName === "") {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>Document must be named before analyzing.</p>
            </div>);
        }
        else {
            setAlert(true);
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Analyzing</p>
                    <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            try {
                // check if industry is not empty
                if (state.industry) {
                    let response = await processCompetitiveLandscapeTest({ ...state, documentId });

                    setAlert(true);
                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                        <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View results</a></p>
                    </div>);

                    // check response
                    if (response.statusCode === "MAX_ATTEMPTS_EXCEEDED") {
                        setAlert(true);
                        setMessage(<div style={{ paddingTop: "10px" }}>
                            <p style={{ color: "red" }}><BsExclamationTriangle className="alert-icon" /> Free trial is over</p>
                            <p style={{ marginLeft: "28px" }}>Sorry, you've exceeded 3 analyses per day for your Freemium Plan. Try again tomorrow or unlock unlimited usage with our subscription plans. <a href="https://www.roiquant.com/pricing" target="_blank" rel="noopener noreferrer">Compare plans now</a>.</p>
                        </div>);
                    }
                    else {
                        const data = response.body;
                        if (data.groups.length > 0) {
                            setResults(data);
                        }
                        else {
                            setAlert(true);
                            setMessage(<div style={{ paddingTop: "10px" }}>
                                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                <p style={{ marginLeft: "28px" }}>We found no match of similar companies within the selected business categories or verticals in this industry.</p>
                            </div>);
                        }
                    }
                }
            } catch (error) {
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>An unknown error has occurred with no exception details. Please try again.</p>
                </div>);
            }
        }
    }

    // function to direct user to index page
    function handleClose() {
        history.replace("/competitive-intel/competitive-landscape");
    }

    return (
        <>
            {/* navbar */}
            <NavBar />

            {/* alert message */}
            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <Container id="competitivelandscape" className="col-11">

                <div className="pitchdeckheaderdiv">
                    {/* title */}
                    <div className="pitchdecktitlediv">
                        <br />
                        <span className="pitchdeckcreatetext">
                            Title
                        </span>
                        <br />
                        <Form>
                            <Form.Group>
                                <Form.Control
                                    placeholder="Give this analysis a name"
                                    className="pitchdecktitlefield"
                                    value={state.competitiveLandscapeName}
                                    onChange={(e) => { dispatch({ 'type': 'update competitiveLandscapeName', 'value': e.target.value }) }}
                                />
                            </Form.Group>
                        </Form>
                    </div>

                    {/* buttons */}
                    <div className="pitchdeckstatsdiv">
                        <Button onClick={() => handleSubmit()} className="pitchdeckanalysebutton glintanimation">
                            <span>Analyze</span>
                        </Button>

                        <button onClick={() => handleClose()} className="pitchdeckclosebutton">
                            <span>Close</span>
                        </button>

                        <FaEllipsisH style={{ marginLeft: "20px" }} />
                    </div>
                </div>

                <div className="pitchdeckheaderdiv">
                    <div className="pitchdecktitlediv"></div>

                    {/* statistics table */}
                    <div className="competitivelandscapestatsdiv">
                        <table style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td className="table-data db-label" colSpan="2">Industry statistics</td>
                                </tr>
                                <tr>
                                    <td className="table-data db-header">Total shutdowns</td>
                                    <td className="table-data db-header">Total labor force</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-data db-data">{!results?.statistics?.totalShutdowns ? "0" : Math.round(results.statistics.totalShutdowns).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td className="table-data db-data">{!results?.statistics?.totalLabourForces ? "0" : Math.round(results.statistics.totalLabourForces).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* card */}
                <Accordion className="companyaccordion" defaultActiveKey="howCompetitiveIsYourMarketLandscape">
                    <CreateEditMarketLandscapeCompetition state={state} setState={dispatch} />
                </Accordion>

                {/* results */}
                <div className="competitivelandscapediv">
                    {/* industry cards - limited to 12 */}
                    <div className="competitive-landscape-cards competitivecardrow" style={{ paddingBottom: "200px" }}>
                        {results?.groups?.length > 0
                            ? results?.groups.slice(0, 12).map((group, index) =>
                                <div className="competitive-landscape-cards-row" key={index}>
                                    <div className="competitivecardcard competitivecardf competitivecardimgthumbnail competitivecardfxlayout1">
                                        <div className="competitive-landscape-card-header">
                                            <div className="competitive-landscape-card-title">{group._id}</div>

                                            <div className="competitive-landscape-card-ellipsis">
                                                {/* <span className="competitivelandscapecatscore">87.13%</span> */}
                                                <FaEllipsisH className="competitivelandscapeellipsisicon" style={{ marginLeft: "10px" }} />
                                            </div>
                                        </div>

                                        {/* companies statistics */}
                                        <div className="competitivelandscapecategorystats">
                                            <table style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <td className="competitivelandscapetablecategoryheader">
                                                            Total shutdowns
                                                        </td>
                                                        <td className="competitivelandscapetablecategoryheader">
                                                            Total labor force
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="competitivelandscapetablecategorydata">
                                                            {group.statuses.map((status) =>
                                                                !status.total
                                                                    ? "0"
                                                                    : Math.round(status.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            )}
                                                        </td>
                                                        <td className="competitivelandscapetablecategorydata">
                                                            {!group.labourForces
                                                                ? "0"
                                                                : Math.round(group.labourForces).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <hr className="competitivelandscapeline" />

                                        {/* companies logo - limited to 15 */}
                                        <div className="competitivecardcompanycard competitivelogorow competitivecardml1 competitivecardmr0">
                                            {group.articles.slice(0, 15).map((article, index) =>
                                                <div className="competitivelogocardrow" key={index}>
                                                    <div className="competitivelogof competitivecardimgthumbnail competitivelogofxlayout1">
                                                        {!article.logo
                                                            ? (<img src={SampleImage} alt={article.name} className="competitivelandscapelogo" />)
                                                            : (<img src={S3Media + 'assets/photos/logo/' + article.logo} alt={article.name} className="competitivelandscapelogo" />)
                                                        }
                                                    </div>
                                                    <p className="competitivelandscapename">{article.name}</p>
                                                </div>
                                            )}
                                        </div>

                                        {/* number of companies */}
                                        <div className="competitivelandscapeindex">
                                            {group.articles.length > 1
                                                ? group.articles.length > 15
                                                    ? (<span>15 of {group.articles.length} companies</span>)
                                                    : (<span>{group.articles.length} of {group.articles.length} companies</span>)
                                                : (<span>{group.articles.length} of {group.articles.length} company</span>)
                                            }
                                        </div>
                                    </div>
                                </div>)
                            : ""
                        }
                    </div>
                </div>
            </Container>
        </>
    )
}

export default EditCompetitiveLandscape;