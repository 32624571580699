import React, { useState, useEffect } from 'react';
import NavBar from '../../../layout/navbar/navbar';
import SampleImage from '../../../../assets/logo/roiquant - logo not found.png';
import CompaniesCard from '../../pop-up-card/companies-card';
import { Button, Form, Modal, Spinner, Alert } from 'react-bootstrap';
import { AiOutlineCloseCircle, AiOutlineExpandAlt } from 'react-icons/ai';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList } from 'react-icons/bs';
import { useRealmApp } from '../../../RealmApp';
import { S3Media } from '../../../third-party-services';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import '../../data/shutdown-data/shutdown-data.css';
import '../../admins/users.css';

const DataRoomPitchDecks = () => {

    // hook for alert component - show alert on page load
    const [alert, setAlert] = useState(true);

    // hook for alert message - show message on page load
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // function to scroll table
    function scrollToTop() {
        window.scrollTo(0, 0);

        document.getElementById('table-data').scrollIntoView();
    }

    // realm functions declaration
    const { currentUser, logInAnonymous, getUserTier } = useRealmApp();

    // declare const
    const browseHistory = useHistory();

    // function to go back to previous page
    function goToPreviousPath() {
        browseHistory.goBack();
    }

    // check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // table functions declaration
    const { getDataRoomPitchDecksTest, getCompanyDetailsForExpand } = currentUser.functions;

    // hook for argument to realm function
    const [argument, setArgument] = useState({
        numOfRows: 50,
        currentPage: 1,
        searchTerm: "",
        // filterCompanies: "Filter companies"
    });

    // hook for front-end pagination
    const [page, setPage] = useState({
        startNum: 1,
        endNum: 50,
        lastPage: 0
    });

    // hook for table data
    const [tableData, setTableData] = useState({
        results: [],
        total: 0,
        lastPage: 0
    });

    // fetch data on page load
    useEffect(async () => {
        try {
            if (!getUserTier() || getUserTier() < 2) {
                setTableData("");
            }
            else {
                let response = await getDataRoomPitchDecksTest(argument)
                setTableData(JSON.parse(response.body));

                page["lastPage"] = JSON.parse(response.body).lastPage;

                setPage({ ...page });
            }
        } catch (err) { }
        // close alert when data is received
        setAlert(false);
    }, []);

    // function to update hooks
    async function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // change current page value
        if (name.includes("Page")) {

            let currentPageName = "currentPage", currentPageValue,
                startNumName = "startNum", startNumValue,
                endNumName = "endNum", endNumValue;

            if (name === "firstPage") {
                currentPageValue = 1;
                startNumValue = 1;
                endNumValue = argument.numOfRows;
            }
            if (name === "prevPage") {
                currentPageValue = argument.currentPage - 1;
                startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
                endNumValue = currentPageValue * argument.numOfRows;
            }
            if (name === "nextPage") {
                currentPageValue = argument.currentPage + 1;
                startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
                endNumValue = currentPageValue * argument.numOfRows;
            }
            if (name === "lastPage") {
                currentPageValue = page.lastPage;
                startNumValue = ((currentPageValue - 1) * argument.numOfRows) + 1;
                endNumValue = tableData.total;
            }

            argument[currentPageName] = currentPageValue;
            page[startNumName] = startNumValue;
            page[endNumName] = endNumValue;

            setArgument({ ...argument });
            setPage({ ...page });
        }

        // if numOfRows is changed, then reset all values for pagination
        if (name === "numOfRows") {
            let lastPage = Math.ceil(tableData.total / value);

            argument["currentPage"] = 1;
            argument["numOfRows"] = value;
            page["startNum"] = 1;
            page["endNum"] = value;
            page["lastPage"] = lastPage;

            setArgument({ ...argument });
            setPage({ ...page });
        }

        // change search term value
        if (name === "searchTerm") {
            let argumentCopy = { ...argument };

            argumentCopy[name] = value;

            setArgument({ ...argumentCopy });
        }

        // only fetch data if numOfRows or currentPage is changed
        if (name.localeCompare("searchTerm") !== 0) {
            // show alert when data is fetching
            setAlert(true);
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                    <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            try {
                let response = await getDataRoomPitchDecksTest(argument);
                setTableData(JSON.parse(response.body));
                // console.log(tableData);

                // show alert when data is received
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                    <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
                </div>);
            } catch (err) { }
        }
    }

    // function to submit search term
    async function submitSearch(e) {
        e.preventDefault();

        let argumentCopy = { ...argument };

        argumentCopy["numOfRows"] = "50";
        argumentCopy["currentPage"] = "1";

        setArgument({ ...argumentCopy });

        // show alert when data is fetching
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            let response = await getDataRoomPitchDecksTest(argument)
            setTableData(JSON.parse(response.body));
            // console.log(tableData);

            if (JSON.parse(response.body).total == 0) {
                // show alert when data is received
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Sorry, your search query returned no results.</p>
                    <ul style={{ marginLeft: "3px" }}>
                        <li>Check for typing or spelling errors</li>
                        <li>Use a different search term and try a new search</li>
                        <li>We may not yet have the item you're searching for</li>
                    </ul>
                    <p style={{ marginLeft: "28px" }}>If you still can't find what you're looking for, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">share your feedback</a> to help us improve.</p>
                </div>);
            }
            else {
                // show alert when data is received
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                    <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
                </div>);
            }

            let pageCopy = { ...page };

            pageCopy["endNum"] = JSON.parse(response.body).results.length.toString();
            pageCopy["lastPage"] = JSON.parse(response.body).lastPage;

            setPage({ ...pageCopy });
        } catch (err) { }
    }

    // function to listen to keyboard
    function enterSearch(e) {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            submitSearch(e);
        }
    };

    // function to reset search term
    async function resetSearch(e) {
        e.preventDefault();

        argument["searchTerm"] = "";
        argument["currentPage"] = 1;

        setArgument({ ...argument });
        setTableData({
            results: [],
            total: 0,
            lastPage: 0
        });
        setPage({
            startNum: 1,
            endNum: 50,
            lastPage: 0
        });

        // show alert when data is fetching
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            let response = await getDataRoomPitchDecksTest(argument);
            setTableData(JSON.parse(response.body));
            // console.log(tableData);

            page["lastPage"] = JSON.parse(response.body).lastPage;

            setPage({ ...page });

            // show alert when data is received
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
            </div>);
        } catch (err) { }
    }

    // hook for expand card modal
    const [expandCard, setExpandCard] = useState(false);

    // hook for expand card data
    const [expandData, setExpandData] = useState();

    // function to fetch more details for expand modal
    async function getCompanyDetails(e) {

        // show alert message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await getCompanyDetailsForExpand(e);

            const array1 = response[0].companies;

            const array2 = response[0].details;

            let mergedArray;

            if (e.field == "competitors" || e.field == "similarEntities") {
                setExpandData(array2);
            }
            else {
                mergedArray = mergeArraysOfObjectsByKey(array1, array2, "name");

                setExpandData(mergedArray);
            }
            setExpandCard(true);
        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

        // close alert
        closeAlert();
    }

    // merge two arrays of objects together based on key
    function mergeArraysOfObjectsByKey(array1, array2, key) {
        const mergedArray = [];

        // iterate over the first array
        array1.forEach((object1) => {
            // find the corresponding object in the second array
            const object2 = array2.find((object2) => object2[key] === object1[key]);

            // if there is a corresponding object in the second array, merge the two objects
            if (object2) {
                const mergedObject = Object.assign({}, object1, object2);
                mergedArray.push(mergedObject);
            } else {
                // if there is no corresponding object in the second array, just add the first object to the merged array
                mergedArray.push(object1);
            }
        });

        return mergedArray;
    }

    // function to open expand modal
    const openExpandCard = (e) => {
        setExpandCard(true);
        setExpandData(e);
    }

    // function to close expand modal
    function closeExpandCard() {
        setExpandCard(false);
        setExpandData(null);
    }

    // function to show pop up card when user clicks logo 
    const [showCard, setShowCard] = useState(false);
    const [moreInfo, setMoreInfo] = useState([]);

    const handleData = (e) => {

        try {
            const newData = tableData.results.filter(companies => companies.tradeName === e)
                .map(companies =>
                    companies = {
                        "slug": companies.slug,
                        "name": companies.name,
                        "tradeName": companies.tradeName,
                        "description": companies.description,
                        "logo": companies.logo,
                        "reasonsForFailure": companies.reasonsForFailure,
                        "categories": companies.categories,
                        "location": companies.details.location,
                        "country": companies.details.location.country,
                        "countryCode": companies.details.location.countryCode,
                        "state": companies.details.location.state,
                        "city": companies.details.location.city,
                        "industry": companies.industry,
                        "products": companies.products
                    });

            setMoreInfo(newData);
            setShowCard(true);

        } catch (error) {

            const newData = tableData.results.filter(companies => companies.tradeName === e)
                .map(companies =>
                    companies = {
                        "slug": companies.slug,
                        "name": companies.name,
                        "tradeName": companies.tradeName,
                        "description": companies.description,
                        "logo": companies.logo,
                        "reasonsForFailure": companies.reasonsForFailure,
                        "categories": companies.categories,
                        "industry": companies.industry,
                        "products": companies.products
                    });

            setMoreInfo(newData);
            setShowCard(true);
        }
    }

    function hideCard() {
        setShowCard(false);
    }

    return (
        <>
            <NavBar />

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="shutdowndata">
                <div className="shutdowndataheaderdiv">
                    <div className="shutdowndatatitlediv">
                        <br />
                        <span className="shutdowndatatext">
                            Data room
                            <sup data-tip="">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </span>
                    </div>
                </div>

                {
                    !getUserTier() || getUserTier() < 2
                        ? (<Modal className="modal-div" show="true" onHide={goToPreviousPath} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton style={{ borderBottom: "none" }} />
                            <Modal.Body>
                                <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Get started with Standard Plan at USD $7 per month</p>

                                {/* <p style={{ textAlign: "left" }}>Analyze your commercial viability while building your business plan.</p> */}

                                <div style={{ textAlign: "center" }}>
                                    <a href="https://www.roiquant.com/pricing" target="_blank" rel="noopener noreferrer">
                                        <Button className="modal-compare glintanimation">Compare plans</Button>
                                    </a>
                                </div>

                                <hr style={{ margin: "1.5rem 0" }} />

                                <p style={{ fontWeight: "bold", marginBottom: "0" }}>Things to know:</p>

                                <ul className="modal-list">
                                    <li>Downgrade your subscription plan at any time</li>
                                    <li>Once payment is made, there is no refund</li>
                                    <li>If you downgrade or cancel, you will no longer have access to the features that are only available in the subscription plan which you downgraded or cancelled from</li>
                                </ul>

                                <div style={{ textAlign: "center" }}>
                                    <a href="#">
                                        <Button className="modal-product glintanimation">Learn more about the product</Button>
                                    </a>
                                </div>
                            </Modal.Body>
                        </Modal>)
                        : (<>
                            <div className="shutdowndatasearchdiv">
                                <div className="shutdowndatasearchbox">
                                    <Form>
                                        <Form.Group controlId="formSearch">
                                            <Form.Control className="inputbox" value={argument.searchTerm} type="name" name="searchTerm" placeholder="Search from data room" onChange={changeFilters} onKeyDown={enterSearch} />
                                        </Form.Group>
                                    </Form>
                                </div>

                                <div className="shutdowndatasearchbuttons">
                                    {
                                        argument.searchTerm == null || argument.searchTerm == ""
                                            ? <button className="shutdowndataresetbutton" disabled={true}>
                                                <span>Clear</span>
                                            </button>
                                            : <button className="shutdowndataresetbutton" onClick={resetSearch}>
                                                <span>Clear</span>
                                            </button>
                                    }

                                    {
                                        argument.searchTerm == null || argument.searchTerm == ""
                                            ? <Button className="shutdowndatasearchbutton" disabled>
                                                <span>Search</span>
                                            </Button>
                                            : <Button className="shutdowndatasearchbutton" onClick={submitSearch}>
                                                <span>Search</span>
                                            </Button>
                                    }
                                </div>

                                {/* <div className="shutdowndatadownloadbuttons">
                        <Form.Control className="inputbox" as="select" onChange={changeFilters} style={{ width: "auto", float: "right" }}>
                            <option>Filter companies</option>
                            <option>All</option>
                            <option>Active</option>
                            <option>Inactive</option>
                            <option>Unknown</option>
                        </Form.Control>
                    </div> */}
                            </div>

                            <div className="shutdowndatadiv">
                                <table className="userstable pitch-decks" id="table-data">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Image</th>
                                            <th>Name</th>

                                            {/* shuttered */}
                                            <th>Entity type</th>

                                            {/* new */}
                                            <th>Operational status</th>
                                            <th>Files</th>

                                            <th>Stage of development</th>
                                            <th>Description</th>
                                            <th>Industry</th>
                                            <th>Categories</th>
                                            <th>Location</th>
                                            <th>Ownership status</th>
                                            <th>Transactional model type</th>
                                            <th>Defensibility moats</th>
                                            <th>Product review</th>
                                            <th>Last financing round</th>
                                            <th>Total raised</th>
                                            <th>Post-money valuation</th>
                                            <th>Last financing date</th>
                                            <th>No. of funding round(s)</th>
                                            <th>No. of investors</th>
                                            <th>Investors</th>

                                            {/* shuttered */}
                                            <th>Total revenue</th>
                                            <th>Market capitalization</th>
                                            <th>No. of investments</th>
                                            <th>No. of acquisitions</th>
                                            <th>No. of board seats taken</th>

                                            <th>Competition</th>
                                            <th>Similar entities</th>
                                            <th>Management</th>
                                            <th>No. of employees</th>
                                            <th>Contact info</th>

                                            {/* shuttered */}
                                            <th>Company reputation</th>

                                            <th>Operational duration</th>

                                            {/* only for discontinued/shuttered */}
                                            <th>Shutdown date</th>
                                            <th>Reasons for shutdown</th>

                                            <th>Founded date</th>

                                            {/* shuttered */}
                                            <th>Legal name</th>
                                            <th>Former legal name(s)</th>
                                            <th>Ticker</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            tableData.total == 0
                                                ? (<tr>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>)
                                                : tableData.results.map((company, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}.</td>

                                                        {/* Image */}
                                                        {!company.logo
                                                            ? <td><img src={SampleImage} alt={company.tradeName} className="shutteredlogo" onClick={(e) => handleData(company.tradeName)} /></td>
                                                            : <td><img src={S3Media + 'assets/photos/logo/' + company.logo} alt={company.tradeName} className="shutteredlogo" onClick={(e) => handleData(company.tradeName)} /></td>
                                                        }

                                                        {/* Name */}
                                                        {!company.tradeName
                                                            ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td>
                                                                {!company.slug
                                                                    ? <p className="detailmediumdata">{company.tradeName}</p>
                                                                    : <p className="detailmediumdata">
                                                                        {/* dynamically pass slug of element that is clicked */}
                                                                        <Link to={`/company/${company.slug}`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                                            {company.tradeName}
                                                                        </Link>
                                                                    </p>
                                                                }
                                                            </td>
                                                        }

                                                        {/* shuttered */}
                                                        {/* Entity type */}
                                                        {!company.entityType
                                                            ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmediumdata">{company.entityType}</p></td>
                                                        }

                                                        {/* Operational status */}
                                                        {!company.isActive
                                                            ? <td><p className="detailmediumdata">Unknown</p></td>
                                                            : company.isActive === true
                                                                ? <td><p className="detailmediumdata">Active</p></td>
                                                                : company.isActive === false
                                                                    ? <td><p className="detailmediumdata">Inactive</p></td>
                                                                    : <td><p className="detailmediumdata">Unknown</p></td>
                                                        }

                                                        {/* Files */}
                                                        <td><p className="detailshortdata">
                                                            <Link to={`/company/${company.slug}#data-room`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                                Pitch deck
                                                            </Link>
                                                        </p></td>

                                                        {/* Stage of development */}
                                                        {!company.stageOfDevelopment
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmldata" style={{ textAlign: "center" }}>{company.stageOfDevelopment}</p></td>
                                                        }

                                                        {/* Description */}
                                                        {!company.description
                                                            ? <td><p className="detaillongdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td>
                                                                <Button className="expand-button" onClick={() => openExpandCard(company.description)}><AiOutlineExpandAlt /></Button>
                                                                <p className="detaillongdata">{company.description}</p>
                                                            </td>
                                                        }

                                                        {/* Industry */}
                                                        {!company.industry
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmldata">{company.industry}</p></td>
                                                        }

                                                        {/* Categories */}
                                                        {!company.categories
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td>
                                                                <Button className="expand-button" onClick={() => openExpandCard(company.categories.join(", "))}><AiOutlineExpandAlt /></Button>
                                                                <p className="detailmldata detailcutdata">{company.categories.join(", ")}</p>
                                                            </td>
                                                        }

                                                        {/* Location */}
                                                        {!company.details.location
                                                            ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmediumdata">{!company.details.location.city ? "" : <span>{company.details.location.city},</span>} {!company.details.location.state ? "" : <span>{company.details.location.state},</span>} {!company.details.location.country ? "" : <span>{company.details.location.country}</span>}</p></td>
                                                        }

                                                        {/* Ownership status */}
                                                        <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>

                                                        {/* Transactional model type */}
                                                        {!company.businessModelType
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmldata" style={{ textAlign: "center" }}>{company.businessModelType}</p></td>
                                                        }

                                                        {/* Defensibility moats */}
                                                        {!company.defensibilityMoat
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td>
                                                                <Button className="expand-button" onClick={() => openExpandCard(company.defensibilityMoat.join(", "))}><AiOutlineExpandAlt /></Button>
                                                                <p className="detailmldata detailcutdata">{company.defensibilityMoat.join(", ")}</p>
                                                            </td>
                                                        }

                                                        {/* Product review */}
                                                        <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>

                                                        {/* Last financing round */}
                                                        {!company.details.lastFinancingRound
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmldata">{company.details.lastFinancingRound}</p></td>
                                                        }

                                                        {/* Total raised */}
                                                        {!company.details.totalInvestment
                                                            ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{company.details.totalInvestment.currency} {company.details.totalInvestment.total}{company.details.totalInvestment.symbol}</p></td>
                                                        }

                                                        {/* Post-money valuation */}
                                                        <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>

                                                        {/* Last financing date */}
                                                        {!company.details.lastFinancingDate
                                                            ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{company.details.lastFinancingDate}</p></td>
                                                        }

                                                        {/* No. of funding round(s) */}
                                                        {!company.details.numOfFundingRounds
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmldata" style={{ textAlign: "center" }}>{company.details.numOfFundingRounds}</p></td>
                                                        }

                                                        {/* No. of investors */}
                                                        {!company.details.numOfInvestors
                                                            ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{company.details.numOfInvestors.total}</p></td>
                                                        }

                                                        {/* Investors */}
                                                        {!company.investors
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td>
                                                                <Button className="expand-button" onClick={() => getCompanyDetails({ slug: company.slug, field: "investors", table: "data_room" })}><AiOutlineExpandAlt /></Button>
                                                                <div className="detailmldata detailcutdata">
                                                                    <ol className="detailproductlist">{company.investors.map((investor, index) =>
                                                                        !investor
                                                                            ? ""
                                                                            : (<li key={index} className="detailbodydata">{investor.name}</li>)
                                                                    )}
                                                                    </ol>
                                                                </div>
                                                            </td>
                                                        }

                                                        {/* shuttered */}
                                                        {/* Total revenue */}
                                                        <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td>

                                                        {/* Market capitalization */}
                                                        <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>

                                                        {/* No. of investments */}
                                                        <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td>

                                                        {/* No. of acquisitions */}
                                                        {!company.details.numOfAcquisitions
                                                            ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailshortdata">{company.details.numOfAcquisitions}</p></td>
                                                        }

                                                        {/* No. of board seats taken */}
                                                        <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>

                                                        {/* Competition */}
                                                        {!company.competitors
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td>
                                                                <Button className="expand-button" onClick={() => getCompanyDetails({ slug: company.slug, field: "competitors", table: "data_room" })}><AiOutlineExpandAlt /></Button>
                                                                <div className="detailmldata detailcutdata">
                                                                    <ol className="detailproductlist">{company.competitors.map((competitor, index) =>
                                                                        !competitor
                                                                            ? ""
                                                                            : (<li key={index} className="detailbodydata">{competitor.name}</li>)
                                                                    )}
                                                                    </ol>
                                                                </div>
                                                            </td>
                                                        }

                                                        {/* Similar entities */}
                                                        {!company.similarEntities
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td>
                                                                <Button className="expand-button" onClick={() => getCompanyDetails({ slug: company.slug, field: "similarEntities", table: "data_room" })}><AiOutlineExpandAlt /></Button>
                                                                <div className="detailmldata detailcutdata">
                                                                    <ol className="detailproductlist">{company.similarEntities.map((similar, index) =>
                                                                        !similar
                                                                            ? ""
                                                                            : (<li key={index} className="detailbodydata">{similar.name}</li>)
                                                                    )}
                                                                    </ol>
                                                                </div>
                                                            </td>
                                                        }

                                                        {/* Management */}
                                                        {!company.management
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td>
                                                                <Button className="expand-button" onClick={() => openExpandCard(company.management)}><AiOutlineExpandAlt /></Button>
                                                                <div className="detailmldata detailcutdata">
                                                                    <ol className="detailproductlist">{company.management.map((founder, index) =>
                                                                        !founder
                                                                            ? ""
                                                                            : (<li key={index} className="detailbodydata">{founder.name}</li>)
                                                                    )}
                                                                    </ol>
                                                                </div>
                                                            </td>
                                                        }

                                                        {/* No. of employees */}
                                                        {!company.details.numOfEmployees
                                                            ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{company.details.numOfEmployees.min} - {company.details.numOfEmployees.max}</p></td>
                                                        }

                                                        {/* Contact info */}
                                                        {!company.details.phoneNumber
                                                            ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailshortdata">{company.details.phoneNumber}</p></td>
                                                        }

                                                        {/* shuttered */}
                                                        {/* Company reputation */}
                                                        <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>

                                                        {/* Operational duration */}
                                                        {!company.details.year
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : company.details.year.lifespan !== 1
                                                                ? <td><p className="detailmldata" style={{ textAlign: "center" }}>{company.details.year.lifespan} years</p></td>
                                                                : <td><p className="detailmldata" style={{ textAlign: "center" }}>{company.details.year.lifespan} year</p></td>
                                                        }

                                                        {/* only for discontinued/shuttered */}
                                                        {/* Shutdown date */}
                                                        {!company.details.date
                                                            ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmediumdata" style={{ textAlign: "center" }}>{company.details.date.ended}</p></td>
                                                        }

                                                        {/* only for discontinued/shuttered */}
                                                        {/* Reasons for discontinuation */}
                                                        {!company.reasonsForFailure
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td>
                                                                <Button className="expand-button" onClick={() => openExpandCard(company.reasonsForFailure)}><AiOutlineExpandAlt /></Button>
                                                                <div className="detailmldata detailcutdata">
                                                                    <ol className="detailproductlist">{company.reasonsForFailure.map((reason, index) =>
                                                                        !reason
                                                                            ? ""
                                                                            : (<li key={index} className="detailbodydata">{reason.label}</li>)
                                                                    )}
                                                                    </ol>
                                                                </div>
                                                            </td>
                                                        }

                                                        {/* Founded date */}
                                                        {!company.details.date
                                                            ? <td><p className="detailshortdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailshortdata">{company.details.date.started}</p></td>
                                                        }

                                                        {/* shuttered */}
                                                        {/* Legal name */}
                                                        {!company.legalName
                                                            ? <td><p className="detailmediumdata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td><p className="detailmediumdata">{company.legalName}</p></td>
                                                        }

                                                        {/* Former legal name(s) */}
                                                        {!company.former_legalName
                                                            ? <td><p className="detailmldata" style={{ textAlign: "center" }}>-</p></td>
                                                            : <td>
                                                                <Button className="expand-button" onClick={() => openExpandCard(company.former_legalName.join(", "))}><AiOutlineExpandAlt /></Button>
                                                                <p className="detailmldata detailcutdata">{company.former_legalName.join(", ")}</p>
                                                            </td>
                                                        }

                                                        {/* Ticker */}
                                                        <td><p className="detailshortdata">-</p></td>
                                                    </tr>
                                                )
                                        }

                                    </tbody>
                                </table>
                            </div>

                            <div className="shutdowndatafilters">
                                <div className="filterrownumber">
                                    {argument.searchTerm === ""
                                        ? (<p>{page.startNum}-{page.endNum} of {tableData.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>)
                                        : (<p>1-{tableData.results.length} of {tableData.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>)
                                    }
                                </div>

                                <div className="filterpagination">
                                    {
                                        argument.currentPage != 1
                                            ? (<Button name="firstPage" onClick={changeFilters} className="filterpage">First</Button>)
                                            : ""
                                    }
                                    {
                                        argument.currentPage > 1
                                            ? (<Button name="prevPage" onClick={changeFilters} className="filterpage">Prev</Button>)
                                            : ""
                                    }
                                    {
                                        argument.currentPage < page.lastPage
                                            ? (<Button name="nextPage" onClick={changeFilters} className="filterpage">Next</Button>)
                                            : ""
                                    }
                                    {
                                        argument.currentPage != page.lastPage
                                            ? (<Button name="lastPage" onClick={changeFilters} className="filterpage">Last</Button>)
                                            : ""
                                    }
                                </div>

                                <div className="filterrowshow">
                                    <p>Show rows: </p>
                                    <Form.Control value={argument.numOfRows} as="select" name="numOfRows" onChange={changeFilters} className="filterrowselect">
                                        <option>50</option>
                                        {argument.searchTerm === ""
                                            ? (<>
                                                <option>100</option>
                                                <option>500</option>
                                            </>)
                                            : ""
                                        }
                                    </Form.Control>
                                </div>
                            </div>
                        </>)
                }
            </div>

            {/* pop up modal for more row's info */}
            <Modal show={showCard} onHide={hideCard} backdrop="static" fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="companies-header close" closeButton />

                <Modal.Body className="companies-body">

                    {moreInfo.map((companies) =>
                        <div key={companies.slug}>
                            <CompaniesCard
                                slug={companies.slug}
                                name={companies.name}
                                tradeName={companies.tradeName}
                                description={companies.description}
                                logo={companies.logo}
                                reasonsForFailure={companies.reasonsForFailure}
                                categories={companies.categories}
                                location={companies.location}
                                country={companies.country}
                                countryCode={companies.countryCode}
                                state={companies.state}
                                city={companies.city}
                                industry={companies.industry}
                                products={companies.products}
                            />
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            {/* expand card modal */}
            <Modal className="modal-div" show={expandCard} onHide={closeExpandCard} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <div style={{ border: "1px solid #dee2e6", borderRadius: "0.35rem", height: "80vh", overflow: "auto" }}>
                        {Array.isArray(expandData)
                            ? <ol style={{ paddingTop: "1.5em", paddingRight: "1.5em", paddingBottom: "1.5em", margin: "0 !important" }}>
                                {expandData.map((element, index) =>
                                    !element
                                        ? ""
                                        : element.label
                                            ? (<li key={index}>{element.label}{!element.reason ? "" : ": " + element.reason}</li>)
                                            : element.name
                                                ? (<li key={index}>
                                                    {
                                                        !element.slug
                                                            ? element.name
                                                            : (<Link to={`/company/${element.slug}`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                                {element.name}
                                                            </Link>)
                                                    }: {
                                                        !element.description
                                                            ? "No data available at this time."
                                                            : element.description
                                                    }
                                                </li>)
                                                : ""
                                )}
                            </ol>
                            : <p style={{ padding: "1.5em", margin: "0 !important" }}>{expandData}</p>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );

}

export default DataRoomPitchDecks;