import React, { useState } from 'react';
import { Card, Modal, Button, Alert, Spinner } from 'react-bootstrap';
import { pdfjs, Document, Page } from 'react-pdf';
import { AiOutlineDownload, AiOutlineCloseCircle, AiOutlineCheckSquare } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { S3Media } from '../../third-party-services';
import { useRealmApp } from '../../RealmApp';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PitchDecksView(props) {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState();

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    // hook for pdf name
    const [filename, setFilename] = useState();

    // function to reset filename
    function resetFilename() {
        setFilename();
    }

    // hook for viewer modal
    const [viewerModal, setViewerModal] = useState(false);

    // function to open archive modal
    const openViewerModal = (e) => {
        setFilename(e);
        setViewerModal(true);
    }

    // function to close archive modal
    function closeViewerModal() {
        resetFilename();
        setViewerModal(false);
    }

    const { currentUser } = useRealmApp();

    const { getDataRoomPresignedUrl } = currentUser.functions;

    async function downloadFile(e) {
        let url;

        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Downloading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're downloading your document.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await getDataRoomPresignedUrl({ type: "download" ,fileName: e, mode: "download pitch deck" });
            url = response;
        }
        catch (error) {
            setAlert(true);

            if (error.name === "Error") {
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Bad internet issues or server connection errors</p>
                    <p style={{ marginLeft: "28px", fontSize: "14px" }}>Sorry, there seems to be poor internet connection or interrupted connectivity issues or server connection errors. Should the issues persist, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                </div>);
                return;
                // if (error.message.includes("forbidden")) {
                //     setMessage(<div></div>);
                // }
                // if (error.message.includes("failed request")) {
                // }
            }
        }

        window.open(url, "_blank");
        setMessage(<div style={{ paddingTop: "10px" }}>
            <p style={{ color: "#5aa469" }}><AiOutlineCheckSquare className="alert-icon" /> Downloaded</p>
            <p style={{ marginLeft: "28px" }}>Your document is downloaded.</p>
        </div>);
    }

    return (
        <>

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <Card style={{ marginTop: "40px" }}>
                <Card.Body id="companyinformation" style={{ borderRadius: "0.25rem" }}>
                    <div>
                        <table className="companydetailtable pitch-decks">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Name</th>
                                    <th>File type</th>
                                    <th>Date uploaded</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.pitchdecks.map((pitchDeck, index) =>
                                    <tr>
                                        <td>{index + 1}.</td>
                                        <td>{pitchDeck.s3_show == true
                                            ? <a className="shutdowndatalink" onClick={() => openViewerModal(pitchDeck.s3_filename)}>{props.name} pitch deck</a>
                                            : props.name + "pitch deck"
                                        }</td>
                                        <td>Pitch deck</td>
                                        <td>{pitchDeck.filingDate}</td>
                                        <td style={{ textAlign: "center" }}>
                                            <Button className="download-button" onClick={() => downloadFile(pitchDeck.s3_filename)}><AiOutlineDownload /></Button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
            </Card>

            <Modal className="modal-div" show={viewerModal} onHide={closeViewerModal} backdrop="static" size="lg" fullscreen="true" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <div className="pitch-deck-viewer">
                        <Document file={S3Media + 'assets/data-room/' + filename} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                        </Document>

                        <div className="pitch-deck-pagination">
                            <p>Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</p>
                            |
                            <button className={pageNumber <= 1 ? "pitch-deck-page-button-disabled" : "pitch-deck-page-button"} type="button" disabled={pageNumber <= 1} onClick={previousPage}>Previous</button>
                            <button className={pageNumber >= numPages ? "pitch-deck-page-button-disabled" : "pitch-deck-page-button"} type="button" disabled={pageNumber >= numPages} onClick={nextPage}>Next</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PitchDecksView;