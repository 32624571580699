export const genders = [
    { "key": "Male" },
    { "key": "Female" },
    { "key": "Corporations" }
];

export const profileGenders = [
    {"key": "Male"},
    {"key": "Female"},
    {"key": "Prefer not to say"}
];