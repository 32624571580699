import React from 'react';
import Flipper from '../../../assets/profile/flipper.png';
import Next from '../../../assets/profile/next.png';
import NavBar from '../../layout/navbar/navbar';
import { Container } from 'react-bootstrap';
import { useRealmApp } from '../../RealmApp';
import './profile.css';

const Profile = () => {

    // variable to fetch realm function
    const { getUserDetails } = useRealmApp()

    // variable using realm function
    const userDetails = getUserDetails();

    return (
        <>
            {/* navbar */}
            <NavBar />

            {/* profile */}
            <div id="profile">
                <Container className="col-6" style={{ paddingTop: "50px", paddingBottom: "200px" }}>
                    <div className="profilewidget">
                        <div className="profilebody">

                            {/* title and info note */}
                            <p className="profiletext">Profile information</p>

                            <p style={{ textAlign: "left", fontSize: "18px" }}>
                                Some info may be visible to other people using roiquant services.<br /><br />
                            </p>

                            {/* table */}
                            <div>
                                <table className="profiletablenoborder">

                                    {/* photo */}
                                    <tr className="profiletablerow">
                                        <td className="profiletabledata">PHOTO</td>
                                        <td className="profiletabledata profiletablecolmiddle">A photo helps to personalise your account</td>
                                        <td className="profiletabledata">
                                            <span style={{ float: "right" }}>
                                                <img style={{ width: "100%" }} src={Flipper} alt="..." />
                                            </span>
                                        </td>
                                    </tr>

                                    {/* name */}
                                    <tr className="profiletablerow">
                                        <td className="profiletabledata">NAME</td>
                                        {/* display name */}
                                        <td className="profiletabledata profiletablecolmiddle">{!userDetails.name ? "Flipper AI Bot" : userDetails.name}</td>
                                        <td className="profiletabledata">
                                            <div style={{ float: "right", marginRight: "10%" }}>
                                                {/* edit button */}
                                                <a href="/edit-profile"><img height="20px" alt="" src={Next} /></a>
                                            </div>
                                        </td>
                                    </tr>

                                    {/* <tr className="profiletablerow">
                                        <td className="profiletabledata">FIRST NAME</td>
                                        <td className="profiletabledata profiletablecolmiddle">{!userDetails.firstname ? "Flipper" : userDetails.firstname}</td>
                                        <td className="profiletabledata"></td>
                                    </tr>

                                    <tr className="profiletablerow">
                                        <td className="profiletabledata">LAST NAME</td>
                                        <td className="profiletabledata profiletablecolmiddle">{!userDetails.lastname ? "AI Bot" : userDetails.lastname}</td>
                                        <td className="profiletabledata"></td>
                                    </tr>

                                    <tr className="profiletablerow">
                                        <td className="profiletabledata">USERNAME</td>
                                        <td className="profiletabledata profiletablecolmiddle">{!userDetails.username ? "flipper.ai.bot" : userDetails.username}</td>
                                        <td className="profiletabledata"></td>
                                    </tr> */}

                                    {/* birthday */}
                                    <tr className="profiletablerow">
                                        <td className="profiletabledata">BIRTHDAY</td>
                                        {/* display birthday */}
                                        <td className="profiletabledata profiletablecolmiddle">{!userDetails.dob ? "13th August 2018" : userDetails.dob}</td>
                                        <td className="profiletabledata">
                                            <div style={{ float: "right", marginRight: "10%" }}>
                                                {/* edit button */}
                                                <a href="/edit-profile"><img height="20px" alt="" src={Next} /></a>
                                            </div>
                                        </td>
                                        {/* <td className="profiletabledata"></td> */}
                                    </tr>

                                    {/* gender */}
                                    <tr className="profiletablerow">
                                        <td className="profiletabledata">GENDER</td>
                                        {/* display gender */}
                                        <td className="profiletabledata profiletablecolmiddle">{!userDetails.gender ? "Male" : userDetails.gender}</td>
                                        <td className="profiletabledata">
                                            <div style={{ float: "right", marginRight: "10%" }}>
                                                {/* edit button */}
                                                <a href="/edit-profile"><img height="20px" alt="" src={Next} /></a>
                                            </div>
                                        </td>
                                        {/* <td className="profiletabledata"></td> */}
                                    </tr>

                                    {/* <tr className="profiletablerow">
                                        <td className="profiletabledata">DESIGNATION</td>
                                        <td className="profiletabledata profiletablecolmiddle">{!userDetails.knowYouBetter ? "-" : userDetails.knowYouBetter}</td>
                                        <td className="profiletabledata"></td>
                                    </tr>

                                    <tr className="profiletablerow">
                                        <td className="profiletabledata">HEARD OF US FROM WHERE</td>
                                        <td className="profiletabledata profiletablecolmiddle">{!userDetails.hearAboutUs ? "-" : userDetails.hearAboutUs}</td>
                                        <td className="profiletabledata"></td>
                                    </tr> */}

                                    {/* date last changed */}
                                    <tr className="profiletablerow">
                                        <td className="profiletabledata">PASSWORD</td>
                                        <td className="profiletabledata profiletablecolmiddle">**********<br />Last changed January 1st, 2020</td>
                                        <td className="profiletabledata"></td>
                                    </tr>
                                </table>
                            </div>

                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Profile;