import React, { useState, useEffect } from 'react';
import '../tools/founders/pitch-deck-analysis/pitch-deck-analysis.css';
import NavBar from '../../layout/navbar/navbar';
import GeneralInformation from './general-information';
import Business from './business';
import Financials from './financials';
import Management from './management';
import { Container, Tabs, Tab, Alert, Spinner, Button } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { useRealmApp } from '../../RealmApp';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { S3Media } from '../../third-party-services'

const Investor = () => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // initialise history and location
    const history = useHistory();
    const location = useLocation();

    // set state from url hash
    const [selectedTab, setSelectedTab] = useState(location.hash.substring(1));

    const handleSelect = (e) => {
        // set state
        setSelectedTab(e);
        // update url
        history.replace({
            hash: `${e}`
        });
    }

    const { currentUser, logInAnonymous, getUserTier } = useRealmApp();

    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // mongoDB realm function
    const { getInvestorDetail } = currentUser.functions;

    // get slug
    const { slug } = useParams();

    // company object
    const [company, setCompany] = useState();

    // fetch company data
    useEffect(async () => {

        setAlert(true);

        let response = await getInvestorDetail(slug)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
            });

        setAlert(false);
        setCompany(response);
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar />
            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="pitchdeck">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>

                    <div className="companyheader" style={{ paddingTop: "80px" }}>
                        <div className="companylogodiv">
                            <img src={S3Media + 'assets/photos/investors/' + company?.logo} alt={company?.name} className="companylogo" />
                        </div>
                        <div className="companyheaderright">
                            <h3>{company?.name}</h3>
                            <p>{company?.description}</p>
                        </div>
                    </div>

                    <div>
                        <Tabs className="companytabs" defaultActiveKey="generalInformation">
                            <Tab eventKey="generalInformation" title="Profile">
                                <GeneralInformation
                                    // profileType
                                    entityType={company?.entityType}
                                    investorTypes={company?.investorType}
                                    entityName={company?.name}
                                    legalName={company?.legalName}
                                    formerLegalName={company?.former_legalName}
                                    otherBusinessName={company?.otherBusinessNames} // New attribute
                                    currentWork={{ currentDesignation: company?.primaryJobTitle, currentOrganization: company?.primaryOrganization }}
                                    city={company?.location.city}
                                    state={company?.location.state}
                                    country={company?.location.country}
                                    // industry
                                    categories={company?.categories}
                                    // education
                                    // previousProfileType
                                    previousWork={company?.experience}
                                    // previousCity
                                    // previousState
                                    // previousCountry
                                    shortDescription={company?.description}
                                    longDescription={company?.f_description}
                                    // ownershipStatus
                                    operationalStatus={company?.status}
                                    foundedDate={company?.foundedDate}
                                    // operationalDuration
                                    // shutdownDate
                                    tickerSymbol={company?.stockSymbol}
                                    email={company?.contact?.emails}
                                    telephone={company?.contact?.phoneNumber}
                                    website={company?.websiteAddresses}
                                    socialMedia={company?.socialMedia}
                                    gender={company?.gender}
                                // firstTimeFounder
                                // technicalFounder
                                />
                            </Tab>

                            <Tab eventKey="business" title="Business">
                                <Business
                                    // shortInvestmentThesis
                                    // longInvestmentThesis
                                    // stageOfDevelopment
                                    // transactionalModelType
                                    // defensibilityMoats
                                    // assetsUnderManagement
                                    investmentRange={company?.investmentRange}
                                    sweetSpot={company?.sweetSpot}
                                    preferredInvestmentStages={company?.investmentStage}
                                    preferredInvestmentFocus={company?.investmentFocus}
                                    preferredInvestmentGeography={company?.investmentGeography}
                                    stagesInvestedIn={company?.investmentTrackRecord} // New attribute
                                // fundProduct
                                // product
                                // supportSustainableDevelopmentGoals
                                // markets
                                // totalAddressableMarket
                                // serviceableAvailableMarket
                                // serviceableObtainableMarket
                                // customerPain
                                // competitiveDifferentiation
                                // directCompetitors
                                />
                            </Tab>

                            <Tab eventKey="management" title="Management">
                                <Management
                                    management={company?.management} // Fetch managementEmail & managementMobilePhone from this object
                                    // numOfPartners
                                    // partners
                                    numOfEmployees={company?.numOfEmployees}
                                    numOfBoardMembers={company?.numOfBoardMembers}
                                    boardMembers={company?.boardMembers}
                                    // managementWebsite
                                    // managementBlog
                                    // managementSocialMedia
                                    founders={company?.founders} // New attribute
                                />
                            </Tab>

                            <Tab eventKey="financials" title="Financials">
                                <Financials
                                    numOfFunds={company?.numOfFunds}
                                    // funds
                                    // numOfLimitedPartners
                                    // limitedPartners
                                    // lastFinancingDate // To be cleaned
                                    // lastFinancingRound // To be cleaned
                                    // lastFinancingAmount // To be cleaned // New attribute
                                    // financialViability
                                    // financingType
                                    // fundraisingTargetAmount
                                    totalAmountRaised={company?.totalAmountRaised}
                                    // remainingTargetAmount
                                    // numOfFundingRounds
                                    // postMoneyValuation
                                    // totalRevenue
                                    // marketCapitalization
                                    // numOfInvestors
                                    // fundingRounds
                                    portfolioCompanies={company?.portfolioCompanies}
                                    shutteredPortfolioCompanies={company?.shutteredPortfolioCompanies} // New attribute
                                    numOfInvestments={company?.numOfInvestments}
                                    numOfLeadInvestments={company?.numOfLeadInvestments}
                                    numOfCoInvestments={company?.numOfCoInvestments}
                                    numOfFollowOnInvestments={company?.numOfFollowOnInvestments}
                                    numOfFailedInvestments={company?.numOfFailedInvestments}
                                    numOfExits={company?.numOfExits}
                                    numOfAcquisitions={company?.numOfAcquisitions}
                                    // numOfBoardSeatsTaken
                                    numOfOrganizationsFounded={company?.numOfOrgFounded} // New attribute
                                    lossOnInvestments={company?.lossOnInvestments} // New attribute
                                    coInvestors={company?.coInvestors} // New attribute
                                    investments={company?.investedIn} // New attribute
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </div>
        </>
    )

}

export default Investor;