import CreateAccountForm from './create-account-form';
import React, { useState } from 'react';
import validator from 'validator';
import { useParams } from 'react-router';
import { useRealmApp } from '../../RealmApp';
import isStrongPassword from 'validator/lib/isStrongPassword';
import isLength from 'validator/lib/isLength';
import { Button, Alert, Spinner } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai';

function CreateAccountPage() {

    const { register } = useRealmApp()

    // get referredBy attribute
    const { slug } = useParams();

    const [newUser, setNewUser] = useState({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: '',

        referredBy: slug,

        knowYouBetter: '',
        hearAboutUs: '',
    });

    const [response, setResponse] = useState("");

    async function handleRegistration(user) {
        const isValidEmailAddress = validateEmailAddress(user.email);
        const passwordsMatch = (user.password.localeCompare(user.confirmPassword) === 0)
        const fieldsCheck = (!user.knowYouBetter == "" && !user.hearAboutUs == "" && !user.firstname == "" && !user.lastname == "" && !user.email == "" && !user.password == "" && !user.confirmPassword == "" && !user.username == "")

        // Register the user
        if (fieldsCheck) {
            if (isValidEmailAddress) {
                if (passwordsMatch) {

                    setResponse(
                        <Alert className="error-message floating-alert" variant="light">
                            <div>
                                <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                                    onClick={(event) => {
                                        event.preventDefault()
                                        setResponse("")
                                    }}>
                                    <IoMdClose /></Button></div>
                                <div style={{ display: "flex", paddingTop: "10px" }}>
                                    <div style={{ width: "90%" }}>
                                        <p><span style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</span></p>
                                        <p style={{ marginLeft: "28px", fontSize: "14px" }}>Attempting to log in</p>
                                        <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                                    </div>
                                    <div style={{ width: "10%", textAlign: "right" }}>
                                        <Spinner animation="border" variant="secondary" />
                                    </div>
                                </div>
                            </div>
                        </Alert>
                    );

                    try {

                        const response = await fetch('https://data.mongodb-api.com/app/flipidea-3-pputk/endpoint/register_user2',
                            {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(
                                    {
                                        email: user.email,
                                        firstname: trimName(user.firstname),
                                        lastname: trimName(user.lastname),
                                        username: trimName(user.username),
                                        referredBy: user.referredBy,
                                        knowYouBetter: user.knowYouBetter,
                                        hearAboutUs: user.hearAboutUs
                                    }
                                ) // body data type must match "Content-Type" header
                            }
                        );

                        if (!response.ok) {

                            const error = await response.json();
                            throw error;
                        }
                    }
                    catch (error) {

                        // fetch will throw only when a network error is encountered or CORS is misconfigured on the server-side
                        // A 404 does not constitute a network error, for example

                        if (error.error) {

                            const errorMssg = JSON.parse(error.error);
                            if (errorMssg === "database write failure") {

                                setResponse(
                                    <Alert className="error-message floating-alert" variant="light">
                                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                                            event.preventDefault()
                                            setResponse("")
                                        }}><IoMdClose /></Button></div>
                                        <div style={{ paddingTop: "10px" }}>
                                            <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                            <p style={{ marginLeft: "28px" }}>
                                                Sorry, we've encountered an internal database error. Try again.
                                                If you continue to get this error, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.
                                            </p>
                                        </div>
                                    </Alert>
                                );

                                return;
                            }
                            else if (errorMssg === "email address already in use") {

                                // Procceed to create new account
                            }
                            else {

                                setResponse(
                                    <Alert className="error-message floating-alert" variant="light">
                                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                                            event.preventDefault()
                                            setResponse("")
                                        }}><IoMdClose /></Button></div>
                                        <div style={{ paddingTop: "10px" }}>
                                            <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                            <p style={{ marginLeft: "28px" }}>Unhandled Error</p>
                                        </div>
                                    </Alert>
                                );
                                return;
                            }
                        }
                        else {

                            setResponse(
                                <Alert className="error-message floating-alert" variant="light">
                                    <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                                        event.preventDefault()
                                        setResponse("")
                                    }}><IoMdClose /></Button></div>
                                    <div style={{ paddingTop: "10px" }}>
                                        <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                        <p style={{ marginLeft: "28px" }}>Network Error Encountered</p>
                                    </div>
                                </Alert>
                            );

                            return;
                        }
                    }

                    try {

                        // console.log("Placeholder message: New account created");

                        await register(user.email, user.password);

                    } catch (error) {

                        if (error.error) {
                            if (error.error === "name already in use") {
                                setResponse(
                                    <Alert className="error-message floating-alert" variant="light">
                                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                                            event.preventDefault()
                                            setResponse("")
                                        }}><IoMdClose /></Button></div>
                                        <div style={{ paddingTop: "10px" }}>
                                            <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                            <p style={{ marginLeft: "28px" }}>The email you've entered is already in use. Did you forget your password? <br></br><br></br>No worries, we've got you. <a href="https://app.roiquant.com/reset-password">Reset your password now</a> to log in.</p>
                                        </div>
                                    </Alert>
                                );
                            }
                            else {
                                setResponse(
                                    <Alert className="error-message floating-alert" variant="light">
                                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                                            event.preventDefault()
                                            setResponse("")
                                        }}><IoMdClose /></Button></div>
                                        <div style={{ paddingTop: "10px" }}>
                                            <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                            <p style={{ marginLeft: "28px" }}>{error.error}</p>
                                        </div>
                                    </Alert>
                                );
                            }
                        }

                        return;

                    }

                    setResponse(
                        <Alert className="error-message floating-alert" variant="light">
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                                event.preventDefault()
                                setResponse("")
                            }}><IoMdClose /></Button></div>
                            <div style={{ paddingTop: "10px" }}>
                                <p style={{ color: "#0f9d58" }}><AiOutlineCheckCircle className="alert-icon" /> Successful</p>
                                <p style={{ marginLeft: "28px" }}>Your account was successfully created! We've sent you an email to verify your account.</p>
                                <p style={{ marginLeft: "28px" }}>For security reasons, you need to verify your account in order to use our services.</p>
                            </div>
                        </Alert>
                    );

                }
                else {


                    setResponse(
                        <Alert className="error-message floating-alert" variant="light">
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                                event.preventDefault()
                                setResponse("")
                            }}><IoMdClose /></Button></div>
                            <div style={{ paddingTop: "10px" }}>
                                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                <p style={{ marginLeft: "28px" }}>Passwords do not match</p>
                            </div>
                        </Alert>
                    );
                }
            } else {


                setResponse(
                    <Alert className="error-message floating-alert" variant="light">
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                            event.preventDefault()
                            setResponse("")
                        }}><IoMdClose /></Button></div>
                        <div style={{ paddingTop: "10px" }}>
                            <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                            <p style={{ marginLeft: "28px" }}>You have entered an invalid email address. Please double check and enter a valid email address.</p>
                        </div>
                    </Alert>
                );
            }
        } else {


            setResponse(
                <Alert className="error-message floating-alert" variant="light">
                    <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                        event.preventDefault()
                        setResponse("")
                    }}><IoMdClose /></Button></div>
                    <div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                        <p style={{ marginLeft: "28px" }}>Please fill in the empty fields.</p>
                    </div>
                </Alert>
            );
        }
    }

    function validateEmailAddress(emailAddress) {

        return validator.isEmail(emailAddress);
    };

    function trimName(name) {

        return validator.trim(name);
    };

    function checkPwdMatch(password, confirmPassword) {
        //Returns a boolean value / Check if passwords match
        return (password.localeCompare(confirmPassword) === 0)
    }

    function checkPwdLength(password) {
        //Check if password is atleast 8 characters
        return isLength(password, { min: 8 })
    }

    function measurePwdLength(password) {
        //Measure the password length
        return password.length
    }

    function checkPwdSymbol(password) {
        //Check if password contains at least 1 symbol
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 0, minSymbols: 1, minLowercase: 0 })
    }

    function checkPwdUppercase(password) {
        //Check if password contains at least 1 uppercase
        return isStrongPassword(password, { minUppercase: 1, minLength: 0, minNumbers: 0, minSymbols: 0, minLowercase: 0 })
    }

    function checkPwdLowercase(password) {
        //Check if password contains at least 1 lowercase
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 0, minSymbols: 0, minLowercase: 1 })
    }

    function checkPwdNum(password) {
        //Check if password contains at least 1 number
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 1, minSymbols: 0, minLowercase: 0 })
    }

    return (
        <CreateAccountForm
            handleRegistration={handleRegistration}
            newUser={newUser} setNewUser={setNewUser} response={response}
            checkPwdMatch={checkPwdMatch} checkPwdLength={checkPwdLength} checkPwdSymbol={checkPwdSymbol} checkPwdUppercase={checkPwdUppercase} checkPwdLowercase={checkPwdLowercase} checkPwdNum={checkPwdNum} measurePwdLength={measurePwdLength}
        />
    )
}

export default CreateAccountPage