export const entityTypes = [
    { "key": "Charitable incorporated organisation (CIO)" },
    { "key": "Community interest company (CIC)" },
    { "key": "Corporation" },
    { "key": "General partnership" },
    { "key": "Government" },
    { "key": "Holding company" },
    { "key": "Joint venture" },
    { "key": "Joint-stock company" },
    { "key": "Limited liability company" },
    { "key": "Limited liability partnership" },
    { "key": "Limited partnership" },
    { "key": "Non-governmental organisation" },
    { "key": "Non-profit organisation" },
    { "key": "Open-ended investment company (OEIC)" },
    { "key": "Private limited company" },
    { "key": "Private unlimited company" },
    { "key": "Product" },
    { "key": "Project" },
    { "key": "Protected cell company (PCC)" },
    { "key": "Public limited company" },
    { "key": "Sole proprietorship" },
    { "key": "State-owned enterprise" },
    { "key": "Statutory corporation" },
    { "key": "Subsidiary company" }
];

export const organizationEntityTypes = [
    { "key": "For-profit organization" },
    { "key": "Non-profit organization" },
    { "key": "Non-governmental organization" },
];

export const financeEntityTypes = [
    { "key": "Institutional investor" },
    { "key": "Non-institutional investor" },
    { "key": "Advisor" },
    { "key": "Financial institution" },
];

export const personEntityTypes = [
    { "key": "Employer" },
    { "key": "Employee" },
    { "key": "Executive board" },
];

export const educationEntityTypes = [
    { "key": "School" },
    { "key": "University" },
];

export const governmentEntityTypes = [
    { "key": "Government" },
    { "key": "Government authority" },
    { "key": "Government-owned corporation" },
];