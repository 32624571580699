import React, { useState, useEffect } from 'react';
import Survival from './filters/survival.js';
import Reasons from './filters/reasons.js';
import Locations from './filters/locations.js';
import Industries from './filters/industries.js';
import Lifespans from './filters/lifespans.js';
import FundingRounds from './filters/funding-rounds.js';
import FundingAmounts from './filters/funding-amounts.js';
import Employees from './filters/employees.js';
import GenderStatistics from './filters/gender-statistics.js';
import NavBar from '../../layout/navbar/navbar';
import './postmortem-analysis.css';
import { Accordion, Card, Container, Alert, Spinner, Button } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { FaChevronLeft, FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { useRealmApp } from '../../RealmApp';

const PostmortemAnalysis = () => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    const { currentUser, logInAnonymous } = useRealmApp();

    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    const { getDashboardStatistics, getPostmortemAnalysisStatistics } = currentUser.functions;

    // hook for dashboard statistics
    const [dashboardStatistics, setDashboardStatistics] = useState({
        postmortemAnalysis: 0,
        postmortemAnalysisReasonsForFailure: 0
    });

    // hook for footnotes statistics
    const [footnotesStats, setFootnotesStats] = useState({
        uniqueReasons: 0,
        companiesWithReasons: 0,
        companiesWithoutReasons: 0,
        companiesWithLocation: 0,
        companiesWithoutLocation: 0,
        uniqueIndustries: 0,
        companiesWithIndustries: 0,
        companiesWithoutIndustries: 0,
        companiesWithLifespans: 0,
        companiesWithoutLifespans: 0,
        companiesWithFundingRounds: 0,
        companiesWithoutFundingRounds: 0,
        companiesWithFundingAmounts: 0,
        companiesWithoutFundingAmounts: 0,
        companiesWithEmployees: 0,
        companiesWithoutEmployees: 0,
        companiesWithGenderData: 0,
        companiesWithoutGenderData: 0
    });

    // get dashboard statistics on page load
    useEffect(async () => {
        try {
            let response = await getDashboardStatistics();
            setDashboardStatistics(JSON.parse(response.body));
            // console.log("current stats:", dashboardStatistics);

            closeAlert();
        } catch (err) {
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Bad internet issues or server connection errors</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Sorry, there seems to be poor internet connection or interrupted connectivity issues or server connection errors. Should the issues persist, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>);
        }
    }, []);

    // get footnotes stats on page load
    useEffect(async () => {
        try {
            let response = await getPostmortemAnalysisStatistics();
            setFootnotesStats(JSON.parse(response.body));
            // console.log("current stats:", dashboardStatistics);
        } catch (err) { }
    }, []);

    // hook for arrow icons
    const [changeArrow, setChangeArrow] = useState({
        survival: false,
        reasons: false,
        locations: false,
        industries: false,
        lifespans: false,
        fundingRounds: false,
        fundingAmounts: false,
        employees: false,
        genderStatistics: false,
    });

    // functions to update arrow icons
    function changeSurvival() {
        setChangeArrow({ survival: !changeArrow.survival });
    }

    function changeReasons() {
        setChangeArrow({ reasons: !changeArrow.reasons });
    }

    function changeLocations() {
        setChangeArrow({ locations: !changeArrow.locations });
    }

    function changeIndustries() {
        setChangeArrow({ industries: !changeArrow.industries });
    }

    function changeLifespans() {
        setChangeArrow({ lifespans: !changeArrow.lifespans });
    }

    function changeFundingRounds() {
        setChangeArrow({ fundingRounds: !changeArrow.fundingRounds });
    }

    function changeFundingAmounts() {
        setChangeArrow({ fundingAmounts: !changeArrow.fundingAmounts });
    }

    function changeEmployees() {
        setChangeArrow({ employees: !changeArrow.employees });
    }

    function changeGenderStatistics() {
        setChangeArrow({ genderStatistics: !changeArrow.genderStatistics });
    }

    return (
        <>

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            {/* navbar */}
            <NavBar />

            {/* postmortem analysis */}
            <div id="postmortemanalysis">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>
                    <div className="postmortemanalysisheaderdiv">

                        {/* title and info note */}
                        <div className="postmortemanalysistitlediv">
                            <br />
                            <span className="postmortemanalysistext">
                                Postmortem analysis
                                <sup data-tip="Examine the pitfalls and mistakes others had made with our structured frameworks.">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </span>
                        </div>

                        {/* statistics table */}
                        <div className="postmortemanalysisstatsdiv">
                            <table className="tg" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <td className="table-data db-header">
                                            Inactive companies
                                        </td>
                                        <td className="table-data db-header">
                                            Reasons for shutdown
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="table-data db-data">
                                            {dashboardStatistics.postmortemAnalysis.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </td>
                                        <td className="table-data db-data">
                                            {dashboardStatistics.postmortemAnalysisReasonsForFailure.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* accordions as topics */}
                    <div className="tab-body">

                        {/* reasons for shutdown */}
                        <Accordion>
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="reasons" onClick={changeReasons}>
                                    <div className="companyarrowicon">
                                        {changeArrow.reasons
                                            ? (<FaChevronDown className="arrow-icon" />)
                                            : (<FaChevronLeft className="arrow-icon" />)
                                        }
                                    </div>

                                    <div className="companytitlecard">
                                        What are the top reasons for shutdown?
                                    </div>

                                    <div className="companyellipsisicon">
                                        <FaEllipsisH />
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="reasons">
                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                        <Reasons
                                            uniqueReasons={footnotesStats.uniqueReasons}
                                            companiesWithReasons={footnotesStats.companiesWithReasons}
                                            companiesWithoutReasons={footnotesStats.companiesWithoutReasons}
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* locations */}
                        <Accordion style={{ marginTop: "10px" }}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="locations" onClick={changeLocations}>
                                    <div className="companyarrowicon">
                                        {changeArrow.locations
                                            ? (<FaChevronDown className="arrow-icon" />)
                                            : (<FaChevronLeft className="arrow-icon" />)
                                        }
                                    </div>

                                    <div className="companytitlecard">
                                        Where did the companies base at?
                                    </div>

                                    <div className="companyellipsisicon">
                                        <FaEllipsisH />
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="locations">
                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                        <Locations
                                            companiesWithLocation={footnotesStats.companiesWithLocation}
                                            companiesWithoutLocation={footnotesStats.companiesWithoutLocation}
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* industries */}
                        <Accordion style={{ marginTop: "10px" }}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="industries" onClick={changeIndustries}>
                                    <div className="companyarrowicon">
                                        {changeArrow.industries
                                            ? (<FaChevronDown className="arrow-icon" />)
                                            : (<FaChevronLeft className="arrow-icon" />)
                                        }
                                    </div>

                                    <div className="companytitlecard">
                                        Which industries did the companies operate in?
                                    </div>

                                    <div className="companyellipsisicon">
                                        <FaEllipsisH />
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="industries">
                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                        <Industries
                                            uniqueIndustries={footnotesStats.uniqueIndustries}
                                            companiesWithIndustries={footnotesStats.companiesWithIndustries}
                                            companiesWithoutIndustries={footnotesStats.companiesWithoutIndustries}
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* lifespans */}
                        <Accordion style={{ marginTop: "10px" }}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="lifespans" onClick={changeLifespans}>
                                    <div className="companyarrowicon">
                                        {changeArrow.lifespans
                                            ? (<FaChevronDown className="arrow-icon" />)
                                            : (<FaChevronLeft className="arrow-icon" />)
                                        }
                                    </div>

                                    <div className="companytitlecard">
                                        What were the lifespans of the companies?
                                    </div>

                                    <div className="companyellipsisicon">
                                        <FaEllipsisH />
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="lifespans">
                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                        <Lifespans
                                            companiesWithLifespans={footnotesStats.companiesWithLifespans}
                                            companiesWithoutLifespans={footnotesStats.companiesWithoutLifespans}
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* funding rounds */}
                        <Accordion style={{ marginTop: "10px" }}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="fundingRounds" onClick={changeFundingRounds}>
                                    <div className="companyarrowicon">
                                        {changeArrow.fundingRounds
                                            ? (<FaChevronDown className="arrow-icon" />)
                                            : (<FaChevronLeft className="arrow-icon" />)
                                        }
                                    </div>

                                    <div className="companytitlecard">
                                        What were the last funding rounds of the companies?
                                    </div>

                                    <div className="companyellipsisicon">
                                        <FaEllipsisH />
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="fundingRounds">
                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                        <FundingRounds
                                            companiesWithFundingRounds={footnotesStats.companiesWithFundingRounds}
                                            companiesWithoutFundingRounds={footnotesStats.companiesWithoutFundingRounds}
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* funding amounts */}
                        <Accordion style={{ marginTop: "10px" }}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="fundingAmounts" onClick={changeFundingAmounts}>
                                    <div className="companyarrowicon">
                                        {changeArrow.fundingAmounts
                                            ? (<FaChevronDown className="arrow-icon" />)
                                            : (<FaChevronLeft className="arrow-icon" />)
                                        }
                                    </div>

                                    <div className="companytitlecard">
                                        What were the total funding amounts raised by the companies?
                                    </div>

                                    <div className="companyellipsisicon">
                                        <FaEllipsisH />
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="fundingAmounts">
                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                        <FundingAmounts
                                            companiesWithFundingAmounts={footnotesStats.companiesWithFundingAmounts}
                                            companiesWithoutFundingAmounts={footnotesStats.companiesWithoutFundingAmounts}
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* employees */}
                        <Accordion style={{ marginTop: "10px" }}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="employees" onClick={changeEmployees}>
                                    <div className="companyarrowicon">
                                        {changeArrow.employees
                                            ? (<FaChevronDown className="arrow-icon" />)
                                            : (<FaChevronLeft className="arrow-icon" />)
                                        }
                                    </div>

                                    <div className="companytitlecard">
                                        How many employees did the companies hire?
                                    </div>

                                    <div className="companyellipsisicon">
                                        <FaEllipsisH />
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="employees">
                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                        <Employees
                                            companiesWithEmployees={footnotesStats.companiesWithEmployees}
                                            companiesWithoutEmployees={footnotesStats.companiesWithoutEmployees}
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* gender */}
                        <Accordion style={{ marginTop: "10px" }}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="genderStatistics" onClick={changeGenderStatistics}>
                                    <div className="companyarrowicon">
                                        {changeArrow.genderStatistics
                                            ? (<FaChevronDown className="arrow-icon" />)
                                            : (<FaChevronLeft className="arrow-icon" />)
                                        }
                                    </div>

                                    <div className="companytitlecard">
                                        What is the gender statistics among the companies' founders?
                                    </div>

                                    <div className="companyellipsisicon">
                                        <FaEllipsisH />
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="genderStatistics">
                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                        <GenderStatistics
                                            companiesWithGenderData={footnotesStats.companiesWithGenderData}
                                            companiesWithoutGenderData={footnotesStats.companiesWithoutGenderData}
                                        />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        {/* survival chart */}
                        <Accordion style={{ marginTop: "10px" }}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="survival" onClick={changeSurvival}>
                                    <div className="companyarrowicon">
                                        {changeArrow.survival
                                            ? (<FaChevronDown className="arrow-icon" />)
                                            : (<FaChevronLeft className="arrow-icon" />)
                                        }
                                    </div>

                                    <div className="companytitlecard">
                                        Bonus: survival rate of private sector establishments in the United States by opening year
                                    </div>

                                    <div className="companyellipsisicon">
                                        <FaEllipsisH />
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="survival">
                                    <Card.Body style={{ fontFamily: "AileronRegular" }}>
                                        <Survival />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default PostmortemAnalysis;