import React, { useState, useReducer } from 'react';
import './market-sizing.css';
import NavBar from '../../../../layout/navbar/navbar';
import BasicVersion from './versions/basic-version';
import { Accordion, Col, Card, Container, Tabs, Tab, Row } from 'react-bootstrap';
import { FaChevronLeft, FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useRealmApp } from '../../../../RealmApp';

const MarketSizing = (props) => {

    // realm function starts here
    const { currentUser } = useRealmApp();

    // check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // hook to display methods
    const [state, dispatch] = useReducer(reducer, {
        preRevenueMethod: true
    })

    // function to update hook
    function reducer(state, action) {
        let newState;

        switch (action.type) {
            case 'change preRevenueMethod':
                newState = {
                    preRevenueMethod: !state.preRevenueMethod
                };
                break;

            default:
                throw new Error();
        }

        return newState;
    }

    return (
        <>
            {/* navbar */}
            <NavBar />

            {/* market sizing */}
            <div id="marketsizing">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>

                    {/* title and info note */}
                    <div id="title-div">
                        <span id="title-text">
                            Market sizing
                            <sup data-tip="">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </span>
                    </div>

                    {/* tabs based on methods */}
                    <div>
                        <Tabs className="companytabs" defaultActiveKey="basicVersion">
                            <Tab eventKey="basicVersion" title="Basic" style={{ marginTop: "0" }}>
                                <div className="tab-body">

                                    <Accordion defaultActiveKey="preRevenueMethod">
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="preRevenueMethod" onClick={() => dispatch({ type: "change preRevenueMethod" })}>
                                                <div className="companyarrowicon">
                                                    {state.preRevenueMethod
                                                        ? (<FaChevronDown className="arrow-icon" />)
                                                        : (<FaChevronLeft className="arrow-icon" />)
                                                    }
                                                </div>

                                                <div className="companytitlecard">
                                                    Pre-revenue method
                                                </div>

                                                <div className="companyellipsisicon">
                                                    <FaEllipsisH />
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="preRevenueMethod">
                                                <BasicVersion currentUser={currentUser} />
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default MarketSizing;