import React, { useState } from 'react';
import { Card, Form, Alert, Button, Spinner } from 'react-bootstrap';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList } from 'react-icons/bs'
import './methods.css';

const BerkusMethod = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    // realm function starts here
    const { berkusMethod } = props.currentUser.functions;
    // console.log("Current User", props.currentUser.functions)

    // hook for form
    const [filters, setFilters] = useState({
        soundIdea: "",
        prototype: "",
        qualityManagementTeam: "",
        strategicRelationships: "",
        productRollout: ""
    });

    // hook for results
    const [valuation, setValuation] = useState({
        preMoney: 0
    });

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            soundIdea: "",
            prototype: "",
            qualityManagementTeam: "",
            strategicRelationships: "",
            productRollout: ""
        });
        setValuation({
            preMoney: 0
        });
        closeAlert();
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        setFilters({ ...filtersCopy });
    }

    // function to submit form
    async function submitFilters(e) {
        e.preventDefault();

        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Simulating</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        let form = {
            soundIdea: parseInt(filters.soundIdea),
            protoType: parseInt(filters.prototype),
            qualityManagementTeam: parseInt(filters.qualityManagementTeam),
            strategicRelationships: parseInt(filters.strategicRelationships),
            productRollout: parseInt(filters.productRollout)
        };

        // console.log("Berkus Method Form", form);

        try {
            const response = await berkusMethod(form);
            const data = JSON.parse(response).body;

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View results</a></p>
            </div>);

            setValuation({
                preMoney: data["Pre-money_valuation"]
            })

        } catch (err) {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>Please try again.</p>
            </div>);

            // console.error(err);
        }
    }

    // function to submit form on enter
    function enterFilters(e) {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            submitFilters(e);
        }
    };

    return (
        // card as method
        <Card.Body className="prerevmethodsaccordioncardbody">

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            {/* form */}
            <div className="col-12 prerevmethodsdivfilter">
                <Form>
                    <Form.Group>
                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Sound idea (reduces business risk or product risk)
                                    <sup data-tip="An idea is considered sound when it has the potential to solve a real-world and/or unsolved problem which reduces business risk or product risk">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control placeholder="If you have a viable business idea that shows potential scalable growth, how much value would you assign to your idea?" type="number"
                                    name="soundIdea" value={filters.soundIdea} onChange={changeFilters} onKeyDown={enterFilters} />
                                <Form.Text className="prerevmethodstextmuted">Estimate value within USD $0 - $500,000</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Prototype (reduces technology risk)
                                    <sup data-tip="The availability of a functional prototype or minimum-viable-product (MVP) not only reduces development risk but also technology risk">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control placeholder="If you have a completed and/or functional prototype to attract early customers, how much value would you assign to your prototype?" type="number"
                                    name="prototype" value={filters.prototype} onChange={changeFilters} onKeyDown={enterFilters} />
                                <Form.Text className="prerevmethodstextmuted">Estimate value within USD $0 - $500,000</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Quality management team (reduces execution risk)
                                    <sup data-tip="Founders with deep domain expertise in their respective fields will define a great quality management team which reduces execution risk">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control placeholder="If you have put together a qualified management team, how much value would you assign to your management team?" type="number"
                                    name="qualityManagementTeam" value={filters.qualityManagementTeam} onChange={changeFilters} onKeyDown={enterFilters} />
                                <Form.Text className="prerevmethodstextmuted">Estimate value within USD $0 - $500,000</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Strategic relationships (reduces market risk or competitive risk)
                                    <sup data-tip="Building strategic relationships with well-established parties that have strong expertise and resources will lower investors' investment risk because strong alliances reduce market risk and competitive risk">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control placeholder="If you have strategic alliances with well-established parties (such as, big corporations, institutions, community groups, and so on), how much value would you assign to your strategic relationships?" type="number"
                                    name="strategicRelationships" value={filters.strategicRelationships} onChange={changeFilters} onKeyDown={enterFilters} />
                                <Form.Text className="prerevmethodstextmuted">Estimate value within USD $0 - $500,000</Form.Text>
                            </div>
                        </div>

                        {/* form field */}
                        <div className="prerevmethodsrow2">
                            <div>
                                <Form.Label>
                                    Product rollout or sales (reduces production risk or financial risk)
                                    <sup data-tip="Well-planned rollout process from pre-launch to post-launch activities that benefited the target market not only reduces marketing risk but production risk and financial risk as well">
                                        <IoMdInformationCircleOutline id="information-icon" />
                                    </sup>
                                </Form.Label>
                                <Form.Control placeholder="If your product rollout is showing signs of revenue growth and a pathway to profitability, how much value would you assign to your product rollout or early sales?" type="number"
                                    name="productRollout" value={filters.productRollout} onChange={changeFilters} onKeyDown={enterFilters} />
                                <Form.Text className="prerevmethodstextmuted">Estimate value within USD $0 - $500,000</Form.Text>
                            </div>
                        </div>

                        <div className="prerevmethodsdivbuttons">
                            {/* reset button */}
                            <button className="prerevmethodsresetbutton" onClick={resetFilters}>
                                <span>Clear</span>
                            </button>

                            {/* submit button */}
                            <Button className="prerevmethodssubmitbutton" onClick={submitFilters}>
                                <span>Simulate</span>
                            </Button>
                        </div>
                    </Form.Group>
                </Form>
            </div>

            {/* results */}
            <div className="col-12">
                <Card className="prerevmethodscard">
                    <div className="prerevmethods">
                        <table className="prerevmethodstable">
                            <tbody>
                                <tr>
                                    <td style={{ fontWeight: "bold", borderRight: "1px solid #dee2e6" }}>Pre-money valuation</td>
                                    <td style={{ borderLeft: "1px solid #dee2e6" }}>USD $ {valuation.preMoney?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>

            {/* notes */}
            <div className="col-12 prerevmethodsdivnotes">
                <span className="info-notes prerevmethodsnotes">
                    Note:
                    <ul>
                        <li>
                            <a href="https://www.investopedia.com/terms/r/rollout.asp" target="_blank" rel="noopener noreferrer">
                                Product rollout</a> refers to an informal business term for the introduction and integration of a new product or service to the market.
                        </li>
                        <li>
                            The Berkus method allows aspiring and budding entrepreneurs, as well as very-early stage investors, to focus on the risk factors rather than final projections to estimate a pre-revenue business.
                        </li>
                        <li>
                            “The Berkus method assigns a number, a financial valuation, to each of the five major elements of risk faced by all young companies.”
                            (<a href="https://www.angelcapitalassociation.org/blog/after-20-years-updating-the-berkus-method-of-valuation/" target="_blank" rel="noopener noreferrer">
                                Dave Berkus, 2021</a>)
                        </li>
                        <li>
                            It is important to note that “these numbers are maximums that can be “earned” to form a valuation, allowing for a pre-revenue valuation of up to USD $2 million (or a post rollout value of up to USD $2.5 million), but certainly also allowing the investor to put much lower values into each test, resulting in valuations well below that amount.”
                            (<a href="https://berkonomics.com/?p=1214" target="_blank" rel="noopener noreferrer">
                                Dave Berkus, 2012</a>)
                        </li>
                        <li>
                            <a href="https://www.investopedia.com/ask/answers/difference-between-premoney-and-postmoney/" target="_blank" rel="noopener noreferrer">
                                Pre-money valuation</a> refers to the value of a company not including external funding or the latest round of funding; best described as how much a startup might be worth before receiving any investments into the company.
                        </li>
                        <li>
                            The <a href="https://www.angelcapitalassociation.org/blog/after-20-years-updating-the-berkus-method-of-valuation/" target="_blank" rel="noopener noreferrer">
                                berkus method</a> was created by Dave Berkus in the mid-1990s, which uses both qualitative and quantitative factors to calculate a valuation based on five drivers.
                        </li>
                        <li>
                            “As some businesses are easier to value than others, the uncertainty associated with value estimation is different for different businesses. It is not how precise a valuation is that determines its usefulness, but how precise the value is relative to the estimation of other investors.” (<a href="https://www.amazon.com/Damodaran-Valuation-Security-Investment-Corporate/dp/0471751219" target="_blank" rel="noopener noreferrer">
                                Damodaran, 2006</a>)
                        </li>
                    </ul>
                </span>
            </div>
        </Card.Body>
    );
}

export default BerkusMethod;