const PitchDeckDetail = ({ displayName, fieldName, data, className }) => {

  return (
    <div className={className}>
      <p className='companydetailtitle'>{displayName}</p>

      {data?.[fieldName] ? (
        Array.isArray(data[fieldName]) ? (
          data[fieldName].length === 0 ? (
            <p className='companydetaildata'>-</p>
          ) : (
            <p className='companydetaildata'>{data[fieldName].join(', ')}</p>
          )
        ) : (
          <p className='companydetaildata'>{data[fieldName]}</p>
        )
      ) : (
        <p className='companydetaildata'>-</p>
      )}
    </div>
  )
}

export default PitchDeckDetail