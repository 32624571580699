import React from 'react'
import { Card } from 'react-bootstrap'
import './company-detail.css'

function CompanyInformation(props) {

    return (
        <Card.Body id="companyinformation">
            <div>
                <p className="companydetailtitle">Description</p>
                {!props?.description
                    ? (<p className="companydetaildata">-</p>)
                    : (<p className="companydetaildata">{props.description}</p>)
                }

            </div>

            <div className="companydetailrows">
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Legal Name</p>
                    {!props?.legalName
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.legalName}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Former Legal Name(s)</p>
                    {!props?.formerLegalName
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.formerLegalName.map((name, index) =>
                            !name
                                ? ""
                                : (<span key={index}>{name}, </span>)
                        )}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Company Reputation</p>
                    <p className="companydetaildata">-</p>
                </div>
            </div>

            <div className="companydetailrows">
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Entity Type</p>
                    {!props?.entityType
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.entityType}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Ownership Status</p>
                    <p className="companydetaildata">-</p>
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Operational Status</p>
                    {!props?.isActive
                        // false
                        ? props.isActive == false
                            ? (<p className="companydetaildata">Inactive</p>)
                            : (<p className="companydetaildata">-</p>)
                        // true
                        : props.isActive == true
                            ? (<p className="companydetaildata">Active</p>)
                            : (<p className="companydetaildata">-</p>)
                    }
                </div>
            </div>

            <div className="companydetailrows">
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Founded Date</p>
                    {!props?.foundedDate
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.foundedDate}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Operational Duration</p>
                    {!props?.operationalDuration
                        ? (<p className="companydetaildata">-</p>)
                        : props.operationalDuration != 1
                            ? (<p className="companydetaildata">{props.operationalDuration} years</p>)
                            : (<p className="companydetaildata">{props.operationalDuration} year</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Shutdown Date</p>
                    {!props?.shutdownDate
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.shutdownDate}</p>)
                    }
                </div>
            </div>

            <div className="companydetailrows">
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Last Financing Round</p>
                    {!props?.lastFinancingRound
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.lastFinancingRound}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Total Raised</p>
                    {!props?.totalRaised
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.totalRaisedCurrency} {props.totalRaised}{props.totalRaisedSymbol}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Last Financing Date</p>
                    {!props?.lastFinancingDate
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.lastFinancingDate}</p>)
                    }
                </div>
            </div>

            <div className="companydetailrows">
                <div className="companydetaildiv">
                    <p className="companydetailtitle">No. of Funding Round(s)</p>
                    {!props?.noOfFundingRounds
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.noOfFundingRounds}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">No. of Investor(s)</p>
                    {!props?.investors
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.investors.length}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">No. of Employees</p>
                    {!props?.noOfEmployeesMin && !props?.noOfEmployeesMax
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.noOfEmployeesMin} - {props.noOfEmployeesMax}</p>)
                    }
                </div>
            </div>

            <div className="companydetailrows">
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Total Revenue</p>
                    <p className="companydetaildata">-</p>
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Post-money Valuation</p>
                    <p className="companydetaildata">-</p>
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Market Capitalization</p>
                    <p className="companydetaildata">-</p>
                </div>
            </div>

            <div className="companydetailrows">
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Stage of Development</p>
                    {!props?.stageOfDevelopment
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.stageOfDevelopment}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Transactional Model Type</p>
                    {!props?.businessModelType
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.businessModelType}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Defensibility Moats</p>
                    {!props?.defensibilityMoat
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.defensibilityMoat.map((moat, index) =>
                            !moat
                                ? ""
                                : (<span key={index}>{moat}, </span>)
                        )}</p>)
                    }
                </div>
            </div>

            <div className="companydetailrows">
                <div className="companydetaildiv">
                    <p className="companydetailtitle">City</p>
                    {!props?.city
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.city}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">State/Province</p>
                    {!props?.state
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.state}</p>)
                    }
                </div>
                <div className="companydetaildiv">
                    <p className="companydetailtitle">Country</p>
                    {!props?.country
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">{props.country}</p>)
                    }
                </div>
            </div>

            <div>
                <p className="companydetailtitle">Industry</p>
                {!props?.industry
                    ? (<p className="companydetaildata">-</p>)
                    : (<p className="companydetaildata">{props.industry}</p>)
                }
            </div>

            <div>
                <p className="companydetailtitle">Categories</p>
                {!props?.categories
                    ? (<p className="companydetaildata">-</p>)
                    : (<p className="companydetaildata">{props.categories.map((category, index) =>
                        !category
                            ? ""
                            : (<span key={index}>{category}, </span>)
                    )}</p>)
                }
            </div>
        </Card.Body>
    )
}

export default CompanyInformation;