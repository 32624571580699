import React from 'react';
import { Card } from 'react-bootstrap';
import '../data-contribution.css';

function ViewBusiness(props) {

    return (
        <>
            {props.profileType === "Finance"
                ? (<>
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div>
                                <p className="companydetailtitle">Short investment thesis</p>
                                {!props?.shortInvestmentThesis
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.shortInvestmentThesis}</p>)
                                }
                            </div>

                            <div>
                                <p className="companydetailtitle">Long investment thesis</p>
                                {!props?.longInvestmentThesis
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">{props.longInvestmentThesis}</p>)
                                }
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Stage of development</p>
                                    {!props?.stageOfDevelopment
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.stageOfDevelopment}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Transactional model type(s)</p>
                                    {!props?.transactionalModelType
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.transactionalModelType.map((type, index) =>
                                            !type
                                                ? ""
                                                : (<span key={index}>{type}, </span>)
                                        )}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Competitive moat(s)</p>
                                    {!props?.defensibilityMoats
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.defensibilityMoats.map((moat, index) =>
                                            !moat
                                                ? ""
                                                : (<span key={index}>{moat}, </span>)
                                        )}</p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Assets under management</p>
                                    {!props?.assetsUnderManagement
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.assetsUnderManagement}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Investment range</p>
                                    {!props?.investmentRange
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.investmentRange}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Sweet spot</p>
                                    {!props?.sweetSpot
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.sweetSpot}</p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Preferred investment stage(s)</p>
                                    {!props?.preferredInvestmentStages
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.preferredInvestmentStages.map((stage, index) =>
                                            !stage
                                                ? ""
                                                : (<span key={index}>{stage}, </span>)
                                        )}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Preferred investment focus</p>
                                    {!props?.preferredInvestmentFocus
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.preferredInvestmentFocus.map((focus, index) =>
                                            !focus
                                                ? ""
                                                : (<span key={index}>{focus}, </span>)
                                        )}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Preferred investment geography</p>
                                    {!props?.preferredInvestmentGeography
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.preferredInvestmentGeography.map((geo, index) =>
                                            !geo
                                                ? ""
                                                : (<span key={index}>{geo}, </span>)
                                        )}</p>)
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div>
                                <p className="companydetailtitle">Fund product</p>
                                {!props?.fundProduct
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">
                                        <ol>{props.fundProduct.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<>
                                                    <li className="pitchdeckanswers" key={index}>{point.fundProductName}</li>
                                                    <ul>
                                                        <li className="pitchdeckanswers" key={index}>Short description: {point.fundProductShortDescription}</li>
                                                        <li className="pitchdeckanswers" key={index}>Long description: {point.fundProductLongDescription}</li>
                                                    </ul>
                                                </>)
                                        )}</ol>
                                    </p>)
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </>)
                : (<>
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Stage of development</p>
                                    {!props?.stageOfDevelopment
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.stageOfDevelopment}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Transactional model type(s)</p>
                                    {!props?.transactionalModelType
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.transactionalModelType.map((type, index) =>
                                            !type
                                                ? ""
                                                : (<span key={index}>{type}, </span>)
                                        )}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Competitive moat(s)</p>
                                    {!props?.defensibilityMoats
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.defensibilityMoats.map((moat, index) =>
                                            !moat
                                                ? ""
                                                : (<span key={index}>{moat}, </span>)
                                        )}</p>)
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div>
                                <p className="companydetailtitle">Product</p>
                                {!props?.product
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">
                                        <ol>{props.product.map((point, index) =>
                                            !point
                                                ? ""
                                                : (<>
                                                    <li className="pitchdeckanswers" key={index}>{point.productName}</li>
                                                    <ul>
                                                        <li className="pitchdeckanswers" key={index}>Product proprietorship: {point.productProprietorship}</li>
                                                        <li className="pitchdeckanswers" key={index}>Short description: {point.productShortDescription}</li>
                                                        <li className="pitchdeckanswers" key={index}>Long description: {point.productLongDescription}</li>
                                                    </ul>
                                                </>)
                                        )}</ol>
                                    </p>)
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </>)
            }

            {props.profileType === "Organization" || props.profileType === "Finance" || props.profileType === "Education" || props.profileType === "Project" || props.profileType === "Product"
                ? (<>
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body id="companyinformation">
                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Does your business idea support/align with one or multiple SDGs (Sustainable Development Goals) of the United Nations?</p>
                                    {!props?.supportSustainableDevelopmentGoals
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.supportSustainableDevelopmentGoals.map((type, index) =>
                                            !type
                                                ? ""
                                                : (<span key={index}>{type}, </span>)
                                        )}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">Which market(s) are you targeting?</p>
                                    {!props?.markets
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.markets.map((moat, index) =>
                                            !moat
                                                ? ""
                                                : (<span key={index}>{moat}, </span>)
                                        )}</p>)
                                    }
                                </div>
                            </div>

                            <div className="companydetailrows">
                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">How big is your Total Addressable Market (TAM)?</p>
                                    {!props?.totalAddressableMarket
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.totalAddressableMarket}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">How big is your Serviceable Available Market (SAM)?</p>
                                    {!props?.serviceableAvailableMarket
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.serviceableAvailableMarket}</p>)
                                    }
                                </div>

                                <div className="pitchdeckdetaildiv">
                                    <p className="companydetailtitle">How big is your Serviceable Obtainable Market (SOM)?</p>
                                    {!props?.serviceableObtainableMarket
                                        ? (<p className="companydetaildata">-</p>)
                                        : (<p className="companydetaildata">{props.serviceableObtainableMarket}</p>)
                                    }
                                </div>
                            </div>

                            <div>
                                <p className="companydetailtitle">What real-world problem are you solving (customer pain)?</p>
                                {!props?.customerPain
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">
                                        <ul>{props.customerPain.map((fund, index) =>
                                            !fund
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{fund}</li>)
                                        )}</ul>
                                    </p>)
                                }
                            </div>

                            <div>
                                <p className="companydetailtitle">What is your differentiated solution?</p>
                                {!props?.competitiveDifferentiation
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">
                                        <ul>{props.competitiveDifferentiation.map((fund, index) =>
                                            !fund
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{fund}</li>)
                                        )}</ul>
                                    </p>)
                                }
                            </div>

                            <div>
                                <p className="companydetailtitle">Who are your direct/indirect competitors? (It's almost impossible to have no competitors)</p>
                                {!props?.directCompetitors
                                    ? (<p className="companydetaildata">-</p>)
                                    : (<p className="companydetaildata">
                                        <ul>{props.directCompetitors.map((fund, index) =>
                                            !fund
                                                ? ""
                                                : (<li className="pitchdeckanswers" key={index}>{fund}</li>)
                                        )}</ul>
                                    </p>)
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </>)
                : ""
            }
        </>
    );
}

export default ViewBusiness