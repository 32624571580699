import React, { useState, PureComponent } from 'react';
import { Card, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import './filters.css';
import { useRealmApp } from '../../../RealmApp';
import { survivalIndustries } from '../../dropdown-options/industries';
import { usStates } from '../../dropdown-options/us-states';
import { survivalYearsEnd } from '../../dropdown-options/years-end';

// class for chart axis label
class CustomizedAxisTick extends PureComponent {
    render() {
        const {
            x, y, stroke, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" transform="rotate(-20)">{payload.value}</text>
            </g>
        );
    }
}

const Survival = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // realm functions declaration
    const { currentUser, logInAnonymous } = useRealmApp();

    // to check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // chart function declaration
    const { survivalPostmortemAnalysis } = currentUser.functions;

    // hook for user input
    const [filters, setFilters] = useState({
        industry: "Industry",
        state: "State",
        year: "Year ended"
    });

    // hook for results
    const [investments, setInvestments] = useState([
        {
            "Year ended": "1994",
            "Year": "1994",
            "No. of years since starting": "1",
            "Frequency": "569,387 establishments",
            "% survived": 100.0
        },
        {
            "Year ended": "1994",
            "Year": "1995",
            "No. of years since starting": "2",
            "Frequency": "453,105 establishments",
            "% survived": 79.6
        },
        {
            "Year ended": "1994",
            "Year": "1996",
            "No. of years since starting": "3",
            "Frequency": "387,838 establishments",
            "% survived": 68.1
        },
        {
            "Year ended": "1994",
            "Year": "1997",
            "No. of years since starting": "4",
            "Frequency": "345,128 establishments",
            "% survived": 60.6
        },
        {
            "Year ended": "1994",
            "Year": "1998",
            "No. of years since starting": "5",
            "Frequency": "309,064 establishments",
            "% survived": 54.3
        },
        {
            "Year ended": "1994",
            "Year": "1999",
            "No. of years since starting": "6",
            "Frequency": "282,466 establishments",
            "% survived": 49.6
        },
        {
            "Year ended": "1994",
            "Year": "2000",
            "No. of years since starting": "7",
            "Frequency": "257,476 establishments",
            "% survived": 45.2
        },
        {
            "Year ended": "1994",
            "Year": "2001",
            "No. of years since starting": "8",
            "Frequency": "236,088 establishments",
            "% survived": 41.5
        },
        {
            "Year ended": "1994",
            "Year": "2002",
            "No. of years since starting": "9",
            "Frequency": "218,169 establishments",
            "% survived": 38.3
        },
        {
            "Year ended": "1994",
            "Year": "2003",
            "No. of years since starting": "10",
            "Frequency": "203,484 establishments",
            "% survived": 35.7
        },
        {
            "Year ended": "1994",
            "Year": "2004",
            "No. of years since starting": "11",
            "Frequency": "191,435 establishments",
            "% survived": 33.6
        },
        {
            "Year ended": "1994",
            "Year": "2005",
            "No. of years since starting": "12",
            "Frequency": "180,919 establishments",
            "% survived": 31.8
        },
        {
            "Year ended": "1994",
            "Year": "2006",
            "No. of years since starting": "13",
            "Frequency": "172,816 establishments",
            "% survived": 30.4
        },
        {
            "Year ended": "1994",
            "Year": "2007",
            "No. of years since starting": "14",
            "Frequency": "163,491 establishments",
            "% survived": 28.7
        },
        {
            "Year ended": "1994",
            "Year": "2008",
            "No. of years since starting": "15",
            "Frequency": "154,955 establishments",
            "% survived": 27.2
        },
        {
            "Year ended": "1994",
            "Year": "2009",
            "No. of years since starting": "16",
            "Frequency": "145,131 establishments",
            "% survived": 25.5
        },
        {
            "Year ended": "1994",
            "Year": "2010",
            "No. of years since starting": "17",
            "Frequency": "137,003 establishments",
            "% survived": 24.1
        },
        {
            "Year ended": "1994",
            "Year": "2011",
            "No. of years since starting": "18",
            "Frequency": "131,017 establishments",
            "% survived": 23.0
        },
        {
            "Year ended": "1994",
            "Year": "2012",
            "No. of years since starting": "19",
            "Frequency": "125,389 establishments",
            "% survived": 22.0
        },
        {
            "Year ended": "1994",
            "Year": "2013",
            "No. of years since starting": "20",
            "Frequency": "120,631 establishments",
            "% survived": 21.2
        },
        {
            "Year ended": "1994",
            "Year": "2014",
            "No. of years since starting": "21",
            "Frequency": "115,662 establishments",
            "% survived": 20.3
        },
        {
            "Year ended": "1994",
            "Year": "2015",
            "No. of years since starting": "22",
            "Frequency": "111,209 establishments",
            "% survived": 19.5
        },
        {
            "Year ended": "1994",
            "Year": "2016",
            "No. of years since starting": "23",
            "Frequency": "106,799 establishments",
            "% survived": 18.8
        },
        {
            "Year ended": "1994",
            "Year": "2017",
            "No. of years since starting": "24",
            "Frequency": "102,396 establishments",
            "% survived": 18.0
        },
        {
            "Year ended": "1994",
            "Year": "2018",
            "No. of years since starting": "25",
            "Frequency": "98,047 establishments",
            "% survived": 17.2
        },
        {
            "Year ended": "1994",
            "Year": "2019",
            "No. of years since starting": "26",
            "Frequency": "94,347 establishments",
            "% survived": 16.6
        },
        {
            "Year ended": "1994",
            "Year": "2020",
            "No. of years since starting": "27",
            "Frequency": "89,752 establishments",
            "% survived": 15.8
        },
        {
            "Year ended": "1994",
            "Year": "2021",
            "No. of years since starting": "28",
            "Frequency": "85,207 establishments",
            "% survived": 15.0
        },
        {
            "Year ended": "1994",
            "Year": "2022",
            "No. of years since starting": "29",
            "Frequency": "81,888 establishments",
            "% survived": 14.4
        },
        {
            "Year ended": "1994",
            "Year": "2023",
            "No. of years since starting": "30",
            "Frequency": "78,152 establishments",
            "% survived": 13.7
        }
    ]);

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            industry: "Industry",
            state: "State",
            year: "Year ended"
        });
        setAlert(false);
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        setFilters({ ...filtersCopy });
    }

    // function to submit form
    function submitFilters(e) {
        setAlert(true);

        if (filters.year !== "Year ended" && (filters.state !== "State" || filters.industry !== "Industry")) {
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                    <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                    <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            survivalPostmortemAnalysis(filters)
                .then(response => {
                    let graphData = response;
                    setInvestments(graphData);
                    closeAlert();
                })
                .catch(error => {
                    setAlert(true);
                    setMessage(<div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                        <p style={{ marginLeft: "28px" }}>Your filter query returned no results. Please change your filter values and try again.</p>
                    </div>);
                })
        }
        else {
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>Please choose a value for Year ended and either State or Industry and try again.</p>
            </div>);
        }

        e.preventDefault();
    }

    return (
        <Card.Body className="accordion-card-body">

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div className="col-12 div-filter">
                {/* form */}
                <Form onSubmit={submitFilters}>
                    <Form.Group>
                        <Form.Row className="align-items-center">
                            {
                                filters.state !== "State"
                                    ? ("")
                                    : <Col xs="auto" className="filter-dropdown">
                                        <Form.Control value={filters.industry}
                                            as="select" name="industry" onChange={changeFilters}>
                                            <option>Industry</option>
                                            {
                                                survivalIndustries.map((option) =>
                                                    <option>{option.key}</option>
                                                )
                                            }
                                        </Form.Control>
                                    </Col>
                            }
                            {
                                filters.industry !== "Industry"
                                    ? ("")
                                    : <Col xs="auto" className="filter-dropdown">
                                        <Form.Control value={filters.state} as="select" name="state" onChange={changeFilters}>
                                            <option>State</option>
                                            {
                                                usStates.map((option) =>
                                                    <option>{option.key}</option>
                                                )
                                            }
                                        </Form.Control>
                                    </Col>
                            }
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.year} as="select" name="year" onChange={changeFilters}>
                                    <option>Year ended</option>
                                    {
                                        survivalYearsEnd.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            {/* reset button */}
                            <Col xs="auto">
                                <button onClick={resetFilters} className="reset-button tour-clear-filter">
                                    <span>Clear</span>
                                </button>
                            </Col>
                            {/* submit button */}
                            <Col xs="auto">
                                <button type="submit" className="submit-button">
                                    <span>Filter</span>
                                </button>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>
            </div>

            {/* results */}
            <div className="col-12">
                <Card className="graph-card">
                    <div>
                        {/* chart component */}
                        <ResponsiveContainer id="vc-charts">
                            <BarChart data={investments}
                                margin={{ top: 20, right: 20, left: 20, bottom: 100 }}
                                width={1100}
                                height={500}
                                fontFamily="AileronRegular"
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Year"
                                    interval={0}
                                    fontSize="12px"
                                    fontFamily="AileronRegular"
                                    tick={<CustomizedAxisTick />}
                                    label={{ value: "Year", offset: -80, position: "insideBottom" }} />
                                <YAxis fontSize="15px"
                                    fontFamily="AileronRegular"
                                    label={{ value: "Survival rate in percentage", angle: -90, position: "insideLeft" }} />
                                <Tooltip
                                    content={(props) => (
                                        <div style={{ border: "lightgrey 1px solid", fontFamily: "AileronRegular", backgroundColor: "white" }}>
                                            <p style={{ margin: "0", padding: "12px 10px 3px 10px", color: "black" }}>
                                                {props.payload && props.payload[0] != null && props.payload[0].payload['Year']}
                                            </p>
                                            <p style={{ margin: "0", padding: "3px 10px", color: "rgb(136, 132, 216)" }}>
                                                Survival rate:{' '}{props.payload && props.payload[0] != null && props.payload[0].payload['% survived']}%
                                            </p>
                                            <p style={{ margin: "0", padding: "3px 10px 12px 10px", color: "rgb(136, 132, 216)" }}>
                                                Frequency:{' '}{props.payload && props.payload[0] != null && props.payload[0].payload['Frequency']}
                                            </p>
                                        </div>
                                    )}
                                />
                                <Bar dataKey="% survived" fill="#8884d8" barSize={50}>
                                    <LabelList dataKey="%" position="top" fill="black" />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </div>

            {/* notes */}
            <div className="col-12 extra-notes">
                <span className="info-notes">
                    Note:
                    <ul>
                        <li id="notes-points">
                            Data sourced from the <a href="https://www.bls.gov/bdm/bdmage.htm" target="_blank" rel="noopener noreferrer">
                                U.S. Bureau of Labor Statistics (U.S. BLS)
                            </a>, this chart shows the surviving establishments based on annual openings from 1994 to 2023 (<span style={{ fontFamily: "AileronItalic" }}>
                                with year ended on March annually</span>), which you may filter the chart by different opening year, and view the number of surviving establishments of the targeted opening year by hovering your mouse over the bars.
                        </li>

                        <li id="notes-points">
                            From the selected opening year, the survival rate of private sector establishments is indicated at 100% (<span style={{ fontFamily: "AileronItalic" }}>
                                number of surviving establishments</span>) because a new dataset is observed and visualized from the selected opening year up to the latest opening year.
                        </li>

                        <li id="notes-points">
                            Importantly, the age and survival data of this dataset are measured at the establishment level (<span style={{ fontFamily: "AileronItalic" }}>
                                not at the firm level</span>), where U.S. BLS used the <a href="https://www.bls.gov/bdm/bdagefaq.htm" target="_blank" rel="noopener noreferrer">
                                "date of first positive employment"
                            </a>, the year and quarter in which a business reports positive employment for the first time, as the birth date.
                        </li>

                        <li id="notes-points">
                            <a href="https://www.bls.gov/opub/mlr/2016/article/establishment-firm-or-enterprise.htm" target="_blank" rel="noopener noreferrer">
                                According to U.S. BLS
                            </a>, “an establishment is a single physical location where one predominant activity occurs, such as a firm is an establishment or a combination of establishments”. Therefore, a <a href="https://www.lawinsider.com/dictionary/private-establishment" target="_blank" rel="noopener noreferrer">
                                private establishment
                            </a> refers to a company, firm, cooperative or other society, associations, trust, agency, institutions, organization, union, factory or such other establishments that are privately owned.
                        </li>

                        <li id="notes-points">
                            For the US Virgin Islands, there is no available data for the year 2001 concerning establishments that opened in 1995 and 2000, as this information does not meet BLS disclosure standards.
                        </li>

                        <li id="notes-points">
                            The survival rates data of Montana for the year 2023 is currently unavailable.
                        </li>
                    </ul>
                </span>
            </div>
        </Card.Body>
    )
}

export default Survival;