import React, { useState } from 'react';
import NavBar from '../../layout/navbar/navbar';
import { Container, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import TextField from '@mui/material/TextField';
import { useRealmApp } from '../../RealmApp';
import './profile.css';

const EditProfile = () => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // realm function starts here
    const { currentUser, getUserDetails, refreshUserDetails } = useRealmApp()
    const userDetails = getUserDetails();

    const { updateUserDetails } = currentUser.functions;

    // hook to get user details from realm function
    const [details, setDetails] = useState({
        name: userDetails.name,
        dob: userDetails.dob,
        gender: userDetails.gender
    });

    // function to update variables
    function changeDetails(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let detailsCopy = { ...details };

        detailsCopy[name] = value;
        setDetails({ ...detailsCopy });
    }

    // function to submit form
    async function submitDetails() {
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Saving</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your profile is ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            await updateUserDetails(details);
            const update = await refreshUserDetails();

            setDetails(update);

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p><AiOutlineCheckCircle className="alert-icon" /> Saved</p>
                <p style={{ marginLeft: "28px" }}>Your updated profile is saved.</p>
            </div>);
        }
        catch (error) {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>We failed to save your updated profile. Please try again.</p>
            </div>);
        }
    }

    return (
        <>
            {/* navbar */}
            <NavBar />

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            {/* edit profile */}
            <div id="profile">
                <Container className="editprofilecard" style={{ paddingTop: "50px", paddingBottom: "200px" }}>
                    <div className="editprofilewidget">
                        <div className="profilebody">

                            {/* title and info note */}
                            <p className="profiletext">Edit profile</p>

                            <p style={{ textAlign: "left", fontSize: "18px" }}>
                                Some info may be visible to other people using roiquant services.<br /><br />
                            </p>

                            {/* form */}
                            <Form>

                                {/* name */}
                                <Form.Group controlId="formUpdateName">
                                    <Form.Label style={{ fontSize: "16px" }}>Name</Form.Label>
                                    {/* name field */}
                                    <Form.Control className="inputbox" value={details.name} type="name" name="name" placeholder="Name" onChange={changeDetails} />
                                </Form.Group>

                                {/* birthday */}
                                <Form.Group controlId="formUpdateDob">
                                    <Form.Label style={{ fontSize: "16px" }}>Birthday</Form.Label>
                                    {/* birthday field */}
                                    <TextField className="dobinputbox" type="date" defaultValue={details.dob} name="dob" onChange={changeDetails} />
                                </Form.Group>

                                {/* gender */}
                                <Form.Group controlId="formUpdateGender">
                                    <Form.Label style={{ fontSize: "16px" }}>Gender</Form.Label>
                                    {/* gender field */}
                                    <Form.Control value={details.gender} as="select" name="gender" onChange={changeDetails}>
                                        <option>Male</option>
                                        <option>Female</option>
                                        <option>Prefer not to say</option>
                                    </Form.Control>
                                </Form.Group>

                                {/* submit button */}
                                <div style={{ textAlign: "center" }}>
                                    <Button className="resetpasswordbtn glintanimation" onClick={() => { submitDetails(); }}>
                                        <span>Update profile</span>
                                    </Button>
                                </div>
                            </Form>

                            {/* back button */}
                            <div className="loginnote">
                                <span><a href="/profile">Back to profile</a></span>
                            </div>

                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default EditProfile;