import React from 'react'
import { Card, Form } from 'react-bootstrap'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import '../referral-program2.css'

const ReferralRewards = () => {

    return (
        <>
            <p className="referralcardheader">How much rewards have you earned?</p>

            <Card className="referralcard">

                <Form.Control as="select" className="referralselect" style={{ margin: "10px 15px 10px 15px" }}>
                    <option>Referrals</option>
                </Form.Control>

                <hr style={{ marginTop: "0" }} />

                <div className="referralrewardsdiv">
                    <div>
                        <p className="referralrewardsheader">Total credits</p>
                        <p className="referralrewardsdata">N/A</p>
                    </div>

                    <div>
                        <p className="referralrewardsheader">Estimated future credits<sup><IoMdInformationCircleOutline className="referralinfo" /></sup></p>
                        <p className="referralrewardsdata">N/A</p>
                    </div>
                </div>

                <hr style={{ marginBottom: "0" }} />

                <Form.Control as="select" className="referralselect">
                    <option>Last 7 days</option>
                </Form.Control>

            </Card>
        </>
    );
}

export default ReferralRewards