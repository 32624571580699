export const reasonsForShutdown = [
    { "key": "Acqui-hire" },
    { "key": "Acquired" },
    { "key": "Anti-competitive Tactics" },
    { "key": "Bad Hire" },
    { "key": "Bad Management" },
    { "key": "Bankruptcy" },
    { "key": "Breach of Contract" },
    { "key": "Communication Issues" },
    { "key": "Conflict of Interest" },
    { "key": "Covid-19 Pandemic" },
    { "key": "Customer Manipulation" },
    { "key": "Data Fabrication" },
    { "key": "Death of Founder" },
    { "key": "Delayed Product Delivery" },
    { "key": "Demonetization" },
    { "key": "Failed Acquisition (Takeover)" },
    { "key": "Failed Fundraising" },
    { "key": "Failed M&A" },
    { "key": "Failed Product" },
    { "key": "Failed to Adapt" },
    { "key": "Failed to Pivot" },
    { "key": "Financial Difficulties" },
    { "key": "Founder Departure" },
    { "key": "Founder Issues" },
    { "key": "Fraud" },
    { "key": "Funds Mismanagement" },
    { "key": "Geographic Limitations" },
    { "key": "High Burn Rate" },
    { "key": "High Business Cost" },
    { "key": "High Cash Burn Rate" },
    { "key": "High Customer Acquisition Cost" },
    { "key": "High Customer Retention Cost" },
    { "key": "High Employee Turnover" },
    { "key": "High Operational Cost" },
    { "key": "Immature Market" },
    { "key": "Inappropriate Sexual Relationship" },
    { "key": "Investor Issues" },
    { "key": "Issues with Government" },
    { "key": "Lack of Diversification" },
    { "key": "Lack of Experience" },
    { "key": "Lack of Focus" },
    { "key": "Lack of Funds" },
    { "key": "Lack of Innovation" },
    { "key": "Lack of Knowledge" },
    { "key": "Lack of Marketing" },
    { "key": "Lack of Passion" },
    { "key": "Lack of Planning" },
    { "key": "Lack of Product Value" },
    { "key": "Lack of Strategic Partners" },
    { "key": "Lack of Strategy" },
    { "key": "Lack of Time" },
    { "key": "Lack of Trust" },
    { "key": "Lack of Vision" },
    { "key": "Legal Issues" },
    { "key": "Lies and Manipulation" },
    { "key": "Lost Control" },
    { "key": "Low User Acquisition" },
    { "key": "Macroeconomic Factors" },
    { "key": "Market Changes" },
    { "key": "Market Issues" },
    { "key": "Marketing" },
    { "key": "Marketing Issues" },
    { "key": "Monetization Issues" },
    { "key": "No Business Model" },
    { "key": "No Financial Support" },
    { "key": "No Market Validation" },
    { "key": "No Problem-Market Fit" },
    { "key": "No Product Validation" },
    { "key": "No Product-Market Fit" },
    { "key": "No Scalability" },
    { "key": "No Sustainability" },
    { "key": "No Unique Value Proposition" },
    { "key": "Overfunded" },
    { "key": "Partnership Issues" },
    { "key": "Platform Dependency" },
    { "key": "Poor Business Model" },
    { "key": "Poor Crisis Management" },
    { "key": "Poor Customer Acquisition" },
    { "key": "Poor Customer Conversion" },
    { "key": "Poor Customer Support" },
    { "key": "Poor Due Diligence" },
    { "key": "Poor Financial Management" },
    { "key": "Poor Financial Planning" },
    { "key": "Poor Leadership" },
    { "key": "Poor Management" },
    { "key": "Poor Market Timing" },
    { "key": "Poor Marketing" },
    { "key": "Poor Naming" },
    { "key": "Poor Operational Management" },
    { "key": "Poor People Management" },
    { "key": "Poor Pivot" },
    { "key": "Poor Planning" },
    { "key": "Poor Privacy Policy" },
    { "key": "Poor Problem-Market Fit" },
    { "key": "Poor Product" },
    { "key": "Poor Product Development" },
    { "key": "Poor Product-Market Fit" },
    { "key": "Poor Profit Margin" },
    { "key": "Poor Risk Management" },
    { "key": "Poor Sales Performance" },
    { "key": "Poor Strategy" },
    { "key": "Poor Technology" },
    { "key": "Poor User Acquisition" },
    { "key": "Premature IPO Listing" },
    { "key": "Premature Market" },
    { "key": "Premature Scaling" },
    { "key": "Pricing Issues" },
    { "key": "Privacy Issues" },
    { "key": "Public Relations Issues" },
    { "key": "Quality VS Cost Dilemma" },
    { "key": "Regulatory Issues" },
    { "key": "Retention Issues" },
    { "key": "Scam" },
    { "key": "Securities Fraud" },
    { "key": "Senior Executive Departure" },
    { "key": "Sex Solicitation" },
    { "key": "Sexual Harassment" },
    { "key": "Signalling Risk" },
    { "key": "Strategy Issues" },
    { "key": "Strong Competition" },
    { "key": "Talent Shortage" },
    { "key": "Team Burnout" },
    { "key": "Team Issues" },
    { "key": "Technical Issues" },
    { "key": "Technological Problems" },
    { "key": "Violence Threats" },
    { "key": "Visa Issues" }
];