import React, { useState, useEffect } from 'react';
import '../tools/founders/pitch-deck-analysis/pitch-deck-analysis.css';
import NavBar from '../../layout/navbar/navbar';
import ViewGeneralInformation from './view-data-contribution/general-information';
import ViewBusiness from './view-data-contribution/business';
import ViewFinancials from './view-data-contribution/financials';
import ViewManagement from './view-data-contribution/management';
import DataRoomView from '../data-room/data-room-view';
import { Container, Tabs, Tab, Alert, Spinner, Button } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { useRealmApp } from '../../RealmApp';
import { useParams } from 'react-router-dom';

const ViewDataContribution = () => {

    const [alert, setAlert] = useState(true);

    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    const { currentUser } = useRealmApp();

    // if (!currentUser) {
    //     throw new Error(`Must be logged in`);
    // }

    // mongoDB realm function
    const { getMyDataContribution } = currentUser.functions;

    // get dataContributionId
    const { dataContributionId } = useParams();

    const [data, setData] = useState();

    useEffect(() => {
        // Get the selected pitch deck
        async function getDataContribution(dataContributionId) {
            // console.log(id);
            // console.log("Execute the code below");
            try {
                const response = await getMyDataContribution(dataContributionId);
                const dataContribution = JSON.parse(response);
                setData(dataContribution);
                setAlert(false);
                // console.log(response);
                // console.log(dataContribution);
            } catch (err) {
                // console.error("Couldn't fetch data from Realm");
            }
        }

        if (dataContributionId) {
            if (!data) {
                // console.log("Pitch Deck Id:", pitchDeckId);
                getDataContribution(dataContributionId);
            }
        }
    }, [data]);

    return (
        <>
            <NavBar />

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="pitchdeck">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>
                    <div className="pitchdeckheaderdiv">
                        <div className="pitchdecktitlediv">
                            <br />
                            <span className="pitchdecktext">
                                {!data?.dataContributionName
                                    ? ("-")
                                    : data.dataContributionName
                                }
                            </span>
                        </div>
                    </div>

                    <div>
                        <Tabs className="companytabs" defaultActiveKey="generalInformation">
                            <Tab eventKey="generalInformation" title="General Information">
                                <ViewGeneralInformation
                                    profileType={data?.profileType}
                                    entityType={data?.entityType}
                                    investorTypes={data?.investorTypes} // multi-select -1
                                    entityName={data?.entityName}
                                    legalName={data?.legalName}
                                    formerLegalName={data?.formerLegalName} // add/remove
                                    currentWork={data?.currentWork} // group
                                    dateOfBirth={data?.dateOfBirth}
                                    city={data?.city}
                                    state={data?.state}
                                    country={data?.country}
                                    industry={data?.industry}
                                    categories={data?.categories} // multi-select 5
                                    education={data?.education} // add/remove
                                    previousProfileType={data?.previousProfileType}
                                    previousWork={data?.previousWork} // group
                                    previousCity={data?.previousCity}
                                    previousState={data?.previousState}
                                    previousCountry={data?.previousCountry}
                                    shortDescription={data?.shortDescription}
                                    longDescription={data?.longDescription}
                                    ownershipStatus={data?.ownershipStatus}
                                    operationalStatus={data?.operationalStatus}
                                    foundedDate={data?.foundedDate}
                                    operationalDuration={data?.operationalDuration}
                                    shutdownDate={data?.shutdownDate}
                                    tickerSymbol={data?.tickerSymbol}
                                    email={data?.email}
                                    telephone={data?.telephone}
                                    website={data?.website}
                                    blog={data?.blog}
                                    socialMedia={data?.socialMedia} // add/remove
                                    gender={data?.gender}
                                    firstTimeFounder={data?.firstTimeFounder}
                                    technicalFounder={data?.technicalFounder}
                                />
                            </Tab>

                            <Tab eventKey="business" title="Business">
                                <ViewBusiness
                                    profileType={data?.profileType}
                                    shortInvestmentThesis={data?.shortInvestmentThesis}
                                    longInvestmentThesis={data?.longInvestmentThesis}
                                    stageOfDevelopment={data?.stageOfDevelopment}
                                    transactionalModelType={data?.transactionalModelType} // multi-select -1
                                    defensibilityMoats={data?.defensibilityMoats} // multi-select 5
                                    assetsUnderManagement={data?.assetsUnderManagement}
                                    investmentRange={data?.investmentRange}
                                    sweetSpot={data?.sweetSpot}
                                    preferredInvestmentStages={data?.preferredInvestmentStages} // multi-select -1
                                    preferredInvestmentFocus={data?.preferredInvestmentFocus} // multi-select -1
                                    preferredInvestmentGeography={data?.preferredInvestmentGeography} // multi-select -1
                                    fundProduct={data?.fundProduct} // group
                                    product={data?.product} // group
                                    supportSustainableDevelopmentGoals={data?.supportSustainableDevelopmentGoals} // multi-select -1
                                    markets={data?.markets} // multi-select 5
                                    totalAddressableMarket={data?.totalAddressableMarket}
                                    serviceableAvailableMarket={data?.serviceableAvailableMarket}
                                    serviceableObtainableMarket={data?.serviceableObtainableMarket}
                                    customerPain={data?.customerPain} // add/remove
                                    competitiveDifferentiation={data?.competitiveDifferentiation} // add/remove
                                    directCompetitors={data?.directCompetitors} // add/remove
                                />
                            </Tab>

                            <Tab eventKey="financials" title="Financials">
                                <ViewFinancials
                                    profileType={data?.profileType}
                                    numOfFunds={data?.numOfFunds}
                                    funds={data?.funds} // add/remove
                                    numOfLimitedPartners={data?.numOfLimitedPartners}
                                    limitedPartners={data?.limitedPartners} // add/remove
                                    lastFinancingDate={data?.lastFinancingDate}
                                    lastFinancingRound={data?.lastFinancingRound}
                                    financialViability={data?.financialViability}
                                    financingType={data?.financingType}
                                    fundraisingTargetAmount={data?.fundraisingTargetAmount}
                                    totalAmountRaised={data?.totalAmountRaised}
                                    remainingTargetAmount={data?.remainingTargetAmount}
                                    numOfFundingRounds={data?.numOfFundingRounds}
                                    postMoneyValuation={data?.postMoneyValuation}
                                    totalRevenue={data?.totalRevenue}
                                    marketCapitalization={data?.marketCapitalization}
                                    numOfInvestors={data?.numOfInvestors}
                                    fundingRounds={data?.fundingRounds} // group
                                    portfolioCompanies={data?.portfolioCompanies} // add/remove
                                    numOfInvestments={data?.numOfInvestments}
                                    numOfLeadInvestments={data?.numOfLeadInvestments}
                                    numOfCoInvestments={data?.numOfCoInvestments}
                                    numOfFollowOnInvestments={data?.numOfFollowOnInvestments}
                                    numOfFailedInvestments={data?.numOfFailedInvestments}
                                    numOfExits={data?.numOfExits}
                                    numOfAcquisitions={data?.numOfAcquisitions}
                                    numOfBoardSeatsTaken={data?.numOfBoardSeatsTaken}
                                />
                            </Tab >

                            <Tab eventKey="management" title="Management">
                                <ViewManagement
                                    profileType={data?.profileType}
                                    management={data?.management} // add/remove
                                    numOfPartners={data?.numOfPartners}
                                    partners={data?.partners} // add/remove
                                    numOfEmployees={data?.numOfEmployees}
                                    numOfBoardMembers={data?.numOfBoardMembers}
                                    boardMembers={data?.boardMembers} // add/remove
                                    managementEmail={data?.managementEmail}
                                    managementMobilePhone={data?.managementMobilePhone}
                                    managementWebsite={data?.managementWebsite}
                                    managementBlog={data?.managementBlog}
                                    managementSocialMedia={data?.managementSocialMedia} // add/remove
                                />
                            </Tab>

                            <Tab eventKey="dataRoom" title="Data Room">
                                <DataRoomView />
                            </Tab>
                        </Tabs >
                    </div >
                </Container >
            </div >
        </>
    )

}

export default ViewDataContribution;