import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import './pitch-deck-analysis.css'
import { useParams } from 'react-router-dom'
import PlanBuilderPageLayout from '../../../../layout/pitch-deck-analysis-page'
import { useRealmApp } from '../../../../RealmApp'
import useBusinessPlanBuilder from '../../../hooks/useBusinessPlanBuilder'
import LoadingModal from '../../../modals/loading-modal'
import {
  closeAlert,
  handleClose,
  handleEnterSave,
  handleSave,
  handleSubmit,
  scrollToTop
} from './helpers'

const EditPitchDeckAnalysis = () => {
  // hook for alert component
  const [alert, setAlert] = useState(true)

  // hook for alert message
  const [message, setMessage] = useState(<LoadingModal />)
  const { state, dispatch } = useBusinessPlanBuilder()

  // get current user
  const { currentUser } = useRealmApp()
  // get pitchDeckId
  const { pitchDeckId } = useParams()

  // realm functions declaration
  const { analyzePitchDeck, savePitchDeck, getMyPitchDeck } =
    currentUser.functions

  // use history to navigate page
  const history = useHistory()

  const props = {
    state: state,
    dispatch: dispatch,
    setMessage,
    setAlert,
    message,
    alert,
    history,
    analyzePitchDeck,
    savePitchDeck
  }

  const params = {
    ...props,
    closeAlert: () => closeAlert(props),
    scrollToTop: () => scrollToTop(),
    handleSubmit: () => handleSubmit(props),
    handleSave: () => handleSave(props),
    handleEnterSave: e => handleEnterSave(e),
    handleClose: () => handleClose(props)
  }

  // realm function to get selected data
  useEffect(() => {
    async function getPitchDeck(id) {
      try {
        const response = await getMyPitchDeck({ pitchDeckId: id })
        const data = JSON.parse(response)

        dispatch({ type: 'initialize', value: data })

        setAlert(false)
      } catch (err) {
        // To-do: Alert user an error was returned
        // console.error("Couldn't initialize the state. Error:", err);
      }
    }
    if (pitchDeckId) {
      getPitchDeck(pitchDeckId)
    }
  }, [])

  // useEffect(() => {
  //   console.log('state:', state)
  // }, [state])

  return <PlanBuilderPageLayout props={params} />
}

export default EditPitchDeckAnalysis