import React from "react";
import FinancialTableHeader from "./financial-table-header";
import FinancialTableRows from "./financial-table-rows";

const FinancialsTable = ({ props, rowArray }) => {
  return (
    <table className="financialstatementstable">
      <FinancialTableHeader />
      {/* form fields */}
      <tbody>
        <FinancialTableRows
          props={props}
          rowArray={rowArray}
        />
      </tbody>
    </table>
  );
};

export default FinancialsTable;