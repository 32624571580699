import React, { useState, PureComponent } from 'react';
import './filters.css';
import { Card, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { useRealmApp } from '../../../RealmApp';
import { ventureYearsStart } from '../../dropdown-options/years-start';
import { ventureYearsEnd } from '../../dropdown-options/years-end';
import { industriesOld } from '../../dropdown-options/industries';
import { countries } from '../../dropdown-options/countries';

class CustomizedAxisTick extends PureComponent {
    render() {
        const {
            x, y, stroke, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" transform="rotate(-20)">{payload.value}</text>
            </g>
        );
    }
}

const TotalAverageInvestments = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // realm functions declaration
    const { currentUser, logInAnonymous } = useRealmApp();

    // to check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // chart function declaration
    const { investmentPortfolioAnalysis } = currentUser.functions;

    // hook for user input
    const [filters, setFilters] = useState({
        type: "Total investments",
        fundingStage: "Funding Stage",
        yearStart: "Year Start",
        yearEnd: "Year End",
        industry: "Industry",
        location: "Location"
    });

    // hook for results
    const [investments, setInvestments] = useState(
        [
            {
                "fundingStatus": "Accelerator",
                "Total investment": "$100K",
                "Frequency": 1
            },
            {
                "fundingStatus": "Angel",
                "Total investment": "$297M",
                "Frequency": 346
            },
            {
                "fundingStatus": "Convertible Note",
                "Total investment": "$50M",
                "Frequency": 54
            },
            {
                "fundingStatus": "Crowdfunding",
                "Total investment": "$582K",
                "Frequency": 3
            },
            {
                "fundingStatus": "Debt Financing",
                "Total investment": "$3M",
                "Frequency": 9
            },
            {
                "fundingStatus": "Initial Coin Offering",
                "Total investment": "No Data",
                "Frequency": 1
            },
            {
                "fundingStatus": "Non-Equity Assistance",
                "Total investment": "$13M",
                "Frequency": 2
            },
            {
                "fundingStatus": "Pre-Seed",
                "Total investment": "$90M",
                "Frequency": 160
            },
            {
                "fundingStatus": "Undisclosed",
                "Total investment": "$2M",
                "Frequency": 7
            },
            {
                "fundingStatus": "Venture Round",
                "Total investment": "$25M",
                "Frequency": 45
            }
        ]
    );

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            type: "Total investments",
            fundingStage: "Funding Stage",
            yearStart: "Year Start",
            yearEnd: "Year End",
            industry: "Industry",
            location: "Location"
        });
        setAlert(false);
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        setFilters({ ...filtersCopy });
    }

    // function to submit form
    function submitFilters(e) {
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        investmentPortfolioAnalysis(filters)
            .then(response => {
                let graphData = response;
                setInvestments(graphData);
                closeAlert();
            })
            .catch(error => {
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Your filter query returned no results. Please change your filter values and try again.</p>
                </div>);
            })
        e.preventDefault();
    }

    return (
        <Card.Body className="accordion-card-body">

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div className="col-12 div-filter">
                <Form onSubmit={submitFilters}>
                    <Form.Group>
                        <Form.Row className="align-items-center">
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.fundingStage} as="select" name="fundingStage" onChange={changeFilters}>
                                    <option>Funding Stage</option>
                                    <option>Very-Early Stages</option>
                                    <option>Early Stages</option>
                                    <option>Growth Stages</option>
                                    <option>Late Stages</option>
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.yearStart} disabled={filters.fundingStage === "Funding Stage" ? true : false}
                                    as="select" name="yearStart" onChange={changeFilters}>
                                    <option>Year Start</option>
                                    {
                                        ventureYearsStart.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.yearEnd} disabled={filters.fundingStage === "Funding Stage" ? true : false}
                                    as="select" name="yearEnd" onChange={changeFilters}>
                                    <option>Year End</option>
                                    {
                                        ventureYearsEnd.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.industry} disabled={filters.fundingStage === "Funding Stage" ? true : false}
                                    as="select" name="industry" onChange={changeFilters}>
                                    <option>Industry</option>
                                    {
                                        industriesOld.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.location} disabled={filters.fundingStage === "Funding Stage" ? true : false}
                                    as="select" name="location" onChange={changeFilters}>
                                    <option>Location</option>
                                    {
                                        countries.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto">
                                <button onClick={resetFilters} className="reset-button tour-clear-filter">
                                    <span>Clear</span>
                                </button>
                            </Col>
                            <Col xs="auto">
                                <button type="submit" className="submit-button">
                                    <span>Filter</span>
                                </button>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>
            </div>

            <div className="col-12">
                <Card className="graph-card">
                    <div>
                        <ResponsiveContainer id="vc-charts">
                            <ComposedChart data={investments}
                                margin={{ top: 20, right: 20, left: 20, bottom: 100 }}
                                width={1100}
                                height={500}
                                fontFamily="AileronRegular"
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="fundingStatus"
                                    interval={0}
                                    fontSize="11px"
                                    fontFamily="AileronRegular"
                                    tick={<CustomizedAxisTick />}
                                    label={{ value: "Funding Stage", offset: -80, position: "insideBottom" }} />
                                <YAxis fontSize="15px"
                                    fontFamily="AileronRegular"
                                    label={{ value: "Frequency", angle: -90, position: "insideLeft" }} />
                                <Tooltip />
                                <Bar dataKey="Frequency" fill="#8884d8" barSize={50} />
                                <Line type="monotone" dataKey="Total investment" stroke="#264653" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </div>

            <div className="col-12 extra-notes">
                <span className="info-notes">
                    Note:
                    <ul>
                        <li id="notes-points">
                            A total of 13,055 investments were raised by  8,055 companies in the <span style={{ fontFamily: "AileronItalic" }}>very-early stage, early stage, growth stage, and late stage</span> from June 1983 to Sept 2020.
                        </li>
                        <li id="notes-points">
                            Top 10 funding rounds (
                            <span style={{ fontFamily: "AileronItalic" }}>in order from highest to lowest no. of investments made</span>): seed, series A, series B, series C, venture round, series D, angel, convertible note, series E, pre-seed.
                        </li>
                    </ul>
                </span>
            </div>
        </Card.Body>
    );
}

export default TotalAverageInvestments;