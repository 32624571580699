export const inquiry = [
    { "key": "Technical issue" },
    { "key": "Pricing, billing and licensing" },
    { "key": "Data integrity" },
    { "key": "Social community" },
    { "key": "Affiliate partnership" },
    { "key": "Product advice" },
    { "key": "Startup competition" },
    { "key": "Sponsorship" },
    { "key": "Research collaboration" },
    { "key": "Media inquiry" },
    { "key": "Speaking engagement" },
    { "key": "Career opportunity" },
    { "key": "General inquiry" },
    { "key": "Feedback for our founders" },
    { "key": "Other" },
];