import React from 'react';
import { Card } from 'react-bootstrap';
import '../data-contribution/data-contribution.css';

function GeneralInformation(props) {

    return (
        <>
            <Card style={{ marginTop: "40px" }}>
                <Card.Body id="companyinformation">
                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Entity name</p>
                            <p className="companydetaildata">{!props?.entityName ? "-" : props.entityName}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Legal name</p>
                            <p className="companydetaildata">{!props?.legalName ? "-" : props.legalName}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Former legal name(s)</p>
                            <p className="companydetaildata">{!props?.formerLegalName ? "-" : props.formerLegalName.join(", ")}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Other business name(s)</p>
                            <p className="companydetaildata">{!props?.otherBusinessName ? "-" : props.otherBusinessName.join(", ")}</p>
                        </div>
                    </div>

                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Entity type</p>
                            <p className="companydetaildata">{!props?.entityType ? "-" : props.entityType}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Investor types</p>
                            <p className="companydetaildata">{!props?.investorTypes ? "-" : props.investorTypes.join(", ")}</p>
                        </div>
                    </div>

                    <div>
                        <p className="companydetailtitle">Short description</p>
                        <p className="companydetaildata">{!props?.shortDescription ? "-" : props.shortDescription}</p>
                    </div>

                    <div>
                        <p className="companydetailtitle">Long description</p>
                        <p className="companydetaildata">{!props?.longDescription ? "-" : props.longDescription}</p>
                    </div>

                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Operational status</p>
                            <p className="companydetaildata">{!props?.operationalStatus ? "-" : props.operationalStatus}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Founded date</p>
                            <p className="companydetaildata">{!props?.foundedDate ? "-" : props.foundedDate}</p>
                        </div>
                    </div>

                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">City</p>
                            <p className="companydetaildata">{!props?.city ? "-" : props.city}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">State</p>
                            <p className="companydetaildata">{!props?.state ? "-" : props.state}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Country</p>
                            <p className="companydetaildata">{!props?.country ? "-" : props.country}</p>
                        </div>
                    </div>

                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Categories</p>
                            <p className="companydetaildata">{!props?.categories ? "-" : props.categories.join(", ")}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Ticker symbol</p>
                            <p className="companydetaildata">{!props?.tickerSymbol ? "-" : props.tickerSymbol}</p>
                        </div>
                    </div>

                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Email</p>
                            <p className="companydetaildata">{!props?.email ? "-" : <a href={"mailto:" + props.email} className="shutdowndatalink">{props.email}</a>}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Telephone</p>
                            <p className="companydetaildata">{!props?.telephone ? "-" : props.telephone}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Website</p>
                            <p className="companydetaildata">{!props?.website
                                ? "-"
                                : <ul>{props.website.map((element, index) => <li className="pitchdeckanswers"><a key={index} href={element} className="shutdowndatalink" target="_blank" rel="noopener noreferrer">{element}</a></li>)}</ul>
                            }</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Social media</p>
                            <p className="companydetaildata">{!props?.socialMedia
                                ? "-"
                                : <ul>{props.socialMedia.map((element, index) => <li className="pitchdeckanswers"><a key={index} href={element} className="shutdowndatalink" target="_blank" rel="noopener noreferrer">{element}</a></li>)}</ul>
                            }</p>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            {!props?.currentWork.currentDesignation && !props?.currentWork.currentOrganization
                ? ""
                : <Card style={{ marginTop: "40px" }}>
                    <Card.Body id="companyinformation">
                        <div>
                            <p className="companydetailtitle">Current designation</p>
                            <p className="companydetaildata">
                                <ol>
                                    <li className="pitchdeckanswers">{!props?.currentWork.currentDesignation ? "-" : props.currentWork.currentDesignation}</li>
                                    <ul>
                                        <li className="pitchdeckanswers">Current organization: {!props?.currentWork.currentOrganization ? "-" : props.currentWork.currentOrganization}</li>
                                    </ul>
                                </ol>
                            </p>
                        </div>
                    </Card.Body>
                </Card>
            }

            {!props?.previousWork || props?.previousWork.length === 0
                ? ""
                : <Card style={{ marginTop: "40px" }}>
                    <Card.Body id="companyinformation">
                        <div>
                            <p className="companydetailtitle">Previous designation</p>
                            <p className="companydetaildata">
                                <ol>{props.previousWork.map((element, index) =>
                                (<>
                                    <li className="pitchdeckanswers" key={index}>{!element.jobTitle ? "-" : element.jobTitle}</li>
                                    <ul>
                                        <li className="pitchdeckanswers" key={index}>Organization: {!element.prevCompany ? "-" : element.prevCompany}</li>
                                        <li className="pitchdeckanswers" key={index}>Duration: {!element.duration ? "-" : element.duration}</li>
                                    </ul>
                                </>)
                                )}
                                </ol>
                            </p>
                        </div>
                    </Card.Body>
                </Card>
            }
        </>
    );
}

export default GeneralInformation