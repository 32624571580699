import { TextField } from "@mui/material";
import { Form } from "react-bootstrap";

const FormCellDate = ({ props, actualName = "", displayName = "", bottomText }) => {
  return (
    <div className="ideacheckeritem">
      <Form.Label>{displayName}</Form.Label>
      <TextField
        value={props.state[actualName]}
        className="dobinputbox"
        type="date"
        onChange={(e) => {
          props.setState({
            type: "update value to state property",
            stateName: actualName,
            value: e.target.value,
          });
        }}
      />
      <Form.Text className="text-muted">{bottomText}</Form.Text>
    </div>
  );
};

export default FormCellDate;