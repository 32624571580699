import React, { useState } from 'react'
import './confirm-password-reset-form.css'
import { Card, Button, Form, Alert } from 'react-bootstrap'
import HeaderLogo from '../../../assets/navbar/roiquant-600x200px-white-01.png';


function ConfirmPasswordResetForm(props) {
  

  

  


  const handleSubmit = event => {
    event.preventDefault();
  }

  const [passwordShown, setPasswordShown] = useState(false);

  //Password toggle handler (New password)
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [cpasswordShown, setcPasswordShown] = useState(false);

  //Password toggle handler (Confirm new password)
  const togglecPassword = () => {
    setcPasswordShown(!cpasswordShown);
  };

  return (
    <div id="resetpasswordcontent">

      <div className="resetpasswordtop">
        <a href="https://www.roiquant.com/" target="_blank" rel="noopener noreferrer">
          <img src={HeaderLogo} alt="roiquant" width="180px" height="60px" className="roiquantlogo" />
        </a>
      </div>

      <div>{props.response}</div>

      <div id="resetpasswordform">
        <Card className="resetpasswordcard">
          <div className="resetpasswordheader">
            <h3>Password reset confirmation</h3>
          </div>

          <div className="createaccnote">
            <p>For security reasons, please confirm your password reset.</p>
          </div>

          <Form className="resetpasswordformbox" onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Control className="inputbox" type={passwordShown ? "text" : "password"} name="newpassword" placeholder="Enter new password"
                onChange={(e) => { props.setPwds(Object.assign({}, props.pwds, { newPwd: e.target.value })) }}
              />

              <a href="#" className='showpassword' onClick={togglePassword}>{passwordShown ? "Hide" : "Show"} password</a>
            </Form.Group>

            <Form.Group>
              <Form.Control className="inputbox" type={cpasswordShown ? "text" : "password"} name="confirmpassword" placeholder="Confirm new password"
                onChange={(e) => { props.setPwds(Object.assign({}, props.pwds, { confirmPwd: e.target.value })) }}
              />

              <a href="#" className='showcpassword' onClick={togglecPassword}>{cpasswordShown ? "Hide" : "Show"} password</a>
            </Form.Group>

            <div id="validation" style={{ width: "100%", display: "flex", flexDirection: "row" }}>

              <Form.Group controlId="formNewPassword"
                style={{ width: "50%", paddingRight: "1.5%" }}
              >

                <div id="checksField">
                  <p id="char" style={{ color: props.measurePwdLength(props.pwds.newPwd) == 0 ? "grey" : props.checkPwdLength(props.pwds.newPwd) ? "green" : "red" }}>At least 8 characters or as long as possible</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.pwds.newPwd) == 0 ? "grey" : props.checkPwdSymbol(props.pwds.newPwd) ? "green" : "red" }}>Include at least 1 symbol character </p>

                  <p id="char" style={{ color: props.measurePwdLength(props.pwds.newPwd) == 0 ? "grey" : props.checkPwdUppercase(props.pwds.newPwd) ? "green" : "red" }}>At least 1 uppercase letter</p>
                </div>

              </Form.Group>

              <Form.Group
                style={{ width: "50%", paddingLeft: "1.5%" }} >

                <div id="checksField">
                  <p id="char" style={{ color: props.measurePwdLength(props.pwds.newPwd) == 0 ? "grey" : props.checkPwdNum(props.pwds.newPwd) ? "green" : "red" }}>Include at least 1 number</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.pwds.newPwd) == 0 ? "grey" : props.checkPwdLowercase(props.pwds.newPwd) ? "green" : "red" }}>At least 1 lowercase letter</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.pwds.newPwd) == 0 ? "grey" : props.checkPwdMatch(props.pwds.newPwd, props.pwds.confirmPwd) ? "green" : "red" }}>Both passwords must match </p>
                </div>

              </Form.Group>

            </div>

            <div style={{ textAlign: "center" }}>
              <Button type="submit" className="resetpasswordbtn glintanimation" onClick={() => { props.handleCompletePwdChange(props.pwds, props.token, props.tokenId, props.user) }}>
                <span>Confirm password reset</span>
              </Button>
            </div>

          </Form>

          <div className="loginnote">
            <span>
              Forgot your new password? <br></br><a href="/reset-password">Try resetting your password again</a>
            </span>
          </div>

        </Card>
      </div>
    </div>
  )
}

export default ConfirmPasswordResetForm