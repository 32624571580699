import React, { useState, useEffect } from 'react';
import NavBar from '../../../layout/navbar/navbar';
import SampleImage from '../../../../assets/logo/roiquant - logo not found.png';
import './competitive-landscape.css';
import { Button, Card, Accordion, Alert, Spinner, Container } from 'react-bootstrap';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { FaEllipsisH, FaChevronDown } from 'react-icons/fa';
import { BiAnalyse } from 'react-icons/bi';
import { useRealmApp } from '../../../RealmApp';
import { S3Media } from '../../../third-party-services';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const ViewCompetitiveLandscape = () => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // get document id
    const { documentId } = useParams();

    // get current user
    const { currentUser } = useRealmApp();

    // check if user is logged in
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // declare realm functions
    const { getMyCompetitiveLandscapeAnalysis } = currentUser.functions;

    // hook for input
    const [input, setInput] = useState(null);

    // hook for results
    const [results, setResults] = useState(null);

    // get document on page load
    async function initState(documentId) {
        try {
            const response = await getMyCompetitiveLandscapeAnalysis(documentId);

            setInput(response.input);
            setResults(response.result);
            setAlert(false);

        } catch (error) {
            // console.error("Failed to get data: ", error);
        }
    }

    useEffect(() => {
        initState(documentId);
    }, []);

    return (
        <>
            {/* navbar */}
            <NavBar />

            {/* loading alert */}
            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <Container id="competitivelandscape" className="col-11">
                <div className="pitchdeckheaderdiv">
                    {/* title */}
                    <div className="pitchdecktitlediv">
                        <br />
                        <span className="pitchdecktext">
                            {!input?.competitiveLandscapeName
                                ? ("Untitled analysis")
                                : input.competitiveLandscapeName
                            }
                        </span>
                    </div>

                    {/* statistics table */}
                    <div className="competitivelandscapestatsdiv">
                        <table style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td className="table-data db-label" colSpan="2">Industry statistics</td>
                                </tr>
                                <tr>
                                    <td className="table-data db-header">Total shutdowns</td>
                                    <td className="table-data db-header">Total labor force</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-data db-data">{!results?.statistics?.totalShutdowns ? "0" : Math.round(results.statistics.totalShutdowns).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td className="table-data db-data">{!results?.statistics?.totalLabourForces ? "0" : Math.round(results.statistics.totalLabourForces).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* card */}
                <Accordion className="companyaccordion" defaultActiveKey="howCompetitiveIsYourMarketLandscape">
                    <Card>
                        <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="howCompetitiveIsYourMarketLandscape">
                            <div className="companyarrowicon">
                                <FaChevronDown />
                            </div>

                            <div className="companytitlecard">
                                How competitive is your market landscape?
                            </div>

                            <div className="companyellipsisicon">
                                <FaEllipsisH />
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="howCompetitiveIsYourMarketLandscape">
                            <Card.Body id="companyinformation">

                                <div className="companydetailrows">
                                    <div className="pitchdeckdetaildiv">
                                        <p className="companydetailtitle">
                                            Industry
                                            <sup data-tip="Select the industrial grouping that best describe the production process, functions, and operations of your core business">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </p>
                                        {!input?.industry
                                            ? (<p className="companydetaildata">-</p>)
                                            : (<p className="companydetaildata">{input.industry}</p>)
                                        }
                                    </div>

                                    <div className="pitchdeckdetaildiv">
                                        <p className="companydetailtitle">
                                            Category(ies)
                                            <sup data-tip="Select the categories that best define the specific niche of your business focus in a vertical market.">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </p>
                                        {!input?.categories
                                            ? (<p className="companydetaildata">-</p>)
                                            : (<p className="companydetaildata">{input.categories.join(", ")}</p>)
                                        }
                                    </div>

                                    <div className="pitchdeckdetaildiv">
                                        <p className="companydetailtitle">
                                            Location(s)
                                            <sup data-tip="Select the location where your company is headquartered at">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </p>
                                        {!input?.locations
                                            ? (<p className="companydetaildata">-</p>)
                                            : (<p className="companydetaildata">{input.locations.join(", ")}</p>)
                                        }
                                    </div>

                                    <div className="pitchdeckdetaildiv">
                                        <p className="companydetailtitle">
                                            Financing status(es)
                                            <sup data-tip="Select your market location(s) where you offer and sell your product-or-solution">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </p>
                                        {!input?.financingStatus
                                            ? (<p className="companydetaildata">-</p>)
                                            : (<p className="companydetaildata">{input.financingStatus.join(", ")}</p>)
                                        }
                                    </div>
                                </div>

                                <div className="companydetailrows">
                                    <div className="pitchdeckdetaildiv">
                                        <p className="companydetailtitle">
                                            Operational status(es)
                                            <sup data-tip="Select the business entity type that dictates both the legal structure of your organization and how your company will be taxed">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </p>
                                        {!input?.operationalStatus
                                            ? (<p className="companydetaildata">-</p>)
                                            : (<p className="companydetaildata">{input.operationalStatus.join(", ")}</p>)
                                        }
                                    </div>

                                    <div className="pitchdeckdetaildiv">
                                        <p className="companydetailtitle">
                                            Labour force(s)
                                            <sup data-tip="Describe your current stage of business development">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </p>
                                        {!input?.labourForces
                                            ? (<p className="companydetaildata">-</p>)
                                            : (<p className="companydetaildata">{input.labourForces.join(", ")}</p>)
                                        }
                                    </div>

                                    <div className="pitchdeckdetaildiv">
                                        <p className="companydetailtitle">
                                            Similarity score(s)
                                            <sup data-tip="Select the transactional model type that best describe the relationship of transactions between your organization and your counterparts">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </p>
                                        {!input?.similarityScores
                                            ? (<p className="companydetaildata">-</p>)
                                            : (<p className="companydetaildata">{input.similarityScores.join(", ")}</p>)
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

                {/* results */}
                <div className="competitivelandscapediv">
                    {/* industry cards - limited to 12 */}
                    <div className="competitive-landscape-cards competitivecardrow" style={{ paddingBottom: "200px" }}>
                        {results?.groups?.length > 0
                            ? results?.groups.slice(0, 12).map((group, index) =>
                                <div className="competitive-landscape-cards-row" key={index}>
                                    <div className="competitivecardcard competitivecardf competitivecardimgthumbnail competitivecardfxlayout1">
                                        <div className="competitive-landscape-card-header">
                                            <div className="competitive-landscape-card-title">{group._id}</div>

                                            <div className="competitive-landscape-card-ellipsis">
                                                {/* <span className="competitivelandscapecatscore">87.13%</span> */}
                                                <FaEllipsisH className="competitivelandscapeellipsisicon" style={{ marginLeft: "10px" }} />
                                            </div>
                                        </div>

                                        {/* companies statistics */}
                                        <div className="competitivelandscapecategorystats">
                                            <table style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <td className="competitivelandscapetablecategoryheader">
                                                            Total shutdowns
                                                        </td>
                                                        <td className="competitivelandscapetablecategoryheader">
                                                            Total labor force
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="competitivelandscapetablecategorydata">
                                                            {group.statuses.map((status) =>
                                                                !status.total
                                                                    ? "0"
                                                                    : Math.round(status.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            )}
                                                        </td>
                                                        <td className="competitivelandscapetablecategorydata">
                                                            {!group.labourForces
                                                                ? "0"
                                                                : Math.round(group.labourForces).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <hr className="competitivelandscapeline" />

                                        {/* companies logo - limited to 15 */}
                                        <div className="competitivecardcompanycard competitivelogorow competitivecardml1 competitivecardmr0">
                                            {group.articles.slice(0, 15).map((article, index) =>
                                                <div className="competitivelogocardrow" key={index}>
                                                    <div className="competitivelogof competitivecardimgthumbnail competitivelogofxlayout1">
                                                        {!article.logo
                                                            ? (<img src={SampleImage} alt={article.name} className="competitivelandscapelogo" />)
                                                            : (<img src={S3Media + 'assets/photos/logo/' + article.logo} alt={article.name} className="competitivelandscapelogo" />)
                                                        }
                                                    </div>
                                                    <p className="competitivelandscapename">{article.name}</p>
                                                </div>
                                            )}
                                        </div>

                                        {/* number of companies */}
                                        <div className="competitivelandscapeindex">
                                            {group.articles.length > 1
                                                ? group.articles.length > 15
                                                    ? (<span>15 of {group.articles.length} companies</span>)
                                                    : (<span>{group.articles.length} of {group.articles.length} companies</span>)
                                                : (<span>{group.articles.length} of {group.articles.length} company</span>)
                                            }
                                        </div>
                                    </div>
                                </div>)
                            : ""
                        }
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ViewCompetitiveLandscape;