import React, { useState } from 'react'
import './create-account-form.css'
import { Card, Button, Form } from 'react-bootstrap'
import HeaderLogo from '../../../assets/navbar/roiquant-600x200px-white-01.png';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { knowYouBetter } from '../dropdown-options/know-you-better';
import { hearAboutUs } from '../dropdown-options/hear-about-us';

function CreateAccountForm(props) {





  const [passwordShown, setPasswordShown] = useState(false);

  //Password toggle handler (New password)
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [cpasswordShown, setcPasswordShown] = useState(false);

  //Password toggle handler (Confirm new password)
  const togglecPassword = () => {
    setcPasswordShown(!cpasswordShown);
  };

  return (
    <div id="createaccountcontent">

      <div className="createaccounttop">
        <a href="https://www.roiquant.com/" target="_blank" rel="noopener noreferrer">
          <img src={HeaderLogo} alt="roiquant" width="180px" height="60px" className="roiquantlogo" />
        </a>
      </div>

      <div>
        {props.response}
      </div>

      <div id="createaccountform">
        <Card className="createaccountcard">
          <div className="createaccountheader">
            <h3>Create free account</h3>
          </div>

          <Form>

            <p style={{ fontSize: "14px" }}>Please fill in all required fields marked with asterisks (<sup>*</sup>).</p>

            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <Form.Group style={{ width: "50%", paddingRight: "1.5%" }}>
                <Form.Control required className="inputbox" type="text" name="firstname" placeholder="First name*"
                  onChange={(e) => props.setNewUser(Object.assign({}, props.newUser, { firstname: e.target.value }))}
                />
              </Form.Group>

              <Form.Group style={{ width: "50%" }}>
                <Form.Control required className="inputbox" type="text" name="lastname" placeholder="Last name*"
                  onChange={(e) => props.setNewUser(Object.assign({}, props.newUser, { lastname: e.target.value }))}
                />
              </Form.Group>
            </div>

            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <Form.Group style={{ width: "50%", paddingRight: "1.5%" }}>
                <Form.Control required className="inputbox" type="text" name="username" placeholder="Username*"
                  onChange={(e) => props.setNewUser(Object.assign({}, props.newUser, { username: e.target.value }))}
                />
              </Form.Group>

              <Form.Group style={{ width: "50%" }} controlId="formBasicEmail">
                <Form.Control required className="inputbox" type="email" name="email" placeholder="Email*"
                  onChange={(e) => props.setNewUser(Object.assign({}, props.newUser, { email: e.target.value }))}
                />
              </Form.Group>
            </div>

            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <Form.Group style={{ width: "50%", paddingRight: "1.5%" }} controlId="formBasicPassword">
                <Form.Control required className="inputbox" type={passwordShown ? "text" : "password"} name="password" placeholder="Password*"
                  onChange={(e) => props.setNewUser(Object.assign({}, props.newUser, { password: e.target.value }))}
                />

                <a href="#" className='showpassword' onClick={togglePassword}>{passwordShown ? "Hide" : "Show"} password</a>

                <div id="checksField">
                  <p id="char" style={{ color: props.measurePwdLength(props.newUser.password) == 0 ? "grey" : props.checkPwdLength(props.newUser.password) ? "green" : "red" }}>At least 8 characters or as long as possible</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.newUser.password) == 0 ? "grey" : props.checkPwdSymbol(props.newUser.password) ? "green" : "red" }}>Include at least 1 symbol character</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.newUser.password) == 0 ? "grey" : props.checkPwdUppercase(props.newUser.password) ? "green" : "red" }}>At least 1 uppercase letter</p>
                </div>

              </Form.Group>

              <Form.Group style={{ width: "50%" }} controlId="formBasicConfirmPassword">
                <Form.Control required className="inputbox" type={cpasswordShown ? "text" : "password"} name="confirmPassword" placeholder="Confirm password*"
                  onChange={(e) => props.setNewUser(Object.assign({}, props.newUser, { confirmPassword: e.target.value }))}
                />

                <a href="#" className='showpassword' onClick={togglecPassword}>{cpasswordShown ? "Hide" : "Show"} password</a>

                <div id="checksField">
                  <p id="char" style={{ color: props.measurePwdLength(props.newUser.password) == 0 ? "grey" : props.checkPwdNum(props.newUser.password) ? "green" : "red" }}>Include at least 1 number</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.newUser.password) == 0 ? "grey" : props.checkPwdLowercase(props.newUser.password) ? "green" : "red" }}>At least 1 lowercase letter</p>

                  <p id="char" style={{ color: props.measurePwdLength(props.newUser.password) == 0 ? "grey" : props.checkPwdMatch(props.newUser.password, props.newUser.confirmPassword) ? "green" : "red" }}>Both passwords must match</p>
                </div>
              </Form.Group>
            </div>

            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>

              <Form.Group style={{ width: "50%", paddingRight: "1.5%" }}
                controlId="formKnowYouBetter" name="knowYouBetter">
                We would like to know you better<sup>*</sup>
                <Form.Control required as="select" placeholder="We would like to know you better"
                  onChange={(e) => props.setNewUser(Object.assign({}, props.newUser, { knowYouBetter: e.target.value }))}>
                  <option></option>
                  {
                    knowYouBetter.map((option) =>
                      <option>{option.key}</option>
                    )
                  }
                </Form.Control>
              </Form.Group>

              <Form.Group style={{ width: "50%" }} controlId="formHearAboutUs" name="hearAboutUs">
                How did you hear about us?<sup>*</sup>
                <Form.Control required as="select" placeholder="How did you hear about us"
                  onChange={(e) => props.setNewUser(Object.assign({}, props.newUser, { hearAboutUs: e.target.value }))}>
                  <option></option>
                  {
                    hearAboutUs.map((option) =>
                      <option>{option.key}</option>
                    )
                  }
                </Form.Control>
              </Form.Group>
            </div>

            <div className="extranote">
              <span>
                By clicking below, you agree to roiquant's <a href="https://viewpoints.roiquant.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  privacy policy</a>, <a href="https://viewpoints.roiquant.com/how-do-we-use-cookies/" target="_blank" rel="noopener noreferrer">
                  cookies</a>, <a href="https://viewpoints.roiquant.com/user-notice/" target="_blank" rel="noopener noreferrer">
                  user notice</a>, <a href="https://viewpoints.roiquant.com/refund-policy/" target="_blank" rel="noopener noreferrer">
                  refund policy</a> and <a href="https://viewpoints.roiquant.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
                  terms of service</a>.
              </span>
            </div>

            <div style={{ textAlign: "center" }}>
              <Button className="createaccountbtn glintanimation" onClick={() => {
                props.handleRegistration(props.newUser)
              }} >
                <span>Agree & create</span>
              </Button>
            </div>

          </Form>

          <div className="loginnote">
            <span>Already got an account? <a href="/login">Log in</a></span>
          </div>

        </Card>
      </div>

    </div>
  )
}

export default CreateAccountForm