import React, { useState, useEffect } from 'react';
import NavBar from '../../layout/navbar/navbar';
import { Button, Form, Spinner, Alert, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from 'react-icons/fa';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList } from 'react-icons/bs';
import { AiOutlineDownload } from 'react-icons/ai';
import { CSVLink } from 'react-csv';
import { useRealmApp } from '../../RealmApp';
import '../data/shutdown-data/shutdown-data.css';
import './users.css';

const UsersList = () => {

    // hook for alert component - show alert on page load
    const [alert, setAlert] = useState(true);

    // hook for alert message - show message on page load
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    function scrollToTop() {
        window.scrollTo(0, 0);

        document.getElementById('table-data').scrollIntoView();
    }

    // realm functions declaration
    const { currentUser, logInAnonymous } = useRealmApp();

    // check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // table functions declaration
    const { usersCollection } = currentUser.functions;

    // hook for argument to realm function
    const [argument, setArgument] = useState({
        searchTerm: ""
    });

    // hook for table data
    const [tableData, setTableData] = useState({
        results: [],
        total: 0
    });

    // fetch data on page load
    useEffect(async () => {
        try {
            let response = await usersCollection(argument)
            setTableData(JSON.parse(response.body));
            // console.log(tableData);
        } catch (err) { }
        // close alert when data is received
        setAlert(false);
    }, []);

    // function to update hooks
    async function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // change search term value
        if (name === "searchTerm") {
            let argumentCopy = { ...argument };

            argumentCopy[name] = value;
            setArgument({ ...argumentCopy });
        }

        // only fetch data if numOfRows or currentPage is changed
        if (name.localeCompare("searchTerm") !== 0) {
            // show alert when data is fetching
            setAlert(true);
            setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
                <div style={{ width: "90%" }}>
                    <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                    <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
                </div>
                <div style={{ width: "10%", textAlign: "right" }}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            </div>);

            try {
                let response = await usersCollection(argument);
                setTableData(JSON.parse(response.body));
                // console.log(tableData);

                // show alert when data is received
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                    <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
                </div>);
            } catch (err) { }
        }
    }

    // function to submit search term
    async function submitSearch(e) {
        e.preventDefault();

        // show alert when data is fetching
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            let response = await usersCollection(argument)
            setTableData(JSON.parse(response.body));
            // console.log(tableData);

            // show alert when data is received
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
            </div>);
        } catch (err) { }
    }

    // function to listen to keyboard
    function enterSearch(e) {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            submitSearch(e);
        }
    };

    // function to reset search term
    async function resetSearch(e) {
        e.preventDefault();

        let argumentCopy = { ...argument },
            name = "searchTerm";

        argumentCopy[name] = "";
        setArgument({ ...argumentCopy });

        setTableData({
            results: [],
            total: 0
        });

        // show alert when data is fetching
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            let response = await usersCollection(argument);
            setTableData(JSON.parse(response.body));
            // console.log(tableData);

            // show alert when data is received
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View</a></p>
            </div>);
        } catch (err) { }
    }

    const list = tableData.results;
    let sortedData;
    let propComparator;

    function sortAscending(property) {

        if (property == "registerDate") {
            propComparator = (propName) => (a, b) =>
                a[propName] // if a != null
                    ? b[propName] // if b != null
                        ? a[propName] - b[propName] // if a & b != null, compare
                        : -1 // if a != null & b == null, return -1
                    : 1; // if a == null, return 1
        }
        else {
            propComparator = (propName) => (a, b) =>
                a[propName] // if a != null
                    ? b[propName] // if b != null
                        ? a[propName].localeCompare(b[propName]) // if a & b != null, compare
                        : -1 // if a != null & b == null, return -1
                    : 1; // if a == null, return 1
        }

        sortedData = list.sort(propComparator(property));

        let name = "results";
        let value = sortedData;

        // create a copy of tableData with desired changes
        let tableDataCopy = { ...tableData };

        tableDataCopy[name] = value;
        setTableData({ ...tableDataCopy });
    }

    function sortDescending(property) {

        if (property == "registerDate") {
            propComparator = (propName) => (a, b) =>
                a[propName] // if a != null
                    ? b[propName] // if b != null
                        ? b[propName] - a[propName] // if a & b != null, compare
                        : 1 // if a != null & b == null, return 1
                    : -1; // if a == null, return -1
        }
        else {
            propComparator = (propName) => (a, b) =>
                a[propName] // if a != null
                    ? b[propName] // if b != null
                        ? a[propName].localeCompare(b[propName]) // if a & b != null, compare
                        : 1 // if a != null & b == null, return 1
                    : -1; // if a == null, return -1
        }

        sortedData = list.sort(propComparator(property)).reverse();

        let name = "results";
        let value = sortedData;

        // create a copy of tableData with desired changes
        let tableDataCopy = { ...tableData };

        tableDataCopy[name] = value;
        setTableData({ ...tableDataCopy });
    }

    let currentDate = new Date().toJSON().slice(0, 10);

    return (
        <>
            <NavBar />

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="shutdowndata">
                <div className="shutdowndataheaderdiv">
                    <div className="shutdowndatatitlediv">
                        <br />
                        <span className="shutdowndatatext">
                            Users
                            <sup data-tip="User management portal is only accessible by roiquant administrators to manage our users.">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </span>
                    </div>

                    <div className="shutdowndatastatsdiv">
                        <table className="tg" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td className="table-data db-header">
                                        Total users
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-data db-data">
                                        {tableData.total}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="shutdowndatasearchdiv">
                    <div className="shutdowndatasearchbox">
                        <Form>
                            <Form.Group controlId="formSearch">
                                <Form.Control className="inputbox" value={argument.searchTerm} type="name" name="searchTerm" placeholder="Search from users list" onChange={changeFilters} onKeyDown={enterSearch} />
                            </Form.Group>
                        </Form>
                    </div>

                    <div className="shutdowndatasearchbuttons">
                        <button className="shutdowndataresetbutton" onClick={resetSearch}>
                            <span>Clear</span>
                        </button>

                        {
                            argument.searchTerm == null || argument.searchTerm == ""
                                ? <Button className="shutdowndatasearchbutton" disabled>
                                    <span>Search</span>
                                </Button>
                                : <Button className="shutdowndatasearchbutton" onClick={submitSearch}>
                                    <span>Search</span>
                                </Button>
                        }
                    </div>

                    <div className="shutdowndatadownloadbuttons">
                        <CSVLink data={tableData.results} filename={"users-list-" + currentDate + ".csv"}>
                            <Button className="download-users-button"><AiOutlineDownload /></Button>
                        </CSVLink>
                    </div>
                </div>

                <div className="shutdowndatadiv">
                    <table className="userstable" id="table-data">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th style={{ width: "20vw" }}>
                                    <Dropdown className="sort-column-button" as={ButtonGroup} alignRight>
                                        <Button disabled>Name</Button>

                                        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => sortAscending("name")}><FaSortAmountDownAlt /> Sort A to Z</Dropdown.Item>
                                            <Dropdown.Item onClick={() => sortDescending("name")}><FaSortAmountUpAlt /> Sort Z to A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </th>
                                <th>
                                    <Dropdown className="sort-column-button" as={ButtonGroup} alignRight>
                                        <Button disabled>First name</Button>

                                        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => sortAscending("firstname")}><FaSortAmountDownAlt /> Sort A to Z</Dropdown.Item>
                                            <Dropdown.Item onClick={() => sortDescending("firstname")}><FaSortAmountUpAlt /> Sort Z to A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </th>
                                <th>
                                    <Dropdown className="sort-column-button" as={ButtonGroup} alignRight>
                                        <Button disabled>Last name</Button>

                                        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => sortAscending("lastname")}><FaSortAmountDownAlt /> Sort A to Z</Dropdown.Item>
                                            <Dropdown.Item onClick={() => sortDescending("lastname")}><FaSortAmountUpAlt /> Sort Z to A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </th>
                                <th>
                                    <Dropdown className="sort-column-button" as={ButtonGroup} alignRight>
                                        <Button disabled>Username</Button>

                                        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => sortAscending("username")}><FaSortAmountDownAlt /> Sort A to Z</Dropdown.Item>
                                            <Dropdown.Item onClick={() => sortDescending("username")}><FaSortAmountUpAlt /> Sort Z to A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </th>
                                <th>
                                    <Dropdown className="sort-column-button" as={ButtonGroup} alignRight>
                                        <Button disabled>Email</Button>

                                        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => sortAscending("email")}><FaSortAmountDownAlt /> Sort A to Z</Dropdown.Item>
                                            <Dropdown.Item onClick={() => sortDescending("email")}><FaSortAmountUpAlt /> Sort Z to A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </th>
                                <th>
                                    <Dropdown className="sort-column-button" as={ButtonGroup} alignRight>
                                        <Button disabled>Account registration date</Button>

                                        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => sortAscending("registerDate")}><FaSortAmountDownAlt /> Sort A to Z</Dropdown.Item>
                                            <Dropdown.Item onClick={() => sortDescending("registerDate")}><FaSortAmountUpAlt /> Sort Z to A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </th>
                                <th>
                                    <Dropdown className="sort-column-button" as={ButtonGroup} alignRight>
                                        <Button disabled>We would like to know you better</Button>

                                        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => sortAscending("knowYouBetter")}><FaSortAmountDownAlt /> Sort A to Z</Dropdown.Item>
                                            <Dropdown.Item onClick={() => sortDescending("knowYouBetter")}><FaSortAmountUpAlt /> Sort Z to A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </th>
                                <th>
                                    <Dropdown className="sort-column-button" as={ButtonGroup} alignRight>
                                        <Button disabled>How did you hear about us?</Button>

                                        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => sortAscending("hearAboutUs")}><FaSortAmountDownAlt /> Sort A to Z</Dropdown.Item>
                                            <Dropdown.Item onClick={() => sortDescending("hearAboutUs")}><FaSortAmountUpAlt /> Sort Z to A</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                tableData.total == 0
                                    ? (<tr>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>)
                                    : tableData.results.map((user, index) =>
                                        <tr key={index}>
                                            <td>{index + 1}.</td>
                                            <td><p className="detaillongdata">{!user.name ? "-" : user.name}</p></td>
                                            <td><p className="detaillongdata">{!user.firstname ? "-" : user.firstname}</p></td>
                                            <td><p className="detaillongdata">{!user.lastname ? "-" : user.lastname}</p></td>
                                            <td><p className="detaillongdata">{!user.username ? "-" : user.username}</p></td>
                                            <td><p className="detaillongdata">{!user.email ? "-" : user.email}</p></td>
                                            <td><p className="detaillongdata">{!user.registerDate ? "-" : user.registerDate.substring(0, 10)}</p></td>
                                            <td><p className="detaillongdata">{!user.knowYouBetter ? "-" : user.knowYouBetter}</p></td>
                                            <td><p className="detaillongdata">
                                                {
                                                    !user.hearAboutUs
                                                        ? "-"
                                                        : user.hearAboutUs == "Recommended by a friend or colleague"
                                                            ? user.hearAboutUs + ": " + user.recommend
                                                            : user.hearAboutUs == "Other"
                                                                ? user.hearAboutUs + ": " + user.other
                                                                : user.hearAboutUs
                                                }
                                            </p></td>
                                        </tr>
                                    )
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default UsersList;