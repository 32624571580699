import React, { useState } from 'react';
import { Card, Form, Alert, Button, Spinner } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { BsCardList } from 'react-icons/bs'
import './versions.css';

const BasicVersion = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    // realm function starts here
    const { basicPreRevenueMarketSizing } = props.currentUser.functions;

    // hook for form
    const [filters, setFilters] = useState({
        numOfTargetCustomersTAMHigh: 0,
        numOfTargetCustomersTAMMedium: 0,
        numOfTargetCustomersTAMLow: 0,
        priceOfProductTAMHigh: 0,
        priceOfProductTAMMedium: 0,
        priceOfProductTAMLow: 0,
        penetrationRateSAMHigh: 0,
        penetrationRateSAMMedium: 0,
        penetrationRateSAMLow: 0,
        penetrationRateSOMHigh: 0,
        penetrationRateSOMMedium: 0,
        penetrationRateSOMLow: 0
    });

    // variable for empty results
    const zero = 0;

    // hook for results
    const [results, setResults] = useState({
        high: {},
        medium: {},
        low: {}
    });

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            numOfTargetCustomersTAMHigh: 0,
            numOfTargetCustomersTAMMedium: 0,
            numOfTargetCustomersTAMLow: 0,
            priceOfProductTAMHigh: 0,
            priceOfProductTAMMedium: 0,
            priceOfProductTAMLow: 0,
            penetrationRateSAMHigh: 0,
            penetrationRateSAMMedium: 0,
            penetrationRateSAMLow: 0,
            penetrationRateSOMHigh: 0,
            penetrationRateSOMMedium: 0,
            penetrationRateSOMLow: 0
        });
        setResults({
            high: {},
            medium: {},
            low: {}
        });
        closeAlert();
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        setFilters({ ...filtersCopy });
    }

    // function to submit form
    async function submitFilters(e) {
        e.preventDefault();

        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Simulating</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your results are ready.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        let form = {
            numOfTargetCustomersTAMHigh: parseInt(filters.numOfTargetCustomersTAMHigh),
            numOfTargetCustomersTAMMedium: parseInt(filters.numOfTargetCustomersTAMMedium),
            numOfTargetCustomersTAMLow: parseInt(filters.numOfTargetCustomersTAMLow),
            priceOfProductTAMHigh: parseInt(filters.priceOfProductTAMHigh),
            priceOfProductTAMMedium: parseInt(filters.priceOfProductTAMMedium),
            priceOfProductTAMLow: parseInt(filters.priceOfProductTAMLow),
            penetrationRateSAMHigh: parseInt(filters.penetrationRateSAMHigh),
            penetrationRateSAMMedium: parseInt(filters.penetrationRateSAMMedium),
            penetrationRateSAMLow: parseInt(filters.penetrationRateSAMLow),
            penetrationRateSOMHigh: parseInt(filters.penetrationRateSOMHigh),
            penetrationRateSOMMedium: parseInt(filters.penetrationRateSOMMedium),
            penetrationRateSOMLow: parseInt(filters.penetrationRateSOMLow)
        };

        // console.log("Form:", form)

        try {
            const response = await basicPreRevenueMarketSizing(form);

            // const response = {
            //     "statusCode": 200,
            //     "body": {
            //         "High": {
            //             "forecastedRevenueTAM": 42300.0,
            //             "numOfTargetCustomersSAM": 200.0,
            //             "priceOfProductSAM": 84.6,
            //             "forecastedRevenueSAM": 16920.0,
            //             "numOfTargetCustomersSOM": 80.0,
            //             "priceOfProductSOM": 84.6,
            //             "forecastedRevenueSOM": 6768.0
            //         },
            //         "Medium": {
            //             "forecastedRevenueTAM": 50760.0,
            //             "numOfTargetCustomersSAM": 180.0,
            //             "priceOfProductSAM": 84.60,
            //             "forecastedRevenueSAM": 15227.999999999998,
            //             "numOfTargetCustomersSOM": 54.0,
            //             "priceOfProductSOM": 84.60,
            //             "forecastedRevenueSOM": 4568.4
            //         },
            //         "Low": {
            //             "forecastedRevenueTAM": 8460.0,
            //             "numOfTargetCustomersSAM": 20.0,
            //             "priceOfProductSAM": 84.60,
            //             "forecastedRevenueSAM": 1692.0,
            //             "numOfTargetCustomersSOM": 4.0,
            //             "priceOfProductSOM": 84.60,
            //             "forecastedRevenueSOM": 338.4
            //         }
            //     }
            // };

            // console.log("Response:", response);
            const data = JSON.parse(response).body;
            // const data = response.body;
            // console.log("Data:", data);

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#1f5f8b" }}><BsCardList className="alert-icon" /> Results</p>
                <p style={{ marginLeft: "28px" }}>Your results are ready. <a href="#" className="alert-cta" onClick={scrollToTop}>View results</a></p>
            </div>);

            setResults({
                high: data.High,
                medium: data.Medium,
                low: data.Low
            });

            // console.log("Results:", results);

        } catch (err) {
            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                <p style={{ marginLeft: "28px" }}>Please try again.</p>
            </div>);

            // console.error(err);
        }
    }

    // function to submit form on enter
    function enterFilters(e) {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            submitFilters(e);
        }
    };

    return (
        // card as method
        <Card.Body className="marketsizingversionsaccordioncardbody">

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            {/* form */}
            <div className="col-12 marketsizingversionsdivfilter">
                <Form>
                    <Form.Group>

                        <div className="marketsizingversionsrow2">
                            <div>
                                <div className="marketsizingversionsrow">
                                    <table className="marketsizingversionshistorytable">
                                        <thead>
                                            <tr className="marketsizingversionstablerowresult" style={{ borderTop: "none" }}>
                                                <th colSpan={2} className="marketsizingversionstabledataresultlast"></th>
                                                <th className="marketsizingversionstabledataresultlast">High</th>
                                                <th className="marketsizingversionstabledataresultlast">Medium</th>
                                                <th className="marketsizingversionstabledataresultlast">Low</th>
                                            </tr>
                                        </thead>
                                        {/* form fields */}
                                        <tbody>
                                            <tr className="marketsizingversionstablerowresult">
                                                <th rowSpan={3} className="marketsizingversionstabledataresult">Total addressable market (TAM)</th>
                                                <th className="marketsizingversionstabledataresult">No. of target customers</th>
                                                <td className="marketsizingversionstabledataresult">
                                                    <Form.Control type="number" name="numOfTargetCustomersTAMHigh" value={filters.numOfTargetCustomersTAMHigh} onChange={changeFilters} />
                                                </td>
                                                <td className="marketsizingversionstabledataresult">
                                                    <Form.Control type="number" name="numOfTargetCustomersTAMMedium" value={filters.numOfTargetCustomersTAMMedium} onChange={changeFilters} />
                                                </td>
                                                <td className="marketsizingversionstabledataresultlast">
                                                    <Form.Control type="number" name="numOfTargetCustomersTAMLow" value={filters.numOfTargetCustomersTAMLow} onChange={changeFilters} />
                                                </td>
                                            </tr>

                                            <tr className="marketsizingversionstablerowresult">
                                                <th className="marketsizingversionstabledataresult">Price of product or service (USD $)</th>
                                                <td className="marketsizingversionstabledataresult">
                                                    <Form.Control type="number" name="priceOfProductTAMHigh" value={filters.priceOfProductTAMHigh} onChange={changeFilters} />
                                                </td>
                                                <td className="marketsizingversionstabledataresult">
                                                    <Form.Control type="number" name="priceOfProductTAMMedium" value={filters.priceOfProductTAMMedium} onChange={changeFilters} />
                                                </td>
                                                <td className="marketsizingversionstabledataresultlast">
                                                    <Form.Control type="number" name="priceOfProductTAMLow" value={filters.priceOfProductTAMLow} onChange={changeFilters} />
                                                </td>
                                            </tr>

                                            <tr className="marketsizingversionstablerowresult" style={{ borderBottom: "1px solid darkgrey" }}>
                                                <th className="marketsizingversionstabledataresult">Forecasted revenue</th>
                                                <td className="marketsizingversionstabledataresult">
                                                    USD $ {Object.keys(results.high).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.high.forecastedRevenueTAM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresult">
                                                    USD $ {Object.keys(results.medium).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.medium.forecastedRevenueTAM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresultlast">
                                                    USD $ {Object.keys(results.low).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.low.forecastedRevenueTAM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                            </tr>

                                            <tr className="marketsizingversionstablerowresult">
                                                <th rowSpan={4} className="marketsizingversionstabledataresult">Serviceable attainable market (SAM)</th>
                                                <th className="marketsizingversionstabledataresult">Penetration rate of TAM (in %)</th>
                                                <td className="marketsizingversionstabledataresult">
                                                    <Form.Control type="number" name="penetrationRateSAMHigh" value={filters.penetrationRateSAMHigh} onChange={changeFilters} />
                                                </td>
                                                <td className="marketsizingversionstabledataresult">
                                                    <Form.Control type="number" name="penetrationRateSAMMedium" value={filters.penetrationRateSAMMedium} onChange={changeFilters} />
                                                </td>
                                                <td className="marketsizingversionstabledataresultlast">
                                                    <Form.Control type="number" name="penetrationRateSAMLow" value={filters.penetrationRateSAMLow} onChange={changeFilters} />
                                                </td>
                                            </tr>

                                            <tr className="marketsizingversionstablerowresult">
                                                <th className="marketsizingversionstabledataresult">No. of target customers in SAM</th>
                                                <td className="marketsizingversionstabledataresult">
                                                    {Object.keys(results.high).length === 0
                                                        ? 0
                                                        : results.high.numOfTargetCustomersSAM?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresult">
                                                    {Object.keys(results.medium).length === 0
                                                        ? 0
                                                        : results.medium.numOfTargetCustomersSAM?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresultlast">
                                                    {Object.keys(results.low).length === 0
                                                        ? 0
                                                        : results.low.numOfTargetCustomersSAM?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                            </tr>

                                            <tr className="marketsizingversionstablerowresult">
                                                <th className="marketsizingversionstabledataresult">Price of product or service (USD $)</th>
                                                <td className="marketsizingversionstabledataresult">
                                                    USD $ {Object.keys(results.high).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.high.priceOfProductSAM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresult">
                                                    USD $ {Object.keys(results.medium).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.medium.priceOfProductSAM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresultlast">
                                                    USD $ {Object.keys(results.low).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.low.priceOfProductSAM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                            </tr>

                                            <tr className="marketsizingversionstablerowresult" style={{ borderBottom: "1px solid darkgrey" }}>
                                                <th className="marketsizingversionstabledataresult">Forecasted revenue of SAM (USD $)</th>
                                                <td className="marketsizingversionstabledataresult">
                                                    USD $ {Object.keys(results.high).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.high.forecastedRevenueSAM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresult">
                                                    USD $ {Object.keys(results.medium).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.medium.forecastedRevenueSAM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresultlast">
                                                    USD $ {Object.keys(results.low).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.low.forecastedRevenueSAM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                            </tr>

                                            <tr className="marketsizingversionstablerowresult">
                                                <th rowSpan={4} className="marketsizingversionstabledataresult">Serviceable obtainable market (SOM)</th>
                                                <th className="marketsizingversionstabledataresult">Penetration rate of SAM (in %)</th>
                                                <td className="marketsizingversionstabledataresult">
                                                    <Form.Control type="number" name="penetrationRateSOMHigh" value={filters.penetrationRateSOMHigh} onChange={changeFilters} />
                                                </td>
                                                <td className="marketsizingversionstabledataresult">
                                                    <Form.Control type="number" name="penetrationRateSOMMedium" value={filters.penetrationRateSOMMedium} onChange={changeFilters} />
                                                </td>
                                                <td className="marketsizingversionstabledataresultlast">
                                                    <Form.Control type="number" name="penetrationRateSOMLow" value={filters.penetrationRateSOMLow} onChange={changeFilters} />
                                                </td>
                                            </tr>

                                            <tr className="marketsizingversionstablerowresult">
                                                <th className="marketsizingversionstabledataresult">No. of target customers in SOM</th>
                                                <td className="marketsizingversionstabledataresult">
                                                    {Object.keys(results.high).length === 0
                                                        ? 0
                                                        : results.high.numOfTargetCustomersSOM?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresult">
                                                    {Object.keys(results.medium).length === 0
                                                        ? 0
                                                        : results.medium.numOfTargetCustomersSOM?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresultlast">
                                                    {Object.keys(results.low).length === 0
                                                        ? 0
                                                        : results.low.numOfTargetCustomersSOM?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                            </tr>

                                            <tr className="marketsizingversionstablerowresult">
                                                <th className="marketsizingversionstabledataresult">Price of product or service (USD $)</th>
                                                <td className="marketsizingversionstabledataresult">
                                                    USD $ {Object.keys(results.high).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.high.priceOfProductSOM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresult">
                                                    USD $ {Object.keys(results.medium).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.medium.priceOfProductSOM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresultlast">
                                                    USD $ {Object.keys(results.low).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.low.priceOfProductSOM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                            </tr>

                                            <tr className="marketsizingversionstablerowresult">
                                                <th className="marketsizingversionstabledataresult">Forecasted revenue of SOM (USD $)</th>
                                                <td className="marketsizingversionstabledataresult">
                                                    USD $ {Object.keys(results.high).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.high.forecastedRevenueSOM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresult">
                                                    USD $ {Object.keys(results.medium).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.medium.forecastedRevenueSOM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                                <td className="marketsizingversionstabledataresultlast">
                                                    USD $ {Object.keys(results.low).length === 0
                                                        ? zero.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        : results.low.forecastedRevenueSOM?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                {/* <Form.Text className="marketsizingversionstextmuted">Consider the additional factor(s) that may potentially increase or decrease your business valuation</Form.Text> */}
                            </div>
                        </div>

                        <div className="marketsizingversionsdivbuttons">
                            {/* reset button */}
                            <button className="marketsizingversionsresetbutton" onClick={resetFilters}>
                                <span>Clear</span>
                            </button>

                            {/* submit button */}
                            <Button className="marketsizingversionssubmitbutton" onClick={submitFilters}>
                                <span>Simulate</span>
                            </Button>
                        </div>
                    </Form.Group>
                </Form>
            </div>

            {/* notes */}
            <div className="col-12 marketsizingversionsdivnotes">
                <span className="marketsizingversionsnotes">
                    Note:
                    <ul>
                        <li>
                            This is a pre-revenue top-down market sizing approach analyzing "from a macro/outside-in perspective towards a micro view, which means industry size estimates are taken as starting point and narrowed down into sales targets that are fit for your company" (<a href="https://www.ey.com/en_nl/finance-navigator/why-top-down-forecasting-is-a-crucial-exercise-for-startups" target="_blank" rel="noopener noreferrer">
                                EY, 2018</a>).
                        </li>

                        <ul>
                            <li>
                                In other words, it uses "macroeconomic factors to define a subset of customers that are unified by characteristics of using a product or service" (<a href="https://www.toptal.com/finance/market-sizing/total-addressable-market-example" target="_blank" rel="noopener noreferrer">
                                    Toptal, 2018</a>).
                            </li>

                            <li>
                                The top-down approach is also called the <span style={{ fontWeight: "bold" }}>
                                    chain ratio method</span>, which involves defining a "universe" target market and applying various filters that continually reduces the figure to an estimation of the "net" market" (<a href="https://www.ifc.org/wps/wcm/connect/05177cca-f7a9-437e-b6e5-1a25d7b05663/Market%2BSizing%2BOverview.pdf?MOD=AJPERES&CVID=jlzPnRN" target="_blank" rel="noopener noreferrer">
                                    IFC, 2012</a>).
                            </li>
                        </ul>

                        <li>
                            <a href="https://www.investopedia.com/terms/m/marketshare.asp" target="_blank" rel="noopener noreferrer">
                                Market share</a> is the percentage of total sales in an industry, or a market's total sales, generated by a particular company.
                        </li>

                        <li>
                            <a href="https://blog.hubspot.com/marketing/tam-sam-som" target="_blank" rel="noopener noreferrer">
                                Total Addressable Market (TAM)</a> estimates the total market for your product or service.
                        </li>

                        <ul>
                            <li>
                                It's the maximum amount of revenue a company can possibly generate by selling its product or service in a specific market.
                            </li>

                            <li>
                                TAM is most useful for companies to objectively estimate a specific market's potential for long-term growth targets, such as over 10 years.
                            </li>
                        </ul>

                        <li>
                            <a href="https://blog.hubspot.com/marketing/tam-sam-som" target="_blank" rel="noopener noreferrer">
                                Serviceable Available Market (SAM)</a> estimates the portion of your market you can acquire based on your business model (your targets).
                        </li>

                        <ul>
                            <li>
                                Due to the limitations of your business model, such as specialization or geographic limitations, you will not likely be able to service your total addressable market.
                            </li>

                            <li>
                                SAM is most useful for companies to objectively estimate the portion of the market they can acquire to determine their medium-term growth targets, such as within 3 to 10 years.
                            </li>
                        </ul>

                        <li>
                            <a href="https://blog.hubspot.com/marketing/tam-sam-som" target="_blank" rel="noopener noreferrer">
                                Serviceable Obtainable Market (SOM)</a> estimates the percentage of SAM you can realistically capture.
                        </li>

                        <ul>
                            <li>
                                No company is able to capture the entire 100% of its SAM because it is extremely difficult to convince an entire market to only buy your product or service.
                            </li>

                            <li>
                                SOM is most useful for companies to determine short-term growth targets, such as within 1 to 2 years.
                            </li>
                        </ul>

                        <li>
                            <a href="https://www.investopedia.com/terms/m/market-penetration.asp" target="_blank" rel="noopener noreferrer">
                                Penetration rate</a> is a quantitative measurement (in percentage) of how much a product or service is being used or purchased by customers compared to the total estimated market for that product or service.
                        </li>

                        <ul>
                            <li>
                                Simply, <a href="https://www.oxfordreference.com/view/10.1093/oi/authority.20110803100135169" target="_blank" rel="noopener noreferrer">
                                    market penetration</a> is the extent to which your product or service has managed to infiltrate, or consumed by, your target market.
                            </li>
                            <li>
                                <a href="https://corporatefinanceinstitute.com/resources/knowledge/economics/market-penetration/" target="_blank" rel="noopener noreferrer">
                                    Market penetration</a> is also used by companies to determine the potential to increase their overall revenue by increasing market share.
                            </li>

                            <li>
                                SOM is most useful for companies to determine short-term growth targets, such as within 1 to 2 years.
                            </li>
                        </ul>

                        <li>
                            How we calculate:
                        </li>

                        <ul>
                            <li>
                                Total Addressable Market (TAM)
                            </li>

                            <ul>
                                <li>
                                    (No. of target customers) x (price of product or service) = <span style={{ fontWeight: "bold" }}>
                                        forecasted revenue of TAM</span>
                                </li>
                            </ul>

                            <li>
                                Serviceable Addressable Market (SAM)
                            </li>

                            <ul>
                                <li>
                                    (No. of target customers from TAM) x (penetration rate of TAM) = no. of target customers in SAM
                                </li>

                                <li>
                                    (No. of target customers in SAM) x (price of product or service) = <span style={{ fontWeight: "bold" }}>
                                        forecasted revenue of SAM</span>
                                </li>
                            </ul>

                            <li>
                                Serviceable Obtainable Market (SOM)
                            </li>

                            <ul>
                                <li>
                                    (No. of target customer from SAM) x (penetration rate of SAM) = no. of target customers in SOM
                                </li>

                                <li>
                                    (No. of target customers in SOM) x (price of product or service) = <span style={{ fontWeight: "bold" }}>
                                        forecasted revenue of SOM</span>
                                </li>
                            </ul>
                        </ul>

                        <li>
                            How to use:
                        </li>

                        <ul>
                            <li>
                                Enter only numeric values into the cells.
                            </li>

                            <li>
                                You may separately estimate your TAM, SAM, and SOM, or collectively estimate all of them at the same time.
                            </li>

                            <li>
                                Similarly, you may also collectively estimate all 3 levels of assumption at the same time, or separately with each level.
                            </li>
                        </ul>

                    </ul>
                </span>
            </div>
        </Card.Body >
    );
}

export default BasicVersion;