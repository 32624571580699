export const countries = [
    { key: "Afghanistan" },
    { key: "Albania" },
    { key: "Algeria" },
    { key: "American Samoa" },
    { key: "Andorra" },
    { key: "Angola" },
    { key: "Argentina" },
    { key: "Armenia" },
    { key: "Australia" },
    { key: "Austria" },
    { key: "Azerbaijan" },
    { key: "Bahamas" },
    { key: "Bahrain" },
    { key: "Bangladesh" },
    { key: "Barbados" },
    { key: "Belarus" },
    { key: "Belgium" },
    { key: "Belize" },
    { key: "Benin" },
    { key: "Berkshire" },
    { key: "Bermuda" },
    { key: "Bhutan" },
    { key: "Bolivia" },
    { key: "Bosnia and Herzegovina" },
    { key: "Botswana" },
    { key: "Brazil" },
    { key: "Brunei Darussalam" },
    { key: "Bulgaria" },
    { key: "Burkina Faso" },
    { key: "California" },
    { key: "Cambodia" },
    { key: "Cameroon" },
    { key: "Canada" },
    { key: "Cayman Islands" },
    { key: "Chile" },
    { key: "China" },
    { key: "Chinese Taipei" },
    { key: "City of" },
    { key: "Colombia" },
    { key: "Comoros" },
    { key: "Congo" },
    { key: "Costa Rica" },
    { key: "Croatia" },
    { key: "Cuba" },
    { key: "Cyprus" },
    { key: "Czech Republic" },
    { key: "Côte d'Ivoire" },
    { key: "Denmark" },
    { key: "Dominica" },
    { key: "Dominican Republic" },
    { key: "Ecuador" },
    { key: "Egypt" },
    { key: "El Salvador" },
    { key: "Estonia" },
    { key: "Ethiopia" },
    { key: "Faroe Islands" },
    { key: "Finland" },
    { key: "France" },
    { key: "Gambia" },
    { key: "Georgia" },
    { key: "Germany" },
    { key: "Ghana" },
    { key: "Gibraltar" },
    { key: "Greece" },
    { key: "Guatemala" },
    { key: "Guernsey" },
    { key: "Guinea" },
    { key: "Guyana" },
    { key: "Haiti" },
    { key: "Honduras" },
    { key: "Hong Kong" },
    { key: "Hungary" },
    { key: "Iceland" },
    { key: "India" },
    { key: "Indonesia" },
    { key: "Iran" },
    { key: "Iraq" },
    { key: "Ireland" },
    { key: "Isle of Man" },
    { key: "Israel" },
    { key: "Italy" },
    { key: "Jamaica" },
    { key: "Japan" },
    { key: "Jersey" },
    { key: "Jordan" },
    { key: "Kazakhstan" },
    { key: "Kenya" },
    { key: "Korea" },
    { key: "Kuwait" },
    { key: "Lao People's Democratic Republic" },
    { key: "Latvia" },
    { key: "Lebanon" },
    { key: "Liberia" },
    { key: "Liechtenstein" },
    { key: "Lithuania" },
    { key: "Luxembourg" },
    { key: "Macao" },
    { key: "Macedonia" },
    { key: "Madagascar" },
    { key: "Malawi" },
    { key: "Malaysia" },
    { key: "Mali" },
    { key: "Malta" },
    { key: "Martinique" },
    { key: "Mauritius" },
    { key: "Mexico" },
    { key: "Moldova" },
    { key: "Mongolia" },
    { key: "Montenegro" },
    { key: "Morocco" },
    { key: "Mozambique" },
    { key: "Myanmar" },
    { key: "Namibia" },
    { key: "Nepal" },
    { key: "Netherlands" },
    { key: "New Zealand" },
    { key: "Nicaragua" },
    { key: "Niger" },
    { key: "Nigeria" },
    { key: "Norway" },
    { key: "Oman" },
    { key: "Pakistan" },
    { key: "Palestinian Territory" },
    { key: "Panama" },
    { key: "Papua New Guinea" },
    { key: "Paraguay" },
    { key: "Peru" },
    { key: "Philippines" },
    { key: "Poland" },
    { key: "Portugal" },
    { key: "Puerto Rico" },
    { key: "Qatar" },
    { key: "Reunion" },
    { key: "Romania" },
    { key: "Russian Federation" },
    { key: "Rwanda" },
    { key: "Saint Kitts and Nevis" },
    { key: "Saint-Martin (France)" },
    { key: "Saudi Arabia" },
    { key: "Senegal" },
    { key: "Serbia" },
    { key: "Seychelles" },
    { key: "Sierra Leone" },
    { key: "Singapore" },
    { key: "Slovakia (Slovak Republic)" },
    { key: "Slovenia" },
    { key: "Somalia" },
    { key: "South Africa" },
    { key: "South Korea" },
    { key: "Spain" },
    { key: "Sri Lanka" },
    { key: "Suriname" },
    { key: "Sweden" },
    { key: "Switzerland" },
    { key: "Taiwan" },
    { key: "Tanzania" },
    { key: "Thailand" },
    { key: "The Netherlands" },
    { key: "Togo" },
    { key: "Trinidad and Tobago" },
    { key: "Tunisia" },
    { key: "Turkey" },
    { key: "Uganda" },
    { key: "Ukraine" },
    { key: "United Arab Emirates" },
    { key: "United Kingdom" },
    { key: "United States" },
    { key: "Uruguay" },
    { key: "Utah" },
    { key: "Uzbekistan" },
    { key: "Venezuela" },
    { key: "Vietnam" },
    { key: "Yemen" },
    { key: "Zambia" },
    { key: "Zimbabwe" }
];