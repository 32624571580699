import React, { useState } from 'react';
import './filters.css';
import { Card, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import { MapContainer, CircleMarker, TileLayer, Tooltip } from 'react-leaflet';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useRealmApp } from '../../../RealmApp';
import { fundingRounds } from '../../dropdown-options/funding-rounds';
import { ventureYearsStart } from '../../dropdown-options/years-start';
import { ventureYearsEnd } from '../../dropdown-options/years-end';
import { industriesOld } from '../../dropdown-options/industries';

const ActiveInvestmentMarkets = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // realm functions declaration
    const { currentUser, logInAnonymous } = useRealmApp();

    // to check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // chart function declaration
    const { investmentPortfolioAnalysis } = currentUser.functions;

    // hook for user input
    const [filters, setFilters] = useState({
        type: "Active investment markets",
        fundingRound: "Funding Round",
        yearStart: "Year Start",
        yearEnd: "Year End",
        industry: "Industry"
    });

    // hook for render
    const [readyToRender, setReadyToRender] = useState(false);

    // hook for coordinates
    const [coordinates, setCoordinates] = useState(
        {
            "city": [],
            "minLat": -85,
            "maxLat": 85,
            "minLong": -180,
            "maxLong": 180
        }
    );

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            type: "Active investment markets",
            fundingRound: "Funding Round",
            yearStart: "Year Start",
            yearEnd: "Year End",
            industry: "Industry"
        });
        setAlert(false);
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        setFilters({ ...filtersCopy });
    }

    // function to submit form
    function submitFilters(e) {
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        investmentPortfolioAnalysis(filters)
            .then(response => {
                let graphData = response;
                setCoordinates(graphData);
                setReadyToRender(true);
                closeAlert();
            })
            .catch(error => {
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Your filter query returned no results. Please change your filter values and try again.</p>
                </div>);
            })
        e.preventDefault();
    }

    var centerLat = (coordinates.minLat + coordinates.maxLat) / 2;
    var distanceLat = coordinates.maxLat - coordinates.minLat;
    var bufferLat = distanceLat * 0.05;
    var centerLong = (coordinates.minLong + coordinates.maxLong) / 2;
    var distanceLong = coordinates.maxLong - coordinates.minLong;
    var bufferLong = distanceLong * 0.05;

    return (
        <Card.Body className="accordion-card-body">

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" />
            <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"></script>

            <div className="col-12 div-filter">
                <Form onSubmit={submitFilters}>
                    <Form.Group>
                        <Form.Row className="align-items-center tour-filter">
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.fundingRound} as="select" name="fundingRound" onChange={changeFilters}>
                                    <option>Funding Round</option>
                                    {
                                        fundingRounds.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.yearStart}
                                    as="select" name="yearStart" onChange={changeFilters}>
                                    <option>Year Start</option>
                                    {
                                        ventureYearsStart.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.yearEnd}
                                    as="select" name="yearEnd" onChange={changeFilters}>
                                    <option>Year End</option>
                                    {
                                        ventureYearsEnd.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.industry}
                                    as="select" name="industry" onChange={changeFilters}>
                                    <option>Industry</option>
                                    {
                                        industriesOld.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto">
                                <button onClick={resetFilters} className="reset-button tour-clear-filter">
                                    <span>Clear</span>
                                </button>
                            </Col>
                            <Col xs="auto">
                                <button type="submit" className="submit-button">
                                    <span>Filter</span>
                                </button>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>
            </div>

            <div className="col-12">
                <IoMdInformationCircleOutline id="information-icon" />
                <span className="info-text">
                    Select single or multiple values and click 'filter' to display the data. Due to the large amount of companies, it may take up to 1 min to show results.
                </span>
            </div>

            <div className="col-12">
                <Card className="graph-card">
                    <div>
                        {readyToRender && (
                            <MapContainer
                                style={{ height: "500px", width: "100%", background: "white", fontFamily: "AileronRegular", zIndex: "0" }}
                                zoom={2}
                                scrollWheelZoom={false}
                                minZoom={2}
                                //maxZoom={4}
                                center={[centerLat, centerLong]}
                                bounds={[
                                    [coordinates.minLat - bufferLat, coordinates.minLong - bufferLong],
                                    [coordinates.maxLat + bufferLat, coordinates.maxLong + bufferLong]
                                ]}
                                worldCopyJump={true}
                            >
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                {coordinates.city.map((city, k) => {
                                    return (
                                        <CircleMarker
                                            key={k}
                                            center={[city["coordinates"][1], city["coordinates"][0]]}
                                            radius={10 * Math.log(city["totalInvestment"])}
                                            fillOpacity={0.5}
                                            stroke={false}
                                        >
                                            <Tooltip direction="right" offset={[-8, -2]} opacity={1}>
                                                <span>{city["name"] + ": " + "Total Investment " + city["totalInvestmentText"]}</span>
                                            </Tooltip>
                                        </CircleMarker>);
                                })
                                }
                            </MapContainer>
                        )}
                    </div>
                </Card>
            </div>

            <div className="col-12 extra-notes">
                <span className="info-notes">
                    Note:
                    <ul>
                        <li id="notes-points">
                            Based on 8,055 companies across 6 continents that raised funding in the <span style={{ fontFamily: "AileronItalic" }}>very-early stage, early stage, growth stage, late stage and post-IPO stage</span> from June 1983 to Sept 2020.
                        </li>
                        <li id="notes-points">
                            Top 10 countries (<span style={{ fontFamily: "AileronItalic" }}>from highest to lowest amount of investments made</span>): United States, United Kingdom, Cayman Islands, India, Germany, Canada, Brazil, France, China, Israel.
                        </li>
                    </ul>
                </span>
            </div>
        </Card.Body>
    );
}

export default ActiveInvestmentMarkets;