import React from 'react'
import { Card, Carousel } from 'react-bootstrap'
import { S3Media } from '../../third-party-services'
import './company-detail.css'

function ProductGallery(props) {

    return (
        <Card.Body id="productgallery">
            <div className="companydetailproductdiv">
                <Carousel>
                    {!props?.products
                        ? ("")
                        : props.products.map((product, index) =>
                            product.images.map((image, index) =>
                                <Carousel.Item key={index}>
                                    <img src={S3Media + 'assets/photos/products/' + image.image} alt={props.tradeName} className="companydetailproductimage" />
                                </Carousel.Item>
                            )
                        )}
                </Carousel>
            </div>
        </Card.Body>
    )
}

export default ProductGallery;