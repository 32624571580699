export const knowYouBetter = [
    { "key": "I'm an aspiring entrepreneur or founder" },
    { "key": "I'm an entrepreneur or founder" },
    { "key": "I'm from a startup" },
    { "key": "I'm from a small-&-medium enterprise or business" },
    { "key": "I'm from a scaleup" },
    { "key": "I'm from a corporation" },
    { "key": "I'm from an incubator or accelerator" },
    { "key": "I'm a researcher" },
    { "key": "I'm an aspiring developer or engineer" },
    { "key": "I'm a developer or engineer" },
    { "key": "I'm an aspiring investor or emerging manager" },
    { "key": "I'm an analyst" },
    { "key": "I'm an angel investor" },
    { "key": "I'm a venture capitalist (VC)" },
    { "key": "I'm a limited partner (LP)" },
    { "key": "I'm a hedge fund manager" },
    { "key": "I'm from an investment firm" },
    { "key": "I'm from an asset management firm" },
    { "key": "I'm from a private financial institution" },
    { "key": "I'm from a public financial institution" },
    { "key": "I'm an investment scout" },
    { "key": "I'm a broker or investment advisor" },
    { "key": "I'm a student" },
    { "key": "I'm a lecturer or professor" },
    { "key": "I'm from a school or university" },
    { "key": "I'm from the media" },
    { "key": "I'm with the government or government authority" },
    { "key": "I'm from a state-owned enterprise or government-linked company" },
    { "key": "I'm from a non-governmental or non-profit organization" },
    { "key": "I'm just curious" },
];