import React, { useState, PureComponent } from 'react';
import './filters.css';
import { Card, Col, Form, Alert, Spinner, Button } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { useRealmApp } from '../../../RealmApp';
import { ventureYearsStart } from '../../dropdown-options/years-start';
import { ventureYearsEnd } from '../../dropdown-options/years-end';
import { industriesOld } from '../../dropdown-options/industries';
import { countries } from '../../dropdown-options/countries';

class CustomizedAxisTick extends PureComponent {
    render() {
        const {
            x, y, stroke, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" transform="rotate(-20)">{payload.value}</text>
            </g>
        );
    }
}

const TotalFollowOnInvestments = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(false);

    // hook for alert message
    const [message, setMessage] = useState("");

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // realm functions declaration
    const { currentUser, logInAnonymous } = useRealmApp();

    // to check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // chart function declaration
    const { investmentPortfolioAnalysis } = currentUser.functions;

    // hook for user input
    const [filters, setFilters] = useState({
        type: "Total follow-on investments",
        fundingStage: "Funding Stage",
        yearStart: "Year Start",
        yearEnd: "Year End",
        industry: "Industry",
        location: "Location"
    });

    // hook for results
    const [investments, setInvestments] = useState(
        [
            {
                "amountRaised": "<$500K",
                "Convertible Note": 23.0,
                "Crowdfunding": null,
                "Debt Financing": 4.0,
                "Initial Coin Offering": null,
                "No follow-on": 542.0,
                "Non-Equity Assistance": null,
                "Private Equity": null,
                "SAFE": null,
                "Seed": 24.0,
                "Undisclosed": null,
                "Venture Round": null
            },
            {
                "amountRaised": "$500K-$1M",
                "Convertible Note": 2.0,
                "Crowdfunding": null,
                "Debt Financing": null,
                "Initial Coin Offering": null,
                "No follow-on": 664.0,
                "Non-Equity Assistance": null,
                "Private Equity": null,
                "SAFE": null,
                "Seed": 34.0,
                "Undisclosed": 2.0,
                "Venture Round": 1.0
            },
            {
                "amountRaised": "$1M-$1.5M",
                "Convertible Note": 3.0,
                "Crowdfunding": null,
                "Debt Financing": null,
                "Initial Coin Offering": null,
                "No follow-on": 965.0,
                "Non-Equity Assistance": null,
                "Private Equity": null,
                "SAFE": null,
                "Seed": 58.0,
                "Undisclosed": null,
                "Venture Round": 8.0
            },
            {
                "amountRaised": "$1.5M-$2M",
                "Convertible Note": 1.0,
                "Crowdfunding": 1.0,
                "Debt Financing": null,
                "Initial Coin Offering": null,
                "No follow-on": 966.0,
                "Non-Equity Assistance": null,
                "Private Equity": null,
                "SAFE": null,
                "Seed": 42.0,
                "Undisclosed": null,
                "Venture Round": 11.0
            },
            {
                "amountRaised": "$2M-$2.5M",
                "Convertible Note": null,
                "Crowdfunding": null,
                "Debt Financing": null,
                "Initial Coin Offering": null,
                "No follow-on": 667.0,
                "Non-Equity Assistance": 1.0,
                "Private Equity": null,
                "SAFE": null,
                "Seed": 27.0,
                "Undisclosed": null,
                "Venture Round": 5.0
            },
            {
                "amountRaised": "$2.5M-$3M",
                "Convertible Note": 2.0,
                "Crowdfunding": null,
                "Debt Financing": 1.0,
                "Initial Coin Offering": null,
                "No follow-on": 542.0,
                "Non-Equity Assistance": null,
                "Private Equity": null,
                "SAFE": null,
                "Seed": 17.0,
                "Undisclosed": 1.0,
                "Venture Round": 14.0
            },
            {
                "amountRaised": "$3M-$3.5M",
                "Convertible Note": null,
                "Crowdfunding": 1.0,
                "Debt Financing": null,
                "Initial Coin Offering": null,
                "No follow-on": 296.0,
                "Non-Equity Assistance": null,
                "Private Equity": null,
                "SAFE": null,
                "Seed": 10.0,
                "Undisclosed": null,
                "Venture Round": 6.0
            },
            {
                "amountRaised": "$3.5M-$4M",
                "Convertible Note": null,
                "Crowdfunding": null,
                "Debt Financing": null,
                "Initial Coin Offering": null,
                "No follow-on": 302.0,
                "Non-Equity Assistance": null,
                "Private Equity": null,
                "SAFE": null,
                "Seed": 5.0,
                "Undisclosed": null,
                "Venture Round": 4.0
            },
            {
                "amountRaised": "$4M-$4.5M",
                "Convertible Note": 4.0,
                "Crowdfunding": null,
                "Debt Financing": null,
                "Initial Coin Offering": null,
                "No follow-on": 158.0,
                "Non-Equity Assistance": null,
                "Private Equity": null,
                "SAFE": null,
                "Seed": 3.0,
                "Undisclosed": null,
                "Venture Round": 2.0
            },
            {
                "amountRaised": "$4.5M-$5M",
                "Convertible Note": null,
                "Crowdfunding": null,
                "Debt Financing": null,
                "Initial Coin Offering": null,
                "No follow-on": 134.0,
                "Non-Equity Assistance": null,
                "Private Equity": null,
                "SAFE": null,
                "Seed": 3.0,
                "Undisclosed": null,
                "Venture Round": 2.0
            },
            {
                "amountRaised": ">$5M",
                "Convertible Note": null,
                "Crowdfunding": null,
                "Debt Financing": null,
                "Initial Coin Offering": 1.0,
                "No follow-on": 294.0,
                "Non-Equity Assistance": null,
                "Private Equity": 1.0,
                "SAFE": null,
                "Seed": 16.0,
                "Undisclosed": null,
                "Venture Round": null
            },
            {
                "amountRaised": "No Data",
                "Convertible Note": 28.0,
                "Crowdfunding": null,
                "Debt Financing": 1.0,
                "Initial Coin Offering": null,
                "No follow-on": 1304.0,
                "Non-Equity Assistance": null,
                "Private Equity": null,
                "SAFE": 1.0,
                "Seed": 37.0,
                "Undisclosed": null,
                "Venture Round": null
            }
        ]
    );

    // function to reset form
    function resetFilters(e) {
        e.preventDefault();
        setFilters({
            type: "Total follow-on investments",
            fundingStage: "Funding Stage",
            yearStart: "Year Start",
            yearEnd: "Year End",
            industry: "Industry",
            location: "Location"
        });
        setAlert(false);
    }

    // function to update form
    function changeFilters(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let filtersCopy = { ...filters };

        filtersCopy[name] = value;
        setFilters({ ...filtersCopy });
    }

    // function to submit form
    function submitFilters(e) {
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        investmentPortfolioAnalysis(filters)
            .then(response => {
                let graphData = response;
                setInvestments(graphData);
                closeAlert();
            })
            .catch(error => {
                setAlert(true);
                setMessage(<div style={{ paddingTop: "10px" }}>
                    <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                    <p style={{ marginLeft: "28px" }}>Your filter query returned no results. Please change your filter values and try again.</p>
                </div>);
            })
        e.preventDefault();
    }

    return (
        <Card.Body className="accordion-card-body">

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div className="col-12 div-filter">
                <Form onSubmit={submitFilters}>
                    <Form.Group>
                        <Form.Row className="align-items-center">
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.fundingStage} as="select" name="fundingStage" onChange={changeFilters}>
                                    <option>Funding Stage</option>
                                    <option>Very-Early Stages</option>
                                    <option>Early Stages</option>
                                    <option>Growth Stages</option>
                                    <option>Late Stages</option>
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.yearStart} disabled={filters.fundingStage === "Funding Stage" ? true : false}
                                    as="select" name="yearStart" onChange={changeFilters}>
                                    <option>Year Start</option>
                                    {
                                        ventureYearsStart.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.yearEnd} disabled={filters.fundingStage === "Funding Stage" ? true : false}
                                    as="select" name="yearEnd" onChange={changeFilters}>
                                    <option>Year End</option>
                                    {
                                        ventureYearsEnd.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.industry} disabled={filters.fundingStage === "Funding Stage" ? true : false}
                                    as="select" name="industry" onChange={changeFilters}>
                                    <option>Industry</option>
                                    {
                                        industriesOld.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto" className="filter-dropdown">
                                <Form.Control value={filters.location} disabled={filters.fundingStage === "Funding Stage" ? true : false}
                                    as="select" name="location" onChange={changeFilters}>
                                    <option>Location</option>
                                    {
                                        countries.map((option) =>
                                            <option>{option.key}</option>
                                        )
                                    }
                                </Form.Control>
                            </Col>
                            <Col xs="auto">
                                <button onClick={resetFilters} className="reset-button tour-clear-filter">
                                    <span>Clear</span>
                                </button>
                            </Col>
                            <Col xs="auto">
                                <button type="submit" className="submit-button">
                                    <span>Filter</span>
                                </button>
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>
            </div>

            <div className="col-12">
                <Card className="graph-card">
                    <div>
                        <ResponsiveContainer id="vc-charts">
                            <BarChart data={investments}
                                margin={{ top: 20, right: 20, left: 20, bottom: 100 }}
                                width={1100}
                                height={500}
                                fontFamily="AileronRegular"
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="amountRaised"
                                    interval={0}
                                    fontSize="11px"
                                    fontFamily="AileronRegular"
                                    tick={<CustomizedAxisTick />}
                                    label={{ value: "USD", offset: -80, position: "insideBottom" }} />
                                <YAxis fontSize="15px"
                                    fontFamily="AileronRegular"
                                    label={{ value: "Frequency", angle: -90, position: "insideLeft" }} />
                                <Tooltip />

                                <Bar dataKey="Accelerator" stackId="a" fill="#264653" barSize={50} />
                                <Bar dataKey="Angel" stackId="a" fill="#2a9d8f" barSize={50} />
                                <Bar dataKey="Bridge" stackId="a" fill="#e9c46a" barSize={50} />
                                <Bar dataKey="Bridge(Post-series A)" stackId="a" fill="#f4a261" barSize={50} />
                                <Bar dataKey="Bridge(Pre-series B)" stackId="a" fill="#e76f51" barSize={50} />
                                <Bar dataKey="Convertible Note" stackId="a" fill="#AF1B3F" barSize={50} />
                                <Bar dataKey="Corporate Round" stackId="a" fill="#2B0504" barSize={50} />
                                <Bar dataKey="Crowdfunding" stackId="a" fill="#874000" barSize={50} />
                                <Bar dataKey="Debt Financing" stackId="a" fill="#BC5F04" barSize={50} />
                                <Bar dataKey="Equity Crowdfunding" stackId="a" fill="#F4442E" barSize={50} />
                                <Bar dataKey="Exit" stackId="a" fill="#4C212A" barSize={50} />
                                <Bar dataKey="Funding Round" stackId="a" fill="#01172F" barSize={50} />
                                <Bar dataKey="Exit" stackId="a" fill="#00635D" barSize={50} />
                                <Bar dataKey="Grant" stackId="a" fill="#08A4BD" barSize={50} />
                                <Bar dataKey="Growth" stackId="a" fill="#446DF6" barSize={50} />
                                <Bar dataKey="Initial Coin Offering" stackId="a" fill="#B74F6F" barSize={50} />
                                <Bar dataKey="Limited Partnership" stackId="a" fill="#ADBDFF" barSize={50} />
                                <Bar dataKey="Mezzanine" stackId="a" fill="#3185FC" barSize={50} />
                                <Bar dataKey="Non-Equity Assistance" stackId="a" fill="#D33F49" barSize={50} />
                                <Bar dataKey="Post-Seed" stackId="a" fill="#D7C0D0" barSize={50} />
                                <Bar dataKey="Pre-IPO" stackId="a" fill="#EFF0D1" barSize={50} />
                                <Bar dataKey="Pre-Seed" stackId="a" fill="#77BA99" barSize={50} />
                                <Bar dataKey="Pre-series A" stackId="a" fill="#8FBFE0" barSize={50} />
                                <Bar dataKey="Private Equity" stackId="a" fill="#7C77B9" barSize={50} />
                                <Bar dataKey="Product Crowdfunding" stackId="a" fill="#1D8A99" barSize={50} />
                                <Bar dataKey="SAFE" stackId="a" fill="#0BC9CD" barSize={50} />
                                <Bar dataKey="Seed" stackId="a" fill="#C46D5E" barSize={50} />
                                <Bar dataKey="Series A" stackId="a" fill="#5F634F" barSize={50} />
                                <Bar dataKey="Series B" stackId="a" fill="#9BC4CB" barSize={50} />
                                <Bar dataKey="Series C" stackId="a" fill="#CFEBDF" barSize={50} />
                                <Bar dataKey="Series D" stackId="a" fill="#E2FADB" barSize={50} />
                                <Bar dataKey="Series E" stackId="a" fill="#DBEFBC" barSize={50} />
                                <Bar dataKey="Series F" stackId="a" fill="#EDFFEC" barSize={50} />
                                <Bar dataKey="Series G" stackId="a" fill="#61E786" barSize={50} />
                                <Bar dataKey="Series H" stackId="a" fill="#5A5766" barSize={50} />
                                <Bar dataKey="Series I" stackId="a" fill="#48435C" barSize={50} />
                                <Bar dataKey="Series J" stackId="a" fill="#9792E3" barSize={50} />
                                <Bar dataKey="Undisclosed" stackId="a" fill="#0A2463" barSize={50} />
                                <Bar dataKey="Venture Round" stackId="a" fill="#473144" barSize={50} />
                                <Bar dataKey="No follow-on" fill="#34E5FF" barSize={50} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </div>

            <div className="col-12 extra-notes">
                <span className="info-notes">
                    Note:
                    <ul>
                        <li id="notes-points">
                            Based on 7,918 companies that were invested by 1,953 investors (with funding rounds data) across the <span style={{ fontFamily: "AileronItalic" }}>very-early stage, early stage, growth stage, and late-stage</span> from June 1983 to Sept 2020.
                        </li>
                        <li id="notes-points">
                            Out of 19,702 investments (<span style={{ fontFamily: "AileronItalic" }}>comprised of both leads and co-leads</span>), a total of 4,761 follow-on investments were committed to the funded companies (<span style={{ fontFamily: "AileronItalic" }}>164 investments lack financing data</span>).
                        </li>
                        <li id="notes-points">
                            Moreover, out of 19,702 investments (<span style={{ fontFamily: "AileronItalic" }}>comprised of both leads and co-leads</span>), the investors did not commit to the potential 11,690 follow-on investments of the funded companies (<span style={{ fontFamily: "AileronItalic" }}>1,672 investments lack financing data</span>).
                        </li>
                        <li id="notes-points">
                            By lack of financing data, we mean that data collected for the financing round were incomplete, such as <span style={{ fontFamily: "AileronItalic" }}> unknown financing type, undisclosed financing amount, unrecorded financing date</span>, and so on.
                        </li>
                        <li id="notes-points">
                            Top 10 funding rounds (in order from highest to lowest no. of follow-on investments made): <span style={{ fontFamily: "AileronItalic" }}>series B, series A, series C, series D, seed, venture round, series E, convertible note, series F, debt financing</span>.
                        </li>
                    </ul>
                </span>
            </div>
        </Card.Body>
    );
}

export default TotalFollowOnInvestments;