import ResetPasswordForm from './reset-password-form'
import React, { useState } from 'react'
import { useRealmApp } from '../../RealmApp'
import isEmail from 'validator/lib/isEmail';
import isStrongPassword from 'validator/lib/isStrongPassword';
import isLength from 'validator/lib/isLength';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { Button, Alert, Spinner } from 'react-bootstrap';
import { BiAnalyse } from 'react-icons/bi';


function ResetPasswordPage() {
    const { sendResetPwdEmail } = useRealmApp()

    const [credentials, setCredentials] = useState({
        email: '',
        newPwd: '',
        confirmPwd: ''
    })

    // Keep track of input validation/errors
    const [response, setResponse] = useState("");

    // Handle submit password change
    async function handleChangePwd(creds) {

        setResponse(
            <Alert className="error-message floating-alert" variant="light">
                <div>
                    <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                        onClick={(event) => {
                            event.preventDefault()
                            setResponse("")
                        }}>
                        <IoMdClose /></Button></div>
                    <div style={{ display: "flex", paddingTop: "10px" }}>
                        <div style={{ width: "90%" }}>
                            <p><span style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</span></p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Attempting to reset your password</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                        </div>
                        <div style={{ width: "10%", textAlign: "right" }}>
                            <Spinner animation="border" variant="secondary" />
                        </div>
                    </div>
                </div>
            </Alert>
        );

        // To do: Validate the submitted email
        const checkEmail = isEmail(creds.email)

        const passwordsMatch = (creds.newPwd.localeCompare(creds.confirmPwd) === 0)
        // Check if passwords match
        if (checkEmail && passwordsMatch) {
            // Submit form
            try {
                await sendResetPwdEmail(creds.email, creds.newPwd);

                setResponse(
                    <Alert className="error-message floating-alert" variant="light">
                        <div style={{ textAlign: "right", paddingTop: "10px" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                            event.preventDefault()
                            setResponse("")
                        }}><IoMdClose /></Button></div>
                        <div style={{ paddingTop: "10px" }}>
                            <p style={{ color: "#0f9d58" }}><AiOutlineCheckCircle className="alert-icon" /> Successful</p>
                            <p style={{ marginLeft: "28px" }}>Please check your email for password reset instruction!</p>
                        </div>
                    </Alert>
                );
            } catch (error) {
                if (error.error) {
                    setResponse(
                        <Alert className="error-message floating-alert" variant="light">
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                                event.preventDefault()
                                setResponse("")
                            }}><IoMdClose /></Button></div>
                            <div style={{ paddingTop: "10px" }}>
                                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                <p style={{ marginLeft: "28px" }}>{error.error}</p>
                            </div>
                        </Alert>
                    );
                }
                else {
                    setResponse(
                        <Alert className="error-message floating-alert" variant="light">
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                                event.preventDefault()
                                setResponse("")
                            }}><IoMdClose /></Button></div>
                            <div style={{ paddingTop: "10px" }}>
                                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                <p style={{ marginLeft: "28px" }}>{error}</p>
                            </div>
                        </Alert>
                    );
                }
            }
        }
    }

    function checkPwdMatch(password, confirmPassword) {
        //Returns a boolean value / Check if passwords match
        return (password.localeCompare(confirmPassword) === 0)
    }

    function checkPwdLength(password) {
        //Check if password is atleast 8 characters
        return isLength(password, { min: 8 })
    }

    function measurePwdLength(password) {
        //Measure the password length
        return password.length
    }

    function checkPwdSymbol(password) {
        //Check if password contains at least 1 symbol
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 0, minSymbols: 1, minLowercase: 0 })
    }

    function checkPwdUppercase(password) {
        //Check if password contains at least 1 uppercase
        return isStrongPassword(password, { minUppercase: 1, minLength: 0, minNumbers: 0, minSymbols: 0, minLowercase: 0 })
    }

    function checkPwdLowercase(password) {
        //Check if password contains at least 1 lowercase
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 0, minSymbols: 0, minLowercase: 1 })
    }

    function checkPwdNum(password) {
        //Check if password contains at least 1 number
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 1, minSymbols: 0, minLowercase: 0 })
    }

    return (
        <ResetPasswordForm handleChangePwd={handleChangePwd} credentials={credentials} setCredentials={setCredentials} response={response}
            checkPwdMatch={checkPwdMatch} checkPwdLength={checkPwdLength} checkPwdSymbol={checkPwdSymbol} checkPwdUppercase={checkPwdUppercase} checkPwdLowercase={checkPwdLowercase} checkPwdNum={checkPwdNum}
            measurePwdLength={measurePwdLength}
        />
    )
}

export default ResetPasswordPage