export const table = {
    'Business development phase': [
        {
            name: 'DISCOVERY',
            col: 2
        },
        {
            name: 'VALIDATION',
            col: 2
        },
        {
            name: 'SCALE-UP',
            col: 2
        },
        {
            name: 'STRATEGIC-GROWTH',
            col: 2
        }
    ],
    'Core competency': [
        {
            name: 'Planning',
            col: 2
        },
        {
            name: 'Product',
            col: 2
        },
        {
            name: 'People',
            col: 2
        },
        {
            name: 'Processes',
            col: 2
        }
    ],
    'Validation type': [
        {
            name: 'Problem-market fit',
            col: 2
        },
        {
            name: 'Product-market fit',
            col: 2
        },
        {
            name: 'Vision-market fit',
            col: 2
        },
        {
            name: 'Business model-market fit',
            col: 2
        }
    ],
    'Funding round': [
        {
            name: 'Preseed',
            col: 2
        },
        {
            name: 'Seed',
            col: 1
        },
        {
            name: 'Series A',
            col: 1
        },
        {
            name: 'Series B',
            col: 1
        },
        {
            name: 'Series C',
            col: 1
        },
        {
            name: 'Series D and beyond',
            col: 2
        }
    ],
    Goal: [
        {
            name: 'Reach/achieve product-market fit',
            col: 3
        },
        {
            name: 'Maximize speed of growth',
            col: 2
        },
        {
            name: 'Expand or exit',
            col: 3
        }
    ],
    'Entry criteria for each phase': [
        {
            name: 'Pilot ready to test with real customers; founder(s) or team leader(s) in place; lean funding plan based on 6 to 18 months runway to validate key hypotheses',
            col: 3
        },
        {
            name: 'Market appetite for product is validated by paying customers; unit economics tested; leadership team hired; business plan delivers rapid scale in 3 to 5 years',
            col: 2
        },
        {
            name: 'Business model validated through scaled economics; first-class and scaled operating team in place; business plan transitions to lower-growth phase',
            col: 3
        }
    ],
    'Evaluation criteria: traction & product-market fit (PMF)': [
        {
            name: 'Pre-product, or pre-launch, or launched but pre-revenue, or launched but with very-early customers (2-10 who can serve as references); raising money to get product to market for early market feedback; evidence of problem-market fit with strong indication of a large opportunity (based on customer validation or user interviews)',
            col: 2
        },
        {
            name: 'Product is launched with some traction (usage or revenue) and loved by early users with strong engagement and stickiness which validated a real business opportunity; raising money to further develop product and a compelling Go-to-Market (GTM) strategy to get into a large Total Addressable Market (TAM) over time; strong founder-market fit',
            col: 1
        },
        {
            name: 'Early/clear evidence of PMF with meaningful traction (e.g. $1M ARR) high usage, low churn, high net promoter score, etc.; strong customer references, compelling "why now", and large TAM potential; team growing to support growth and product expansion',
            col: 1
        },
        {
            name: 'Predictable, scalable and profitable sales-&-marketing machine that is able to unlock new levers of growth; evidence of strong PMF, signs of a succesful brand, platform, data play, and unique position in a multi-billion dollar market with more than $300M ARR potential',
            col: 4
        }
    ],
    'Employees size (persons)': [
        {
            median: '3',
            range: '(1 - 8)',
            col: 2
        },
        {
            median: '18',
            range: '(8 - 38)',
            col: 1
        },
        {
            median: '63',
            range: '(38 - 88)',
            col: 1
        },
        {
            median: '113',
            range: '(63 - 176)',
            col: 1
        },
        {
            median: '176',
            range: '(176 - 626)',
            col: 1
        },
        {
            median: '626',
            range: '(226 - 907)',
            col: 2
        }
    ],
    'Monthly recurring revenue (MRR in USD)': [
        {
            median: '$21K',
            range: '($0 - $42K)',
            col: 2
        },
        {
            median: '$167K',
            range: '($42K - $167K)',
            col: 1
        },
        {
            median: '$500K',
            range: '($167K - $500K)',
            col: 1
        },
        {
            median: '$1.25M',
            range: '($500K - $1.25M)',
            col: 1
        },
        {
            median: '$500K',
            range: '($1.25M - $2.92M)',
            col: 1
        },
        {
            median: '$4.17M',
            range: '($2.92M - $4.17M)',
            col: 2
        }
    ],
    'Annual recurring revenue (ARR in USD)': [
        {
            median: '$250K',
            range: '($0 - $900K)',
            col: 2
        },
        {
            median: '$2M',
            range: '($500K - $2M)',
            col: 1
        },
        {
            median: '$6M',
            range: '($2M - $5M)',
            col: 1
        },
        {
            median: '$15M',
            range: '($6M - $15M)',
            col: 1
        },
        {
            median: '$15M',
            range: '($6M - $35M)',
            col: 1
        },
        {
            median: '$50M',
            range: '($35M - $50M)',
            col: 2
        }
    ],
    'Year-on-Year (YoY) growth rate (%)': [
        {
            median: '20%',
            range: '(10% - 50%)',
            col: 2
        },
        {
            median: '100%',
            range: '(41% - 304%)',
            col: 1
        },
        {
            median: '100%',
            range: '(30% - 180%)',
            col: 1
        },
        {
            median: '61%',
            range: '(30% - 150%)',
            col: 1
        },
        {
            median: '35%',
            range: '(25% - 60%)',
            col: 1
        },
        {
            median: '42%',
            range: '(23% - 59%)',
            col: 2
        }
    ],
    'Monthly burn rate (USD)': [
        {
            median: '$6K',
            range: '($1K - $60K)',
            col: 2
        },
        {
            median: '$50K',
            range: '($50K - $175K)',
            col: 1
        },
        {
            median: '$375K',
            range: '($50K - $625K)',
            col: 1
        },
        {
            median: '$625K',
            range: '($375K - $1.25M)',
            col: 1
        },
        {
            median: '$1.75M',
            range: '($188K - $1.88M)',
            col: 1
        },
        {
            median: '$2M',
            range: '($1.25M - $20M)',
            col: 2
        }
    ],
    'Investment size (USD)': [
        {
            range: '$750K - $1.5M',
            col: 2
        },
        {
            range: '$2M - $5M',
            col: 1
        },
        {
            range: '$5M - $15M',
            col: 1
        },
        {
            range: '$10M - $40M',
            col: 1
        },
        {
            range: '$43M - $100M+',
            col: 1
        },
        {
            range: '$100M - $100M+',
            col: 2
        }
    ],
    'Equity stake (%)': [
        {
            name: '5% - 30%',
            col: 2
        },
        {
            name: '10% - 63%',
            col: 1
        },
        {
            name: '8% - 75%',
            col: 1
        },
        {
            name: '5% - 50%',
            col: 1
        },
        {
            name: '2% - 19%+',
            col: 1
        },
        {
            name: '2% - 10%+',
            col: 2
        }
    ],
    'Post-money valuation (USD)': [
        {
            name: '$5M - $15M',
            col: 2
        },
        {
            name: '$8M - $20M',
            col: 1
        },
        {
            name: '$20M - $60M',
            col: 1
        },
        {
            name: '$80M - $200M',
            col: 1
        },
        {
            name: '$538M - $2B',
            col: 1
        },
        {
            name: '$1B - $5B',
            col: 2
        }
    ],
    'Funding sources': [
        {
            name: 'Founders, friends, family, angel investors, incubators, accelerators, micro-VCs, dedicated preseed venture studios, grants offered by governments or non-profit organizations or universities, startup competitions, dedicated preseed VCs',
            col: 2
        },
        {
            name: 'Angel investors, accelerators, micro-VCs, seed VCs, dedicated seed venture studios, solo capitalists, grants offered by governments or non-profit organizations or universities, startup competitions',
            col: 1
        },
        {
            name: 'Super angels, seed VCs, early-stage VCs, solo capitalists, grants offered by governments or non-profit organizations, startup competitions',
            col: 1
        },
        {
            name: 'Early-stage VCs, growth VCs, solo capitalists, government grants, startup competitions, secondary market in private equity',
            col: 1
        },
        {
            name: 'Growth-stage VCs, late-stage VCs, CVCs, solo capitalists, secondary market in private equity, private equity funds, investment banks',
            col: 1
        },
        {
            name: 'Late-stage VCs, private equity funds, CVCs, family offices, IPOs and SPACs (public markets via stock exchanges), hedge funds, SPVs and secondaries, investment banks, buyout funds, sovereign wealth funds',
            col: 2
        }
    ],
    'Defensibility moat': [
        {
            name: 'Great ideas with proof-of-concept or MVP (Minimum Viable Product)',
            col: 2
        },
        {
            name: 'Great tech with product development velocity',
            col: 1
        },
        {
            name: 'Early signs of an emerging mini-brand',
            col: 1
        },
        {
            name: 'Increasing conviction that you can create strong defensibility (e.g. by leveraging proprietary algorithms and data, network effects, etc.)		',
            col: 4
        }
    ],
    'Evaluation criteria: conviction': {
        row1: [
            {
                title: 'Prove',
                name: ': pre-PMF with non-to-early revenue while proving the hypothesis; belief in $10M+ ARR potential	',
                col: 3
            },
            {
                title: 'Build',
                name: ': PMF with revenues, building organization with repeatable and scalable sales, and GTM growth playbooks; conviction in $100M+ ARR potential',
                col: 1
            },
            {
                title: 'Scale',
                name: ': pushing ARR to multi-million with a method for strong profitable growth	',
                col: 2
            },
            {
                title: 'Breakout',
                name: ': possible fund returner',
                col: 2
            }
        ],
        row2: [
            {
                name: 'Belief capital = crowdfunding (community) to angel',
                col: 2
            },
            {
                name: 'Proof capital = seed to pre-series A',
                col: 1
            },
            {
                name: 'Scale capital = series A to series B',
                col: 2
            },
            {
                name: 'Exit capital = series C to pre-IPO and beyond',
                col: 3
            }
        ],
        row3: [
            {
                name: "Primarily evaluating the founders' clear, ambitious, and enterprising vision",
                col: 2
            },
            {
                name: "Primarily evaluating the team's strength and product differentiation",
                col: 1
            },
            {
                name: 'Primarily evaluating the growth traction',
                col: 2
            },
            {
                name: 'Primarily evaluating the validated strong business unit economics for further market expansion and great returns',
                col: 3
            }
        ]
    }
};