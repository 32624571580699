import React, { useState, useEffect } from 'react'
import { Card, Form, Tabs, Tab } from 'react-bootstrap'
import { useRealmApp } from '../../../RealmApp'
import '../referral-program2.css'

const ReferralPerform = () => {

    const { currentUser } = useRealmApp();

    // realm functions
    const { trackReferralInvitation } = currentUser.functions;

    // hook for statistics
    const [stats, setStats] = useState({
        referralsSentInvitationLink: "",
        referralsSuccessfulInvitationLink: ""
    });

    // get statistics
    useEffect(async () => {
        try {
            let response = await trackReferralInvitation();

            // console.log(JSON.parse(response.body));

            setStats(JSON.parse(response.body));
        } catch (err) {
            // console.error();
        }
    }, []);

    const [display, setDisplay] = useState({
        duration: "Last 7 days"
    });

    function changeDuration(e) {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        // create a copy of filters with desired changes
        let displayChanged = { ...display };

        displayChanged[name] = value;
        setDisplay({ ...displayChanged });
    }

    return (
        <>
            <p className="referralcardheader" style={{ paddingTop: "40px" }}>How are you performing?</p>

            <Card id="referralperform" className="referralcard">

                <Tabs className="referraltabs" defaultActiveKey="invitationLink">
                    <Tab id="uncontrolled-tab-example" eventKey="email" title="Email" disabled>
                        <div className="referralperformdiv">

                        </div>
                    </Tab>

                    <Tab eventKey="invitationLink" title="Invitation Link">
                        <div className="referralperformdiv">
                            <div>
                                <p className="referralrewardsheader">Total no. of referrals sent</p>
                                <p className="referralrewardsdata">{stats.referralsSentInvitationLink === "" ? 0 : stats.referralsSentInvitationLink}</p>
                            </div>

                            <div>
                                <p className="referralrewardsheader">No. of successful referrals</p>
                                <p className="referralrewardsdata">{stats.referralsSuccessfulInvitationLink === "" ? 0 : stats.referralsSuccessfulInvitationLink}</p>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey="socialMedia" title="Social Media" disabled>
                        <div className="referralperformdiv">

                        </div>
                    </Tab>
                </Tabs>

                <hr style={{ marginBottom: "0" }} />

                <Form.Control as="select" className="referralselect" name="duration" value={display.duration} onChange={e => changeDuration(e)}>
                    <option>Last 7 days</option>
                </Form.Control>

            </Card>
        </>
    );
}

export default ReferralPerform