import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import '../data-contribution.css';
import { Card, Form, Button } from 'react-bootstrap';
import { IoMdClose, IoMdAdd } from 'react-icons/io';
import { Multiselect } from 'multiselect-react-dropdown';
import { industriesNew } from '../../dropdown-options/industries';
import { countries } from '../../dropdown-options/countries';
import { categories } from '../../dropdown-options/categories';
import { profileTypes } from '../../dropdown-options/profile-types';
import { ownershipStatuses } from '../../dropdown-options/ownership-statuses';
import { educationEntityTypes, financeEntityTypes, governmentEntityTypes, organizationEntityTypes, personEntityTypes } from '../../dropdown-options/entity-types';
import { profileGenders } from '../../dropdown-options/genders';

const AddEditGeneralInformation = (props) => {

    const addField = (action) => {
        // action = { type: "example action type", value: <User's input> } // Example action
        props.setState(action);
    }

    const removeField = (action) => {
        props.setState(action)
    }

    const editField = (action) => {
        // action = { type: "example action type", index: 3, value: <User's input> } // Example action
        props.setState(action);
    }

    // References to the elements that will contain Multiselect
    const multiselectInvestorTypesRef = useRef(null);
    const multiselectCategoriesRef1 = useRef(null);
    const multiselectCategoriesRef2 = useRef(null);

    // console.log(props);

    return (
        <>
            {/* card based on subtopics */}
            <Card style={{ marginTop: "40px" }}>
                <Card.Body className="addnewaccordioncardbody">

                    <div className="addnewaccordioncardbodydiv ">
                        <Form>
                            <Form.Group>
                                <div className="addnewscrollablediv">
                                    <div className="addnewshortrow">
                                        <div className="addnewshortfield">
                                            <Form.Label>
                                                Profile type
                                            </Form.Label>

                                            <Form.Control
                                                as="select"
                                                value={props.state.profileType}
                                                onChange={(e) => { props.setState({ "type": "update profileType", "value": e.target.value }) }}
                                            >
                                                <option></option>
                                                {
                                                    profileTypes.map((option) =>
                                                        <option>{option.key}</option>
                                                    )
                                                }
                                            </Form.Control>

                                            <Form.Text className="addnewprompt">Select profile type</Form.Text>
                                        </div>
                                        
                                        {props.state.profileType === "" || props.state.profileType === "Project" || props.state.profileType === "Product"
                                            ? ""
                                            : (<div className="addnewshortfield">
                                                <Form.Label>
                                                    Entity type
                                                </Form.Label>

                                                <Form.Control
                                                    as="select"
                                                    value={props.state.entityType}
                                                    onChange={(e) => { props.setState({ "type": "update entityType", "value": e.target.value }) }}
                                                >
                                                    <option></option>
                                                    {props.state.profileType === "Organization"
                                                        ? organizationEntityTypes.map((option) =>
                                                            <option>{option.key}</option>
                                                        )
                                                        : ""
                                                    }

                                                    {props.state.profileType === "Finance"
                                                        ? financeEntityTypes.map((option) =>
                                                            <option>{option.key}</option>
                                                        )
                                                        : ""
                                                    }

                                                    {props.state.profileType === "Person"
                                                        ? personEntityTypes.map((option) =>
                                                            <option>{option.key}</option>
                                                        )
                                                        : ""
                                                    }

                                                    {props.state.profileType === "Education"
                                                        ? educationEntityTypes.map((option) =>
                                                            <option>{option.key}</option>
                                                        )
                                                        : ""
                                                    }

                                                    {props.state.profileType === "Government"
                                                        ? governmentEntityTypes.map((option) =>
                                                            <option>{option.key}</option>
                                                        )
                                                        : ""
                                                    }
                                                </Form.Control>

                                                <Form.Text className="addnewprompt">Select entity type</Form.Text>
                                            </div>)
                                        }

                                        {props.state.profileType === "Finance"
                                            ? ( <div className="addnewshortfield">
                                                <Form.Label>
                                                    Investor types
                                                </Form.Label>

                                                <Multiselect
                                                    ref={multiselectInvestorTypesRef}
                                                    options={[
                                                        { key: "Accelerator" },
                                                        { key: "Angel" },
                                                        { key: "Angel Group" },
                                                        { key: "Co-Working Space" },
                                                        { key: "Corporate Venture Capital" },
                                                        { key: "Entrepreneurship Program" },
                                                        { key: "Family Investment Office" },
                                                        { key: "Fund Of Funds" },
                                                        { key: "Government Office" },
                                                        { key: "Hedge Fund" },
                                                        { key: "Incubator" },
                                                        { key: "Individual" },
                                                        { key: "Investment Bank" },
                                                        { key: "Investment Partner" },
                                                        { key: "Micro VC" },
                                                        { key: "Pension Funds" },
                                                        { key: "Private Equity Firm" },
                                                        { key: "Secondary Purchaser" },
                                                        { key: "Startup Competition" },
                                                        { key: "Syndicate" },
                                                        { key: "University Program" },
                                                        { key: "Venture Capital" },
                                                        { key: "Venture Debt" }
                                                    ]}
                                                    selectedValues={props.state.investorTypes.map(x => ({ key: x }))}
                                                    displayValue="key"
                                                    avoidHighlightFirstOption="true"
                                                    selectionLimit="-1"
                                                    closeOnSelect={false}
                                                    showCheckbox="true"
                                                    placeholder="What investor type best describes your entity?"
                                                    hidePlaceholder="true"
                                                    emptyRecordMsg=""
                                                    style={{
                                                        searchBox: {
                                                            border: "1px solid #ced4da",
                                                            height: "35px",
                                                            fontFamily: "AileronRegular",
                                                            fontSize: "14px",
                                                            color: "#495057"
                                                        },
                                                        option: {
                                                            fontFamily: "AileronRegular",
                                                            fontSize: "14px",
                                                            color: "#495057"
                                                        }
                                                    }}
                                                    onSelect={() => {
                                                        const selectedItems = multiselectInvestorTypesRef.current.getSelectedItems();
                                                        let list = [];
                                                        selectedItems.forEach(item => {
                                                            for (const key in item) {
                                                                if (key === "key") {
                                                                    list.push(item[key])
                                                                }
                                                            }
                                                        });
                                                        props.setState({ "type": "update investorTypes", "value": list });
                                                    }}
                                                />

                                                <Form.Text className="addnewprompt">Select investor type</Form.Text>
                                            </div>)
                                            : ""
                                        }
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </Card.Body>
            </Card>

            {props.state.profileType === "Person" || props.state.entityType === "Non-institutional investor" || props.state.entityType === "Advisor"
                ? (<>
                    {/* card based on subtopics */}
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv ">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Entity name
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is the name of your entity?"
                                                        value={props.state.entityName}
                                                        onChange={(e) => { props.setState({ "type": "update entityName", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter entity name</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Legal name
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is the legal name of your entity?"
                                                        value={props.state.legalName}
                                                        onChange={(e) => { props.setState({ "type": "update legalName", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter legal name</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Former legal name(s)
                                                    </Form.Label>

                                                    {
                                                        props.state.formerLegalName.map((x, i) => {
                                                            return (
                                                                <div key={i} style={{ display: "flex" }}>
                                                                    <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                        <Form.Control
                                                                            name="formerLegalName"
                                                                            value={props.state.formerLegalName[i]}
                                                                            placeholder="What is the former legal name of your entity, if any?"
                                                                            onChange={(e) => editField({ type: "update formerLegalName", value: e.target.value, index: i })}
                                                                            style={{ borderColor: "transparent" }}
                                                                        />
                                                                        {
                                                                            props.state.formerLegalName.length !== 1 &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { removeField({ type: "remove formerLegalName", index: i }); }}>
                                                                                <IoMdClose />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        props.state.formerLegalName.length - 1 === i &&
                                                                        <Button className="pitchdeckaddfield"
                                                                            onClick={() => { addField({ type: "add formerLegalName", value: "" }) }}>
                                                                            <IoMdAdd />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                    <Form.Text className="addnewprompt">Enter former legal name(s)</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Date of birth
                                                    </Form.Label>

                                                    <TextField value={props.state.dateOfBirth} className="dobinputbox" type="date" onChange={(e) => { props.setState({ "type": "update dateOfBirth", "value": e.target.value }) }} />

                                                    <Form.Text className="addnewprompt">Select date of birth</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Industry
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.industry}
                                                        onChange={(e) => { props.setState({ "type": "update industry", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        {
                                                            industriesNew.map((option) =>
                                                                <option>{option.key}</option>
                                                            )
                                                        }
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select industry</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Categories
                                                    </Form.Label>

                                                    <Multiselect
                                                        ref={multiselectCategoriesRef1}
                                                        options={categories}
                                                        selectedValues={props.state.categories.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="5"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="Which categories best define your entity?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectCategoriesRef1.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update categories", "value": list });
                                                        }}
                                                    />

                                                    <Form.Text className="addnewprompt">Select categories (maximum 5)</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewlongrow">
                                                {/* form field */}
                                                <div className="addnewlongfield">
                                                    <Form.Label>
                                                        Education
                                                    </Form.Label>

                                                    {
                                                        props.state.education.map((x, i) => {
                                                            return (
                                                                <div key={i} style={{ display: "flex" }}>
                                                                    <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                        <Form.Control
                                                                            name="education"
                                                                            value={props.state.education[i]}
                                                                            placeholder="List your educational history"
                                                                            onChange={(e) => editField({ type: "update education", value: e.target.value, index: i })}
                                                                            style={{ borderColor: "transparent" }}
                                                                        />
                                                                        {
                                                                            props.state.education.length !== 1 &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { removeField({ type: "remove education", index: i }); }}>
                                                                                <IoMdClose />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        props.state.education.length - 1 === i &&
                                                                        <Button className="pitchdeckaddfield"
                                                                            onClick={() => { addField({ type: "add education", value: "" }) }}>
                                                                            <IoMdAdd />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                    <Form.Text className="addnewprompt">Enter education</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card >

                    {/* card based on subtopics */}
                    < Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv ">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            {
                                                props.state.currentWork.map((x, i) => {
                                                    return (
                                                        <>
                                                            <div key={i}>
                                                                <div className="addnewshortrow">
                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Current designation
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="currentDesignation"
                                                                            placeholder="What is your current designation?"
                                                                            value={props.state.currentWork[i].currentDesignation}
                                                                            onChange={(e) => { editField({ type: "update currentWork", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter current designation</Form.Text>
                                                                    </div>

                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Current organization
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="currentOrganization"
                                                                            placeholder="What is your current organization?"
                                                                            value={props.state.currentWork[i].currentOrganization}
                                                                            onChange={(e) => { editField({ type: "update currentWork", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter current organization</Form.Text>
                                                                    </div>
                                                                </div>

                                                                <div className="addnewlongrow">
                                                                    <div className="addnewlongfield">
                                                                        <Form.Label>
                                                                            Short biography
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="shortBiography"
                                                                            placeholder="Briefly describe what you do in less than 50 words"
                                                                            value={props.state.currentWork[i].shortBiography}
                                                                            onChange={(e) => { editField({ type: "update currentWork", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Describe yourself within 50 words</Form.Text>
                                                                    </div>
                                                                </div>

                                                                <div className="addnewlongrow">
                                                                    <div className="addnewlongfield">
                                                                        <Form.Label>
                                                                            Long biography
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="longBiography"
                                                                            placeholder="Briefly describe yourself within 300 words"
                                                                            value={props.state.currentWork[i].longBiography}
                                                                            onChange={(e) => { editField({ type: "update currentWork", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Describe yourself within 300 words</Form.Text>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    props.state.currentWork.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove currentWork", index: i }) }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }

                                                                {
                                                                    props.state.currentWork.length - 1 === i &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { addField({ type: "add currentWork", value: { currentDesignation: "", currentOrganization: "", shortBiography: "", longBiography: "" } }) }}>
                                                                        <IoMdAdd />
                                                                    </Button>
                                                                }

                                                                <hr />
                                                            </div>
                                                        </>
                                                    );
                                                })
                                            }

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        City
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Which city is your entity based in?"
                                                        value={props.state.city}
                                                        onChange={(e) => { props.setState({ "type": "update city", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter city</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        State
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Which state is your entity based in?"
                                                        value={props.state.state}
                                                        onChange={(e) => { props.setState({ "type": "update state", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter state</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Country
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.country}
                                                        onChange={(e) => { props.setState({ "type": "update country", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        {
                                                            countries.map((option) =>
                                                                <option>{option.key}</option>
                                                            )
                                                        }
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select country</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card >

                    {/* card based on subtopics */}
                    < Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv ">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Previous profile type
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.previousProfileType}
                                                        onChange={(e) => { props.setState({ "type": "update previousProfileType", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        {
                                                            profileTypes.map((option) =>
                                                                <option>{option.key}</option>
                                                            )
                                                        }
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select previous profile type</Form.Text>
                                                </div>
                                            </div>

                                            {
                                                props.state.previousWork.map((x, i) => {
                                                    return (
                                                        <>
                                                            <div key={i}>
                                                                <div className="addnewshortrow">
                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Previous designation
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="previousDesignation"
                                                                            placeholder="What was your previous designation?"
                                                                            value={props.state.previousWork[i].previousDesignation}
                                                                            onChange={(e) => { editField({ type: "update previousWork", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter previous designation</Form.Text>
                                                                    </div>

                                                                    <div className="addnewshortfield">
                                                                        <Form.Label>
                                                                            Previous organization
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="previousOrganization"
                                                                            placeholder="What was your previous organization?"
                                                                            value={props.state.previousWork[i].previousOrganization}
                                                                            onChange={(e) => { editField({ type: "update previousWork", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Enter previous organization</Form.Text>
                                                                    </div>
                                                                </div>

                                                                <div className="addnewlongrow">
                                                                    <div className="addnewlongfield">
                                                                        <Form.Label>
                                                                            Previous short biography
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="previousShortBiography"
                                                                            placeholder="Briefly describe what you did in less than 50 words"
                                                                            value={props.state.previousWork[i].previousShortBiography}
                                                                            onChange={(e) => { editField({ type: "update previousWork", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Describe yourself within 50 words</Form.Text>
                                                                    </div>
                                                                </div>

                                                                <div className="addnewlongrow">
                                                                    <div className="addnewlongfield">
                                                                        <Form.Label>
                                                                            Previous long biography
                                                                        </Form.Label>

                                                                        <Form.Control
                                                                            name="previousLongBiography"
                                                                            placeholder="Briefly describe what you did within 300 words"
                                                                            value={props.state.previousWork[i].previousLongBiography}
                                                                            onChange={(e) => { editField({ type: "update previousWork", value: { key: e.target.name, value: e.target.value }, index: i }); }}
                                                                        />

                                                                        <Form.Text className="addnewprompt">Describe yourself within 300 words</Form.Text>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    props.state.previousWork.length !== 1 &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { removeField({ type: "remove previousWork", index: i }) }}>
                                                                        <IoMdClose />
                                                                    </Button>
                                                                }

                                                                {
                                                                    props.state.previousWork.length - 1 === i &&
                                                                    <Button className="pitchdeckaddfield"
                                                                        onClick={() => { addField({ type: "add previousWork", value: { previousDesignation: "", previousOrganization: "", previousShortBiography: "", previousLongBiography: "" } }) }}>
                                                                        <IoMdAdd />
                                                                    </Button>
                                                                }

                                                                <hr />
                                                            </div>
                                                        </>
                                                    );
                                                })
                                            }

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Previous city
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Which city was your previous entity based in?"
                                                        value={props.state.previousCity}
                                                        onChange={(e) => { props.setState({ "type": "update previousCity", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter previous city</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Previous state
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Which state was your previous entity based in?"
                                                        value={props.state.previousState}
                                                        onChange={(e) => { props.setState({ "type": "update previousState", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter previous state</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Previous country
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Which country was your previous entity based in?"
                                                        value={props.state.previousCountry}
                                                        onChange={(e) => { props.setState({ "type": "update previousCountry", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter previous country</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card >
                </>)
                : ( // card based on subtopics
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv ">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Entity name
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is the name of your entity?"
                                                        value={props.state.entityName}
                                                        onChange={(e) => { props.setState({ "type": "update entityName", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter entity name</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Legal name
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is the legal name of your entity?"
                                                        value={props.state.legalName}
                                                        onChange={(e) => { props.setState({ "type": "update legalName", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter legal name</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Former legal name(s)
                                                    </Form.Label>

                                                    {
                                                        props.state.formerLegalName.map((x, i) => {
                                                            return (
                                                                <div key={i} style={{ display: "flex" }}>
                                                                    <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                        <Form.Control
                                                                            name="formerLegalName"
                                                                            value={props.state.formerLegalName[i]}
                                                                            placeholder="What is the former legal name of your entity, if any?"
                                                                            onChange={(e) => editField({ type: "update formerLegalName", value: e.target.value, index: i })}
                                                                            style={{ borderColor: "transparent" }}
                                                                        />
                                                                        {
                                                                            props.state.formerLegalName.length !== 1 &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { removeField({ type: "remove formerLegalName", index: i }); }}>
                                                                                <IoMdClose />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        props.state.formerLegalName.length - 1 === i &&
                                                                        <Button className="pitchdeckaddfield"
                                                                            onClick={() => { addField({ type: "add formerLegalName", value: "" }) }}>
                                                                            <IoMdAdd />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                    <Form.Text className="addnewprompt">Enter former legal name(s)</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewlongrow">
                                                {/* form field */}
                                                <div className="addnewlongfield">
                                                    <Form.Label>
                                                        Short description
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Briefly describe what your entity does in less than 50 words"
                                                        value={props.state.shortDescription}
                                                        onChange={(e) => { props.setState({ "type": "update shortDescription", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Describe what you do within 50 words</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewlongrow">
                                                {/* form field */}
                                                <div className="addnewlongfield">
                                                    <Form.Label>
                                                        Long description
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Briefly describe what your entity does in less than 300 words"
                                                        value={props.state.longDescription}
                                                        onChange={(e) => { props.setState({ "type": "update longDescription", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Describe what you do within 300 words</Form.Text>
                                                </div>
                                            </div>


                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Ownership status
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.ownershipStatus}
                                                        onChange={(e) => { props.setState({ "type": "update ownershipStatus", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        {
                                                            ownershipStatuses.map((option) =>
                                                                <option>{option.key}</option>
                                                            )
                                                        }
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select ownership status</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Operational status
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.operationalStatus}
                                                        onChange={(e) => { props.setState({ "type": "update operationalStatus", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        <option>Active</option>
                                                        <option>Inactive</option>
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select operational status</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Founded date
                                                    </Form.Label>

                                                    <TextField value={props.state.foundedDate} className="dobinputbox" type="date" onChange={(e) => { props.setState({ "type": "update foundedDate", "value": e.target.value }) }} />

                                                    <Form.Text className="addnewprompt">Enter founded date</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Operational duration
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="How long has your entity been in operation?"
                                                        value={props.state.operationalDuration}
                                                        onChange={(e) => { props.setState({ "type": "update operationalDuration", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter operational duration (in years)</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Shutdown date
                                                    </Form.Label>

                                                    {props.state.operationalStatus === "Active"
                                                        ? (<TextField disabled value={props.state.shutdownDate} className="dobinputbox" type="date" onChange={(e) => { props.setState({ "type": "update shutdownDate", "value": e.target.value }) }} />)
                                                        : (<TextField value={props.state.shutdownDate} className="dobinputbox" type="date" onChange={(e) => { props.setState({ "type": "update shutdownDate", "value": e.target.value }) }} />)
                                                    }

                                                    <Form.Text className="addnewprompt">Enter shutdown date</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        City
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Which city is your entity based in?"
                                                        value={props.state.city}
                                                        onChange={(e) => { props.setState({ "type": "update city", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter city</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        State
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="Which state is your entity based in?"
                                                        value={props.state.state}
                                                        onChange={(e) => { props.setState({ "type": "update state", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter state</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Country
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.country}
                                                        onChange={(e) => { props.setState({ "type": "update country", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        {
                                                            countries.map((option) =>
                                                                <option>{option.key}</option>
                                                            )
                                                        }
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select country</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Industry
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.industry}
                                                        onChange={(e) => { props.setState({ "type": "update industry", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        {
                                                            industriesNew.map((option) =>
                                                                <option>{option.key}</option>
                                                            )
                                                        }
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select industry</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Categories
                                                    </Form.Label>

                                                    <Multiselect
                                                        ref={multiselectCategoriesRef2}
                                                        options={categories}
                                                        selectedValues={props.state.categories.map(x => ({ key: x }))}
                                                        displayValue="key"
                                                        avoidHighlightFirstOption="true"
                                                        selectionLimit="5"
                                                        closeOnSelect={false}
                                                        showCheckbox="true"
                                                        placeholder="Which categories best defines your entity?"
                                                        hidePlaceholder="true"
                                                        emptyRecordMsg=""
                                                        style={{
                                                            searchBox: {
                                                                border: "1px solid #ced4da",
                                                                height: "35px",
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            },
                                                            option: {
                                                                fontFamily: "AileronRegular",
                                                                fontSize: "14px",
                                                                color: "#495057"
                                                            }
                                                        }}
                                                        onSelect={() => {
                                                            const selectedItems = multiselectCategoriesRef2.current.getSelectedItems();
                                                            let list = [];
                                                            selectedItems.forEach(item => {
                                                                for (const key in item) {
                                                                    if (key === "key") {
                                                                        list.push(item[key])
                                                                    }
                                                                }
                                                            });
                                                            props.setState({ "type": "update categories", "value": list });
                                                        }}
                                                    />

                                                    <Form.Text className="addnewprompt">Select categories (maximum 5)</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    {props.state.profileType === "Education" || props.state.profileType === "Project" || props.state.profileType === "Product" || (props.state.profileType === "Government" && props.state.entityType !== "Government-owned corporation")
                                                        ? ""
                                                        : (<>
                                                            <Form.Label>
                                                                Ticker symbol
                                                            </Form.Label>

                                                            <Form.Control
                                                                placeholder="What is the ticker symbol of your entity, if any?"
                                                                value={props.state.tickerSymbol}
                                                                onChange={(e) => { props.setState({ "type": "update tickerSymbol", "value": e.target.value }) }}
                                                            />

                                                            <Form.Text className="addnewprompt">Enter ticker symbol</Form.Text>
                                                        </>)
                                                    }
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Email
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is your email?"
                                                        value={props.state.email}
                                                        onChange={(e) => { props.setState({ "type": "update email", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter email</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Telephone
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is your telephone number?"
                                                        value={props.state.telephone}
                                                        onChange={(e) => { props.setState({ "type": "update telephone", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter telephone number</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Website
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is the link to your website?"
                                                        value={props.state.website}
                                                        onChange={(e) => { props.setState({ "type": "update website", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter website</Form.Text>
                                                </div>
                                            </div>

                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Blog
                                                    </Form.Label>

                                                    <Form.Control
                                                        placeholder="What is the link to your blog?"
                                                        value={props.state.blog}
                                                        onChange={(e) => { props.setState({ "type": "update blog", "value": e.target.value }) }}
                                                    />

                                                    <Form.Text className="addnewprompt">Enter blog</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Social media
                                                    </Form.Label>

                                                    {
                                                        props.state.socialMedia.map((x, i) => {
                                                            return (
                                                                <div key={i} style={{ display: "flex" }}>
                                                                    <div style={{ display: "flex", border: "1px solid #ced4da", borderRadius: "0.25rem", width: "100%" }}>
                                                                        <Form.Control
                                                                            name="socialMedia"
                                                                            value={props.state.socialMedia[i]}
                                                                            placeholder="What is the link to your social media?"
                                                                            onChange={(e) => editField({ type: "update socialMedia", value: e.target.value, index: i })}
                                                                            style={{ borderColor: "transparent" }}
                                                                        />
                                                                        {
                                                                            props.state.socialMedia.length !== 1 &&
                                                                            <Button className="pitchdeckaddfield"
                                                                                onClick={() => { removeField({ type: "remove socialMedia", index: i }); }}>
                                                                                <IoMdClose />
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        props.state.socialMedia.length - 1 === i &&
                                                                        <Button className="pitchdeckaddfield"
                                                                            onClick={() => { addField({ type: "add socialMedia", value: "" }) }}>
                                                                            <IoMdAdd />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                    <Form.Text className="addnewprompt">Enter social media</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>)
            }

            {props.state.profileType === "Organization" || props.state.profileType === "Finance" || props.state.profileType === "Education" || props.state.profileType === "Project" || props.state.profileType === "Product"
                ? (<>
                    {/* card based on subtopics */}
                    <Card style={{ marginTop: "40px" }}>
                        <Card.Body className="addnewaccordioncardbody">
                            <div className="addnewaccordioncardbodydiv ">
                                <Form>
                                    <Form.Group>
                                        <div className="addnewscrollablediv">
                                            <div className="addnewshortrow">
                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Gender
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.gender}
                                                        onChange={(e) => { props.setState({ "type": "update gender", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        {
                                                            profileGenders.map((option) =>
                                                                <option>{option.key}</option>
                                                            )
                                                        }
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select gender</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Are you a first time founder?
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.firstTimeFounder}
                                                        onChange={(e) => { props.setState({ "type": "update firstTimeFounder", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        <option>Yes, I'm a fresh founder</option>
                                                        <option>No, I'm a serial founder</option>
                                                        <option>Yes but I've got more than 10 years of professional experience</option>
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select founder type</Form.Text>
                                                </div>

                                                {/* form field */}
                                                <div className="addnewshortfield">
                                                    <Form.Label>
                                                        Are you a technical or non-technical founder?
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="select"
                                                        value={props.state.technicalFounder}
                                                        onChange={(e) => { props.setState({ "type": "update technicalFounder", "value": e.target.value }) }}
                                                    >
                                                        <option></option>
                                                        <option>Yes, I'm a technical founder</option>
                                                        <option>No, I'm a non-technical founder</option>
                                                    </Form.Control>

                                                    <Form.Text className="addnewprompt">Select founder type</Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </>)
                : ""
            }
        </>
    );
}

export default AddEditGeneralInformation