import React from 'react'
import '../../components/tools/founders/pitch-deck-analysis/pitch-deck-analysis.css'
import NavBar from '../navbar/navbar'
// import CreateEditManagement from './create-edit-pitch-deck-analysis/management';
import Container from 'react-bootstrap/Container'
import ViewPlanBuilderTabs from '../../components/tools/founders/pitch-deck-analysis/components/tabs/view-tabs'
import MessageContainerModal from '../../components/modals/message-container-modal'

const ViewPlanBuilderPageLayout = ({ props }) => {
  const { data } = props
  return (
    <>
      <NavBar />
      <div id='pitchdeck'>
        <MessageContainerModal props={props} />

        <Container className='col-11' style={{ paddingBottom: '200px' }}>
          <div className='pitchdeckheaderdiv'>
            <div className='pitchdecktitlediv'>
              <br />
              <span className='pitchdecktext'>
                {!data?.pitchDeckName ? '-' : data.pitchDeckName}
              </span>
            </div>
          </div>

          <ViewPlanBuilderTabs data={data} />
        </Container>
      </div>
    </>
  )
}

export default ViewPlanBuilderPageLayout