import React from 'react'
import AccordianCard from '../../../../../../accordion-card/accordian-card'
import '../../../pitch-deck-analysis.css'
import PitchDeckDetails from '../../view-details/details-component'

function ViewRisk({ data }) {
  return (
    <>
      <AccordianCard
        className='companyaccordion'
        title='Risk disclosure'
        activeKey='riskDisclosure'
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `Technology risk`,
              fieldName: 'technologyRisk',
              type: 'bullets'
            },
            {
              displayName: `Intellectual property risk`,
              fieldName: 'intellectualPropertyRisk',
              type: 'bullets'
            },
            {
              displayName: `Development risk`,
              fieldName: 'developmentRisk',
              type: 'bullets'
            },
            {
              displayName: `Operational risk`,
              fieldName: 'operationalRisk',
              type: 'bullets'
            },
            {
              displayName: `Product-fit risk`,
              fieldName: 'productFitRisk',
              type: 'bullets'
            },
            {
              displayName: `Channel risk`,
              fieldName: 'channelRisk',
              type: 'bullets'
            },
            {
              displayName: `Regulatory risk`,
              fieldName: 'regulatoryRisk',
              type: 'bullets'
            },
            {
              displayName: `Competitor risk`,
              fieldName: 'competitorRisk',
              type: 'bullets'
            },
            {
              displayName: `Team risk`,
              fieldName: 'teamRisk',
              type: 'bullets'
            },
            {
              displayName: `Hiring risk`,
              fieldName: 'hiringRisk',
              type: 'bullets'
            },
            {
              displayName: `Financing risk`,
              fieldName: 'financingRisk',
              type: 'bullets'
            },
            {
              displayName: `CEO risk`,
              fieldName: 'ceoRisk',
              type: 'bullets'
            }
          ]}
        />
      </AccordianCard>
    </>
  )
}

export default ViewRisk