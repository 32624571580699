import React from 'react';
import { Card } from 'react-bootstrap';
import '../data-contribution/data-contribution.css';

function Business(props) {

    return (
        <>
            <Card style={{ marginTop: "40px" }}>
                <Card.Body id="companyinformation">
                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Investment range</p>
                            <p className="companydetaildata">{!props?.investmentRange ? "-" : props.investmentRange}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Sweet spot</p>
                            <p className="companydetaildata">{!props?.sweetSpot
                                ? "-"
                                : <ol className="detailproductlist">
                                    {!props.sweetSpot.veryEarlyStage ? "" : <li className="detailbodydata">Very-early stage: {props.sweetSpot.veryEarlyStage}</li>}
                                    {!props.sweetSpot.earlyStage ? "" : <li className="detailbodydata">Early stage: {props.sweetSpot.earlyStage}</li>}
                                    {!props.sweetSpot.growthStage ? "" : <li className="detailbodydata">Growth stage: {props.sweetSpot.growthStage}</li>}
                                    {!props.sweetSpot.lateStage ? "" : <li className="detailbodydata">Late stage: {props.sweetSpot.lateStage}</li>}
                                    {!props.sweetSpot.postIPO ? "" : <li className="detailbodydata">Post-IPO: {props.sweetSpot.postIPO}</li>}
                                </ol>
                            }</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Stages invested in</p>
                            <p className="companydetaildata">{!props?.stagesInvestedIn
                                ? "-"
                                : <ol className="detailproductlist">
                                    {!props.stagesInvestedIn.veryEarlyStage ? "" : <li className="detailbodydata">Very-early stage: {props.stagesInvestedIn.veryEarlyStage}</li>}
                                    {!props.stagesInvestedIn.earlyStage ? "" : <li className="detailbodydata">Early stage: {props.stagesInvestedIn.earlyStage}</li>}
                                    {!props.stagesInvestedIn.growthStage ? "" : <li className="detailbodydata">Growth stage: {props.stagesInvestedIn.growthStage}</li>}
                                    {!props.stagesInvestedIn.lateStage ? "" : <li className="detailbodydata">Late stage: {props.stagesInvestedIn.lateStage}</li>}
                                    {!props.stagesInvestedIn.postIPO ? "" : <li className="detailbodydata">Post-IPO: {props.stagesInvestedIn.postIPO}</li>}
                                </ol>
                            }</p>
                        </div>
                    </div>

                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Preferred investment stage(s)</p>
                            <p className="companydetaildata">{!props?.preferredInvestmentStages ? "-" : props.preferredInvestmentStages.join(", ")}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Preferred investment focus</p>
                            <p className="companydetaildata">{!props?.preferredInvestmentFocus ? "-" : props.preferredInvestmentFocus.join(", ")}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Preferred investment geography</p>
                            <p className="companydetaildata">{!props?.preferredInvestmentGeography ? "-" : props.preferredInvestmentGeography.join(", ")}</p>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default Business