export const steps = [
    {
        element: '.editor',
        intro: 'Welcome to the no-code web editor!',
    },
    {
        element: '.gjs-pn-panel gjs-pn-commands gjs-one-bg gjs-two-color',
        intro: 'This is the canvas. Here you will design pages for your project.',
        tooltipClass: 'tooltip-canvas',
        highlightClass: 'highlight-canvas'
    },
    {
        element: '.gjs-pn-btn fa fa-th-large gjs-pn-active gjs-four-color',
        intro: 'Each page is composed of blocks. Open the block manager for the list of drag-and-drop features for you to design your canvas.',
        tooltipClass: 'tooltip-block-manager',
        highlightClass: 'highlight-block-manager',
    },
    {
        element: 'gjs-pn-btn fa fa-desktop',
        intro: 'You may click view components to highlight the components in the canvas.',
        tooltipClass: 'tooltip-view-comp',
        highlightClass: 'highlight-view-comp',
    },
    {
        element: 'gjs-pn-btn fa fa-desktop',
        intro: 'Click on these icons to check whether your layout is responsive to different screen sizes.',
        tooltipClass: 'tooltip-screen-size',
        highlightClass: 'highlight-screen-size',
    },
    {
        element: 'gjs-pn-btn fa fa-desktop',
        intro: 'Start creating your project by dragging a container from the layout section to your canvas.<br/><sup>*</sup>Scroll down to find the layout section',
        tooltipClass: 'tooltip-layout',
        highlightClass: 'highlight-layout',
    },
    {
        element: 'gjs-pn-btn fa fa-desktop',
        intro: 'Continue to implement a few rows and columns from both the layout or basic section.',
        tooltipClass: 'tooltip-layout',
        highlightClass: 'highlight-layout',
    },
    // {
    //     element: 'gjs-pn-btn fa fa-desktop',
    //     intro: 'You may choose to style your column with some background color.',
    //     tooltipClass: 'tooltip-layout',
    //     highlightClass: 'highlight-layout',
    // },
    {
        element: 'gjs-pn-btn fa fa-desktop',
        intro: 'Drag a text to the canvas and try changing the font style in the typography section. You can also change its font size, color and other options.',
        tooltipClass: 'tooltip-layout',
        highlightClass: 'highlight-layout',
    },
    {
        element: 'gjs-pn-btn fa fa-desktop',
        intro: 'You’re good to go! Now you can add more components into the canvas. If you want to start the guided tour again, click on the i icon.',
        tooltipClass: 'tooltip-i',
        highlightClass: 'highlight-i',
    },
    {
        element: '.gjs-pn-btn fa fa-th-large gjs-pn-active gjs-four-color',
        intro: 'The editor tour is now complete. You can now start building your web pages!',
    },
];