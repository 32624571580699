import React, { useEffect, useRef } from "react";

import { Alert, Button } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";

const MessageContainerModal = ({ props }) => {
  const { alert, closeAlert, message } = props;

  const closeModal = () => closeAlert();

  const ref = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!ref.current.contains(e.target)) {
        closeModal();
      }
    };
    if (alert) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [alert]);

  return (
    <div ref={ref}>
      {alert && (
        <Alert className="error-message floating-alert" variant="light">
          <div>
            <div style={{ textAlign: "right" }}>
              <Button className="pitchdeckmessageclose" onClick={closeAlert}>
                <IoMdClose />
              </Button>
            </div>
            {message}
          </div>
        </Alert>
      )}
    </div>
  );
};

export default MessageContainerModal;
