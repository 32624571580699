import React from 'react';
import { Card } from 'react-bootstrap';
import '../data-contribution.css';

function ViewManagement(props) {

    return (
        <Card style={{ marginTop: "40px" }}>
            <Card.Body id="companyinformation">
                <div>
                    <p className="companydetailtitle">Management</p>
                    {!props?.management
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">
                            <ul>{props.management.map((name, index) =>
                                !name
                                    ? ""
                                    : (<li className="pitchdeckanswers" key={index}>{name}</li>)
                            )}</ul>
                        </p>)
                    }
                </div>

                <div className="companydetailrows">
                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">No. of employees</p>
                        {!props?.numOfEmployees
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.numOfEmployees}</p>)
                        }
                    </div>

                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">No. of partners</p>
                        {!props?.numOfPartners
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.numOfPartners}</p>)
                        }
                    </div>

                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">Partners</p>
                        {!props?.partners
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">
                                <ul>{props.partners.map((partner, index) =>
                                    !partner
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{partner}</li>)
                                )}</ul>
                            </p>)
                        }
                    </div>
                </div>

                <div className="companydetailrows">
                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">No. of board member(s)</p>
                        {!props?.numOfBoardMembers
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.numOfBoardMembers}</p>)
                        }
                    </div>

                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">Board member(s)</p>
                        {!props?.boardMembers
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">
                                <ul>{props.boardMembers.map((member, index) =>
                                    !member
                                        ? ""
                                        : (<li className="pitchdeckanswers" key={index}>{member}</li>)
                                )}</ul>
                            </p>)
                        }
                    </div>
                </div>

                <div className="companydetailrows">
                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">Email</p>
                        {!props?.managementEmail
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.managementEmail}</p>)
                        }
                    </div>

                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">Mobile phone</p>
                        {!props?.managementMobilePhone
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.managementMobilePhone}</p>)
                        }
                    </div>

                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">Website</p>
                        {!props?.managementWebsite
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.managementWebsite}</p>)
                        }
                    </div>
                </div>

                <div className="companydetailrows">
                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">Blog</p>
                        {!props?.managementBlog
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.managementBlog}</p>)
                        }
                    </div>

                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">Mobile phone</p>
                        {!props?.managementMobilePhone
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.managementMobilePhone}</p>)
                        }
                    </div>

                    <div className="pitchdeckdetaildiv">
                        <p className="companydetailtitle">Website</p>
                        {!props?.managementWebsite
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.managementWebsite}</p>)
                        }
                    </div>
                </div>

                <div>
                    <p className="companydetailtitle">Social media</p>
                    {!props?.managementSocialMedia
                        ? (<p className="companydetaildata">-</p>)
                        : (<p className="companydetaildata">
                            <ul>{props.managementSocialMedia.map((social, index) =>
                                !social
                                    ? ""
                                    : (<li className="pitchdeckanswers" key={index}>{social}</li>)
                            )}</ul>
                        </p>)
                    }
                </div>
            </Card.Body>
        </Card>
    );
}

export default ViewManagement