import React, { useRef } from 'react';
import '../competitive-landscape.css';
import { Accordion, Card, Form } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { Multiselect } from 'multiselect-react-dropdown';
import { industriesNew } from '../../../dropdown-options/industries';
import { categories } from '../../../dropdown-options/categories';
import { countries } from '../../../dropdown-options/countries';

const CreateEditMarketLandscapeCompetition = (props) => {

    // refs for multiselect
    const multiselectIndustryRef = useRef(null);
    const multiselectCategoriesRef = useRef(null);
    const multiselectLocationsRef = useRef(null);
    const multiselectFinancingStatusRef = useRef(null);
    const multiselectOperationalStatusRef = useRef(null);
    const multiselectLabourForcesRef = useRef(null);
    const multiselectSimilarityScoresRef = useRef(null);

    return (

        <Card>
            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="howCompetitiveIsYourMarketLandscape">
                <div className="companyarrowicon">
                    <FaChevronDown className="arrow-icon" />
                </div>

                <div className="companytitlecard">
                    How competitive is your market landscape?
                </div>

                <div className="companyellipsisicon">
                    <FaEllipsisH />
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="howCompetitiveIsYourMarketLandscape">
                <Card.Body className="ideacheckeraccordioncardbody">
                    <div className="col-12 div-filter">
                        {/* form */}
                        <Form>
                            <Form.Group>
                                <div className="ideacheckerrow">
                                    <div>
                                        <Form.Label>
                                            Industry
                                            <sup data-tip="Select the industrial grouping that best describe the production process, functions, and operations of your core business">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectIndustryRef}
                                            options={industriesNew}
                                            selectedValues={(props.state.industry) ? [{ key: props.state.industry }] : []}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="1"
                                            showCheckbox="true"
                                            placeholder="Which industry to focus on?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectIndustryRef.current.getSelectedItems();
                                                let list = selectedItems[0].key;
                                                if (!list) list = "";
                                                props.setState({ type: "update industry", value: list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select industry</Form.Text>
                                    </div>
                                </div>

                                <div className="ideacheckerrow">
                                    <div>
                                        <Form.Label>
                                            Categories
                                            <sup data-tip="Select the categories that best define the specific niche of your business focus in a vertical market.">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectCategoriesRef}
                                            options={categories}
                                            selectedValues={props.state.categories ? props.state.categories.map(x => ({ key: x })) : []}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="5"
                                            closeOnSelect={false}
                                            showCheckbox="true"
                                            placeholder="Which business categories or verticals (specific niches) to examine?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectCategoriesRef.current.getSelectedItems();
                                                let list = [];
                                                selectedItems.forEach(item => {
                                                    for (const key in item) {
                                                        if (key === "key") {
                                                            list.push(item[key])
                                                        }
                                                    }
                                                });
                                                props.setState({ "type": "update categories", "value": list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select categories (maximum 5)</Form.Text>
                                    </div>
                                </div>

                                <div className="ideacheckerrow2">
                                    <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                        <Form.Label>
                                            Location(s)
                                            <sup data-tip="Select the location where your company is headquartered at">
                                                <IoMdInformationCircleOutline id="information-icon" />
                                            </sup>
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectLocationsRef}
                                            options={countries}
                                            selectedValues={props.state.locations ? props.state.locations.map(x => ({ key: x })) : []}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="-1"
                                            showCheckbox="true"
                                            placeholder="Which country(ies) to narrow down the locations of the companies' headquarters?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectLocationsRef.current.getSelectedItems();
                                                let list = [];
                                                selectedItems.forEach(item => {
                                                    for (const key in item) {
                                                        if (key === "key") {
                                                            list.push(item[key])
                                                        }
                                                    }
                                                });
                                                props.setState({ "type": "update locations", "value": list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select locations</Form.Text>
                                    </div>

                                    <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                        <Form.Label>
                                            Financing status
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectFinancingStatusRef}
                                            options={[
                                                { key: "Early Stage" },
                                                { key: "Equity Crowdfunding" },
                                                { key: "Initial Coin Offering" },
                                                { key: "Initial Public Offering" },
                                                { key: "Late Stage" },
                                                { key: "M&A" },
                                                { key: "Pre-seed" },
                                                { key: "Private Equity" },
                                                { key: "Seed" },
                                                { key: "Series A" }
                                            ]}
                                            selectedValues={props.state.financingStatus ? props.state.financingStatus.map(x => ({ key: x })) : []}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="-1"
                                            closeOnSelect={false}
                                            showCheckbox="true"
                                            placeholder="Which financing round(s) raised by the companies to focus at?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectFinancingStatusRef.current.getSelectedItems();
                                                let list = [];
                                                selectedItems.forEach(item => {
                                                    for (const key in item) {
                                                        if (key === "key") {
                                                            list.push(item[key])
                                                        }
                                                    }
                                                });
                                                props.setState({ "type": "update financingStatus", "value": list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select financing round(s)</Form.Text>
                                    </div>

                                    <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                        <Form.Label>
                                            Operational status
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectOperationalStatusRef}
                                            options={[
                                                { key: "Active" },
                                                { key: "Inactive" }
                                            ]}
                                            selectedValues={props.state.operationalStatus ? props.state.operationalStatus.map(x => ({ key: x })) : []}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="-1"
                                            closeOnSelect={false}
                                            showCheckbox="true"
                                            placeholder="Which operational status of the companies to focus on?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectOperationalStatusRef.current.getSelectedItems();
                                                let list = [];
                                                selectedItems.forEach(item => {
                                                    for (const key in item) {
                                                        if (key === "key") {
                                                            list.push(item[key])
                                                        }
                                                    }
                                                });
                                                props.setState({ "type": "update operationalStatus", "value": list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select operational status(es)</Form.Text>
                                    </div>
                                </div>

                                <div className="ideacheckerrow2">
                                    <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                        <Form.Label>
                                            Labour force
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectLabourForcesRef}
                                            options={[
                                                { key: "1-10" },
                                                { key: "10-50" },
                                                { key: "50-100" },
                                                { key: "100-250" },
                                                { key: "250-500" },
                                                { key: "500-1,000" },
                                                { key: "1,000-5,000" },
                                                { key: "5,000-10,000" },
                                                { key: "More than 10,000" }
                                            ]}
                                            selectedValues={props.state.labourForces ? props.state.labourForces.map(x => ({ key: x })) : []}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="-1"
                                            showCheckbox="true"
                                            placeholder="What number of employees to narrow down the employment size of the companies?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectLabourForcesRef.current.getSelectedItems();
                                                let list = [];
                                                selectedItems.forEach(item => {
                                                    for (const key in item) {
                                                        if (key === "key") {
                                                            list.push(item[key])
                                                        }
                                                    }
                                                });
                                                props.setState({ "type": "update labourForces", "value": list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select employees size range</Form.Text>
                                    </div>

                                    <div className="idea-checker-field" style={{ paddingRight: "2%" }}>
                                        <Form.Label>
                                            Similarity scores
                                        </Form.Label>
                                        <Multiselect
                                            ref={multiselectSimilarityScoresRef}
                                            options={[
                                                { key: "first quartile (lowest 25%)" },
                                                { key: "second quartile (between 25.1%-50%)" },
                                                { key: "median quartile" },
                                                { key: "third quartile (50.1%-75%)" },
                                                { key: "fourth quartile (highest 25%)" }
                                            ]}
                                            selectedValues={props.state.similarityScores ? props.state.similarityScores.map(x => ({ key: x })) : []}
                                            displayValue="key"
                                            avoidHighlightFirstOption="true"
                                            selectionLimit="-1"
                                            closeOnSelect={false}
                                            showCheckbox="true"
                                            placeholder="Which quartile of the similarity scores to observe?"
                                            hidePlaceholder="true"
                                            emptyRecordMsg=""
                                            style={{
                                                searchBox: {
                                                    border: "1px solid #ced4da",
                                                    height: "35px",
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                },
                                                option: {
                                                    fontFamily: "AileronRegular",
                                                    fontSize: "14px",
                                                    color: "#495057"
                                                }
                                            }}
                                            onSelect={() => {
                                                const selectedItems = multiselectSimilarityScoresRef.current.getSelectedItems();
                                                let list = [];
                                                selectedItems.forEach(item => {
                                                    for (const key in item) {
                                                        if (key === "key") {
                                                            list.push(item[key])
                                                        }
                                                    }
                                                });
                                                props.setState({ "type": "update similarityScores", "value": list });
                                            }}
                                        />
                                        <Form.Text className="text-muted">Select quartile</Form.Text>
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>

    )

}

export default CreateEditMarketLandscapeCompetition;