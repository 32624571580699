import React from "react";
import { Spinner } from "react-bootstrap";
import { BiAnalyse } from "react-icons/bi";

const LoadingModal = () => {
  return (
    <div style={{ display: "flex", paddingTop: "10px" }}>
      <div style={{ width: "90%" }}>
        <p style={{ color: "#0f9d58" }}>
          <BiAnalyse className="alert-icon" /> Loading
        </p>
        <p style={{ marginLeft: "28px", fontSize: "14px" }}>
          Won't be long now. We're always doing our best to reduce waiting time.
        </p>
        <p style={{ marginLeft: "28px", fontSize: "14px" }}>
          If your waiting time is too long,{" "}
          <a
            href="https://www.roiquant.com/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us
          </a>
          .
        </p>
      </div>
      <div style={{ width: "10%", textAlign: "right" }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    </div>
  );
};

export default LoadingModal;
