import React from 'react'
import AccordianCard from '../../../../../../accordion-card/accordian-card'
import '../../../pitch-deck-analysis.css'
import PitchDeckDetails from '../../view-details/details-component'

function ViewMarketing({ data }) {
  return (
    <>
      <AccordianCard
        className='companyaccordion'
        title='Go-to-market (distribution channel)'
        activeKey='goToMarket'
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `What is your go-to-market strategy to gain visibility and
                    expand distribution channels for your product-or-service to
                    acquire new customers?`,
              fieldName: 'goToMarketStrategy',
              type: 'bullets'
            },
            {
              displayName: `Does your business model support customer acquisition
                    profitably?`,
              fieldName: 'doesBusinessModelSupportAcquisition',
              type: 'bullets'
            },
            {
              displayName: `Are you planning to acquire customers through brand
                    marketing or other online forms of acquisition?`,
              fieldName: 'planningToAcquireCustomersThroughMarketing',
              type: 'bullets'
            },
            {
              displayName: `How will your product-or-service be distributed to the end
                    user?`,
              fieldName: 'howProductWillBeDistributed',
              type: 'bullets'
            },
            {
              displayName: `What distribution channels will you use?`,
              fieldName: 'distributionChannelsUsed',
              type: 'bullets'
            },
            {
              displayName: `When, where, why, and how do consumers buy your
                    product-or-service?`,
              fieldName: 'whenWhereWhyHowConsumersBuy',
              type: 'bullets'
            },
            {
              displayName: `Is it transaction oriented (where you have limited
                    communication with your customers and focus instead on
                    efficiency or speed, such as a self-service kiosk at an
                    airport terminal)?`,
              fieldName: 'isProductTransactionOriented',
              type: 'bullets'
            },
            {
              displayName: `Is the process of buying your product-or-service
                    relationship oriented (like a loyalty program where you
                    learn customer preferences and make suggestions about other
                    items of interest)?`,
              fieldName: 'isBuyingProcessRelationshipOriented',
              type: 'bullets'
            }
          ]}
        />
      </AccordianCard>

      <AccordianCard
        className='companyaccordion'
        title='Promotion'
        activeKey='promotion'
      >
        <PitchDeckDetails
          data={data}
          detailsArray={[
            {
              displayName: `Are you planning to build a direct, outside sales force, and
                    can the average selling price of your product-or-service
                    support this go-to-market strategy?`,
              fieldName: 'areYouPlanningToBuildDirectForce',
              type: 'bullets'
            },
            {
              displayName: `How will you communicate with consumers and make them aware
                    of your product-or-service?`,
              fieldName: 'howToCommunicateWithConsumers',
              type: 'bullets'
            },
            {
              displayName: `Will you use word of mouth?`,
              fieldName: 'willUseWordOfMouth',
              type: 'bullets'
            },
            {
              displayName: `Will you opt for sales promotions?`,
              fieldName: 'willOptForSalesPromotions',
              type: 'bullets'
            },
            {
              displayName: `Do direct sales make the most sense?`,
              fieldName: 'doDirectSalesMakeSense',
              type: 'bullets'
            },
            {
              displayName: `Is traditional advertising a better approach?`,
              fieldName: 'isTraditionalBetter',
              type: 'bullets'
            },
            {
              displayName: `Do you need to build brand loyalty, or is your
                    product-or-service the only one that will fill customers'
                    needs?`,
              fieldName: 'doYouNeedToBuildBrandLoyalty',
              type: 'bullets'
            }
          ]}
        />
      </AccordianCard>
    </>
  )
}

export default ViewMarketing