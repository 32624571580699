import React from 'react';
import '../idea-validation.css';
import { Accordion, Card } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdInformationCircleOutline } from 'react-icons/io';

function ViewIdeaUniqueness(props) {

    return (

        <Card>
            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="howUniqueIsYourIdea">
                <div className="companyarrowicon">
                    <FaChevronDown />
                </div>

                <div className="companytitlecard">
                    How unique is your idea?
                </div>

                <div className="companyellipsisicon">
                    <FaEllipsisH />
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="howUniqueIsYourIdea">
                <Card.Body id="companyinformation">
                    <div>
                        <p className="companydetailtitle">
                            <sup>*</sup>
                            Conceptual idea (one-sentence pitch)
                            <sup data-tip="In one single sentence, clearly explain your core business idea by focusing on your “why?”">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        {!props?.idea
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.idea}</p>)
                        }
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            <sup>*</sup>
                            Industry
                            <sup data-tip="Select the industrial grouping that best describe the production process, functions, and operations of your core business">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        {!props?.industry
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.industry}</p>)
                        }
                    </div>

                    <div>
                        <p className="companydetailtitle">
                            <sup>*</sup>
                            Categories
                            <sup data-tip="Select the categories that best define the specific niche of your business focus in a vertical market.">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </p>
                        {!props?.categories
                            ? (<p className="companydetaildata">-</p>)
                            : (<p className="companydetaildata">{props.categories.map((category, index) =>
                                !category
                                    ? ""
                                    : (<span key={index}>{category}, </span>)
                            )}</p>)
                        }
                    </div>

                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">
                                <sup>*</sup>
                                Location
                                <sup data-tip="Select the location where your company is headquartered at">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </p>
                            {!props?.location
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">{props.location}</p>)
                            }
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">
                                <sup>*</sup>
                                Market(s)
                                <sup data-tip="Select your market location(s) where you offer and sell your product-or-solution">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </p>
                            {!props?.markets
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">{props.markets.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<span key={index}>{point}, </span>)
                                )}</p>)
                            }
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">
                                Entity type(s)
                                <sup data-tip="Select the business entity type that dictates both the legal structure of your organization and how your company will be taxed">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </p>
                            {!props?.entityType
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">{props.entityType.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<span key={index}>{point}, </span>)
                                )}</p>)
                            }
                        </div>
                    </div>

                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">
                                Stage of development
                                <sup data-tip="Describe your current stage of business development">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </p>
                            {!props?.stageOfDevelopment
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">{props.stageOfDevelopment}</p>)
                            }
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">
                                Transactional model type(s)
                                <sup data-tip="Select the transactional model type that best describe the relationship of transactions between your organization and your counterparts">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </p>
                            {!props?.businessModelType
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">{props.businessModelType.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<span key={index}>{point}, </span>)
                                )}</p>)
                            }
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">
                                Competitive moat(s)
                                <sup data-tip="Select the defensibility moat that best define your competitive advantage to protect long-term profits and market share">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </p>
                            {!props?.defensibilityMoat
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">{props.defensibilityMoat.map((point, index) =>
                                    !point
                                        ? ""
                                        : (<span key={index}>{point}, </span>)
                                )}</p>)
                            }
                        </div>
                    </div>

                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">
                                Market reach
                                <sup data-tip="Estimate the amount of customers you can reach in a year">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </p>
                            {!props?.reach
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">
                                    {
                                        !props?.businessModelType
                                            ? props.reach + " customers"
                                            : props.businessModelType.includes("B2C") || props.businessModelType.includes("C2C") || props.businessModelType.includes("G2C") || props.businessModelType.includes("M2C")
                                                ? props.reach + "0,000 customers"
                                                : props.reach + " customers"
                                    }
                                </p>)
                            }
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">
                                Impact value
                                <sup data-tip="Estimate the impact value of your business offering to your customers">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </p>
                            {!props?.impact
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">
                                    {
                                        props.impact == 0
                                            ? "-"
                                            : props.impact == 25
                                                ? "Low"
                                                : props.impact == 50
                                                    ? "Moderate"
                                                    : props.impact == 75
                                                        ? "High"
                                                        : props.impact == 100
                                                            ? "Very high"
                                                            : ""
                                    }
                                </p>)
                            }
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">
                                Confidence level
                                <sup data-tip="Determine the level of your confidence to execute your idea">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </p>
                            {!props?.confidence
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">
                                    {
                                        props.confidence == 0
                                            ? "-"
                                            : props.confidence == 25
                                                ? "Low"
                                                : props.confidence == 50
                                                    ? "Moderate"
                                                    : props.confidence == 75
                                                        ? "High"
                                                        : props.confidence == 100
                                                            ? "Very high"
                                                            : ""
                                    }
                                </p>)
                            }
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">
                                Ease of implementation
                                <sup data-tip="Evaluate how easy it is for your team to implement your business idea">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </p>
                            {!props?.ease
                                ? (<p className="companydetaildata">-</p>)
                                : (<p className="companydetaildata">
                                    {
                                        props.ease == 0
                                            ? "-"
                                            : props.ease == 25
                                                ? "Very difficult"
                                                : props.ease == 50
                                                    ? "Difficult"
                                                    : props.ease == 75
                                                        ? "Moderate"
                                                        : props.ease == 100
                                                            ? "Easy"
                                                            : ""
                                    }
                                </p>)
                            }
                        </div>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>

    )

}

export default ViewIdeaUniqueness;