import React, { useReducer, useEffect, useState } from 'react';
import '../../tools/founders/pitch-deck-analysis/pitch-deck-analysis.css';
import NavBar from '../../../layout/navbar/navbar';
import { Button, Alert, Container, Spinner, Modal, DropdownButton, Dropdown } from 'react-bootstrap';
import { FaEllipsisH } from 'react-icons/fa';
import { IoMdClose, IoMdInformationCircleOutline } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { AiOutlineCheckSquare, AiOutlineEdit } from 'react-icons/ai';
import { GoArchive } from 'react-icons/go';
import { useRealmApp } from '../../../RealmApp';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const CompetitiveLandscapeLandingPage = () => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    const { currentUser } = useRealmApp();

    // declare realm functions
    const { getUsersDocuments, archiveCompetitiveLandscapeAnalysis } = currentUser.functions;

    // hook for documents
    const [state, dispatch] = useReducer(reducer, {
        isDataInitialized: false,
        analyses: [],
    });

    // function to update documents
    function reducer(state, action) {
        switch (action.type) {
            case 'update analyses': {
                return { ...state, analyses: action.value }
            }

            case 'initialize analyses': {
                return { ...state, analyses: action.value, isDataInitialized: true }
            }

            default: {
                return { ...state }
            }
        }
    }

    // get documents on page load
    useEffect(() => {
        async function getAnalyses() {
            const response = await getUsersDocuments({ feature: "Competitive landscape analysis" });

            const analyses = JSON.parse(response);

            closeAlert();

            dispatch({ type: "initialize analyses", value: analyses });
        }
        if (state.isDataInitialized === false) {
            try {
                getAnalyses(true);
            } catch (err) {
                // To-do: Alert user an error was returned
                // console.error(err);
            }
        }
    }, [state.isDataInitialized]);

    // hook for id
    const [archiveId, setArchiveId] = useState();

    // hook for archive modal
    const [archiveModal, setArchiveModal] = useState(false);

    // function to open archive modal
    const openArchiveModal = (e) => {
        setArchiveId(e);
        setArchiveModal(true);
    }

    // function to close archive modal
    function closeArchiveModal() {
        setArchiveId();
        setArchiveModal(false);
    }

    // function to archive document
    async function archiveConfirmation() {

        // hide modal component
        closeArchiveModal();

        // show alert message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Archiving</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your analysis is archived.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await archiveCompetitiveLandscapeAnalysis(archiveId);

            const data = JSON.parse(response);

            dispatch({ type: "update analyses", value: data });

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#5aa469" }}><AiOutlineCheckSquare className="alert-icon" /> Archived</p>
                <p style={{ marginLeft: "28px" }}>Your analysis is archived.</p>
            </div>);
        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

        // close alert
        closeAlert();
    }

    return (

        <>
            {/* navbar */}
            <NavBar />

            {/* alert message */}
            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="pitchdeck">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>
                    <div className="pitchdeckheaderdiv">
                        {/* title */}
                        <div className="pitchdecktitlediv">
                            <br />
                            <span className="pitchdecktext">
                                Competitive landscape
                                <sup data-tip="Define your competitive edge by analyzing your markets with our AI-powered competitive analytics.">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </span>
                        </div>

                        {/* buttons */}
                        <div className="pitchdeckstatsdiv" style={{ display: "flex", justifyContent: "end" }}>
                            <a href="/competitive-intel/competitive-landscape/create">
                                <Button className="pitchdeckaddbutton glintanimation">
                                    <span>Create</span>
                                </Button>
                            </a>

                            <DropdownButton className="ellipsis-dropdown sort-column-button" title={<FaEllipsisH />} style={{ marginLeft: "20px" }}>
                                <Dropdown.Item href="/competitive-intel/competitive-landscape/archive">Archive</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>

                    {/* table */}
                    <div>
                        <table className="pitchdeckhistorytable">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Industry</th>
                                    <th>Version</th>
                                    <th>Modified</th>
                                    <th>Edit</th>
                                    <th>Archive</th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.analyses?.length === 0
                                    ? (<tr className="pitchdecktablerow">
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                    </tr>)
                                    : state.analyses?.map((analysis, index) =>
                                        <tr key={index} className="pitchdecktablerow">
                                            <td className="pitchdecktabledata ideavalidationlandingpagetable" style={{ width: "12vw" }}>
                                                <Link to={`/competitive-intel/competitive-landscape/view/${analysis._id}`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                    {analysis.input.competitiveLandscapeName ? analysis.input.competitiveLandscapeName : "Untitled analysis"}
                                                </Link>
                                            </td>
                                            <td className="pitchdecktabledata" style={{ width: "40vw" }}>
                                                {analysis.input.industry ? analysis.input.industry : "No industry chosen"}
                                            </td>
                                            <td className="pitchdecktabledata">{analysis.version ? analysis.version : "-"}</td>
                                            <td className="pitchdecktabledata">{analysis.dateQuery ? analysis.dateQuery.substring(0, 10) : "-"}</td>
                                            <td className="pitchdecktabledata">
                                                <Link to={`/competitive-intel/competitive-landscape/edit/${analysis._id}`} target="_blank" rel="noopener noreferrer" className="shutdowndatalink">
                                                    <Button className="edit-button"><AiOutlineEdit /></Button>
                                                </Link>
                                            </td>
                                            <td className="pitchdecktabledata">
                                                <Button className="edit-button" onClick={() => openArchiveModal(analysis._id)}><GoArchive /></Button>
                                            </td>
                                        </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                </Container>
            </div>

            {/* archive modal */}
            <Modal className="modal-div" show={archiveModal} onHide={closeArchiveModal} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <p style={{ textAlign: "center" }}>Are you sure you want to archive this analysis?</p>

                    <div style={{ textAlign: "center" }}>
                        <button className="cancelbutton" onClick={closeArchiveModal}>Cancel</button>

                        <button className="modal-delete glintanimation" onClick={archiveConfirmation}>
                            Archive
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )

}

export default CompetitiveLandscapeLandingPage;