export const competitiveMoats = [
    { key: "Advertising" },
    { key: "Brand" },
    { key: "Business model innovation" },
    { key: "Complementary assets" },
    { key: "Cost of capital" },
    { key: "Customer insights" },
    { key: "Disruption" },
    { key: "Economies of scale" },
    { key: "Economies of scope" },
    { key: "Experience curve" },
    { key: "Licensing" },
    { key: "Patents" },
    { key: "Platforms" },
    { key: "Procedural knowledge" },
    { key: "Regulation" },
    { key: "Standards" },
    { key: "Tacit knowledge" },
    { key: "Trade secrets" },
    { key: "Trademarks" },
    { key: "Two-sided marketplaces" },
    { key: "User networks" },
    { key: "Value chain innovation" },
    { key: "Willingness to experiment" }
];