import React from 'react'
import { Form } from 'react-bootstrap'
import AccordianCard from '../../../../../../accordion-card/accordian-card'
import {
  editItemInFieldArray,
  pushItemToFieldArray,
  removeItemFromFieldArray
} from '../../../helpers'
import '../../../pitch-deck-analysis.css'
import FormCellMultiInput from '../../form/form-cell-multi-input'
import ResultsSection from '../results-section'

const CreateEditSolution = props => {
  props = {
    ...props,
    pushItemToFieldArray,
    removeItemFromFieldArray,
    editItemInFieldArray
  }

  return (
    <div className='ideacheckertabbody'>
      <div className='ideacheckerquestions'>
        <AccordianCard
          className='companyaccordion'
          title='Solution'
          activeKey='solution'
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName='customerGain'
                displayName='What product-or-service are you offering (customer
                              gain)?'
              />
            </Form.Group>
          </Form>
        </AccordianCard>

        <AccordianCard
          title='Product-or-service'
          activeKey='productOrService'
          style={{ marginTop: '10px' }}
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName='howProductMeetNeeds'
                displayName='How does your product-or-service uniquely meet the needs of your target market?'
              />
              <FormCellMultiInput
                props={props}
                actualName='willProductSolveNeeds'
                displayName='Will the product-or-service solve a fundamental need in the market (whether or not that need is
                            known currently to customers) such that customers will pay real money to purchase it?'
              />
              <FormCellMultiInput
                props={props}
                actualName='howDidYouGetIdea'
                displayName='How did you get to the current solution idea, incorporating which insights and market data to
                            help inform your opinion?'
              />
              <FormCellMultiInput
                props={props}
                actualName='isProductAspirin'
                displayName='Is your product-or-service more of an aspirin?'
              />
              <FormCellMultiInput
                props={props}
                actualName='isProductVitamin'
                displayName='Is your product-or-service more of a vitamin?'
              />
              <FormCellMultiInput
                props={props}
                actualName='factorsImportantInChoosing'
                displayName='What factors are important to your customer segments in choosing this type of
                            product-or-service?'
                datatip='Explain why your customer segments are choosing your type of product-or-service? Is it due to price or quality or value or other benefits?'
              />
              <FormCellMultiInput
                props={props}
                actualName='howYouRespondToNeed'
                displayName='How are you responding to a customer need in a new
                            and useful way?'
              />
              <FormCellMultiInput
                props={props}
                actualName='doCustomersLoveProduct'
                displayName='Do users and customers love your product-or-service?'
              />
            </Form.Group>
          </Form>
        </AccordianCard>
      </div>

      <ResultsSection props={props} type='solution' />
    </div>
  )
}

export default CreateEditSolution