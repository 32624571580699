import React, { useState, useEffect } from 'react';
import { Button, Alert, Card, Spinner } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { useRealmApp } from '../../RealmApp';
import Carousel from 'react-bootstrap/Carousel';

const DataRoomView = (props) => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    const { currentUser } = useRealmApp();

    const { getMyFiles } = currentUser.functions;

    const [files, setFiles] = useState([]);

    useEffect(async () => {

        try {
            const response = await getMyFiles();

            const data = JSON.parse(response);

            setFiles(data);

            setAlert(false);

        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

    }, []);

    return (
        <>

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            {files?.length === 0 || !files || files?.length < 1
                ? ""
                : <Card style={{ marginTop: "40px" }}>
                    <Card.Body id="companyinformation" style={{ borderRadius: "0.25rem" }}>
                        <div>
                            <Carousel>
                                {files?.map((file, index) =>
                                    <Carousel.Item key={index} style={{ textAlign: "center" }}>
                                        {
                                            !file.fileString
                                                ? ""
                                                : file.fileString.includes("image")
                                                    ? <img src={file.fileString} className="data-room-image" />
                                                    : file.fileString.includes("audio")
                                                        ? (<audio controls className="data-room-object">
                                                            <source src="horse.ogg" type="audio/ogg" />
                                                            <source src="horse.mp3" type="audio/mpeg" />
                                                            Your browser does not support the audio tag.
                                                        </audio>)
                                                        : file.fileString.includes("video")
                                                            ? (<video controls className="data-room-object">
                                                                <source src={file.fileString} type="video/mp4" />
                                                                <source src={file.fileString} type="video/ogg" />
                                                                Your browser does not support the video tag.
                                                            </video>)
                                                            : <object data={file.fileString} className="data-room-object" />
                                        }
                                    </Carousel.Item>)}
                            </Carousel>
                        </div>
                    </Card.Body>
                </Card>
            }
        </>
    )
}

export default DataRoomView;