import React from 'react'
import './verify-form.css'
import { Card, Button } from 'react-bootstrap'
import HeaderLogo from '../../../assets/navbar/roiquant-600x200px-white-01.png';


function VerifyForm(props) {
  

  

  return (
    <div id="verifycontent">

      <div className="verifytop">
        <a href="https://www.roiquant.com/" target="_blank" rel="noopener noreferrer">
          <img src={HeaderLogo} alt="roiquant" width="180px" height="60px" className="roiquantlogo" />
        </a>
      </div>

      <div>{props.response}</div>

      <div id="verifyform">
        <Card className="verifycard">
          <div className="verifyheader">
            <h3>Activate your roiquant account</h3>
          </div>

          <div className="extranote">
            <span>
              By clicking below, you agree to roiquant's <a href="https://viewpoints.roiquant.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                privacy policy</a>, <a href="https://viewpoints.roiquant.com/how-do-we-use-cookies/" target="_blank" rel="noopener noreferrer">
                cookies</a>, <a href="https://viewpoints.roiquant.com/user-notice/" target="_blank" rel="noopener noreferrer">
                user notice</a> and <a href="https://viewpoints.roiquant.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
                terms of service</a>.
            </span>
          </div>

          <div style={{ textAlign: "center" }}>
            <Button className="verifybtn glintanimation" onClick={() => { props.handleConfirm(props.token, props.tokenId); }}>
              <span>Agree & confirm</span>
            </Button>
          </div>

        </Card>
      </div>

    </div>
  )
}

export default VerifyForm