import React from 'react'
import { Accordion, Card, Form } from 'react-bootstrap'
import { FaChevronDown, FaChevronLeft } from 'react-icons/fa'
import '../referral-program2.css'

const ReferralFaq = () => {

    return (
        <>
            <p className="referralcardheader">Frequently asked questions</p>

            <Card className="referralcard">

                <Form.Control as="select" className="referralselect" disabled style={{ color: "transparent", backgroundColor: "transparent", margin: "10px 15px 10px 15px" }}></Form.Control>

                <hr style={{ marginTop: "0" }} />

                <Accordion className="referralfaqaccordion">

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1" className="referralfaqheader">
                            <div className="referralfaqtext">What is the roiquant referral program?</div>

                            <div className="referralfaqarrow">
                                <FaChevronDown />
                            </div>
                        </Accordion.Toggle>

                        {/* <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <div>
                                    <ul>
                                        <li>Who can I invite to earn rewards?</li>
                                        <li>What kind of rewards will I earn?</li>
                                        <li>How can I tell if I have earned my rewards?</li>
                                    </ul>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="2" className="referralfaqheader">
                            <div className="referralfaqtext">How does the referral program work?</div>

                            <div className="referralfaqarrow">
                                <FaChevronDown />
                            </div>
                        </Accordion.Toggle>

                        {/* <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <div>
                                    <ul>
                                        <li>What conditions must I meet to earn the rewards?</li>
                                        <li>How will I receive my rewards?</li>
                                        <li>Who will be rewarded (2-sided incentives)?</li>
                                    </ul>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="3" className="referralfaqheader">
                            <div className="referralfaqtext">How many invites do I send in order to earn my rewards?</div>

                            <div className="referralfaqarrow">
                                <FaChevronDown />
                            </div>
                        </Accordion.Toggle>

                        {/* <Accordion.Collapse eventKey="3">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="4" className="referralfaqheader">
                            <div className="referralfaqtext">How to claim my rewards and what can I do with them?</div>

                            <div className="referralfaqarrow">
                                <FaChevronDown />
                            </div>
                        </Accordion.Toggle>

                        {/* <Accordion.Collapse eventKey="4">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="5" className="referralfaqheader">
                            <div className="referralfaqtext">Is there a limit to the number of invitations I can send or the amount of rewards I can earn?</div>

                            <div className="referralfaqarrow">
                                <FaChevronDown />
                            </div>
                        </Accordion.Toggle>

                        {/* <Accordion.Collapse eventKey="5">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="6" className="referralfaqheader">
                            <div className="referralfaqtext">What if a friend signed up and forgot to use my invitation link or promo code?</div>

                            <div className="referralfaqarrow">
                                <FaChevronDown />
                            </div>
                        </Accordion.Toggle>

                        {/* <Accordion.Collapse eventKey="6">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="7" className="referralfaqheader">
                            <div className="referralfaqtext">Where am I allowed to share my invitations?</div>

                            <div className="referralfaqarrow">
                                <FaChevronDown />
                            </div>
                        </Accordion.Toggle>

                        {/* <Accordion.Collapse eventKey="7">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="8" className="referralfaqheader">
                            <div className="referralfaqtext">Do the referral rewards expire?</div>

                            <div className="referralfaqarrow">
                                <FaChevronDown />
                            </div>
                        </Accordion.Toggle>

                        {/* <Accordion.Collapse eventKey="8">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="9" className="referralfaqheader">
                            <div className="referralfaqtext">I have many online and social media followers, do you offer affiliate partnership?</div>

                            <div className="referralfaqarrow">
                                <FaChevronDown />
                            </div>
                        </Accordion.Toggle>

                        {/* <Accordion.Collapse eventKey="9">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="10" className="referralfaqheader">
                            <div className="referralfaqtext">Who can I speak to if my question is not answered or if I have more questions?</div>

                            <div className="referralfaqarrow">
                                <FaChevronDown />
                            </div>
                        </Accordion.Toggle>

                        {/* <Accordion.Collapse eventKey="10">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse> */}
                    </Card>

                </Accordion>

                <hr />
                <br />

            </Card>
        </>
    );
}

export default ReferralFaq