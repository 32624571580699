// file to store functions used in plan builder pages

import { Spinner } from 'react-bootstrap'
import { AiOutlineCheckSquare, AiOutlineCloseCircle } from 'react-icons/ai'
import { BiAnalyse } from 'react-icons/bi'
import { BsCardList } from 'react-icons/bs'
import { Link } from 'react-router-dom'

export const pushItemToFieldArray = (props, stateName, value) => {
  // action = { type: "example action type", value: <User's input> } // Example action
  props.setState({
    type: 'push item to state array',
    value: value,
    stateName: stateName
  })
}

export const removeItemFromFieldArray = (props, stateName, index) => {
  props.setState({
    type: 'remove item from state array by index',
    index: index,
    stateName: stateName
  })
}

export const editItemInFieldArray = (props, stateName, value, index) => {
  // action = { type: "example action type", index: 3, value: <User's input> } // Example action
  props.setState({
    type: 'edit item in state array by index',
    value: value,
    index: index,
    stateName: stateName
  })
}

export const updateFieldItem = (props, stateName, value) => {
  props.setState({
    type: 'update value to state property',
    stateName: stateName,
    value: value
  })
}

export function closeAlert(props) {
  const { setAlert, setMessage } = props

  setAlert(false)
  setMessage('')
}

export function scrollToTop() {
  window.scrollTo(0, 0)
}

// submit function
export async function handleSubmit(props) {
  const { state, dispatch, setAlert, setMessage, analyzePitchDeck } = props

  setAlert(true)
  setMessage(
    <div style={{ display: 'flex', paddingTop: '10px' }}>
      <div style={{ width: '90%' }}>
        <p style={{ color: '#0f9d58' }}>
          <BiAnalyse className='alert-icon' /> Analyzing
        </p>
        <p style={{ marginLeft: '28px' }}>
          Our real-time analytics might take up to 1 min to compute and show
          results.
        </p>
        <p style={{ marginLeft: '28px' }}>
          We'll notify you once your results are ready.
        </p>
      </div>
      <div style={{ width: '10%', textAlign: 'right' }}>
        <Spinner animation='border' variant='secondary' />
      </div>
    </div>
  )

  try {
    // console.log("Submitted form:", state);
    let response = await analyzePitchDeck(state)

    const data = JSON.parse(response)

    if (data === -1) throw 'Negative.'

    // console.log("data:", data);

    if (data) dispatch({ type: 'update analysisResult', value: data })

    setAlert(true)
    setMessage(
      <div style={{ paddingTop: '10px' }}>
        <p style={{ color: '#1f5f8b' }}>
          <BsCardList className='alert-icon' /> Results
        </p>
        <p style={{ marginLeft: '28px' }}>
          Your results are ready.{' '}
          <a href='#' className='alert-cta' onClick={scrollToTop}>
            View results
          </a>
        </p>
      </div>
    )
  } catch (err) {
    // To-do: Alert user an error was returned
    // console.error(err);
  }
}

// save function
export async function handleSave(props) {
  const { state, setAlert, setMessage, savePitchDeck } = props
  if (state.pitchDeckName === '') {
    setAlert(true)
    setMessage(
      <div style={{ paddingTop: '10px' }}>
        <p style={{ color: 'red' }}>
          <AiOutlineCloseCircle className='alert-icon' /> Error
        </p>
        <p style={{ marginLeft: '28px' }}>
          New business plan must be named before saving and/or adding.
        </p>
      </div>
    )
  } else {
    setAlert(true)
    setMessage(
      <div style={{ display: 'flex', paddingTop: '10px' }}>
        <div style={{ width: '90%' }}>
          <p style={{ color: '#0f9d58' }}>
            <BiAnalyse className='alert-icon' /> Saving
          </p>
          <p style={{ marginLeft: '28px' }}>
            We'll notify you once your business plan is ready.
          </p>
        </div>
        <div style={{ width: '10%', textAlign: 'right' }}>
          <Spinner animation='border' variant='secondary' />
        </div>
      </div>
    )

    // Save
    try {
      await savePitchDeck(state)
      // console.log(state);

      setAlert(true)
      setMessage(
        <div style={{ paddingTop: '10px' }}>
          <p style={{ color: '#5aa469' }}>
            <AiOutlineCheckSquare className='alert-icon' /> Saved
          </p>
          <p style={{ marginLeft: '28px' }}>
            Your business plan is saved and updated.{' '}
            <Link
              to={`/founders/business-plan/view/${state.pitchDeckId}`}
              target='_blank'
              rel='noopener noreferrer'
              className='alert-cta'
            >
              View business plan
            </Link>
          </p>
        </div>
      )
    } catch (err) {
      // To-do: Alert user an error was returned
      // console.error("Could not save the Pitch Deck. Reasons:", err);

      setAlert(true)
      setMessage(
        <div style={{ paddingTop: '10px' }}>
          <p style={{ color: 'red' }}>
            <AiOutlineCloseCircle className='alert-icon' /> Error
          </p>
          <p style={{ marginLeft: '28px' }}>
            We failed to save your updated work. Please try again.
          </p>
        </div>
      )
    }
  }
}

// handler on enter key pressed when saving project name
export const handleEnterSave = async e => {
  if (e.code === 'Enter' || e.code === 'NumpadEnter') {
    e.preventDefault()
    handleSave()
  }
}

export function handleClose(props) {
  const { history } = props
  // Send user to the Pitch Deck Landing Page
  history.replace('/tools/founders/business-plan')
}