export const industriesNew = [
    { key: "Accounting, bookkeeping and auditing activities; tax consultancy" },
    { key: "Activities of holding companies" },
    { key: "Activities of amusement parks and theme parks" },
    { key: "Activities of business and employers membership organizations" },
    { key: "Activities of call centres" },
    { key: "Activities of collection agencies and credit bureaus" },
    { key: "Activities of employment placement agencies" },
    { key: "Activities of extraterritorial organizations and bodies" },
    { key: "Activities of head offices" },
    { key: "Activities of households as employers of domestic personnel" },
    { key: "Activities of insurance agents and brokers" },
    { key: "Activities of other membership organizations not elsewhere classified" },
    { key: "Activities of political organizations" },
    { key: "Activities of professional membership organizations" },
    { key: "Activities of religious organizations" },
    { key: "Activities of sports clubs" },
    { key: "Activities of trade unions" },
    { key: "Administration of financial markets" },
    { key: "Advertising" },
    { key: "Architectural and engineering activities and related technical consultancy" },
    { key: "Beverage serving activities" },
    { key: "Book publishing" },
    { key: "Botanical and zoological gardens and nature reserves activities" },
    { key: "Building completion and finishing" },
    { key: "Building of pleasure and sporting boats" },
    { key: "Building of ships and floating structures" },
    { key: "Camping grounds, recreational vehicle parks and trailer parks" },
    { key: "Cargo handling" },
    { key: "Casting of iron and steel" },
    { key: "Casting of non-ferrous metals" },
    { key: "Central banking" },
    { key: "Collection of hazardous waste" },
    { key: "Collection of non-hazardous waste" },
    { key: "Combined facilities support activities" },
    { key: "Combined office administrative service activities" },
    { key: "Compulsory social security activities" },
    { key: "Computer consultancy and computer facilities management activities" },
    { key: "Computer programming activities" },
    { key: "Construction of buildings" },
    { key: "Construction of other civil engineering projects" },
    { key: "Construction of roads and railways" },
    { key: "Construction of utility projects" },
    { key: "Courier activities" },
    { key: "Creative, arts and entertainment activities" },
    { key: "Cultural education" },
    { key: "Cutting, shaping and finishing of stone" },
    { key: "Data processing, hosting and related activities" },
    { key: "Defence activities" },
    { key: "Demolition" },
    { key: "Distilling, rectifying and blending of spirits" },
    { key: "Educational support activities" },
    { key: "Electric power generation, transmission and distribution" },
    { key: "Electrical installation" },
    { key: "Event catering" },
    { key: "Extraction of crude petroleum" },
    { key: "Extraction of natural gas" },
    { key: "Extraction of peat" },
    { key: "Extraction of salt" },
    { key: "Financial leasing" },
    { key: "Finishing of textiles" },
    { key: "Foreign affairs" },
    { key: "Forging, pressing, stamping and roll-forming of metal; powder metallurgy" },
    { key: "Freight air transport" },
    { key: "Freight rail transport" },
    { key: "Freight transport by road" },
    { key: "Freshwater aquaculture" },
    { key: "Freshwater fishing" },
    { key: "Fund management activities" },
    { key: "Funeral and related activities" },
    { key: "Gambling and betting activities" },
    { key: "Gathering of non-wood forest products" },
    { key: "General cleaning of buildings" },
    { key: "General public administration activities" },
    { key: "General secondary education" },
    { key: "Growing of beverage crops" },
    { key: "Growing of cereals (except rice), leguminous crops and oil seeds" },
    { key: "Growing of citrus fruits" },
    { key: "Growing of fibre crops" },
    { key: "Growing of grapes" },
    { key: "Growing of oleaginous fruits" },
    { key: "Growing of other non-perennial crops" },
    { key: "Growing of other perennial crops" },
    { key: "Growing of other tree and bush fruits and nuts" },
    { key: "Growing of pome fruits and stone fruits" },
    { key: "Growing of rice" },
    { key: "Growing of spices, aromatic, drug and pharmaceutical crops" },
    { key: "Growing of sugar cane" },
    { key: "Growing of tobacco" },
    { key: "Growing of tropical and subtropical fruits" },
    { key: "Growing of vegetables and melons, roots and tubers" },
    { key: "Hairdressing and other beauty treatment" },
    { key: "Higher education" },
    { key: "Hospital activities" },
    { key: "Hunting, trapping and related service activities" },
    { key: "Inland freight water transport" },
    { key: "Inland passenger water transport" },
    { key: "Installation of industrial machinery and equipment" },
    { key: "Investigation activities" },
    { key: "Landscape care and maintenance service activities" },
    { key: "Leasing of intellectual property and similar products, except copyrighted works" },
    { key: "Legal activities" },
    { key: "Library and archives activities" },
    { key: "Life insurance" },
    { key: "Logging" },
    { key: "Maintenance and repair of motor vehicles" },
    { key: "Management consultancy activities" },
    { key: "Manufacture of agricultural and forestry machinery" },
    { key: "Manufacture of air and spacecraft and related machinery" },
    { key: "Manufacture of articles of concrete, cement and plaster" },
    { key: "Manufacture of articles of fur" },
    { key: "Manufacture of bakery products" },
    { key: "Manufacture of basic chemicals" },
    { key: "Manufacture of basic iron and steel" },
    { key: "Manufacture of basic precious and other non-ferrous metals" },
    { key: "Manufacture of batteries and accumulators" },
    { key: "Manufacture of bearings, gears, gearing and driving elements" },
    { key: "Manufacture of bicycles and invalid carriages" },
    { key: "Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers" },
    { key: "Manufacture of builders' carpentry and joinery" },
    { key: "Manufacture of carpets and rugs" },
    { key: "Manufacture of cement, lime and plaster" },
    { key: "Manufacture of clay building materials" },
    { key: "Manufacture of cocoa, chocolate and sugar confectionery" },
    { key: "Manufacture of coke oven products" },
    { key: "Manufacture of communication equipment" },
    { key: "Manufacture of computers and peripheral equipment" },
    { key: "Manufacture of consumer electronics" },
    { key: "Manufacture of cordage, rope, twine and netting" },
    { key: "Manufacture of corrugated paper and paperboard and of containers of paper and paperboard" },
    { key: "Manufacture of cutlery, hand tools and general hardware" },
    { key: "Manufacture of dairy products" },
    { key: "Manufacture of domestic appliances" },
    { key: "Manufacture of electric lighting equipment" },
    { key: "Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus" },
    { key: "Manufacture of electronic components and boards" },
    { key: "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines" },
    { key: "Manufacture of fertilizers and nitrogen compounds" },
    { key: "Manufacture of fibre optic cables" },
    { key: "Manufacture of fluid power equipment" },
    { key: "Manufacture of footwear" },
    { key: "Manufacture of furniture" },
    { key: "Manufacture of games and toys" },
    { key: "Manufacture of gas; distribution of gaseous fuels through mains" },
    { key: "Manufacture of glass and glass products" },
    { key: "Manufacture of grain mill products" },
    { key: "Manufacture of imitation jewellery and related articles" },
    { key: "Manufacture of irradiation, electromedical and electrotherapeutic equipment" },
    { key: "Manufacture of jewellery and related articles" },
    { key: "Manufacture of knitted and crocheted apparel" },
    { key: "Manufacture of knitted and crocheted fabrics" },
    { key: "Manufacture of lifting and handling equipment" },
    { key: "Manufacture of luggage, handbags and the like, saddlery and harness" },
    { key: "Manufacture of macaroni, noodles, couscous and similar farinaceous products" },
    { key: "Manufacture of machinery for food, beverage and tobacco processing" },
    { key: "Manufacture of machinery for metallurgy" },
    { key: "Manufacture of machinery for mining, quarrying and construction" },
    { key: "Manufacture of machinery for textile, apparel and leather production" },
    { key: "Manufacture of made-up textile articles, except apparel" },
    { key: "Manufacture of magnetic and optical media" },
    { key: "Manufacture of malt liquors and malt" },
    { key: "Manufacture of man-made fibres" },
    { key: "Manufacture of measuring, testing, navigating and control equipment" },
    { key: "Manufacture of medical and dental instruments and supplies" },
    { key: "Manufacture of metal-forming machinery and machine tools" },
    { key: "Manufacture of military fighting vehicles" },
    { key: "Manufacture of motor vehicles" },
    { key: "Manufacture of motorcycles" },
    { key: "Manufacture of musical instruments" },
    { key: "Manufacture of office machinery and equipment (except computers and peripheral equipment)" },
    { key: "Manufacture of optical instruments and photographic equipment" },
    { key: "Manufacture of other articles of paper and paperboard" },
    { key: "Manufacture of other chemical products not elsewhere classified" },
    { key: "Manufacture of other electrical equipment" },
    { key: "Manufacture of other electronic and electric wires and cables" },
    { key: "Manufacture of other fabricated metal products not elsewhere classified" },
    { key: "Manufacture of other food products not elsewhere classified" },
    { key: "Manufacture of other general-purpose machinery" },
    { key: "Manufacture of other non-metallic mineral products not elsewhere classified" },
    { key: "Manufacture of other porcelain and ceramic products" },
    { key: "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials" },
    { key: "Manufacture of other pumps, compressors, taps and valves" },
    { key: "Manufacture of other rubber products" },
    { key: "Manufacture of other special-purpose machinery" },
    { key: "Manufacture of other textiles not elsewhere classified" },
    { key: "Manufacture of other transport equipment not elsewhere classified" },
    { key: "Manufacture of ovens, furnaces and furnace burners" },
    { key: "Manufacture of paints, varnishes and similar coatings, printing ink and mastics" },
    { key: "Manufacture of parts and accessories for motor vehicles" },
    { key: "Manufacture of pesticides and other agrochemical products" },
    { key: "Manufacture of pharmaceuticals, medicinal chemical and botanical products" },
    { key: "Manufacture of plastics and synthetic rubber in primary forms" },
    { key: "Manufacture of plastics products" },
    { key: "Manufacture of power-driven hand tools" },
    { key: "Manufacture of prepared animal feeds" },
    { key: "Manufacture of prepared meals and dishes" },
    { key: "Manufacture of pulp, paper and paperboard" },
    { key: "Manufacture of railway locomotives and rolling stock" },
    { key: "Manufacture of refined petroleum products" },
    { key: "Manufacture of refractory products" },
    { key: "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres" },
    { key: "Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations" },
    { key: "Manufacture of soft drinks; production of mineral waters and other bottled waters" },
    { key: "Manufacture of sports goods" },
    { key: "Manufacture of starches and starch products" },
    { key: "Manufacture of steam generators, except central heating hot water boilers" },
    { key: "Manufacture of structural metal products" },
    { key: "Manufacture of sugar" },
    { key: "Manufacture of tanks, reservoirs and containers of metal" },
    { key: "Manufacture of tobacco products" },
    { key: "Manufacture of vegetable and animal oils and fats" },
    { key: "Manufacture of veneer sheets and wood-based panels" },
    { key: "Manufacture of watches and clocks" },
    { key: "Manufacture of weapons and ammunition" },
    { key: "Manufacture of wearing apparel, except fur apparel" },
    { key: "Manufacture of wines" },
    { key: "Manufacture of wiring devices" },
    { key: "Manufacture of wooden containers" },
    { key: "Marine aquaculture" },
    { key: "Marine fishing" },
    { key: "Market research and public opinion polling" },
    { key: "Materials recovery" },
    { key: "Medical and dental practice activities" },
    { key: "Mining of chemical and fertilizer minerals" },
    { key: "Mining of hard coal" },
    { key: "Mining of iron ores" },
    { key: "Mining of lignite" },
    { key: "Mining of other non-ferrous metal ores" },
    { key: "Mining of uranium and thorium ores" },
    { key: "Mixed farming" },
    { key: "Motion picture projection activities" },
    { key: "Motion picture, video and television programme distribution activities" },
    { key: "Motion picture, video and television programme post-production activities" },
    { key: "Motion picture, video and television programme production activities" },
    { key: "Museums activities and operation of historical sites and buildings" },
    { key: "News agency activities" },
    { key: "Non-life insurance" },
    { key: "Non-specialized wholesale trade" },
    { key: "Operation of sports facilities" },
    { key: "Organization of conventions and trade shows" },
    { key: "Other accommodation" },
    { key: "Other activities auxiliary to financial service activities" },
    { key: "Other activities auxiliary to insurance and pension funding" },
    { key: "Other amusement and recreation activities not elsewhere classified" },
    { key: "Other building and industrial cleaning activities" },
    { key: "Other business support service activities not elsewhere classified" },
    { key: "Other construction installation" },
    { key: "Other credit granting" },
    { key: "Other education not elsewhere classified" },
    { key: "Other financial service activities, except insurance and pension funding activities, not elsewhere classified" },
    { key: "Other food service activities" },
    { key: "Other human health activities" },
    { key: "Other human resources provision" },
    { key: "Other information service activities not elsewhere classified" },
    { key: "Other information technology and computer service activities" },
    { key: "Other manufacturing not elsewhere classified" },
    { key: "Other mining and quarrying not elsewhere classified" },
    { key: "Other monetary intermediation" },
    { key: "Other passenger land transport" },
    { key: "Other personal service activities not elsewhere classified" },
    { key: "Other professional, scientific and technical activities not elsewhere classified" },
    { key: "Other publishing activities" },
    { key: "Other reservation service and related activities" },
    { key: "Other residential care activities" },
    { key: "Other retail sale in non-specialized stores" },
    { key: "Other retail sale not in stores, stalls or markets" },
    { key: "Other retail sale of new goods in specialized stores" },
    { key: "Other social work activities without accommodation" },
    { key: "Other specialized construction activities" },
    { key: "Other sports activities" },
    { key: "Other telecommunications activities" },
    { key: "Other transportation support activities" },
    { key: "Packaging activities" },
    { key: "Passenger air transport" },
    { key: "Passenger rail transport, interurban" },
    { key: "Pension funding" },
    { key: "Photocopying, document preparation and other specialized office support activities" },
    { key: "Photographic activities" },
    { key: "Plant propagation" },
    { key: "Plumbing, heat and air-conditioning installation" },
    { key: "Post-harvest crop activities" },
    { key: "Postal activities" },
    { key: "Pre-primary and primary education" },
    { key: "Preparation and spinning of textile fibres" },
    { key: "Printing" },
    { key: "Private security activities" },
    { key: "Processing and preserving of fish, crustaceans and molluscs" },
    { key: "Processing and preserving of fruit and vegetables" },
    { key: "Processing and preserving of meat" },
    { key: "Public order and safety activities" },
    { key: "Publishing of directories and mailing lists" },
    { key: "Publishing of newspapers, journals and periodicals" },
    { key: "Quarrying of stone, sand and clay" },
    { key: "Radio broadcasting" },
    { key: "Raising of camels and camelids" },
    { key: "Raising of cattle and buffaloes" },
    { key: "Raising of horses and other equines" },
    { key: "Raising of other animals" },
    { key: "Raising of poultry" },
    { key: "Raising of sheep and goats" },
    { key: "Raising of swine/pigs" },
    { key: "Real estate activities on a fee or contract basis" },
    { key: "Real estate activities with own or leased property" },
    { key: "Regulation of and contribution to more efficient operation of businesses" },
    { key: "Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security" },
    { key: "Reinsurance" },
    { key: "Remediation activities and other waste management services" },
    { key: "Renting and leasing of motor vehicles" },
    { key: "Renting and leasing of other machinery, equipment and tangible goods" },
    { key: "Renting and leasing of other personal and household goods" },
    { key: "Renting and leasing of recreational and sports goods" },
    { key: "Renting of video tapes and disks" },
    { key: "Repair of communication equipment" },
    { key: "Repair of computers and peripheral equipment" },
    { key: "Repair of consumer electronics" },
    { key: "Repair of electrical equipment" },
    { key: "Repair of electronic and optical equipment" },
    { key: "Repair of fabricated metal products" },
    { key: "Repair of footwear and leather goods" },
    { key: "Repair of furniture and home furnishings" },
    { key: "Repair of household appliances and home and garden equipment" },
    { key: "Repair of machinery" },
    { key: "Repair of other equipment" },
    { key: "Repair of other personal and household goods" },
    { key: "Repair of transport equipment, except motor vehicles" },
    { key: "Reproduction of recorded media" },
    { key: "Research and experimental development on natural sciences and engineering" },
    { key: "Research and experimental development on social sciences and humanities" },
    { key: "Residential care activities for mental retardation, mental health and substance abuse" },
    { key: "Residential care activities for the elderly and disabled" },
    { key: "Residential nursing care facilities" },
    { key: "Restaurants and mobile food service activities" },
    { key: "Retail sale in non-specialized stores with food, beverages or tobacco predominating" },
    { key: "Retail sale of audio and video equipment in specialized stores" },
    { key: "Retail sale of automotive fuel in specialized stores" },
    { key: "Retail sale of beverages in specialized stores" },
    { key: "Retail sale of books, newspapers and stationary in specialized stores" },
    { key: "Retail sale of carpets, rugs, wall and floor coverings in specialized stores" },
    { key: "Retail sale of clothing, footwear and leather articles in specialized stores" },
    { key: "Retail sale of computers, peripheral units, software and telecommunications equipment in specialized stores" },
    { key: "Retail sale of electrical household appliances, furniture, lighting equipment and other household articles in specialized stores" },
    { key: "Retail sale of food in specialized stores" },
    { key: "Retail sale of games and toys in specialized stores" },
    { key: "Retail sale of hardware, paints and glass in specialized stores" },
    { key: "Retail sale of music and video recordings in specialized stores" },
    { key: "Retail sale of pharmaceutical and medical goods, cosmetic and toilet articles in specialized stores" },
    { key: "Retail sale of second-hand goods" },
    { key: "Retail sale of sporting equipment in specialized stores" },
    { key: "Retail sale of textiles in specialized stores" },
    { key: "Retail sale of tobacco products in specialized stores" },
    { key: "Retail sale via mail order houses or via Internet" },
    { key: "Retail sale via stalls and markets of food, beverages and tobacco products" },
    { key: "Retail sale via stalls and markets of other goods" },
    { key: "Retail sale via stalls and markets of textiles, clothing and footwear" },
    { key: "Risk and damage evaluation" },
    { key: "Sale of motor vehicle parts and accessories" },
    { key: "Sale of motor vehicles" },
    { key: "Sale, maintenance and repair of motorcycles and related parts and accessories" },
    { key: "Satellite telecommunications activities" },
    { key: "Sawmilling and planing of wood" },
    { key: "Sea and coastal freight water transport" },
    { key: "Sea and coastal passenger water transport" },
    { key: "Security and commodity contracts brokerage" },
    { key: "Security systems service activities" },
    { key: "Seed processing for propagation" },
    { key: "Service activities incidental to air transportation" },
    { key: "Service activities incidental to land transportation" },
    { key: "Service activities incidental to water transportation" },
    { key: "Service activities related to printing" },
    { key: "Sewerage" },
    { key: "Short term accommodation activities" },
    { key: "Silviculture and other forestry activities" },
    { key: "Site preparation" },
    { key: "Social work activities without accommodation for the elderly and disabled" },
    { key: "Software publishing" },
    { key: "Sound recording and music publishing activities" },
    { key: "Specialized design activities" },
    { key: "Sports and recreation education" },
    { key: "Steam and air conditioning supply" },
    { key: "Support activities for animal production" },
    { key: "Support activities for crop production" },
    { key: "Support activities for other mining and quarrying" },
    { key: "Support activities for petroleum and natural gas extraction" },
    { key: "Support services to forestry" },
    { key: "Tanning and dressing of leather; dressing and dyeing of fur" },
    { key: "Technical and vocational secondary education" },
    { key: "Technical testing and analysis" },
    { key: "Television programming and broadcasting activities" },
    { key: "Temporary employment agency activities" },
    { key: "Tour operator activities" },
    { key: "Transport via pipeline" },
    { key: "Travel agency activities" },
    { key: "Treatment and coating of metals; machining" },
    { key: "Treatment and disposal of hazardous waste" },
    { key: "Treatment and disposal of non-hazardous waste" },
    { key: "Trusts, funds and similar financial entities" },
    { key: "Undifferentiated goods-producing activities of private households for own use" },
    { key: "Undifferentiated service-producing activities of private households for own use" },
    { key: "Urban and suburban passenger land transport" },
    { key: "Veterinary activities" },
    { key: "Warehousing and storage" },
    { key: "Washing and (dry-) cleaning of textile and fur products" },
    { key: "Water collection, treatment and supply" },
    { key: "Weaving of textiles" },
    { key: "Web portals" },
    { key: "Wholesale of agricultural machinery, equipment and supplies" },
    { key: "Wholesale of agricultural raw materials and live animals" },
    { key: "Wholesale of computers, computer peripheral equipment and software" },
    { key: "Wholesale of construction materials, hardware, plumbing and heating equipment and supplies" },
    { key: "Wholesale of electronic and telecommunications equipment and parts" },
    { key: "Wholesale of food, beverages and tobacco" },
    { key: "Wholesale of metals and metal ores" },
    { key: "Wholesale of other household goods" },
    { key: "Wholesale of other machinery and equipment" },
    { key: "Wholesale of solid, liquid and gaseous fuels and related products" },
    { key: "Wholesale of textiles, clothing and footwear" },
    { key: "Wholesale of waste and scrap and other products not elsewhere classified" },
    { key: "Wholesale on a fee or contract basis" },
    { key: "Wired telecommunications activities" },
    { key: "Wireless telecommunications activities" }
];

export const industriesOld = [
    { "key": "Automotive" },
    { "key": "Aviation & Aerospace" },
    { "key": "Blockchain" },
    { "key": "Computer & Network Security" },
    { "key": "Computer & Network Security, Internet" },
    { "key": "Computer Games" },
    { "key": "Computer Hardware" },
    { "key": "Computer Networking" },
    { "key": "Computer Software" },
    { "key": "Consumer Electronics" },
    { "key": "Consumer Services" },
    { "key": "Cyber Security Awareness" },
    { "key": "Design" },
    { "key": "E-Learning" },
    { "key": "Education Management" },
    { "key": "Education Services" },
    { "key": "Entertainment" },
    { "key": "Financial Services" },
    { "key": "Food & Beverages" },
    { "key": "Food & Staples Retailing" },
    { "key": "Health, Wellness & Fitness" },
    { "key": "Hospital & Health Care" },
    { "key": "Hospitality" },
    { "key": "Human Resources" },
    { "key": "Industrial Automation" },
    { "key": "Information Services" },
    { "key": "Information Technology and Services" },
    { "key": "Interactive Media & Services" },
    { "key": "Internet" },
    { "key": "Legal Services" },
    { "key": "Leisure, Travel & Tourism" },
    { "key": "Market Research" },
    { "key": "Marketing and Advertising" },
    { "key": "Medical Device" },
    { "key": "Online Publishing" },
    { "key": "Package/Freight Delivery" },
    { "key": "Professional Services" },
    { "key": "Renewables & Environment" },
    { "key": "Research" },
    { "key": "Retail" },
    { "key": "Security and Investigations" },
    { "key": "Technology" },
    { "key": "Transportation" }
];

export const survivalIndustries = [
    { "key": "Accommodation and Food Services" },
    { "key": "Administrative and Waste Services" },
    { "key": "Agriculture, Forestry, Fishing and Hunting" },
    { "key": "Arts, Entertainment, and Recreation" },
    { "key": "Construction" },
    { "key": "Educational Services" },
    { "key": "Finance and Insurance" },
    { "key": "Health Care and Social Assistance" },
    { "key": "Information" },
    { "key": "Management of Companies and Enterprises" },
    { "key": "Manufacturing" },
    { "key": "Mining, Quarrying, and Oil and Gas Extraction" },
    { "key": "Other Services (except Public Administration)" },
    { "key": "Professional, Scientific, and Technical Services" },
    { "key": "Real Estate and Rental and Leasing" },
    { "key": "Retail Trade" },
    { "key": "Transportation and Warehousing" },
    { "key": "Utilities" },
    { "key": "Wholesale Trade" },
];