import ConfirmPasswordResetForm from './confirm-password-reset-form'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useRealmApp } from '../../RealmApp'
import isStrongPassword from 'validator/lib/isStrongPassword';
import isLength from 'validator/lib/isLength';
import { Button, Alert, Spinner } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';

function ConfirmPasswordResetPage() {

    const search = useLocation().search;
    const queryParamToken = new URLSearchParams(search).get('token');
    const queryParamTokenId = new URLSearchParams(search).get('tokenId');
    const queryParamUser = new URLSearchParams(search).get('user');

    const { completePwdReset } = useRealmApp()

    const [pwds, setPwds] = useState({
        newPwd: '',
        confirmPwd: ''
    })

    // Keep track of input validation/errors
    const [response, setResponse] = useState("");

    async function handleCompletePwdChange(passwords, token, tokenId, user) {

        setResponse(
            <Alert className="error-message floating-alert" variant="light">
                <div>
                    <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                        onClick={(event) => {
                            event.preventDefault()
                            setResponse("")
                        }}>
                        <IoMdClose /></Button></div>
                    <div style={{ display: "flex", paddingTop: "10px" }}>
                        <div style={{ width: "90%" }}>
                            <p><span style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</span></p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Attempting to reset your password</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                        </div>
                        <div style={{ width: "10%", textAlign: "right" }}>
                            <Spinner animation="border" variant="secondary" />
                        </div>
                    </div>
                </div>
            </Alert>
        );

        const passwordsMatch = (passwords.newPwd.localeCompare(passwords.confirmPwd) === 0)
        if (passwordsMatch) {
            try {
                await completePwdReset(token, tokenId, passwords.newPwd);

                setResponse(
                    <Alert className="error-message floating-alert" variant="light">
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                            event.preventDefault()
                            setResponse("")
                        }}><IoMdClose /></Button></div>
                        <div style={{ paddingTop: "10px" }}>
                            <p style={{ color: "#0f9d58" }}><AiOutlineCheckCircle className="alert-icon" /> Successful</p>
                            <p style={{ marginLeft: "28px" }}>Your password reset was successful! <a href="/login">Click here to log in</a></p>
                        </div>
                    </Alert>
                );
            } catch (error) {
                if (error.error) {

                    setResponse(
                        <Alert className="error-message floating-alert" variant="light">
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                                event.preventDefault()
                                setResponse("")
                            }}><IoMdClose /></Button></div>
                            <div style={{ paddingTop: "10px" }}>
                                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                <p style={{ marginLeft: "28px" }}>{error.error}</p>
                            </div>
                        </Alert>
                    );
                }
                else {
                    setResponse(
                        <Alert className="error-message floating-alert" variant="light">
                            <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                                event.preventDefault()
                                setResponse("")
                            }}><IoMdClose /></Button></div>
                            <div style={{ paddingTop: "10px" }}>
                                <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                                <p style={{ marginLeft: "28px" }}>{error}</p>
                            </div>
                        </Alert>
                    );
                }
            }
            // Send email
            try {
                const result = await fetch('https://data.mongodb-api.com/app/flipidea-3-pputk/endpoint/notify_user_of_successful_password_reset',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ user: user }) // body data type must match "Content-Type" header
                    }
                )
            }
            catch (error) {
                // To-do: Handle error
            }
        }
        else {
            setResponse(
                <Alert className="error-message floating-alert" variant="light">
                    <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                        event.preventDefault()
                        setResponse("")
                    }}><IoMdClose /></Button></div>
                    <div style={{ paddingTop: "10px" }}>
                        <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                        <p style={{ marginLeft: "28px" }}>Passwords do not match</p>
                    </div>
                </Alert>
            );

        }
    }

    function checkPwdMatch(password, confirmPassword) {
        //Returns a boolean value / Check if passwords match
        return (password.localeCompare(confirmPassword) === 0)
    }

    function checkPwdLength(password) {
        //Check if password is atleast 8 characters
        return isLength(password, { min: 8 })
    }

    function measurePwdLength(password) {
        //Measure the password length
        return password.length
    }

    function checkPwdSymbol(password) {
        //Check if password contains at least 1 symbol
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 0, minSymbols: 1, minLowercase: 0 })
    }

    function checkPwdUppercase(password) {
        //Check if password contains at least 1 uppercase
        return isStrongPassword(password, { minUppercase: 1, minLength: 0, minNumbers: 0, minSymbols: 0, minLowercase: 0 })
    }

    function checkPwdLowercase(password) {
        //Check if password contains at least 1 lowercase
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 0, minSymbols: 0, minLowercase: 1 })
    }

    function checkPwdNum(password) {
        //Check if password contains at least 1 number
        return isStrongPassword(password, { minUppercase: 0, minLength: 0, minNumbers: 1, minSymbols: 0, minLowercase: 0 })
    }

    return (
        <ConfirmPasswordResetForm handleCompletePwdChange={handleCompletePwdChange} pwds={pwds} setPwds={setPwds} token={queryParamToken} tokenId={queryParamTokenId} user={queryParamUser} response={response}
            checkPwdMatch={checkPwdMatch} checkPwdLength={checkPwdLength} checkPwdSymbol={checkPwdSymbol} checkPwdUppercase={checkPwdUppercase} checkPwdLowercase={checkPwdLowercase} checkPwdNum={checkPwdNum}
            measurePwdLength={measurePwdLength}
        />
    )
}

export default ConfirmPasswordResetPage;