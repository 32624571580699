import { Multiselect } from 'multiselect-react-dropdown'
import { useRef } from 'react'
import { Form } from 'react-bootstrap'
import { IoMdInformationCircleOutline } from 'react-icons/io'

const FormCellMultiselect = ({
  props,
  required = false,
  actualName,
  displayName,
  options,
  placeholder,
  datatip,
  bottomText,
  selectionLimit
}) => {
  const multiselectRef = useRef(null)

  return (
    <div className='ideacheckeritem'>
      <Form.Label>
        {required && <sup>*</sup>}
        {displayName}
        {datatip && (
          <sup data-tip={datatip}>
            <IoMdInformationCircleOutline id='information-icon' />
          </sup>
        )}
      </Form.Label>
      <Multiselect
        ref={multiselectRef}
        options={options}
        selectedValues={
          selectionLimit === 1
            ? props.state[actualName]
              ? [{ key: props.state[actualName] }]
              : []
            : props.state[actualName].map(x => ({
              key: x
            }))
        }
        displayValue='key'
        avoidHighlightFirstOption='true'
        selectionLimit={selectionLimit}
        showCheckbox='true'
        placeholder={placeholder}
        hidePlaceholder='true'
        emptyRecordMsg=''
        style={{
          searchBox: {
            border: '1px solid #ced4da',
            height: '35px',
            fontFamily: 'AileronRegular',
            fontSize: '14px',
            color: '#495057'
          },
          option: {
            fontFamily: 'AileronRegular',
            fontSize: '14px',
            color: '#495057'
          }
        }}
        onSelect={() => {
          const selectedItems = multiselectRef.current.getSelectedItems()
          let list
          if (selectionLimit === 1) {
            list = selectedItems[0].key
            if (!list) list = ''
          } else {
            list = []
            selectedItems.forEach(item => {
              for (const key in item) {
                if (key === 'key') {
                  list.push(item[key])
                }
              }
            })
          }

          props.setState({
            type: 'update value to state property',
            stateName: actualName,
            value: list
          })
        }}
      />
      <Form.Text className='text-muted'>{bottomText}</Form.Text>
    </div>
  )
}

export default FormCellMultiselect