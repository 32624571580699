const BulletPitchDeckDetail = ({ displayName, fieldName, data, className }) => {
  return (
    <div className={className}>
      <p className='companydetailtitle'>{displayName}</p>

      {data?.[fieldName] ? (
        <p className='companydetaildata'>
          {data[fieldName].map((point, index) =>
            !point ? (
              <>-</>
            ) : (
              <ul style={{ paddingLeft: '20px' }}>
                <li className='pitchdeckanswers' key={index}>
                  {point}
                </li>
              </ul>
            )
          )}
        </p>
      ) : (
        <p className='companydetaildata'>-</p>
      )}
    </div>
  )
}

export default BulletPitchDeckDetail