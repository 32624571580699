import React from 'react'
import SampleImage from '../../../assets/logo/roiquant - logo not found.png';
import { Card } from 'react-bootstrap'
import { S3Media } from '../../third-party-services'
import './company-detail.css'

function DirectCompetitors(props) {

    return (
        <Card.Body id="directcompetitors">
            <div className="companydetailtablediv">

                <div className="companydetailnote">
                    <p className="companydetailbodytitle"><i>Note: Direct competitors are defined by similarity score of more than 40%, while similar companies are defined by similarity score of less than 40%</i></p>
                </div>

                <table className="companydetailtable">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th style={{ color: "transparent" }}>Image</th>
                            <th>Name</th>
                            <th>Similarity score</th>
                            <th>Description</th>
                            <th>Industry</th>
                            <th>Categories</th>
                            <th>Location</th>
                            <th>Ownership status</th>
                            <th>Entity type</th>
                            <th>Stage of development</th>
                            <th>Transactional model type</th>
                            <th>Defensibility moats</th>
                            <th>Last financing round</th>
                            <th>Total raised</th>
                            <th>Post-money valuation</th>
                            <th>Last financing date</th>
                            <th>No. of funding round(s)</th>
                            <th>No. of investors</th>
                            <th>Investors</th>
                            <th>Total revenue</th>
                            <th>Market capitalization</th>
                            <th>No. of investments</th>
                            <th>No. of acquisitions</th>
                            <th>No. of board seats taken</th>
                            <th>Competition</th>
                            <th>Management</th>
                            <th>No. of employees</th>
                            <th>Company reputation</th>
                            <th>Contact info</th>
                            <th>Operational status</th>
                            <th>Operational duration</th>
                            <th>Shutdown date</th>
                            <th>Founded date</th>
                            <th>Legal name</th>
                            <th>Former legal name(s)</th>
                            <th>Ticker</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!props?.directCompetitors && !props?.similarEntities
                            ? (<tr>
                                <td className="companydetailemptydata"><p className="companydetailxsdata">-</p></td>
                                <td>
                                    <img src={SampleImage} className="companydetaillogo" alt="" />
                                </td>
                                <td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailmldata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                                <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>
                            </tr>)
                            : ""
                        }

                        {!props?.directCompetitors
                            ? ""
                            : props.directCompetitors.map((competitors, index) =>
                                <tr key={index}>

                                    <td><p className="companydetailxsdata">{index + 1}.</p></td>

                                    <td>
                                        {!competitors.logo
                                            ? (<img src={SampleImage} className="companydetaillogo" alt="" />)
                                            : (<img src={S3Media + 'assets/photos/logo/' + competitors.logo} alt={competitors.name} className="companydetaillogo" />)
                                        }
                                    </td>

                                    {!competitors.name
                                        ? (<td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>)
                                        : (<td><p className="companydetailmediumdata">{competitors.name}</p></td>)
                                    }

                                    {!competitors.score
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{(competitors.score * 100).toFixed(1) + "%"}</p></td>)
                                    }

                                    {!competitors.desc
                                        ? (<td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>)
                                        : (<td><p className="companydetaillongdata">{competitors.desc}</p></td>)
                                    }

                                    {!competitors.industry
                                        ? (<td className="companydetailemptydata"><p className="companydetailmldata">-</p></td>)
                                        : (<td><p className="companydetailmldata">{competitors.industry}</p></td>)
                                    }

                                    {!competitors.categories
                                        ? (<td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>)
                                        : (<td><p className="companydetaillongdata">{competitors.categories}</p></td>)
                                    }

                                    {!competitors.location
                                        ? (<td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>)
                                        : (<td><p className="companydetaillongdata">{competitors.location}</p></td>)
                                    }

                                    {/* ownership status */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* entity type */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* stage of development */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* transactional model type */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* defensibility moat */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!competitors.funding
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{competitors.funding}</p></td>)
                                    }

                                    {!competitors.totalInvestment
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{competitors.totalInvestment}</p></td>)
                                    }

                                    {/* post-money valuation */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* last financing round */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* num of funding rounds */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* num of investors */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* investors */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* total revenue */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* market capitalization */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* no. of investments */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* num of acquisitions */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* no. of board seats taken */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* competitors */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* founders */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!competitors.employeenum
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{competitors.employeenum}</p></td>)
                                    }

                                    {/* company reputation */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* phone number */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!competitors.isactive
                                        // false
                                        ? competitors.isactive == false
                                            ? (<td><p className="companydetailshortdata">Inactive</p></td>)
                                            : (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        // true
                                        : competitors.isactive == true
                                            ? (<td><p className="companydetailshortdata">Active</p></td>)
                                            : (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                    }

                                    {/* lifespan */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* shutdown date */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!competitors.started
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{competitors.started}</p></td>)
                                    }

                                    {/* legal name */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* former legal name */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* ticker symbol */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                </tr>
                            )
                        }

                        {!props?.similarEntities
                            ? ""
                            : props.similarEntities.map((similarities, index) =>
                                <tr key={index}>

                                    <td><p className="companydetailxsdata">{index + 1}.</p></td>

                                    <td>
                                        {!similarities.logo
                                            ? (<img src={SampleImage} className="companydetaillogo" alt="" />)
                                            : (<img src={S3Media + 'assets/photos/logo/' + similarities.logo} alt={similarities.name} className="companydetaillogo" />)
                                        }
                                    </td>

                                    {!similarities.name
                                        ? (<td className="companydetailemptydata"><p className="companydetailmediumdata">-</p></td>)
                                        : (<td><p className="companydetailmediumdata">{similarities.name}</p></td>)
                                    }

                                    {!similarities.score
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{(similarities.score * 100).toFixed(1) + "%"}</p></td>)
                                    }

                                    {!similarities.desc
                                        ? (<td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>)
                                        : (<td><p className="companydetaillongdata">{similarities.desc}</p></td>)
                                    }

                                    {!similarities.industry
                                        ? (<td className="companydetailemptydata"><p className="companydetailmldata">-</p></td>)
                                        : (<td><p className="companydetailmldata">{similarities.industry}</p></td>)
                                    }

                                    {!similarities.categories
                                        ? (<td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>)
                                        : (<td><p className="companydetaillongdata">{similarities.categories}</p></td>)
                                    }

                                    {!similarities.location
                                        ? (<td className="companydetailemptydata"><p className="companydetaillongdata">-</p></td>)
                                        : (<td><p className="companydetaillongdata">{similarities.location}</p></td>)
                                    }

                                    {/* ownership status */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* entity type */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* stage of development */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* transactional model type */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* defensibility moat */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!similarities.funding
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{similarities.funding}</p></td>)
                                    }

                                    {!similarities.totalInvestment
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{similarities.totalInvestment}</p></td>)
                                    }

                                    {/* post-money valuation */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* last financing round */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* num of funding rounds */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* num of investors */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* investors */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* total revenue */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* market capitalization */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* no. of investments */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* num of acquisitions */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* no. of board seats taken */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* competitors */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* founders */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!similarities.employeenum
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{similarities.employeenum}</p></td>)
                                    }

                                    {/* company reputation */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* phone number */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!similarities.isactive
                                        // false
                                        ? similarities.isactive == false
                                            ? (<td><p className="companydetailshortdata">Inactive</p></td>)
                                            : (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        // true
                                        : similarities.isactive == true
                                            ? (<td><p className="companydetailshortdata">Active</p></td>)
                                            : (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                    }

                                    {/* lifespan */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* shutdown date */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {!similarities.started
                                        ? (<td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>)
                                        : (<td><p className="companydetailshortdata">{similarities.started}</p></td>)
                                    }

                                    {/* legal name */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* former legal name */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                    {/* ticker symbol */}
                                    <td className="companydetailemptydata"><p className="companydetailshortdata">-</p></td>

                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </Card.Body>
    )
}

export default DirectCompetitors;