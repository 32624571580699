import React from 'react'
import { Card, Row } from 'react-bootstrap'
import { FaExternalLinkAlt } from 'react-icons/fa'
import './company-detail.css'

function ReasonsForShutdown(props) {

    return (
        <Card.Body id="reasonsforshutdown">
            {!props?.reasonForShutdown
                ? ""
                : (<Row>
                    <ol className="companydetailproductlist">
                        {props.reasonForShutdown.map((reason, index) =>
                            <div key={index}>
                                <li className="companydetailbodydata companydetailreasonlabel">{reason.label}</li>
                                <p className="companydetailbodydata">{reason.reason}</p>

                                <ul className="companydetailproductlist2">
                                    {reason.details
                                        ? (reason.details.map((detail, index) =>
                                            <li key={index}>{detail.text}
                                                {!detail.source
                                                    ? ""
                                                    : (<a href={detail.source} target="_blank" rel="noopener noreferrer">
                                                        <sup><FaExternalLinkAlt className="companydetailsourceicon" /></sup>
                                                    </a>)
                                                }
                                            </li>
                                        ))
                                        : ""
                                    }
                                </ul>

                                <hr />
                            </div>
                        )}
                    </ol>
                </Row>)
            }
        </Card.Body>
    )
}

export default ReasonsForShutdown;