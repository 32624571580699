import React from 'react'
import FinancialTableRow from './financial-table-row'
import FinancialTableEmptyRow from './financial-table-empty-row'
import FinancialTableRowHeader from './financial-table-row-header'
import FinancialTableRowHeaderCenter from './financial-table-row-center-header'

const FinancialTableRows = ({ props, rowArray }) => {
  return rowArray.map((row, index) =>
    row.type === 'bodyHeader' ? (
      <FinancialTableRowHeader
        key={index}
        displayName={row.displayName}
        className={row.className}
      />
    ) : row.type === 'rowBreak' ? (
      <FinancialTableEmptyRow key={index} />
    ) : row.type === 'bodyHeaderCenter' ? (
      <FinancialTableRowHeaderCenter
        key={index}
        displayName={row.displayName}
      />
    ) : (
      <FinancialTableRow
        key={index}
        props={props}
        fieldName={row.fieldName}
        displayName={row.displayName}
        className={row.className}
      />
    )
  )
}

export default FinancialTableRows