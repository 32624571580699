import VerifyForm from './verify-form'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useRealmApp } from '../../RealmApp'
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Button, Alert, Spinner } from 'react-bootstrap'
import { BiAnalyse } from 'react-icons/bi';


// This page is where a user confirms their account

function VerifyPage() {

    const { confirm } = useRealmApp()

    const search = useLocation().search;

    const history = useHistory();

    const queryParamToken = new URLSearchParams(search).get('token');

    const queryParamTokenId = new URLSearchParams(search).get('tokenId');

    // Keep track of input validation/errors
    const [response, setResponse] = useState('')

    async function handleConfirm(token, tokenId) {

        setResponse(
            <Alert className="error-message floating-alert" variant="light">
                <div>
                    <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose"
                        onClick={(event) => {
                            event.preventDefault()
                            setResponse("")
                        }}>
                        <IoMdClose /></Button></div>
                    <div style={{ display: "flex", paddingTop: "10px" }}>
                        <div style={{ width: "90%" }}>
                            <p><span style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</span></p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Attempting to activate your account</p>
                            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
                        </div>
                        <div style={{ width: "10%", textAlign: "right" }}>
                            <Spinner animation="border" variant="secondary" />
                        </div>
                    </div>
                </div>
            </Alert>
        );

        try {
            await confirm(token, tokenId)
            // console.log('Successfully confirmed user')
            history.push('/login')
        } catch (error) {
            if (error.error) {
                setResponse(
                    <Alert className="error-message floating-alert" variant="light">
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                            event.preventDefault()
                            setResponse("")
                        }}><IoMdClose /></Button></div>
                        <div style={{ paddingTop: "10px" }}>
                            <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                            <p style={{ marginLeft: "28px" }}>{error.error}</p>
                        </div>
                    </Alert>
                );
            } else {
                setResponse(
                    <Alert className="error-message floating-alert" variant="light">
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={(event) => {
                            event.preventDefault()
                            setResponse("")
                        }}><IoMdClose /></Button></div>
                        <div style={{ paddingTop: "10px" }}>
                            <p style={{ color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Error</p>
                            <p style={{ marginLeft: "28px" }}>{error}</p>
                        </div>
                    </Alert>
                );
            }
        }
    }

    return (
        <VerifyForm handleConfirm={handleConfirm} token={queryParamToken} tokenId={queryParamTokenId} response={response} />
    )
}

export default VerifyPage