import React, { useState, useEffect } from 'react';
import NavBar from '../../../layout/navbar/navbar';
import { Button, Alert, Container, Spinner, Modal } from 'react-bootstrap';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { BiAnalyse } from 'react-icons/bi';
import { AiOutlineDelete, AiOutlineCheckSquare } from 'react-icons/ai';
import { MdRestore } from 'react-icons/md'
import { useRealmApp } from '../../../RealmApp';
// import { useHistory } from 'react-router-dom';

const ArchiveCompetitiveLandscape = () => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    // hook for id
    const [analysisId, setAnalysisId] = useState();

    // hook for restore modal
    const [restoreModal, setRestoreModal] = useState(false);

    // function to open restore modal
    const openRestoreModal = (e) => {
        setAnalysisId(e);
        setRestoreModal(true);
    }

    // function to close restore modal
    function closeRestoreModal() {
        setAnalysisId(null);
        setRestoreModal(false);
    }

    // hook for delete modal
    const [deleteModal, setDeleteModal] = useState(false);

    // function to open delete modal
    const openDeleteModal = (e) => {
        setAnalysisId(e);
        setDeleteModal(true);
    }

    // function to close delete modal
    function closeDeleteModal() {
        setAnalysisId(null);
        setDeleteModal(false);
    }

    const { currentUser } = useRealmApp();

    // declare realm functions
    const { getUsersDocuments, restoreCompetitiveLandscapeAnalysis, deleteCompetitiveLandscapeAnalysis } = currentUser.functions;

    // hook for documents
    const [analyses, setAnalyses] = useState([]);

    // get documents on page load
    useEffect(async () => {
        try {
            const response = await getUsersDocuments({ feature: "Competitive landscape analysis", archive: true });

            const data = JSON.parse(response);

            setAnalyses(data);

            setAlert(false);

        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }
    }, []);

    // function to restore document
    async function restoreCompetitiveLandscapeAnalysisConfirmation() {

        // hide modal component
        closeRestoreModal();

        // show alert message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Restoring</p>
                <p style={{ marginLeft: "28px" }}>We'll notify you once your analysis is restored.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await restoreCompetitiveLandscapeAnalysis(analysisId);

            const data = JSON.parse(response);

            setAnalyses(data);

            setAlert(true);
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ color: "#5aa469" }}><AiOutlineCheckSquare className="alert-icon" /> Restored</p>
                <p style={{ marginLeft: "28px" }}>Your analysis is restored.</p>
            </div>);
        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

        // close alert
        closeAlert();
    }

    // function to delete document
    async function deleteCompetitiveLandscapeAnalysisConfirmation() {

        // hide modal component
        closeDeleteModal();

        // show alert message
        setAlert(true);
        setMessage(<div style={{ display: "flex", paddingTop: "10px" }}>
            <div style={{ width: "90%" }}>
                <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>
            <div style={{ width: "10%", textAlign: "right" }}>
                <Spinner animation="border" variant="secondary" />
            </div>
        </div>);

        try {
            const response = await deleteCompetitiveLandscapeAnalysis(analysisId);

            const data = JSON.parse(response);

            setAnalyses(data);
        } catch (err) {
            // To-do: Alert user an error was returned
            // console.error(err);
        }

        // close alert
        closeAlert();
    }

    return (
        <>
            {/* navbar */}
            <NavBar />

            {/* alert message */}
            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            <div id="pitchdeck">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>
                    <div className="pitchdeckheaderdiv">
                        {/* title */}
                        <div className="pitchdecktitlediv">
                            <br />
                            <span className="pitchdecktext">
                                Archive
                                <sup data-tip="">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </span>
                        </div>

                        {/* buttons */}
                        <div className="pitchdeckstatsdiv" style={{ display: "flex", justifyContent: "end" }}>
                            <a href="/competitive-intel/competitive-landscape">
                                <Button className="pitchdeckaddbutton glintanimation">
                                    <span>Back</span>
                                </Button>
                            </a>
                        </div>
                    </div>

                    {/* table */}
                    <div>
                        <table className="pitchdeckhistorytable">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Modified</th>
                                    <th>Restore</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {analyses?.length === 0
                                    ? (<tr className="pitchdecktablerow">
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                        <td className="pitchdecktabledata">-</td>
                                    </tr>)
                                    : analyses?.map((analysis, index) =>
                                        <tr key={index} className="pitchdecktablerow">
                                            <td className="pitchdecktabledata">{analysis.input.competitiveLandscapeName ? analysis.input.competitiveLandscapeName : "Untitled analysis"}</td>
                                            <td className="pitchdecktabledata">{analysis.dateQuery ? analysis.dateQuery.substring(0, 10) : "-"}</td>
                                            <td className="pitchdecktabledata">
                                                <Button className="edit-button" onClick={() => openRestoreModal(analysis._id)}><MdRestore /></Button>
                                            </td>
                                            <td className="pitchdecktabledata">
                                                <Button className="delete-button" onClick={() => openDeleteModal(analysis._id)}><AiOutlineDelete /></Button>
                                            </td>
                                        </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                </Container>
            </div>

            {/* restore modal */}
            <Modal className="modal-div" show={restoreModal} onHide={closeRestoreModal} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <p style={{ textAlign: "center" }}>Are you sure you want to restore this analysis?</p>

                    <div style={{ textAlign: "center" }}>
                        <button className="cancelbutton" onClick={closeRestoreModal}>Cancel</button>

                        <button className="modal-compare glintanimation" onClick={restoreCompetitiveLandscapeAnalysisConfirmation}>
                            Restore
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* delete modal */}
            <Modal className="modal-div" show={deleteModal} onHide={closeDeleteModal} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ borderBottom: "none" }} />
                <Modal.Body>
                    <p style={{ textAlign: "center" }}>Are you sure you want to delete this analysis?</p>

                    <div style={{ textAlign: "center" }}>
                        <button className="cancelbutton" onClick={closeDeleteModal}>Cancel</button>

                        <button className="modal-delete glintanimation" onClick={deleteCompetitiveLandscapeAnalysisConfirmation}>
                            Delete
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ArchiveCompetitiveLandscape;