export const categories = [
    { key: "3D Printing" },
    { key: "Accommodation" },
    { key: "AdTech" },
    { key: "Aerospace" },
    { key: "Agriculture" },
    { key: "Agrochemical Products" },
    { key: "Air Transportation" },
    { key: "Airport Services" },
    { key: "Alcoholic Beverages" },
    { key: "Amusement Park" },
    { key: "Apps" },
    { key: "Aquaculture" },
    { key: "Architecture" },
    { key: "Artificial & Synthetic Fibres" },
    { key: "Auditing" },
    { key: "Automotive" },
    { key: "Aviation" },
    { key: "Banking" },
    { key: "Batteries" },
    { key: "Bed & Breakfast" },
    { key: "Beverages" },
    { key: "Bicycles & Carriages" },
    { key: "Biotechnology" },
    { key: "Booking Systems" },
    { key: "Broadcasting" },
    { key: "Building Materials" },
    { key: "Cables & Wires" },
    { key: "Cafeteria" },
    { key: "Call Center" },
    { key: "Car Rental" },
    { key: "Cargo & Shipping" },
    { key: "Carpentry" },
    { key: "Caterers" },
    { key: "Charitable Trusts" },
    { key: "Chemical Products" },
    { key: "Cinemas" },
    { key: "Civil Engineering" },
    { key: "Cleaning Services" },
    { key: "Cleaning Supplies" },
    { key: "Coal Mining" },
    { key: "Coke Oven Products" },
    { key: "Collection Agencies" },
    { key: "College" },
    { key: "Commodity Contracts Brokerage" },
    { key: "Communication Equipment" },
    { key: "Compact Disc" },
    { key: "Computers" },
    { key: "Construction" },
    { key: "Construction Materials" },
    { key: "Consumer Electronics" },
    { key: "Consumer Services" },
    { key: "Copywriting" },
    { key: "Corporate Offices" },
    { key: "Couriers & Express Delivery Services" },
    { key: "Credit Unions" },
    { key: "Cutlery" },
    { key: "Dental Practice" },
    { key: "Digital Media" },
    { key: "Distributor" },
    { key: "Dropshipping" },
    { key: "E-Marketplace" },
    { key: "Education Services" },
    { key: "Electrical Equipment" },
    { key: "Electronic Components" },
    { key: "Electronics" },
    { key: "Employment Agencies" },
    { key: "Events" },
    { key: "Facilities Support" },
    { key: "Farming" },
    { key: "Fertilizers & Nitrogen Compounds" },
    { key: "Financial Services" },
    { key: "Fishing" },
    { key: "Food Delivery" },
    { key: "Food processing" },
    { key: "Footwear" },
    { key: "Forestry" },
    { key: "Freight Delivery" },
    { key: "Fuel" },
    { key: "Funeral Services" },
    { key: "Fur" },
    { key: "Furniture" },
    { key: "Gambling & Casinos" },
    { key: "Gardening & Landscaping" },
    { key: "Gaseous Fuels" },
    { key: "Generators" },
    { key: "Glass Products" },
    { key: "Greeting Card Publishers" },
    { key: "Groceries" },
    { key: "Gyms" },
    { key: "Hair & Beauty" },
    { key: "Health Care" },
    { key: "Holding companies" },
    { key: "Home Furnishing" },
    { key: "Home Furnishings" },
    { key: "Insurance" },
    { key: "International Affairs" },
    { key: "Internet Radio" },
    { key: "Investment Advice" },
    { key: "Iron & Steel" },
    { key: "Irrigation Systems" },
    { key: "Jewelry" },
    { key: "Juice Bars" },
    { key: "Kitchen Appliances" },
    { key: "Kitchenware" },
    { key: "Knitwear" },
    { key: "Land Transportation" },
    { key: "Laundry Services" },
    { key: "Leather Goods" },
    { key: "Legal Services" },
    { key: "Libraries" },
    { key: "Lingerie & Loungewear" },
    { key: "Logistics & Supply Chain" },
    { key: "Luggages & Suitcases" },
    { key: "Machinery" },
    { key: "Magnetic & Optical Media" },
    { key: "Mailing Lists" },
    { key: "Maintenance & Repair" },
    { key: "Manufacturing" },
    { key: "Market Research" },
    { key: "Media & Entertainment" },
    { key: "Medical & Dental Instruments & Supplies" },
    { key: "Medical Equipment" },
    { key: "Mental Health" },
    { key: "Metal" },
    { key: "Metal Mining" },
    { key: "Military Vehicles" },
    { key: "Mining" },
    { key: "Movie Production" },
    { key: "Museums" },
    { key: "Music" },
    { key: "Musical Instruments" },
    { key: "National Security" },
    { key: "Network Security" },
    { key: "News" },
    { key: "Newspapers" },
    { key: "Non-ferrous Metals" },
    { key: "Non-profit Organization" },
    { key: "Nursing Homes" },
    { key: "Office Administrative Services" },
    { key: "Office Equipment" },
    { key: "Oil & Gas" },
    { key: "Packaging" },
    { key: "Paper & Paper Products" },
    { key: "Patents & Royalties" },
    { key: "Pawnshops" },
    { key: "Pension, Health & Welfare Funds" },
    { key: "Pet Services" },
    { key: "Pharmaceuticals" },
    { key: "Pharmacies & Drug Stores" },
    { key: "Photographic Equipment" },
    { key: "Photography" },
    { key: "Plastics" },
    { key: "Podcasts" },
    { key: "Police Protection" },
    { key: "Political Organizations" },
    { key: "Polygraph Machines" },
    { key: "Postal Service" },
    { key: "Printers" },
    { key: "Printing" },
    { key: "Private Households" },
    { key: "Professional Organizations" },
    { key: "Professional Services" },
    { key: "Projectors" },
    { key: "Property Management" },
    { key: "Public Administration" },
    { key: "Pulp, Paper & Paperboard" },
    { key: "RV" },
    { key: "Railroad Transportation" },
    { key: "Real Estate" },
    { key: "Recruiting" },
    { key: "Refined Petroleum Products" },
    { key: "Religious Organizations" },
    { key: "Renewable Energy" },
    { key: "Rental" },
    { key: "Research & Development" },
    { key: "Residential Care" },
    { key: "Retail" },
    { key: "Rolling Stock" },
    { key: "Rubber" },
    { key: "Sales Financing" },
    { key: "Satellite Telecommunications" },
    { key: "Search Engine" },
    { key: "Second-hand Goods" },
    { key: "Security" },
    { key: "Security Systems" },
    { key: "Ships & Boats" },
    { key: "Social Network" },
    { key: "Social Services" },
    { key: "Social Work" },
    { key: "Software Publishers" },
    { key: "Sporting Goods" },
    { key: "Sports & Recreation" },
    { key: "Stalls & Markets" },
    { key: "Steam" },
    { key: "Stock Exchange" },
    { key: "Systems Management" },
    { key: "Tax" },
    { key: "Technical Education" },
    { key: "Telco" },
    { key: "Textile" },
    { key: "Theater" },
    { key: "Tire Cord & Fabrics" },
    { key: "Tobacco" },
    { key: "Tour Operator" },
    { key: "Toys" },
    { key: "Trade Unions" },
    { key: "Travel Agency" },
    { key: "Trusts, Estates & Agency Accounts" },
    { key: "Utilities" },
    { key: "Vending Machine Operators" },
    { key: "Venture Capital & Private Equity" },
    { key: "Warehousing" },
    { key: "Waste Management" },
    { key: "Watches & Clocks" },
    { key: "Water Transportation" },
    { key: "Weapons & Ammunition" },
    { key: "Web" },
    { key: "Wholesale" },
    { key: "Wood Products" },
    { key: "Woodwork" },
    { key: "Zoos & Botanical Gardens" }
];