export const sustainableDevelopmentGoals = [
    { "key": "No poverty" },
    { "key": "Zero hunger" },
    { "key": "Good health and well-being" },
    { "key": "Quality education" },
    { "key": "Gender equality" },
    { "key": "Clean water and sanitation" },
    { "key": "Affordable and clean energy" },
    { "key": "Decent work and economic growth" },
    { "key": "Industry, innovation and infrastructure" },
    { "key": "Reducing inequality" },
    { "key": "Sustainable cities and communities" },
    { "key": "Responsible consumption and production" },
    { "key": "Climate action" },
    { "key": "Life below water" },
    { "key": "Life on land" },
    { "key": "Peace, justice, and strong institutions" },
    { "key": "Partnerships for the goal" }
];