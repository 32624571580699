import React, { useState, useReducer } from 'react';
import './pre-revenue-valuation.css';
import NavBar from '../../../../layout/navbar/navbar';
import BasicVCMethod from './methods/basic-vc-method';
import BerkusMethod from './methods/berkus-method';
import ScorecardMethod from './methods/scorecard-method';
import { Accordion, Col, Card, Container, Tabs, Tab, Row } from 'react-bootstrap';
import { FaChevronLeft, FaChevronDown, FaEllipsisH } from 'react-icons/fa';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useRealmApp } from '../../../../RealmApp';

const PreRevenueValuation = (props) => {

    // realm function starts here
    const { currentUser } = useRealmApp();

    // check if user is logged in or not
    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    // hook to display methods
    const [state, dispatch] = useReducer(reducer, {
        basicvcmethod: true,
        berkusmethod: true,
        scorecardmethod: true
    })

    // function to update hook
    function reducer(state, action) {
        let newState;

        switch (action.type) {
            case 'change basicVCMethod':
                newState = {
                    basicvcmethod: !state.basicvcmethod,
                    berkusmethod: state.berkusmethod,
                    scorecardmethod: state.scorecardmethod
                };
                break;

            case 'change berkusMethod':
                newState = {
                    basicvcmethod: state.basicvcmethod,
                    berkusmethod: !state.berkusmethod,
                    scorecardmethod: state.scorecardmethod
                };
                break;

            case 'change scorecardMethod':
                newState = {
                    basicvcmethod: state.basicvcmethod,
                    berkusmethod: state.berkusmethod,
                    scorecardmethod: !state.scorecardmethod
                };
                break;

            default:
                throw new Error();
        }

        return newState;
    }

    return (
        <>
            {/* navbar */}
            <NavBar />

            {/* pre-revenue business simulation */}
            <div id="prerevenue">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>

                    {/* title and info note */}
                    <div id="title-div">
                        <span id="title-text">
                            Pre-revenue business valuation
                            <sup data-tip="Estimate the valuation of your non-revenue company with industry standards and proven frameworks.">
                                <IoMdInformationCircleOutline id="information-icon" />
                            </sup>
                        </span>
                    </div>

                    {/* tabs based on methods */}
                    <div>
                        <Tabs className="companytabs" defaultActiveKey="venturecapital">
                            <Tab eventKey="venturecapital" title="Venture capital">
                                <div className="tab-body">
                                    <Accordion defaultActiveKey="basicvcmethod">
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="basicvcmethod" onClick={() => dispatch({ type: "change basicVCMethod" })}>
                                                <div className="companyarrowicon">
                                                    {state.basicvcmethod
                                                        ? (<FaChevronDown className="arrow-icon" />)
                                                        : (<FaChevronLeft className="arrow-icon" />)
                                                    }
                                                </div>

                                                <div className="companytitlecard">
                                                    Basic venture capital method
                                                </div>

                                                <div className="companyellipsisicon">
                                                    <FaEllipsisH />
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="basicvcmethod">
                                                <BasicVCMethod currentUser={currentUser} />
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                            </Tab>

                            <Tab eventKey="berkus" title="Berkus">
                                <div className="tab-body">
                                    <Accordion defaultActiveKey="berkusmethod">
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="berkusmethod" onClick={() => dispatch({ type: "change berkusMethod" })}>
                                                <div className="companyarrowicon">
                                                    {state.berkusmethod
                                                        ? (<FaChevronDown className="arrow-icon" />)
                                                        : (<FaChevronLeft className="arrow-icon" />)
                                                    }
                                                </div>

                                                <div className="companytitlecard">
                                                    Berkus method
                                                </div>

                                                <div className="companyellipsisicon">
                                                    <FaEllipsisH />
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="berkusmethod">
                                                <BerkusMethod currentUser={currentUser} />
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                            </Tab>

                            <Tab eventKey="scorecard" title="Scorecard">
                                <div className="tab-body">
                                    <Accordion defaultActiveKey="scorecardmethod">
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="scorecardmethod" onClick={() => dispatch({ type: "change scorecardMethod" })}>
                                                <div className="companyarrowicon">
                                                    {state.scorecardmethod
                                                        ? (<FaChevronDown className="arrow-icon" />)
                                                        : (<FaChevronLeft className="arrow-icon" />)
                                                    }
                                                </div>

                                                <div className="companytitlecard">
                                                    Scorecard method
                                                </div>

                                                <div className="companyellipsisicon">
                                                    <FaEllipsisH />
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="scorecardmethod">
                                                <ScorecardMethod currentUser={currentUser} />
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                            </Tab>

                            {/* <Tab eventKey="riskfactorsimulation" title="Risk Factor Simulation">

                                    </Tab> */}
                        </Tabs>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default PreRevenueValuation;