import React from 'react'
import { Form } from 'react-bootstrap'
import AccordianCard from '../../../../../../accordion-card/accordian-card'
import {
  editItemInFieldArray,
  pushItemToFieldArray,
  removeItemFromFieldArray
} from '../../../helpers'
import '../../../pitch-deck-analysis.css'
import FormCellMultiInput from '../../form/form-cell-multi-input'
import ResultsSection from '../results-section'

const CreateEditRisk = props => {
  props = {
    ...props,
    pushItemToFieldArray,
    removeItemFromFieldArray,
    editItemInFieldArray
  }

  return (
    <div className='ideacheckertabbody'>
      <div className='ideacheckerquestions'>
        <AccordianCard
          title='Risk disclosure'
          activeKey='riskDisclosure'
          className='companyaccordion'
        >
          <Form>
            <Form.Group>
              <FormCellMultiInput
                props={props}
                actualName='technologyRisk'
                displayName='Technology risk'
                datatip='Explain how the innovation of your technology might not actually work in the market.'
              />
              <FormCellMultiInput
                props={props}
                actualName='intellectualPropertyRisk'
                displayName='Intellectual property risk'
                datatip='Explain how you might infringe on existing patents or fail to properly protect your own intellectual property.'
              />
              <FormCellMultiInput
                props={props}
                actualName='developmentRisk'
                displayName='Development risk'
                datatip='Explain how you might not be able to develop a scalable product-or-service for your target market.'
              />
              <FormCellMultiInput
                props={props}
                actualName='operationalRisk'
                displayName='Operational risk'
                datatip='Explain how you might not be able to provide the product-or-service at the target quality, cost, and capacity.'
              />
              <FormCellMultiInput
                props={props}
                actualName='productFitRisk'
                displayName='Product-fit risk'
                datatip='Explain how you might not know the size of your market, who your customers are, the speed to capture your target market, and at what price will they pay for your product-or-service.'
              />
              <FormCellMultiInput
                props={props}
                actualName='channelRisk'
                displayName='Channel risk'
                datatip='Explain how you might not know a cost-efficient way to attract customers and sell them your product-or-service.'
              />
              <FormCellMultiInput
                props={props}
                actualName='regulatoryRisk'
                displayName='Regulatory risk'
                datatip='Explain how your business might not be compliant with all or some of the laws and permit regulations.'
              />
              <FormCellMultiInput
                props={props}
                actualName='competitorRisk'
                displayName='Competitor risk'
                datatip='Explain how might an established company launch a similar product-or-service to compete with yours.'
              />
              <FormCellMultiInput
                props={props}
                actualName='teamRisk'
                displayName='Team risk'
                datatip='Explain how your team might not get along and work well together toward the goals.'
              />
              <FormCellMultiInput
                props={props}
                actualName='hiringRisk'
                displayName='Hiring risk'
                datatip='Explain how you might not be able to recruit the people you need at the time you need them.'
              />
              <FormCellMultiInput
                props={props}
                actualName='financingRisk'
                displayName='Financing risk'
                datatip='Explain how you might not be able to raise all the money you need, even in good times.'
              />
              <FormCellMultiInput
                props={props}
                actualName='ceoRisk'
                displayName='CEO risk'
                datatip='Explain how you or your co-founder might not have the leadership quality and management ability of a CEO.'
              />
            </Form.Group>
          </Form>
        </AccordianCard>
      </div>

      <ResultsSection props={props} type='risk' />
    </div>
  )
}

export default CreateEditRisk