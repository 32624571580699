import React, { useState, useEffect } from 'react';
import ActiveInactiveCompanies from './filters/active-inactive-companies.js';
import CoInvestors from './filters/co-investors.js';
import ActiveInvestmentMarkets from './filters/active-investment-markets.js';
import LeadCoInvestments from './filters/lead-co-investments.js';
import TotalAverageInvestments from './filters/total-average-investments.js';
import TotalFollowOnInvestments from './filters/total-follow-on-investments.js';
// import Tour from '../tour/tour';
import NavBar from '../../layout/navbar/navbar';
import './portfolio-analysis.css';
import { FaChevronLeft, FaEllipsisH } from 'react-icons/fa';
import { useRealmApp } from '../../RealmApp'
import { Accordion, Card, Container, Tabs, Tab, Modal, Button, Alert, Spinner } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiAnalyse } from 'react-icons/bi';
import { IoMdInformationCircleOutline, IoMdClose } from 'react-icons/io';
import { useHistory } from 'react-router';

const PortfolioAnalysis = () => {

    // hook for alert component
    const [alert, setAlert] = useState(true);

    // hook for alert message
    const [message, setMessage] = useState(<div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={{ width: "90%" }}>
            <p style={{ color: "#0f9d58" }}><BiAnalyse className="alert-icon" /> Loading</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>Won't be long now. We're always doing our best to reduce waiting time.</p>
            <p style={{ marginLeft: "28px", fontSize: "14px" }}>If your waiting time is too long, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
        </div>
        <div style={{ width: "10%", textAlign: "right" }}>
            <Spinner animation="border" variant="secondary" />
        </div>
    </div>);

    // function to close alert component
    function closeAlert() {
        setAlert(false);
        setMessage("");
    }

    const { currentUser, logInAnonymous } = useRealmApp();

    if (!currentUser) {
        throw new Error(`Must be logged in`);
    }

    const { getDashboardStatistics } = currentUser.functions;

    // hook for dashboard statistics
    const [dashboardStatistics, setDashboardStatistics] = useState({
        portfolioAnalysisCompanies: 0,
        portfolioAnalysisInvestors: 0
    });

    // get dashboard statistics on page load
    useEffect(async () => {
        try {
            let response = await getDashboardStatistics();
            setDashboardStatistics(JSON.parse(response.body));
            // console.log("current stats:", dashboardStatistics);

            closeAlert();
        } catch (err) {
            setMessage(<div style={{ paddingTop: "10px" }}>
                <p style={{ fontSize: "16px", color: "red" }}><AiOutlineCloseCircle className="alert-icon" /> Bad internet issues or server connection errors</p>
                <p style={{ marginLeft: "28px", fontSize: "14px" }}>Sorry, there seems to be poor internet connection or interrupted connectivity issues or server connection errors. Should the issues persist, <a href="https://www.roiquant.com/contact" target="_blank" rel="noopener noreferrer">contact us</a>.</p>
            </div>);
        }
    }, []);

    // ensure user has permission to access this page
    const { getUserTier } = useRealmApp()

    // onHide modal
    const history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }

    return (
        <>

            {/* loading alert */}

            {alert
                ? (<Alert className="error-message floating-alert" variant="light">
                    <div>
                        <div style={{ textAlign: "right" }}><Button className="pitchdeckmessageclose" onClick={closeAlert}><IoMdClose /></Button></div>
                        {message}
                    </div>
                </Alert>)
                : ""
            }

            {/* navbar */}
            <NavBar />

            {/* portfolio analysis */}
            <div id="portfolioanalysis">
                <Container className="col-11" style={{ paddingBottom: "200px" }}>
                    <div className="postmortemanalysisheaderdiv">
                        {/* title and info note */}
                        <div className="postmortemanalysistitlediv">
                            <br />
                            <span className="postmortemanalysistext">
                                Portfolio analysis
                                <sup data-tip="Evaluate the performance and relationships of portfolio investments with our portfolio risk analytics powered by data science.">
                                    <IoMdInformationCircleOutline id="information-icon" />
                                </sup>
                            </span>
                        </div>

                        {/* statistics table */}
                        <div className="postmortemanalysisstatsdiv">
                            <table className="tg" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <td className="table-data db-header">
                                            Portfolio companies
                                        </td>
                                        <td className="table-data db-header">
                                            Investors
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="table-data db-data">
                                            {dashboardStatistics.portfolioAnalysisCompanies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </td>
                                        <td className="table-data db-data">
                                            {dashboardStatistics.portfolioAnalysisInvestors.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <Tabs className="companytabs" defaultActiveKey="investment">

                            <Tab eventKey="investment" title="Investment">
                                <div className="tab-body">
                                    <Accordion>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="activeinvestmentmarkets">
                                                <div className="companyarrowicon">
                                                    <FaChevronLeft className="arrow-icon" />
                                                </div>

                                                <div className="companytitlecard">
                                                    Which markets have the most active investment activities?
                                                </div>

                                                <div className="companyellipsisicon">
                                                    <FaEllipsisH />
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="activeinvestmentmarkets">
                                                <ActiveInvestmentMarkets />
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    <Accordion style={{ marginTop: "10px" }}>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="totalaverageinvestments">
                                                <div className="companyarrowicon">
                                                    <FaChevronLeft className="arrow-icon" />
                                                </div>

                                                <div className="companytitlecard">
                                                    What is the total investment amount?
                                                </div>

                                                <div className="companyellipsisicon">
                                                    <FaEllipsisH />
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="totalaverageinvestments">
                                                <TotalAverageInvestments />
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    <Accordion style={{ marginTop: "10px" }}>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="leadcoinvestments">
                                                <div className="companyarrowicon">
                                                    <FaChevronLeft className="arrow-icon" />
                                                </div>

                                                <div className="companytitlecard">
                                                    How many are lead and co-investments?
                                                </div>

                                                <div className="companyellipsisicon">
                                                    <FaEllipsisH />
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="leadcoinvestments">
                                                <LeadCoInvestments />
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    <Accordion style={{ marginTop: "10px" }}>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="activeinactivecompanies">
                                                <div className="companyarrowicon">
                                                    <FaChevronLeft className="arrow-icon" />
                                                </div>

                                                <div className="companytitlecard">
                                                    How many active and inactive companies?
                                                </div>

                                                <div className="companyellipsisicon">
                                                    <FaEllipsisH />
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="activeinactivecompanies">
                                                <ActiveInactiveCompanies />
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    <Accordion style={{ marginTop: "10px" }}>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="totalfollowoninvestments">
                                                <div className="companyarrowicon">
                                                    <FaChevronLeft className="arrow-icon" />
                                                </div>

                                                <div className="companytitlecard">
                                                    How many follow-on investments were committed?
                                                </div>

                                                <div className="companyellipsisicon">
                                                    <FaEllipsisH />
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="totalfollowoninvestments">
                                                {
                                                    !getUserTier() || getUserTier() < 2
                                                        ? (<Modal className="modal-div" show="true" onHide={goToPreviousPath} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
                                                            <Modal.Header closeButton style={{ borderBottom: "none" }} />
                                                            <Modal.Body>
                                                                <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Get started with Standard Plan at USD $7 per month</p>

                                                                <p style={{ textAlign: "left" }}>Evaluate your portfolio performance while assessing your risks and returns.</p>

                                                                <div style={{ textAlign: "center" }}>
                                                                    <a href="https://www.roiquant.com/pricing" target="_blank" rel="noopener noreferrer">
                                                                        <Button className="modal-compare glintanimation">Compare plans</Button>
                                                                    </a>
                                                                </div>

                                                                <hr style={{ margin: "1.5rem 0" }} />

                                                                <p style={{ fontWeight: "bold", marginBottom: "0" }}>Things to know:</p>

                                                                <ul className="modal-list">
                                                                    <li>Downgrade your subscription plan at any time</li>
                                                                    <li>Once payment is made, there is no refund</li>
                                                                    <li>If you downgrade or cancel, you will no longer have access to the features that are only available in the subscription plan which you downgraded or cancelled from</li>
                                                                </ul>

                                                                <div style={{ textAlign: "center" }}>
                                                                    <a href="https://www.roiquant.com/venture-intel/portfolio-analysis" target="_blank" rel="noopener noreferrer">
                                                                        <Button className="modal-product glintanimation">Learn more about the product</Button>
                                                                    </a>
                                                                </div>
                                                            </Modal.Body>
                                                        </Modal>)
                                                        : (<TotalFollowOnInvestments />)
                                                }
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                            </Tab>

                            <Tab eventKey="co-investors" title="Co-investors">
                                <div className="tab-body">
                                    <Accordion>
                                        <Card className="card-tour-co-investor">
                                            <Accordion.Toggle as={Card.Header} className="companycardheader" eventKey="coinvestors">
                                                <div className="companyarrowicon">
                                                    <FaChevronLeft className="arrow-icon" />
                                                </div>

                                                <div className="companytitlecard">
                                                    With whom do investors commonly co-invest with?
                                                </div>

                                                <div className="companyellipsisicon">
                                                    <FaEllipsisH />
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="coinvestors">
                                                <CoInvestors />
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                            </Tab>

                            <Tab eventKey="partners" title="Partners" disabled>

                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default PortfolioAnalysis;