export const lifespans = [
    { "key": "Less than 1 year" },
    { "key": "1 to 3 years" },
    { "key": "3 to 5 years" },
    { "key": "5 to 7 years" },
    { "key": "7 to 10 years" },
    { "key": "10 to 15 years" },
    { "key": "15 to 20 years" },
    { "key": "20 to 30 years" },
    { "key": "More than 30 years" }
];