import React from 'react';
import { Card } from 'react-bootstrap';
import '../data-contribution/data-contribution.css';

function Financials(props) {

    return (
        <>
            <Card style={{ marginTop: "40px" }}>
                <Card.Body id="companyinformation">
                    <div>
                        <p className="companydetailtitle">Co-investors</p>
                        <p className="companydetaildata">{!props?.coInvestors ? "-" : props.coInvestors.join(", ")}</p>
                    </div>

                    <div>
                        <p className="companydetailtitle">Portfolio companies</p>
                        <p className="companydetaildata">{!props?.portfolioCompanies
                            ? "-"
                            : props.portfolioCompanies.map((element, index) => (
                                <span key={index} className="pitchdeckanswers">{element.name}, </span>
                            ))
                        }</p>
                    </div>

                    <div>
                        <p className="companydetailtitle">Shuttered portfolio companies</p>
                        <p className="companydetaildata">{!props?.shutteredPortfolioCompanies ? "-" : props.shutteredPortfolioCompanies.join(", ")}</p>
                    </div>
                </Card.Body>
            </Card>

            <Card style={{ marginTop: "40px" }}>
                <Card.Body id="companyinformation">
                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Total amount raised</p>
                            <p className="companydetaildata">{!props?.totalAmountRaised ? "-" : props.totalAmountRaised}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">Loss on investments</p>
                            <p className="companydetaildata">{!props?.lossOnInvestments ? "-" : props.lossOnInvestments}</p>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <Card style={{ marginTop: "40px" }}>
                <Card.Body id="companyinformation">
                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">No. of investments</p>
                            <p className="companydetaildata">{!props?.numOfInvestments ? "-" : props.numOfInvestments}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">No. of lead investments</p>
                            <p className="companydetaildata">{!props?.numOfLeadInvestments ? "-" : props.numOfLeadInvestments}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">No. of co-investments</p>
                            <p className="companydetaildata">{!props?.numOfCoInvestments ? "-" : props.numOfCoInvestments}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">No. of follow-on investments</p>
                            <p className="companydetaildata">{!props?.numOfFollowOnInvestments ? "-" : props.numOfFollowOnInvestments}</p>
                        </div>
                    </div>

                    <div className="companydetailrows">
                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">No. of failed investments</p>
                            <p className="companydetaildata">{!props?.numOfFailedInvestments ? "-" : props.numOfFailedInvestments}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">No. of exits</p>
                            <p className="companydetaildata">{!props?.numOfExits ? "-" : props.numOfExits}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">No. of acquisitions</p>
                            <p className="companydetaildata">{!props?.numOfAcquisitions ? "-" : props.numOfAcquisitions}</p>
                        </div>

                        <div className="pitchdeckdetaildiv">
                            <p className="companydetailtitle">No. of organizations founded</p>
                            <p className="companydetaildata">{!props?.numOfOrganizationsFounded ? "-" : props.numOfOrganizationsFounded}</p>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            {!props?.investments || props.investments.length === 0
                ? ""
                : <Card style={{ marginTop: "40px" }}>
                    <Card.Body id="companyinformation">
                        <div>
                            <p className="companydetailtitle">Investments</p>
                            <p className="companydetaildata">
                                <ul>{props.investments.map((element, index) =>
                                    <>
                                        <li className="pitchdeckanswers" key={index}>{!element.name ? "-" : element.name}: {!element.totalRaised ? "-" : element.totalRaised.currency + " " + element.totalRaised.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</li>
                                        {!element.rounds || element.rounds.length === 0
                                            ? ""
                                            : <ol key={index}>
                                                {element.rounds.map((subElement, index) =>
                                                    <>
                                                        <li className="pitchdeckanswers" key={index}>{!subElement.round ? "-" : subElement.round}</li>
                                                        <ul>
                                                            <li className="pitchdeckanswers">Date: {!subElement.date ? "-" : subElement.date}</li>
                                                            <li className="pitchdeckanswers">Amount: {!subElement.amount ? "-" : subElement.amount}</li>
                                                            <li className="pitchdeckanswers">Co-investors: {!subElement.coInvestors || subElement.coInvestors.length === 0
                                                                ? "-"
                                                                : subElement.coInvestors.map((subElement2, index) => <span key={index}>{subElement2.name}, </span>
                                                                )}
                                                            </li>
                                                        </ul>
                                                    </>
                                                )}
                                            </ol>
                                        }
                                    </>
                                )}</ul>
                            </p>
                        </div>
                    </Card.Body>
                </Card>
            }
        </>
    );
}

export default Financials