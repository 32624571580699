import React from 'react'

const FinancialTableRowHeaderCenter = ({ displayName }) => {
  return (
    <tr className='financialstatementstablerowbreak'>
      <th colSpan={5} className='financialstatementstabledatalast'>
        {displayName}
      </th>
    </tr>
  )
}

export default FinancialTableRowHeaderCenter