import { Button, Form } from "react-bootstrap";
import {
  IoMdAdd,
  IoMdClose,
  IoMdInformationCircleOutline,
} from "react-icons/io";

const FormCellMultiInput = ({
  props,
  actualName,
  displayName,
  datatip = "",
}) => {
  const {
    pushItemToFieldArray,
    removeItemFromFieldArray,
    editItemInFieldArray,
  } = props;

  return (
    <div className="ideacheckerrow">
      <div>
        <Form.Label>
          {displayName}
          {datatip && (
            <sup data-html={true} data-tip={datatip}>
              <IoMdInformationCircleOutline id="information-icon" />
            </sup>
          )}
        </Form.Label>
        {props.state[actualName].map((x, i) => {
          return (
            <div key={i} style={{ display: "flex", marginTop: i !== 0 && "10px" }}>
              <div
                style={{
                  display: "flex",
                  border: "1px solid #ced4da",
                  borderRadius: "0.25rem",
                  width: "100%",
                }}
              >
                <Form.Control
                  name={actualName}
                  value={props.state[actualName][i]}
                  placeholder=""
                  onChange={(e) =>
                    editItemInFieldArray(props, actualName, e.target.value, i)
                  }
                  style={{ borderColor: "transparent" }}
                />
                {props.state[actualName].length !== 1 && (
                  <Button
                    className="pitchdeckaddfield"
                    onClick={() =>
                      removeItemFromFieldArray(props, actualName, i)
                    }
                  >
                    <IoMdClose />
                  </Button>
                )}
              </div>
              {props.state[actualName].length - 1 === i && (
                <Button
                  className="pitchdeckaddfield"
                  onClick={() => pushItemToFieldArray(props, actualName, "")}
                >
                  <IoMdAdd />
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FormCellMultiInput;